import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const translationEn = {
  "LOGIN": "LOGIN",
  "REGISTER": "REGISTER",
  "SKIP TO PAYMENT": "SKIP TO PAYMENT",
  "WELCOME": "WELCOME",
  "Error": "Error",
  "Please enter the correct phone number format. <br /><br /> <small>Example: 0129465628</small>": "Please enter the correct phone number format. <br /><br /> <small>Example: 0129465628</small>",
  "Info": "Info",
  "Please scan QRCode to Login": "Please scan QRCode to Login",
  "Forgot Password": "Forgot Password",
  "Seems like you have forgotten your password<br />Create a temporary password?": "Seems like you have forgotten your password<br />Create a temporary password?",
  "Cancel": "Cancel",
  "Confirm": "Confirm",
  "Reset Your Password": "Reset Your Password",
  "Enter Your Phone Number / Email Address": "Enter Your Phone Number / Email Address",
  "Please enter phone number / email address": "Please enter phone number / email address",
  "Submit": "Submit",
  "Please enter a valid phone number": "Please enter a valid phone number",
  "Please enter a valid email address": "Please enter a valid email address",
  "Success!": "Success!",
  "You may login using your one time password now.": "You may login using your one time password now.",
  "Phone Number": "Phone Number",
  "Password": "Password",
  "Don't have an account?": "Don't have an account?",
  "Forgot password?": "Forgot password?",
  "Unable to register without a QRCode": "Unable to register without a QRCode",
  "Please read and agree to the privacy policies, terms and conditions": "Please read and agree to the privacy policies, terms and conditions",
  "Password length must be at least 6 characters": "Password length must be at least 6 characters",
  "Please enter the confirmation code that was sent to your phone": "Please enter the confirmation code that was sent to your phone",
  "Example: 012xxxxxxx": "Example: 012xxxxxxx",
  "Example: 09xxxxxxxx": "Example: 09xxxxxxxx",
  "Verification Method": "Verification Method",
  "Name": "Name",
  "Email": "Email",
  "optional": "optional",
  "Birth Year": "Birth Year",
  "Year": "Year",
  "Birth Month": "Birth Month",
  "Month": "Month",
  "Jan": "Jan",
  "Feb": "Feb",
  "Mar": "Mar",
  "April": "April",
  "May": "May",
  "June": "June",
  "July": "July",
  "Aug": "Aug",
  "Sept": "Sept",
  "Oct": "Oct",
  "Nov": "Nov",
  "Dec": "Dec",
  "Birth Day": "Birth Day",
  "Day": "Day",
  "OTP": "OTP",
  "OTP Confirmation": "OTP Confirmation",
  "I agree to the": "I agree to the",
  "Privacy Policy": "Privacy Policy",
  "and": "and",
  "Terms": "Terms",
  "Conditions": "Conditions",
  "SEND CODE": "SEND CODE",
  "Our One-Time Password (OTP) emails might end up in your spam or junk folder. We kindly ask you to take a moment to check your spam mailbox.": "Our One-Time Password (OTP) emails might end up in your spam or junk folder. We kindly ask you to take a moment to check your spam mailbox.",
  "Already have an account? Login": "Already have an account? Login",
  "Didn't receive a code?": "Didn't receive a code?",
  "Resend Code": "Resend Code",
  "collected": "collected",
  "Collect": "Collect",
  "stamps": "stamps",
  "get 1 voucher": "get 1 voucher",
  "Minimum": "Minimum",
  "spending to collect each washer stamp": "spending to collect each washer stamp",
  "spending to collect each dryer stamp": "spending to collect each dryer stamp",
  "spending to collect each stamp": "spending to collect each stamp",
  "Device don't have a camera": "Device don't have a camera",
  "This is not a valid QRCode": "This is not a valid QRCode",
  "Select Camera": "Select Camera",
  "Switch": "Switch",
  "Camera": "Camera",
  "Loading": "Loading",
  "Scan the QR code on the machine.": "Scan the QR code on the machine.",
  "Switch Camera": "Switch Camera",
  "Off torch light": "Off torch light",
  "On torch light": "On torch light",
  "switch camera if your camera is not clear": "switch camera if your camera is not clear",
  "Switch your camera if the display is not clear": "Switch your camera if the display is not clear",
  "Please allow this webapp to access your camera": "Please allow this webapp to access your camera",
  "DISCOUNT": "DISCOUNT",
  "Congratulation": "Congratulation",
  "Expired Date": "Expired Date",
  "Voucher": "Voucher",
  "Vouchers": "Vouchers",
  "Something went wrong": "Something went wrong",
  "Stamping Collection": "Stamping Collection",
  "Total e-Token": "Total e-Token",
  "Total Wash": "Total Wash",
  "Total Dry": "Total Dry",
  "Reload": "Reload",
  "Washer": "Washer",
  "Dryer": "Dryer",
  "Congratulations!": "Congratulations!",
  "You have completed your Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "You have completed your Stamp collection.<br />Granted a voucher and stamp collection is refreshed",
  "You have completed Washer Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "You have completed Washer Stamp collection.<br />Granted a voucher and stamp collection is refreshed",
  "You have completed Dryer Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "You have completed Dryer Stamp collection.<br />Granted a voucher and stamp collection is refreshed",
  "Latest News": "Latest News",
  "Transaction History": "Transaction History",
  "Stamping": "Stamping",
  "Transaction": "Transaction",
  "News": "News",
  "Confirmation": "Confirmation",
  "Logout Session?": "Logout Session?",
  "NO": "NO",
  "OK": "OK",
  "Account": "Account",
  "FAQs": "FAQs",
  "Feedback": "Feedback",
  "LOGOUT": "LOGOUT",
  "Logout": "Logout",
  "Are you sure you want to submit your feedback?": "Are you sure you want to submit your feedback?",
  "No": "No",
  "Yes": "Yes",
  "Success": "Success",
  "Failed": "Failed",
  "Pending": "Pending",
  "Your feedback is submitted successfully": "Your feedback is submitted successfully",
  "Please fill in all the required fields before submit.": "Please fill in all the required fields before submit.",
  "Give us your feedback!": "Give us your feedback!",
  "Type your name here (optional)": "Type your name here (optional)",
  "required": "required",
  "Type your number here": "Type your number here",
  "Outlet": "Outlet",
  "Select an outlet": "Select an outlet",
  "Outlet Cleanliness": "Outlet Cleanliness",
  "Washer Machine Performance": "Washer Machine Performance",
  "Dryer Machine Performance": "Dryer Machine Performance",
  "Customer Service": "Customer Service",
  "Comment": "Comment",
  "Type your comment here (optional)": "Type your comment here (optional)",
  "SUBMIT": "SUBMIT",
  "Join as a member now!": "Join as a member now!",
  "Join as a loyalty member now to get more benefits such as vouchers and free machine usage!<br /><br /><strong>Register as loyalty member?</strong>": "Join as a loyalty member now to get more benefits such as vouchers and free machine usage!<br /><br /><strong>Register as loyalty member?</strong>",
  "Maybe Next Time": "Maybe Next Time",
  "Yes, Register Now": "Yes, Register Now",
  "Washer Payment": "Washer Payment",
  "Dryer Payment": "Dryer Payment",
  "Vending Machine Payment": "Vending Machine Payment",
  "Online": "Online",
  "Offline": "Offline",
  "Running": "Running",
  "Vacant": "Vacant",
  "Vending Machine": "Vending Machine",
  "Discount": "Discount",
  "No Vouchers Available": "No Vouchers Available",
  "Payment Method": "Payment Method",
  "Would you like to pay with?": "Would you like to pay with?",
  "e-Token": "e-Token",
  "E-TOKEN": "E-TOKEN",
  "E-Wallet": "E-Wallet",
  "Duitnow QR code": "Duitnow QR code",
  "The Duitnow QR code will be downloaded and you need to open your banking app to make the payment. Pay {{ currency }}{{ totalPaymentAmount }} to {{ machineName }}?": "The Duitnow QR code will be downloaded and you need to open your banking app to make the payment. Pay {{ currency }}{{ totalPaymentAmount }} to {{ machineName }}?",
  "Pay": "Pay",
  "to": "to",
  "You may under utilized the voucher.": "You may under utilized the voucher.",
  "Proceed to pay": "Proceed to pay",
  "Processing ...": "Processing ...",
  "You can't pay with e-token if voucher is in used, please remove your voucher to pay with e-token.": "You can't pay with e-token if voucher is in used, please remove your voucher to pay with e-token.",
  "Insufficient e-Tokens": "Insufficient e-Tokens",
  "Reload your e-token now?": "Reload your e-token now?",
  "Reload now!": "Reload now!",
  "You have": "You have",
  "e-Tokens": "e-Tokens",
  "Tokens to": "Tokens to",
  "This machine is currently offline, please try again later.": "This machine is currently offline, please try again later.",
  "MACHINE": "MACHINE",
  "Machine": "Machine",
  "TYPE": "TYPE",
  "CAPACITY": "CAPACITY",
  "DURATION": "DURATION",
  "Select Duration": "Select Duration",
  "AMOUNT TO PAY": "AMOUNT TO PAY",
  "PRICE": "PRICE",
  "VOUCHER": "VOUCHER",
  "Select Voucher >": "Select Voucher >",
  "TOTAL": "TOTAL",
  "PAY": "PAY",
  "Remove Voucher": "Remove Voucher",
  "Select Your Voucher": "Select Your Voucher",
  "min": "min",
  "MIN": "MIN",
  "Select Payment Amount": "Select Payment Amount",
  "Select Water Temperature": "Select Water Temperature",
  "Select temperature": "Select temperature",
  "Cold": "Cold",
  "Warm": "Warm",
  "Hot": "Hot",
  "e-Tokens to": "e-Tokens to",
  "No Purchase History": "No Purchase History",
  "e-tokens": "e-tokens",
  "No Promotions": "No Promotions",
  "New Password": "New Password",
  "Back": "Back",
  "Buy this package?": "Buy this package?",
  "Purchase": "Purchase",
  "e-Tokens for": "e-Tokens for",
  "Reload Tokens": "Reload Tokens",
  "Are you sure you want to purchase {{ tokenAmount }} e-Token for {{ currency }}{{ paymentAmount }}?": "Are you sure you want to purchase {{ tokenAmount }} e-Token for {{ currency }}{{ paymentAmount }}?",
  "Current e-Token": "Current e-Token",
  "History": "History",
  "Your profile picture has been updated!": "Your profile picture has been updated!",
  "Please only use png, jpg or jpeg file extension type": "Please only use png, jpg or jpeg file extension type",
  "Please only use image type file": "Please only use image type file",
  "User name has updated successfully!": "User name has updated successfully!",
  "Email Address updated successfully": "Email Address updated successfully",
  "Phone number updated successfully": "Phone number updated successfully",
  "Password must be at least 6 characters": "Password must be at least 6 characters",
  "Password updated successfully": "Password updated successfully",
  "User Settings": "User Settings",
  "Profile Picture": "Profile Picture",
  "Change Password": "Change Password",
  "Do you want to submit your feedback?": "Do you want to submit your feedback?",
  "Thank You!": "Thank You!",
  "Thank you for using our services.": "Thank you for using our services.",
  "BACK TO HOMEPAGE": "BACK TO HOMEPAGE",
  "How to pay?": "How to pay?",
  "Step 1": "Step 1",
  "After scan the QR code on the machine, select either register new account, login your account or skip to payment.": "After scan the QR code on the machine, select either register new account, login your account or skip to payment.",
  "Step 2-1": "Step 2-1",
  "For Dryer – Select your desired duration. Different duration will have different prices.": "For Dryer – Select your desired duration. Different duration will have different prices.",
  "Step 2-2": "Step 2-2",
  "For Washer – Select your desired water temperature. Different water temperature will have different prices.": "For Washer – Select your desired water temperature. Different water temperature will have different prices.",
  "Step 3": "Step 3",
  "For login user, Click on ‘Select Voucher’ to select your voucher.": "For login user, Click on ‘Select Voucher’ to select your voucher.",
  "Step 4": "Step 4",
  "Click on ‘PAY’ button to pay. For login user, select your payment method to pay.": "Click on ‘PAY’ button to pay. For login user, select your payment method to pay.",
  "Step 5": "Step 5",
  "After confirm the price and machine details, click on ‘OK’ button to pay.": "After confirm the price and machine details, click on ‘OK’ button to pay.",
  "How to reload your e-Token?": "How to reload your e-Token?",
  'Click on the "Reload" button.': 'Click on the "Reload" button.',
  "Select the package that you wish to purchase.": "Select the package that you wish to purchase.",
  'Click on "Purchase" as confirmation before payment page.': 'Click on "Purchase" as confirmation before payment page.',
  "Is e-token can be refund/transfer back to e-wallet?": "Is e-token can be refund/transfer back to e-wallet?",
  "Why can't I applied my voucher?": "Why can't I applied my voucher?",
  "When there is a Happy Hour Promotion is on going, you are unable to apply your voucher temporarily.": "When there is a Happy Hour Promotion is on going, you are unable to apply your voucher temporarily.",
  "How to apply my voucher?": "How to apply my voucher?",
  'Click on the "Select Voucher" button.': 'Click on the "Select Voucher" button.',
  "Select the voucher that you wish to apply.": "Select the voucher that you wish to apply.",
  'Click on "Pay" once you have verified the voucher is successfully applied.': 'Click on "Pay" once you have verified the voucher is successfully applied.',
  "Step 2": "Step 2",
  "Language has updated successfully!": "Language has updated successfully!",
  "Change Language": "Change Language",
  "Language": "Language",
  "Select language": "Select language",
  "Referral Code": "Referral Code",
  "Copied!": "Copied!",
  "Inviting friends to get more benefits!": "Inviting friends to get more benefits!",
  "Would you like to share your referral code to your friends & family?": "Would you like to share your referral code to your friends & family?",
  "Refer a friend and get": "Refer a friend and get",
  "Share the love to your friends & family?": "Share the love to your friends & family?",
  "voucher": "voucher",
  "token": "token",
  "stamp": "stamp",
  "washer stamp": "washer stamp",
  "dryer stamp": "dryer stamp",
  "Learn More": "Learn More",
  "Welcome Gift": "Welcome Gift",
  "Share Your Referral Code Now": "Share Your Referral Code Now",
  "Birthday Gift for You": "Birthday Gift for You",
  "Let's Pay with E-Token": "Let's Pay with E-Token",
  "Collect 10 Stamps and Get Voucher": "Collect 10 Stamps and Get Voucher",
  "The voucher will be given if you registered your account successfully.": "The voucher will be given if you registered your account successfully.",
  "There is no expiration date for this voucher.": "There is no expiration date for this voucher.",
  "The voucher can be used for dryer machine only.": "The voucher can be used for dryer machine only.",
  "The voucher can be used for washer machine only.": "The voucher can be used for washer machine only.",
  "The voucher can be used for Drop n Go transaction only.": "The voucher can be used for Drop n Go transaction only.",
  "The voucher can be used for washer machine or Drop n Go transaction.": "The voucher can be used for washer machine or Drop n Go transaction.",
  "The voucher can be used for dryer machine or Drop n Go transaction.": "The voucher can be used for dryer machine or Drop n Go transaction.",
  "The voucher can be used for dryer or washer machine.": "The voucher can be used for dryer or washer machine.",
  "The voucher can be used for washer machine, dryer machine or Drop n Go transaction.": "The voucher can be used for washer machine, dryer machine or Drop n Go transaction.",
  "When you applied the voucher during payment, it will give you": "When you applied the voucher during payment, it will give you",
  "discount.": "discount.",
  "When promotion is on going, the voucher is not applicable.": "When promotion is on going, the voucher is not applicable.",
  "The voucher cannot be used if your payment method is E-Token.": "The voucher cannot be used if your payment method is E-Token.",
  "Your referral code can be found in user settings page.": "Your referral code can be found in user settings page.",
  "You can get": "You can get",
  "stamp for the reward.": "stamp for the reward.",
  "e-token for the reward.": "e-token for the reward.",
  "e-token based on transaction payment amount for the reward.": "e-token based on transaction payment amount for the reward.",
  "for the reward.": "for the reward.",
  "based on transaction payment amount for the reward.": "based on transaction payment amount for the reward.",
  "You can get a voucher for the reward": "You can get a voucher for the reward",
  "which have": "which have",
  "it can be used for dryer machine only.": "it can be used for dryer machine only.",
  "it can be used for washer machine only.": "it can be used for washer machine only.",
  "it can be used for Drop n Go transaction only.": "it can be used for Drop n Go transaction only.",
  "it can be used for dryer or washer machine.": "it can be used for dryer or washer machine.",
  "it can be used for dryer machine or Drop n Go transaction.": "it can be used for dryer machine or Drop n Go transaction.",
  "it can be used for washer machine or Drop n Go transaction.": "it can be used for washer machine or Drop n Go transaction.",
  "it can be used for washer machine, dryer machine or Drop n Go transaction.": "it can be used for washer machine, dryer machine or Drop n Go transaction.",
  "washer stamp for the reward.": "washer stamp for the reward.",
  "dryer stamp for the reward.": "dryer stamp for the reward.",
  "washer stamp and": "washer stamp and",
  "cleanproBirthdayShortDesc_one": "Redeem {{ birthdayVoucherAmount }} credit birthday voucher on your special day with Cleanpro Plus. Tap to learn more.",
  "cleanproBirthdayShortDesc_other": "Redeem {{ birthdayVoucherAmount }} credits birthday voucher on your special day with Cleanpro Plus. Tap to learn more.",
  'When you click on "PAY" button on the payment page, you can choose either using E-wallet or E-token to pay.': 'When you click on "PAY" button on the payment page, you can choose either using E-wallet or E-token to pay.',
  "When you using your voucher during payment, you will be unable to pay with E-token.": "When you using your voucher during payment, you will be unable to pay with E-token.",
  "You can reload your E-token by clicking the reload button in the stamping page and select the package to purchase.": "You can reload your E-token by clicking the reload button in the stamping page and select the package to purchase.",
  "You can reload your E-token by clicking the reload button in the home page and select the package to purchase.": "You can reload your E-token by clicking the reload button in the home page and select the package to purchase.",
  "You can view your E-token reload history and E-token transaction history in transaction page.": "You can view your E-token reload history and E-token transaction history in transaction page.",
  "You can view your E-token transaction history in transaction page.": "You can view your E-token transaction history in transaction page.",
  "Using either dryer machine or washer machine with the minimum spending of": "Using either dryer machine or washer machine with the minimum spending of",
  "to collect one stamp.": "to collect one stamp.",
  "The voucher will be given if you have collected 10 stamps.": "The voucher will be given if you have collected 10 stamps.",
  "Using dryer machine with the minimum spending of": "Using dryer machine with the minimum spending of",
  "Using washer machine with the minimum spending of": "Using washer machine with the minimum spending of",
  "to collect one dryer stamp.": "to collect one dryer stamp.",
  "to collect one washer stamp.": "to collect one washer stamp.",
  "The voucher will be given if you have collected 10 dryer stamps or 10 washer stamps.": "The voucher will be given if you have collected 10 dryer stamps or 10 washer stamps.",
  "For the voucher given by the dryer stamps,": "For the voucher given by the dryer stamps,",
  "For the voucher given by the washer stamps,": "For the voucher given by the washer stamps,",
  "When promotion is on going, all the vouchers is not applicable.": "When promotion is on going, all the vouchers is not applicable.",
  "All the vouchers cannot be used if your payment method is E-Token.": "All the vouchers cannot be used if your payment method is E-Token.",
  "Description": "Description",
  "Refer Friends": "Refer Friends",
  "View our guideline": "View our guideline",
  "When a friend / family register using your referral code, and completed a transaction with a minimum spending of": "When a friend / family register using your referral code, and completed a transaction with a minimum spending of",
  ", you will be rewarded automatically.": ", you will be rewarded automatically.",
  "One user only allow to have": "One user only allow to have",
  "referrals.": "referrals.",
  "Confirmation Password": "Confirmation Password",
  "Password and confirmation password should be same.": "Password and confirmation password should be same.",
  "You haven't applied your voucher": "You haven't applied your voucher",
  "Would you like to continue your payment without using your voucher?": "Would you like to continue your payment without using your voucher?",
  "Continue": "Continue",
  "Apply voucher": "Apply voucher",
  "Transfer": "Transfer",
  "Transfer Tokens": "Transfer Tokens",
  "Please fill in the phone number": "Please fill in the phone number",
  "Please fill in the email": "Please fill in the email",
  "Please enter the phone number / email that you want to transfer to": "Please enter the phone number / email that you want to transfer to",
  "Please fill in the transfer amount": "Please fill in the transfer amount",
  "Transfer amount should be more than 0 and less than your current e-Token": "Transfer amount should be more than 0 and less than your current e-Token",
  "Please enter your password": "Please enter your password",
  "Member ID": "Member ID",
  "E-Token Transfer Amount": "E-Token Transfer Amount",
  "Please confirm the details before proceeding": "Please confirm the details before proceeding",
  "Transfer From": "Transfer From",
  "Transfer To": "Transfer To",
  "Transfer Amount": "Transfer Amount",
  "Transfer Successfully!": "Transfer Successfully!",
  "Transfer Time": "Transfer Time",
  "Token": "Token",
  "Done": "Done",
  "Next": "Next",
  "Enter Password": "Enter Password",
  "Transfer E-Token Amount": "Transfer E-Token Amount",
  "User not found": "User not found",
  "Password is incorrect": "Password is incorrect",
  "Please go to your account setting and reset your password": "Please go to your account setting and reset your password",
  "Only up to last 3 months transaction history": "Only up to last 3 months transaction history",
  "Reload History": "Reload History",
  "Transfer History": "Transfer History",
  "{{ point }} History": "{{ point }} History",
  "Only up to last 3 months reload history": "Only up to last 3 months reload history",
  "Only up to last 3 months transfer history": "Only up to last 3 months transfer history",
  "Only up to last 3 months {{ point }} history": "Only up to last 3 months {{ point }} history",
  "If require urgent response, please contact us through whatapp": "If require urgent response, please contact us through whatapp",
  "Please scan the QR code again": "Please scan the QR code again",
  "Home": "Home",
  "Quick": "Quick",
  "Super": "Super",
  "Premium": "Premium",
  "All users will have infinite referrals.": "All users will have infinite referrals.",
  "Collect 10 stamps to get 1 Voucher": "Collect 10 stamps to get 1 Voucher",
  "The voucher is only for customer use only, not for sale.": "The voucher is only for customer use only, not for sale.",
  "Outlet is currently offline, please try again later": "Outlet is currently offline, please try again later",
  "The voucher will be given if you completed a transaction with a minimum spending of": "The voucher will be given if you completed a transaction with a minimum spending of",
  "The birthday voucher will be given on the first day of your birthday month.": "The birthday voucher will be given on the first day of your birthday month.",
  "The voucher only will be given if you have exceed spending amount of": "The voucher only will be given if you have exceed spending amount of",
  "Pay with KiplePay": "Pay with KiplePay",
  "Pay with Scan2Pay": "Pay with Scan2Pay",
  "Pay with Razer": "Pay with Razer",
  "Pay with HitPay": "Pay with HitPay",
  "Pay with Duitnow QR": "Pay with Duitnow QR",
  "Pay with PagBank": "Pay with PagBank",
  "DISCOUNT CODE": "DISCOUNT CODE",
  "Enter code >": "Enter code >",
  "Enter Discount Code": "Enter Discount Code",
  "Remove Code": "Remove Code",
  "Discount Code": "Discount Code",
  "Close": "Close",
  "Please fill in your email address": "Please fill in your email address",
  "Notification": "Notification",
  "Do you want to receive notification when wash cycle done?": "Do you want to receive notification when wash cycle done?",
  "pay extra": "pay extra",
  "Email Address": "Email Address",
  "Please fill in your phone number": "Please fill in your phone number",
  "Invalid Phone Number": "Invalid Phone Number",
  "Washing Complete": "Washing Complete",
  "Drying Complete": "Drying Complete",
  "The voucher will be given if you registered your account successfully, but it only can be used when your total transaction spending reach": "The voucher will be given if you registered your account successfully, but it only can be used when your total transaction spending reach",
  "and above.": "and above.",
  "This voucher is not applicable": "This voucher is not applicable",
  "Finish in": "Finish in",
  "Need to refresh to update": "Need to refresh to update",
  "It's indicative and may change based on washer load": "It's indicative and may change based on washer load",
  "We'll let you know when it's ready to pick up": "We'll let you know when it's ready to pick up",
  "FREE": "FREE",
  "Enter voucher code (if got any)": "Enter voucher code (if got any)",
  "Please fill in the voucher code": "Please fill in the voucher code",
  "Available On": "Available On",
  "Apply": "Apply",
  "Enter a code": "Enter a code",
  "Input Promo Code": "Input Promo Code",
  "Voucher is redeemed successfully!": "Voucher is redeemed successfully!",
  "Not available": "Not available",
  "Unable to register": "Unable to register",
  "Drop n Go Service": "Drop n Go Service",
  "Select Mode": "Select Mode",
  "Quantity": "Quantity",
  "Pack": "Pack",
  "Add more": "Add more",
  "Select Size": "Select Size",
  "Select Services": "Select Services",
  "Are you sure to delete the item?": "Are you sure to delete the item?",
  "Delete": "Delete",
  "Your Duitnow QR code": "Your Duitnow QR code",
  "How to pay using Duitnow QR code?": "How to pay using Duitnow QR code?",
  "Launch": "Launch",
  "Launch your preferred Mobile Banking or eWallet app.": "Launch your preferred Mobile Banking or eWallet app.",
  "Scan QR code from Gallery": "Scan QR code from Gallery",
  "Choose DuitNow QR from your gallery.": "Choose DuitNow QR from your gallery.",
  "Confirm payment details and pay.": "Confirm payment details and pay.",
  "Receive payment confirmation.": "Receive payment confirmation.",
  "expiring on": "expiring on",
  "based on transaction payment amount": "based on transaction payment amount",
  "Join as our": "Join as our",
  "Loyalty Member": "Loyalty Member",
  "now to get more reward": "now to get more reward",
  "Payment amount should be greater than 0": "Payment amount should be greater than 0",
  // admin portal
  "Dashboard": "Dashboard",
  "Report": "Report",
  "Store Performance": "Store Performance",
  "User Performance": "User Performance",
  "Voucher Redemption": "Voucher Redemption",
  "Token Report": "Token Report",
  "SMS Report": "SMS Report",
  "Discount Code Report": "Discount Code Report",
  "User Details": "User Details",
  "User Compensation": "User Compensation",
  "Voucher Settings": "Voucher Settings",
  "Token Settings": "Token Settings",
  "Referral Settings": "Referral Settings",
  "Voucher Code": "Voucher Code",
  "Customize Color": "Customize Color",
  "User Migration": "User Migration",
  "Back To": "Back To",
  "Eligibility": "Eligibility",
  "If ticked, the voucher will have expired date": "If ticked, the voucher will have expired date",
  "Expiry": "Expiry",
  "If ticked, the voucher can apply to washer machine transaction": "If ticked, the voucher can apply to washer machine transaction",
  "Washer Use": "Washer Use",
  "If ticked, the voucher can apply to dryer machine transaction": "If ticked, the voucher can apply to dryer machine transaction",
  "Dryer Use": "Dryer Use",
  "If ticked, the voucher can apply to Drop n Go transaction": "If ticked, the voucher can apply to Drop n Go transaction",
  "Package Use": "Package Use",
  "Voucher Lifespan": "Voucher Lifespan",
  "days": "days",
  "Discount Type": "Discount Type",
  "Select a discount type": "Select a discount type",
  "Flat": "Flat",
  "Rate": "Rate",
  "Voucher Title": "Voucher Title",
  "Voucher Description": "Voucher Description",
  "Voucher Amount": "Voucher Amount",
  "CONFIRM": "CONFIRM",
  "If ticked, the system will give voucher to user who completed washer stamp collection": "If ticked, the system will give voucher to user who completed washer stamp collection",
  "If ticked, the system will give voucher to user who completed dryer stamp collection": "If ticked, the system will give voucher to user who completed dryer stamp collection",
  "If ticked, the user only can get/use the voucher after make a transaction": "If ticked, the user only can get/use the voucher after make a transaction",
  "Pay And Get Voucher": "Pay And Get Voucher",
  "Pay And Use Code": "Pay And Use Code",
  "If ticked, the system will only give the voucher to user after the user make a transaction": "If ticked, the system will only give the voucher to user after the user make a transaction",
  "If ticked, the system will give voucher to user who completed registration process": "If ticked, the system will give voucher to user who completed registration process",
  "If ticked, the system will give voucher to user on their birthday month": "If ticked, the system will give voucher to user on their birthday month",
  "If ticked, the system will give voucher to user who completed stamp collection": "If ticked, the system will give voucher to user who completed stamp collection",
  "Minimum Payment Amount To Get/Use Register Voucher": "Minimum Payment Amount To Get/Use Register Voucher",
  "Voucher Method": "Voucher Method",
  "Select a voucher method": "Select a voucher method",
  "The user directly get the voucher after register but only can use it after make transaction": "The user directly get the voucher after register but only can use it after make transaction",
  "The user only can get voucher after make a transaction": "The user only can get voucher after make a transaction",
  "Minimum Payment Amount To Get Birthday Voucher": "Minimum Payment Amount To Get Birthday Voucher",
  "Minimum Payment Amount To Use Discount Code": "Minimum Payment Amount To Use Discount Code",
  "Outlets": "Outlets",
  "E-Payment Sales": "E-Payment Sales",
  "Number of stamps granted to users": "Number of stamps granted to users",
  "Total number of stamps granted": "Total number of stamps granted",
  "Registered Users": "Registered Users",
  "Stamps": "Stamps",
  "Referral": "Referral",
  "Register": "Register",
  "Top Outlets": "Top Outlets",
  "Users": "Users",
  "Sort by": "Sort by",
  "Top Users": "Top Users",
  "User name": "User name",
  "Number": "Number",
  "Spending": "Spending",
  "Number of registered users": "Number of registered users",
  "Number of voucher given to users": "Number of voucher given to users",
  "Total number of voucher given": "Total number of voucher given",
  "Voucher Type": "Voucher Type",
  "Voucher Value": "Voucher Value",
  "Date": "Date",
  "Discount Code Type": "Discount Code Type",
  "Discount Code Amount": "Discount Code Amount",
  "Number of Redemption": "Number of Redemption",
  "Total Discount Amount": "Total Discount Amount",
  "Select Outlet": "Select Outlet",
  "Select Date": "Select Date",
  "Select Transaction Date": "Select Transaction Date",
  "Reset": "Reset",
  "Download Report": "Download Report",
  "Please fill in the remark": "Please fill in the remark",
  "Transaction Date": "Transaction Date",
  "Machine Type": "Machine Type",
  "Machine Capacity": "Machine Capacity",
  "Total Amount": "Total Amount",
  "Payment Amount": "Payment Amount",
  "Discount From": "Discount From",
  "Discount Amount": "Discount Amount",
  "Action": "Action",
  "Remark": "Remark",
  "Refund Date": "Refund Date",
  "Refund": "Refund",
  "Purchase Date": "Purchase Date",
  "e-Token Purchased (Tokens)": "e-Token Purchased (Tokens)",
  "Bought e-Token using": "Bought e-Token using",
  "Transaction ID": "Transaction ID",
  "Method": "Method",
  "Requery": "Requery",
  "Reward Date": "Reward Date",
  "Reward Type": "Reward Type",
  "Notification Method": "Notification Method",
  "Email Preview": "Email Preview",
  "Message for User": "Message for User",
  "Enter the message to be sent to the user": "Enter the message to be sent to the user",
  "Email Address to show on Email Template": "Email Address to show on Email Template",
  "Input Company Email Address": "Input Company Email Address",
  "Company Name to show on Email Template": "Company Name to show on Email Template",
  "Input Company Name": "Input Company Name",
  "Company Phone No. to show on Email Template": "Company Phone No. to show on Email Template",
  "Input Company Phone No.": "Input Company Phone No.",
  "Voucher ID": "Voucher ID",
  "Washer Stamp": "Washer Stamp",
  "Dryer Stamp": "Dryer Stamp",
  "e-Token Amount": "e-Token Amount",
  "Transfer Date": "Transfer Date",
  "Transfer Amount (Token)": "Transfer Amount (Token)",
  "Number (Transfer From)": "Number (Transfer From)",
  "Name (Transfer From)": "Name (Transfer From)",
  "Member ID (Transfer From)": "Member ID (Transfer From)",
  "Number (Transfer To)": "Number (Transfer To)",
  "Name (Transfer To)": "Name (Transfer To)",
  "Member ID (Transfer To)": "Member ID (Transfer To)",
  "Grant Date": "Grant Date",
  "Claim Date": "Claim Date",
  "Member Details Report": "Member Details Report",
  "Transaction Status": "Transaction Status",
  "Token Reload": "Token Reload",
  "Token Transfer": "Token Transfer",
  "Voucher History": "Voucher History",
  "TOKEN": "TOKEN",
  "Message Sent": "Message Sent",
  "Date Sent": "Date Sent",
  "Remaining SMS Credit": "Remaining SMS Credit",
  "Store": "Store",
  "Total Registered Users": "Total Registered Users",
  "User registered but no transaction record": "User registered but no transaction record",
  "Registered": "Registered",
  "User spending is less than": "User spending is less than",
  "Active": "Active",
  "User spending is from": "User spending is from",
  "until": "until",
  "Tier 1": "Tier 1",
  "User spending is": "User spending is",
  "and above": "and above",
  "Tier 2": "Tier 2",
  "Average spending in each transaction": "Average spending in each transaction",
  "Average Spending": "Average Spending",
  "Total number of transactions": "Total number of transactions",
  "Total Transactions": "Total Transactions",
  "Number of vouchers used in all the transactions": "Number of vouchers used in all the transactions",
  "Voucher Claimed": "Voucher Claimed",
  "Total number of washer machine transactions": "Total number of washer machine transactions",
  "Washer Usage": "Washer Usage",
  "Total number of dryer machine transactions": "Total number of dryer machine transactions",
  "Dryer Usage": "Dryer Usage",
  "User Name": "User Name",
  "e-Token Spent": "e-Token Spent",
  "e-Token Detailed Report": "e-Token Detailed Report",
  "e-Token Purchase History": "e-Token Purchase History",
  "Select Purchase Date": "Select Purchase Date",
  "Outlet Name": "Outlet Name",
  "Total number of e-token used in all the transactions": "Total number of e-token used in all the transactions",
  "Total e-Token Collected": "Total e-Token Collected",
  "Total number of e-token used in washer machine transactions": "Total number of e-token used in washer machine transactions",
  "Washer e-Token Collected": "Washer e-Token Collected",
  "Total number of e-token used in dryer machine transactions": "Total number of e-token used in dryer machine transactions",
  "Dryer e-Token Collected": "Dryer e-Token Collected",
  "Purchase History": "Purchase History",
  "All": "All",
  "Most Bought e-Token Package": "Most Bought e-Token Package",
  "for": "for",
  "Tokens": "Tokens",
  "Total Earned from users buying e-Tokens": "Total Earned from users buying e-Tokens",
  "Total e-Tokens Bought from you": "Total e-Tokens Bought from you",
  "Join Date": "Join Date",
  "Channel": "Channel",
  "Tier": "Tier",
  "Referrer": "Referrer",
  "Referrals": "Referrals",
  "Number of transactions for last 7 days": "Number of transactions for last 7 days",
  "Number of Usage (in week)": "Number of Usage (in week)",
  "Number of transactions for last 1 month": "Number of transactions for last 1 month",
  "Number of Usage (in month)": "Number of Usage (in month)",
  "Total amount spent in all the transactions": "Total amount spent in all the transactions",
  "Total Spent": "Total Spent",
  "Last Transaction Date": "Last Transaction Date",
  "Number of vouchers collected": "Number of vouchers collected",
  "Voucher Collected": "Voucher Collected",
  "Number of vouchers used": "Number of vouchers used",
  "Voucher Spent": "Voucher Spent",
  "Washer Used": "Washer Used",
  "Dryer Used": "Dryer Used",
  "Total number of e-Token spent in all the transactions": "Total number of e-Token spent in all the transactions",
  "Total number of stamps collected": "Total number of stamps collected",
  "Total Stamps": "Total Stamps",
  "Select Join Date": "Select Join Date",
  "Select Grant Date": "Select Grant Date",
  "Amount": "Amount",
  "The image size is over 2MB": "The image size is over 2MB",
  "Duplicate language selected": "Duplicate language selected",
  "Are you sure you want to add FAQ?": "Are you sure you want to add FAQ?",
  "FAQ added successfully": "FAQ added successfully",
  "FAQ Group added successfully": "FAQ Group added successfully",
  "Please provide the faq with the default language": "Please provide the faq with the default language",
  "Add FAQ": "Add FAQ",
  "Default Language": "Default Language",
  "Select Default Language": "Select Default Language",
  "Image (Optional)": "Image (Optional)",
  "max": "max",
  "Question": "Question",
  "Select Language": "Select Language",
  "Remove": "Remove",
  "Answer": "Answer",
  "Add Translation": "Add Translation",
  "Are you sure you want to add FAQ Group?": "Are you sure you want to add FAQ Group?",
  "Please provide the faq group with the default language": "Please provide the faq group with the default language",
  "Add FAQ Group": "Add FAQ Group",
  "Title": "Title",
  "Title Language": "Title Language",
  "Please fill in the title": "Please fill in the title",
  "Please fill in the description": "Please fill in the description",
  "Please fill in the short description": "Please fill in the short description",
  "Please select the end date for your news": "Please select the end date for your news",
  "Please provide the news with the default language": "Please provide the news with the default language",
  "Please provide the banner image": "Please provide the banner image",
  "Please provide the news image": "Please provide the news image",
  "Are you sure you want to add news?": "Are you sure you want to add news?",
  "News added successfully": "News added successfully",
  "Please fill in all the required fields before submit": "Please fill in all the required fields before submit",
  "Add News": "Add News",
  "Banner Image": "Banner Image",
  "News Image": "News Image",
  "Banner": "Banner",
  "aspect ratio": "aspect ratio",
  "Image": "Image",
  "Display in": "Display in",
  "Inbox Page": "Inbox Page",
  "Promotion Banner": "Promotion Banner",
  "Select Period: (optional)": "Select Period: (optional)",
  "Short Description": "Short Description",
  "Your theme has been updated successfully": "Your theme has been updated successfully",
  "Default Theme": "Default Theme",
  "Change Logo": "Change Logo",
  "REMOVE LOGO": "REMOVE LOGO",
  "Sms credit used up": "Sms credit used up",
  "Please top up your sms credit": "Please top up your sms credit",
  "Please": "Please",
  "Again": "Again",
  "Welcome": "Welcome",
  "Ranking": "Ranking",
  "Select Year": "Select Year",
  "Missing Field": "Missing Field",
  "Please select can be used for washer, dryer or package": "Please select can be used for washer, dryer or package",
  "Please fill in the start date and end date": "Please fill in the start date and end date",
  "Discount amount should be more than 0": "Discount amount should be more than 0",
  "Redeem per user should be more than 0": "Redeem per user should be more than 0",
  "Redeem per day should be more than 0": "Redeem per day should be more than 0",
  "Your discount code have edited successfully": "Your discount code have edited successfully",
  "Your discount code have added successfully": "Your discount code have added successfully",
  "Delete this entry?": "Delete this entry?",
  "Your discount code have deleted successfully": "Your discount code have deleted successfully",
  "Created Date": "Created Date",
  "Discount code": "Discount code",
  "General Discount Code": "General Discount Code",
  "Unique Discount Code": "Unique Discount Code",
  "Start Date": "Start Date",
  "End Date": "End Date",
  "Redeem Per User": "Redeem Per User",
  "Redeem Per Day": "Redeem Per Day",
  "Add Discount Code": "Add Discount Code",
  "Generate Discount Code": "Generate Discount Code",
  "No Discount Code": "No Discount Code",
  "Edit Discount Code": "Edit Discount Code",
  "Select Period": "Select Period",
  "Redeem per user": "Redeem per user",
  "How many times the discount code can be used for each user": "How many times the discount code can be used for each user",
  "Redeem per day": "Redeem per day",
  "How many times the discount code can be used in one day": "How many times the discount code can be used in one day",
  "If ticked, the discount code can apply to washer machine transaction": "If ticked, the discount code can apply to washer machine transaction",
  "If ticked, the discount code can apply to dryer machine transaction": "If ticked, the discount code can apply to dryer machine transaction",
  "If ticked, the discount code can apply to Drop n Go transaction": "If ticked, the discount code can apply to Drop n Go transaction",
  "The user enter the discount code": "The user enter the discount code",
  "in the payment page will get": "in the payment page will get",
  "discount": "discount",
  "The discount code only can be used start from {{ start_date }} until {{ end_date }}": "The discount code only can be used start from {{ start_date }} until {{ end_date }}",
  "Every user only can use the discount code {{ redeem_per_user }} times": "Every user only can use the discount code {{ redeem_per_user }} times",
  "Every user only can use {{ redeem_per_user }} discount code from this batch of code": "Every user only can use {{ redeem_per_user }} discount code from this batch of code",
  "Only {{ redeem_per_day }} transactions can use the discount code in one day_one": "Only {{ redeem_per_day }} transaction can use the discount code in one day",
  "Only {{ redeem_per_day }} transactions can use the discount code in one day_other": "Only {{ redeem_per_day }} transactions can use the discount code in one day",
  "The discount code only can be used for washer machine": "The discount code only can be used for washer machine",
  "The discount code only can be used for dryer machine": "The discount code only can be used for dryer machine",
  "The discount code only can be used for Drop n Go transaction": "The discount code only can be used for Drop n Go transaction",
  "The discount code can be used for washer machine or Drop n Go transaction": "The discount code can be used for washer machine or Drop n Go transaction",
  "The discount code can be used for dryer machine or Drop n Go transaction": "The discount code can be used for dryer machine or Drop n Go transaction",
  "The discount code can be used for washer machine, dryer machine or Drop n Go transaction": "The discount code can be used for washer machine, dryer machine or Drop n Go transaction",
  "The discount code can be used for washer or dryer machine": "The discount code can be used for washer or dryer machine",
  "The discount code only can be used after user spending {{ currency }} {{ pay_and_use_amount }}": "The discount code only can be used after user spending {{ currency }} {{ pay_and_use_amount }}",
  "Are you sure you want to edit FAQ?": "Are you sure you want to edit FAQ?",
  "FAQ updated successfully": "FAQ updated successfully",
  "Edit FAQ": "Edit FAQ",
  "Are you sure you want to edit FAQ Group?": "Are you sure you want to edit FAQ Group?",
  "FAQ Group updated successfully": "FAQ Group updated successfully",
  "Edit FAQ Group": "Edit FAQ Group",
  "Are you sure you want to edit news?": "Are you sure you want to edit news?",
  "News edit successfully": "News edit successfully",
  "Edit News": "Edit News",
  "All the FAQ under this group will be deleted. Are you sure you want to delete FAQ Group?": "All the FAQ under this group will be deleted. Are you sure you want to delete FAQ Group?",
  "FAQ Group deleted successfully": "FAQ Group deleted successfully",
  "FAQ List": "FAQ List",
  "NO FAQ GROUP": "NO FAQ GROUP",
  "FAQ Group": "FAQ Group",
  "Are you sure you want to delete FAQ?": "Are you sure you want to delete FAQ?",
  "FAQ deleted successfully": "FAQ deleted successfully",
  "NO FAQs": "NO FAQs",
  "FAQ": "FAQ",
  "Easy": "Easy",
  "Fast Laundromate": "Fast Laundromate",
  "Smart Laundry Management System": "Smart Laundry Management System",
  "Enter your username and password to login": "Enter your username and password to login",
  "Username": "Username",
  "Are you sure you want to delete news?": "Are you sure you want to delete news?",
  "News deleted successfully": "News deleted successfully",
  "NO NEWS": "NO NEWS",
  "The referral setting has been updated successfully!": "The referral setting has been updated successfully!",
  "Number of Stamp": "Number of Stamp",
  "Number of Washer Stamp": "Number of Washer Stamp",
  "Number of Dryer Stamp": "Number of Dryer Stamp",
  "Enable Referral Feature?": "Enable Referral Feature?",
  "Are you sure you want to delete the tier setting?": "Are you sure you want to delete the tier setting?",
  "Minimum referee transaction amount for referrer to get referral rewards": "Minimum referee transaction amount for referrer to get referral rewards",
  "Maximum number of reward within this tier": "Maximum number of reward within this tier",
  "Maximum number of reward can get from same user": "Maximum number of reward can get from same user",
  "Point Grant Type": "Point Grant Type",
  "Select Point Grant Type": "Select Point Grant Type",
  "Token Grant Type": "Token Grant Type",
  "Select Token Grant Type": "Select Token Grant Type",
  "Add Tier": "Add Tier",
  "Stamp": "Stamp",
  "If ticked, the system will give stamp to user": "If ticked, the system will give stamp to user",
  "If ticked, the system will give e-Token to user": "If ticked, the system will give e-Token to user",
  "If ticked, the system will give voucher to user": "If ticked, the system will give voucher to user",
  "Number of E-Token": "Number of E-Token",
  "Reward Giveaway": "Reward Giveaway",
  "Which type of reward you want to give to customer?": "Which type of reward you want to give to customer?",
  "For machine uses (required)": "For machine uses (required)",
  "leave blank if no expiry date": "leave blank if no expiry date",
  "Remarks": "Remarks",
  "Reward": "Reward",
  "Please fill in all the required field. (Title, description, and amount must be more than 0)": "Please fill in all the required field. (Title, description, and amount must be more than 0)",
  "Checkbox required": "Checkbox required",
  "Must select voucher can be used for washer, dryer or package": "Must select voucher can be used for washer, dryer or package",
  "How many e-token?": "How many e-token?",
  "Number of e-Token": "Number of e-Token",
  "Token value must be more than 0": "Token value must be more than 0",
  "STAMP": "STAMP",
  "Please fill in at least one of the required field": "Please fill in at least one of the required field",
  "The value must be more than 0": "The value must be more than 0",
  "How many times rewarded to user": "How many times rewarded to user",
  "Rewarded Times": "Rewarded Times",
  "How many times rewarded voucher to user": "How many times rewarded voucher to user",
  "Voucher Rewarded (times)": "Voucher Rewarded (times)",
  "How many times rewarded stamp to user": "How many times rewarded stamp to user",
  "Stamps Rewarded (times)": "Stamps Rewarded (times)",
  "Total number of washer stamps rewarded to user": "Total number of washer stamps rewarded to user",
  "Washer Stamps Granted (stamp)": "Washer Stamps Granted (stamp)",
  "Total number of dryer stamps rewarded to user": "Total number of dryer stamps rewarded to user",
  "Dryer Stamps Granted (stamp)": "Dryer Stamps Granted (stamp)",
  "How many times rewarded e-Token to user": "How many times rewarded e-Token to user",
  "e-Token Rewarded (times)": "e-Token Rewarded (times)",
  "Total number of e-Token rewarded to user": "Total number of e-Token rewarded to user",
  "Total e-Token Granted (token)": "Total e-Token Granted (token)",
  "Point Rewarded (times)": "Point Rewarded (times)",
  "How many times rewarded point to user": "How many times rewarded point to user",
  "Total Point Granted (point)": "Total Point Granted (point)",
  "Total number of point rewarded to user": "Total number of point rewarded to user",
  "Point Amount": "Point Amount",
  "Reward History": "Reward History",
  "Are you sure you want to change how the stamping mechanics works?": "Are you sure you want to change how the stamping mechanics works?",
  "The stamping mechanics setting has updated successfully!": "The stamping mechanics setting has updated successfully!",
  "The voucher setting has been updated successfully!": "The voucher setting has been updated successfully!",
  "Mechanics Settings": "Mechanics Settings",
  "Minimum transaction amount for getting stamp": "Minimum transaction amount for getting stamp",
  "Select User Stamping Mechanic": "Select User Stamping Mechanic",
  "Separated Stamping": "Separated Stamping",
  "Combined Stamping": "Combined Stamping",
  "Stamp for?": "Stamp for?",
  "Both": "Both",
  "Washer Only": "Washer Only",
  "Dryer Only": "Dryer Only",
  "Separated Stamping = Different washer and dryer stamp collection. (example: Using Washer will gain 1 washer's stamp, and Dryer will gain 1 dryer stamp)": "Separated Stamping = Different washer and dryer stamp collection. (example: Using Washer will gain 1 washer's stamp, and Dryer will gain 1 dryer stamp)",
  "Combined Stamping = Washer and dryer has the same collection. (example: Using Washer or Dryer will gain 1 stamp)": "Combined Stamping = Washer and dryer has the same collection. (example: Using Washer or Dryer will gain 1 stamp)",
  "Stamp for": "Stamp for",
  "User will be able to collect both washer stamp and dryer stamp": "User will be able to collect both washer stamp and dryer stamp",
  "User will only able to collect Washer stamp": "User will only able to collect Washer stamp",
  "User will only able to collect Dryer stamp": "User will only able to collect Dryer stamp",
  "Registration": "Registration",
  "Birthday": "Birthday",
  "Please choose at least one payment method": "Please choose at least one payment method",
  "Please choose at least one member or non-member payment": "Please choose at least one member or non-member payment",
  "Are you sure you want to change the payment setting?": "Are you sure you want to change the payment setting?",
  "The payment setting has updated successfully!": "The payment setting has updated successfully!",
  "Invalid input": "Invalid input",
  "The price and token must be more than 0": "The price and token must be more than 0",
  "Maximum number of purchase must be more than 1": "Maximum number of purchase must be more than 1",
  "Point amount must be more than 1": "Point amount must be more than 1",
  "Added a new Token Bundle Package": "Added a new Token Bundle Package",
  "Bundle deleted successfully!": "Bundle deleted successfully!",
  "Reload voucher setting have edited successfully!": "Reload voucher setting have edited successfully!",
  "Added on": "Added on",
  "remove": "remove",
  "edit": "edit",
  "NO PACKAGES ON SALES": "NO PACKAGES ON SALES",
  "Point Settings": "Point Settings",
  "Enable Loyalty Point": "Enable Loyalty Point",
  "Point Name": "Point Name",
  "Number Of Point Equals To": "Number Of Point Equals To",
  "If ticked, the point will have expired date": "If ticked, the point will have expired date",
  "Point Lifespan": "Point Lifespan",
  "months": "months",
  "Minimum Transaction Amount To Get Point": "Minimum Transaction Amount To Get Point",
  "Point Grant Amount": "Point Grant Amount",
  "Point settings updated successfully!": "Point settings updated successfully!",
  "Point Eligibility": "Point Eligibility",
  "If ticked, the system will give point to user when user reload token successfully": "If ticked, the system will give point to user when user reload token successfully",
  "{{ point_amount }} point will be given after user reload token successfully": "{{ point_amount }} point will be given after user reload token successfully",
  "Payment Settings": "Payment Settings",
  "Select Payment Method": "Select Payment Method",
  "Enable E-Payment?": "Enable E-Payment?",
  "Enable Token Currency?": "Enable Token Currency?",
  "Member and Non-Member Settings": "Member and Non-Member Settings",
  "Enable Member Payment?": "Enable Member Payment?",
  "Enable Non-Member Payment?": "Enable Non-Member Payment?",
  "Promotion Settings": "Promotion Settings",
  "Enable Non Member Promotion?": "Enable Non Member Promotion?",
  "Enable Notification When Cycle Done?": "Enable Notification When Cycle Done?",
  "SMS charges": "SMS charges",
  "SMS Settings": "SMS Settings",
  "Enable Free Sms For Members": "Enable Free Sms For Members",
  "If enable notification, user can choose either receive email or sms when complete washing or drying": "If enable notification, user can choose either receive email or sms when complete washing or drying",
  "If user choose sms, non member have to pay extra {{ currency }}{{ sms_notification_amount }} for the sms": "If user choose sms, non member have to pay extra {{ currency }}{{ sms_notification_amount }} for the sms",
  "If user choose sms, member and non member have to pay extra {{ currency }}{{ sms_notification_amount }} for the sms": "If user choose sms, member and non member have to pay extra {{ currency }}{{ sms_notification_amount }} for the sms",
  "Add Package": "Add Package",
  "Edit e-Token Package": "Edit e-Token Package",
  "Add e-Token Package": "Add e-Token Package",
  "Please only input numberic value to prevent any bugs.": "Please only input numberic value to prevent any bugs.",
  "Price": "Price",
  "If ticked, the system will limit the number of purchase": "If ticked, the system will limit the number of purchase",
  "Limit Number of Purchase": "Limit Number of Purchase",
  "Number of maximum purchase": "Number of maximum purchase",
  "Reload Voucher": "Reload Voucher",
  "If ticked, the system will give voucher to user when user reload token successfully": "If ticked, the system will give voucher to user when user reload token successfully",
  "If ticked, the system will give voucher repeatly": "If ticked, the system will give voucher repeatly",
  "Recurring": "Recurring",
  "Total number of voucher given to user": "Total number of voucher given to user",
  "Period for giving next voucher": "Period for giving next voucher",
  "Token package {{ currency }}{{ price }} for {{ numberOfToken }} tokens_one": "Token package {{ currency }}{{ price }} for {{ numberOfToken }} token",
  "Token package {{ currency }}{{ price }} for {{ numberOfToken }} tokens_other": "Token package {{ currency }}{{ price }} for {{ numberOfToken }} tokens",
  "Each user only can purchase {{ max_purchase }} times for the token package": "Each user only can purchase {{ max_purchase }} times for the token package",
  "Voucher will be given after user reload token successfully": "Voucher will be given after user reload token successfully",
  "Voucher will not given after user reload tokens successfully": "Voucher will not given after user reload tokens successfully",
  "The voucher will be expired after {{ voucher_duration }} days_one": "The voucher will be expired after {{ voucher_duration }} day",
  "The voucher will be expired after {{ voucher_duration }} days_other": "The voucher will be expired after {{ voucher_duration }} days",
  "The voucher will not expired": "The voucher will not expired",
  "Total {{ recurring_number }} vouchers will be given to user and each voucher will discount_one": "Total {{ recurring_number }} voucher will be given to user and each voucher will discount",
  "Total {{ recurring_number }} vouchers will be given to user and each voucher will discount_other": "Total {{ recurring_number }} vouchers will be given to user and each voucher will discount",
  "Edit Package": "Edit Package",
  "Age": "Age",
  "Total number of vouchers collected": "Total number of vouchers collected",
  "Vouchers Acquired": "Vouchers Acquired",
  "Total amount spent in washer machine transactions": "Total amount spent in washer machine transactions",
  "Total Washer Payment": "Total Washer Payment",
  "Total amount spent in dryer machine transactions": "Total amount spent in dryer machine transactions",
  "Total Dryer Payment": "Total Dryer Payment",
  "Total amount spent in all transactions": "Total amount spent in all transactions",
  "Total Payment": "Total Payment",
  "report": "report",
  "Total amount spent for buying e-Token": "Total amount spent for buying e-Token",
  "Total number of e-Token purchased": "Total number of e-Token purchased",
  "Total e-Token Purchased (Tokens)": "Total e-Token Purchased (Tokens)",
  "Total number of e-Token used in washer machine transactions": "Total number of e-Token used in washer machine transactions",
  "Washer Payment (Tokens)": "Washer Payment (Tokens)",
  "Total number of e-Token used in dryer machine transactions": "Total number of e-Token used in dryer machine transactions",
  "Dryer Payment (Tokens)": "Dryer Payment (Tokens)",
  "Total number of e-Token used in all the transactions": "Total number of e-Token used in all the transactions",
  "Total Payment (Tokens)": "Total Payment (Tokens)",
  "Number of washer stamp collected currently": "Number of washer stamp collected currently",
  "Number of dryer stamp collected currently": "Number of dryer stamp collected currently",
  "User Phone Number / Member ID / Email": "User Phone Number / Member ID / Email",
  "Example: 0123456789,A123456": "Example: 0123456789,A123456",
  "SEARCH": "SEARCH",
  "Total Users Registered": "Total Users Registered",
  "Total Active Outlets": "Total Active Outlets",
  "Total Vouchers Given": "Total Vouchers Given",
  "Total Transaction Counts": "Total Transaction Counts",
  "Total User Stamps Collected": "Total User Stamps Collected",
  "Distributor Login": "Distributor Login",
  "Client Login": "Client Login",
  "Kiosk Login": "Kiosk Login",
  "Promotion": "Promotion",
  "Please select operator": "Please select operator",
  "Please select distributor": "Please select distributor",
  "Select Operator": "Select Operator",
  "Select Distributor": "Select Distributor",
  "User can login or skip to payment without login to make the payment": "User can login or skip to payment without login to make the payment",
  "User have to login only can make the payment": "User have to login only can make the payment",
  "User only can skip to payment without login to make the payment": "User only can skip to payment without login to make the payment",
  "Only CSV file is accepted": "Only CSV file is accepted",
  "The user and the balance have updated": "The user and the balance have updated",
  "Register Time": "Register Time",
  "Upload User CSV file": "Upload User CSV file",
  "Sample CSV file": "Sample CSV file",
  "Please follow the CSV file format": "Please follow the CSV file format",
  "User CSV file": "User CSV file",
  "Only CSV file (comma delimited) is support now": "Only CSV file (comma delimited) is support now",
  "Confirm uploaded data": "Confirm uploaded data",
  "Operator": "Operator",
  "Distributor": "Distributor",
  "Refund By": "Refund By",
  "Reward By": "Reward By",
  "Number Of Voucher Code": "Number Of Voucher Code",
  "Number Of Discount Code": "Number Of Discount Code",
  "New User Only": "New User Only",
  "Minimum Transaction Amount To Redeem": "Minimum Transaction Amount To Redeem",
  "Download": "Download",
  "Generate Voucher Code": "Generate Voucher Code",
  "Your voucher code is generating": "Your voucher code is generating",
  "Your discount code is generating": "Your discount code is generating",
  "Voucher Code Settings": "Voucher Code Settings",
  "If ticked, the voucher code only can redeem in register page": "If ticked, the voucher code only can redeem in register page",
  "If ticked, the user only can use the voucher after make a transaction": "If ticked, the user only can use the voucher after make a transaction",
  "If ticked, the user only can use the discount code after make a transaction": "If ticked, the user only can use the discount code after make a transaction",
  "Expired Date Of Voucher Code": "Expired Date Of Voucher Code",
  "Minimum Transaction Amount To Use Voucher": "Minimum Transaction Amount To Use Voucher",
  "Minimum Transaction Amount To Use Code": "Minimum Transaction Amount To Use Code",
  "Max redemption per user in this batch voucher code": "Max redemption per user in this batch voucher code",
  "Max redemption per user in this batch discount code": "Max redemption per user in this batch discount code",
  "Add Voucher": "Add Voucher",
  "Completed": "Completed",
  "Voucher Code Report": "Voucher Code Report",
  "Floating Token Report": "Floating Token Report",
  "Floating Point Report": "Floating Point Report",
  "E-Payment Report": "E-Payment Report",
  "Referral Reward History Report": "Referral Reward History Report",
  "Redemption Date": "Redemption Date",
  "Redemption Code": "Redemption Code",
  "Select Redemption Date": "Select Redemption Date",
  "Select Voucher Type": "Select Voucher Type",
  "Giveaway": "Giveaway",
  "Select Name": "Select Name",
  "Amount must be more than 1": "Amount must be more than 1",
  "Payment method setting have updated successfully!": "Payment method setting have updated successfully!",
  "Edit Payment Method": "Edit Payment Method",
  "Minimum payment amount to include all the payment methods": "Minimum payment amount to include all the payment methods",
  "Exclude Payment Method in Revenue Monster": "Exclude Payment Method in Revenue Monster",
  "Welcome to": "Welcome to",
  "Instruction": "Instruction",
  "Please select the machine": "Please select the machine",
  "Login your account": "Login your account",
  "Select the setting and make the payment": "Select the setting and make the payment",
  "Phone Number / Email": "Phone Number / Email",
  "Reload & Transfer In": "Reload & Transfer In",
  "Usage & Transfer Out": "Usage & Transfer Out",
  "Usage": "Usage",
  "Remaining": "Remaining",
  "Gain": "Gain",
  "Type": "Type",
  "Order Date": "Order Date",
  "Pack Number": "Pack Number",
  "Pack Details": "Pack Details",
  "Size": "Size",
  "Mode": "Mode",
  "Machine Payment": "Machine Payment",
  "DNG History": "DNG History",
  "Token History": "Token History",
  "Stamp History": "Stamp History",
  "Staff Login": "Staff Login",
  "Drop And Go Portal": "Drop And Go Portal",
  "Available Task": "Available Task",
  "Completed Task": "Completed Task",
  "Task ID": "Task ID",
  "Start Task": "Start Task",
  "Select Machine": "Select Machine",
  "Start Machine": "Start Machine",
  "Reward To": "Reward To",
  "Reward From": "Reward From",
  "Transaction Account": "Transaction Account",
  "Point": "Point",
  "POINT": "POINT",
  "Number of Point": "Number of Point",
  "Point History": "Point History",
  "Customer Name": "Customer Name",
  "Number of Washer Transactions": "Number of Washer Transactions",
  "Number of Dryer Transactions": "Number of Dryer Transactions",
  "Vouchers Redeemed": "Vouchers Redeemed",
  "Discount Code Redemption": "Discount Code Redemption",
  "Total Spend": "Total Spend",
  "Select Outlet(s)": "Select Outlet(s)",
  "User Usage Report": "User Usage Report",
  // cleanpro plus en
  "Wash": "Wash",
  "Dry": "Dry",
  "Fold": "Fold",
  "Scan QR": "Scan QR",
  "Capacity": "Capacity",
  "Duration": "Duration",
  "Custom duration": "Custom duration",
  "Choose duration": "Choose duration",
  "Select the correct amount on the machine after payment.": "Select the correct amount on the machine after payment.",
  "Offers": "Offers",
  "An easier way to pay": "An easier way to pay",
  "Create account": "Create account",
  "Login": "Login",
  "By signing up, you agree to": "By signing up, you agree to",
  "Amount to Pay": "Amount to Pay",
  "1 voucher selected": "1 voucher selected",
  "No voucher selected": "No voucher selected",
  "Credits": "Credits",
  "credits": "credits",
  "credit": "credit",
  "Credit balance": "Credit balance",
  "Please ensure that the machine door is shut & your laundry is inside.": "Please ensure that the machine door is shut & your laundry is inside.",
  "Insufficient balance": "Insufficient balance",
  "Topup now": "Topup now",
  "Copyright © 2023 Cleanpro Laundry Holdings Sdn Bhd (821842-P) All rights reserved.": "Copyright © 2023 Cleanpro Laundry Holdings Sdn Bhd (821842-P) All rights reserved.",
  "Good Evening!": "Good Evening!",
  "Hello": "Hello",
  "Wallet Balance": "Wallet Balance",
  "Topup": "Topup",
  "More details": "More details",
  "Collect 10 e-stamps to earn x1 FREE wash or dry voucher": "Collect 10 e-stamps to earn x1 FREE wash or dry voucher",
  "For every wash or dry cycle you complete with us, you'll earn one e-stamp. Collect 10 and you'll be able to redeem a FREE wash or dry cycle. It's our way of saying thank you for choosing Cleanpro!": "For every wash or dry cycle you complete with us, you'll earn one e-stamp. Collect 10 and you'll be able to redeem a FREE wash or dry cycle. It's our way of saying thank you for choosing Cleanpro!",
  "For every wash cycle you complete with us, you'll earn one e-stamp. Collect 10 and you'll be able to redeem a FREE wash or dry cycle. It's our way of saying thank you for choosing Cleanpro!": "For every wash cycle you complete with us, you'll earn one e-stamp. Collect 10 and you'll be able to redeem a FREE wash or dry cycle. It's our way of saying thank you for choosing Cleanpro!",
  "For every dry cycle you complete with us, you'll earn one e-stamp. Collect 10 and you'll be able to redeem a FREE wash or dry cycle. It's our way of saying thank you for choosing Cleanpro!": "For every dry cycle you complete with us, you'll earn one e-stamp. Collect 10 and you'll be able to redeem a FREE wash or dry cycle. It's our way of saying thank you for choosing Cleanpro!",
  "Credits OFF": "Credits OFF",
  "% OFF": "% OFF",
  "Valid Till": "Valid Till",
  "Used On": "Used On",
  "Expired On": "Expired On",
  "Available": "Available",
  "Expired": "Expired",
  "Used": "Used",
  "Promotions": "Promotions",
  "Rewards": "Rewards",
  "Inbox": "Inbox",
  "Activities": "Activities",
  "Support": "Support",
  "Active Rewards": "Active Rewards",
  "Past Rewards": "Past Rewards",
  "Get rewarded for your loyalty with Cleanpro Plus Rewards!": "Get rewarded for your loyalty with Cleanpro Plus Rewards!",
  "Earn vouchers and redeem them for discounts, freebies and more. The more you use our services the more you save!": "Earn vouchers and redeem them for discounts, freebies and more. The more you use our services the more you save!",
  "Up to 3 months history": "Up to 3 months history",
  "Filter": "Filter",
  "All Transactions": "All Transactions",
  "Transactions": "Transactions",
  "Select amount to topup": "Select amount to topup",
  "Balance": "Balance",
  "You've just paid": "You've just paid",
  "Need help?": "Need help?",
  "24-Hour Careline Number": "24-Hour Careline Number",
  "Email Us": "Email Us",
  "Call Now": "Call Now",
  "Email Now": "Email Now",
  "Update name": "Update name",
  "Update email address": "Update email address",
  "Update phone number": "Update phone number",
  "Change password": "Change password",
  "Change language": "Change language",
  "User ID": "User ID",
  "Let us know your feedback": "Let us know your feedback",
  "Poor": "Poor",
  "Good": "Good",
  "Leave addtional feedback here (optional)": "Leave addtional feedback here (optional)",
  "Submit feedback": "Submit feedback",
  "Pay as guest": "Pay as guest",
  "Pay now": "Pay now",
  "Select voucher to use": "Select voucher to use",
  "Use voucher": "Use voucher",
  "Total": "Total",
  "Transaction Type": "Transaction Type",
  "Credit Topup": "Credit Topup",
  "Credit topup": "Credit topup",
  "Credit Transfer": "Credit Transfer",
  "Date/Time": "Date/Time",
  "Reference ID": "Reference ID",
  "Status": "Status",
  "Share Your Referral Code": "Share Your Referral Code",
  "cleanproReferralShortDesc_one": "Get free {{ voucherAmount }} credit when you share your referral code to friends and family after a successful transaction. Tap to learn more.",
  "cleanproReferralShortDesc_other": "Get free {{ voucherAmount }} credits when you share your referral code to friends and family after a successful transaction. Tap to learn more.",
  "Use": "Use",
  "dryer or washer": "dryer or washer",
  "washer": "washer",
  "dryer": "dryer",
  "dryer and washer": "dryer and washer",
  "machine with the minimum spending of": "machine with the minimum spending of",
  "Welcome to Cleanpro Plus": "Welcome to Cleanpro Plus",
  "Only scan, set & pay! An easier, more convenient way to pay with Cleanpro Plus! No need to download an app. Enjoy more rewards when you use Cleanpro Plus today! Tap to learn more.": "Only scan, set & pay! An easier, more convenient way to pay with Cleanpro Plus! No need to download an app. Enjoy more rewards when you use Cleanpro Plus today! Tap to learn more.",
  "Cleanpro Pay Migration Notice": "Cleanpro Pay Migration Notice",
  "Dear valued Cleanpro customers, please be informed that as of 22 June 2023, the Cleanpro Pay app will be upgraded and and no longer available for use. Tap to learn more.": "Dear valued Cleanpro customers, please be informed that as of 22 June 2023, the Cleanpro Pay app will be upgraded and and no longer available for use. Tap to learn more.",
  "Answer simple questions and win credits for your next wash or dry! This contest is only available for selected outlets only. Tap to learn more.": "Answer simple questions and win credits for your next wash or dry! This contest is only available for selected outlets only. Tap to learn more.",
  "The all-new Cleanpro Plus is a new payment method that includes loyalty features built into the app. The more you use our services and pay using Cleanpro Plus, the more you can save. With every transaction, you can earn rewards that will help you save on your next laundry expenses.": "The all-new Cleanpro Plus is a new payment method that includes loyalty features built into the app. The more you use our services and pay using Cleanpro Plus, the more you can save. With every transaction, you can earn rewards that will help you save on your next laundry expenses.",
  "To use Cleanpro Plus, simply scan the QR code on the machines and pay using your preferred credit/debit card or e-wallet. We hope that Cleanpro Plus will help simplify your laundry routine and give you more time for the things that matter.": "To use Cleanpro Plus, simply scan the QR code on the machines and pay using your preferred credit/debit card or e-wallet. We hope that Cleanpro Plus will help simplify your laundry routine and give you more time for the things that matter.",
  "If you have any questions about Cleanpro Plus, please read our FAQ in the menu or contact our careline at +603 2770 0100 or email us at info@cleanprolaundry.com. Thank you for choosing Cleanpro.": "If you have any questions about Cleanpro Plus, please read our FAQ in the menu or contact our careline at +603 2770 0100 or email us at info@cleanprolaundry.com. Thank you for choosing Cleanpro.",
  "Dear valued Cleanpro customers, please be informed that as of 22 June 2023, the existing Cleanpro Pay mobile app will be upgraded and no longer available for use.": "Dear valued Cleanpro customers, please be informed that as of 22 June 2023, the existing Cleanpro Pay mobile app will be upgraded and no longer available for use.",
  "We are migrating to the all-new Cleanpro Plus. It is a new cashless payment method, without the need to download apps.": "We are migrating to the all-new Cleanpro Plus. It is a new cashless payment method, without the need to download apps.",
  "If you are the existing user of Cleanpro Pay, any of your remaining balance in the app will be transferred to your Cleanpro Plus account. You are required to use the same mobile number to sign up for Cleanpro Plus for balance transfer.": "If you are the existing user of Cleanpro Pay, any of your remaining balance in the app will be transferred to your Cleanpro Plus account. You are required to use the same mobile number to sign up for Cleanpro Plus for balance transfer.",
  "If you need help on Cleanpro Plus, please feel free to call our careline at +603 2770 0100 or email us at info@cleanprolaundry.com": "If you need help on Cleanpro Plus, please feel free to call our careline at +603 2770 0100 or email us at info@cleanprolaundry.com",
  "Answer simple questions and win credits for your next wash or dry!": "Answer simple questions and win credits for your next wash or dry!",
  "Step 1 : Scan the Jawab & Gerenti Menang QR code in the participated outlets.": "Step 1 : Scan the Jawab & Gerenti Menang QR code in the participated outlets.",
  "Step 2 : Answer 3 simple questions.": "Step 2 : Answer 3 simple questions.",
  "Step 3 : Submit and stand a chance to win prizes.": "Step 3 : Submit and stand a chance to win prizes.",
  "Grand Prize :": "Grand Prize :",
  "x10 per outlet - 100 credits worth of vouchers in Cleanpro Plus": "x10 per outlet - 100 credits worth of vouchers in Cleanpro Plus",
  "Consolation Prize :": "Consolation Prize :",
  "X25 per outlet - 50 credits worth of vouchers in Cleanpro Plus": "X25 per outlet - 50 credits worth of vouchers in Cleanpro Plus",
  "Participation prize:": "Participation prize:",
  "Every participants are eligible to receive a FREE 3 credits voucher (worth RM3) in Cleanpro Plus.": "Every participants are eligible to receive a FREE 3 credits voucher (worth RM3) in Cleanpro Plus.",
  "If you have any questions please don't hesitate to contact our careline at +603 2770 0100 or email us at info@cleanprolaundry.com. Thank you for choosing Cleanpro.": "If you have any questions please don't hesitate to contact our careline at +603 2770 0100 or email us at info@cleanprolaundry.com. Thank you for choosing Cleanpro.",
  "Payment Confirmation": "Payment Confirmation",
  "This campaign is only eligible for users who use the all-new Cleanpro Plus web application.": "This campaign is only eligible for users who use the all-new Cleanpro Plus web application.",
  "ONE e-stamp will be rewarded for each wash or dry cycle.": "ONE e-stamp will be rewarded for each wash or dry cycle.",
  "ONE e-stamp will be rewarded for each wash cycle.": "ONE e-stamp will be rewarded for each wash cycle.",
  "ONE e-stamp will be rewarded for each dry cycle.": "ONE e-stamp will be rewarded for each dry cycle.",
  "Regardless of the washer or dryer settings, the number of e-stamps being collected is the same.": "Regardless of the washer or dryer settings, the number of e-stamps being collected is the same.",
  "The type of e-stamp collected is based on the services that you have used.": "The type of e-stamp collected is based on the services that you have used.",
  "Washing service will reward washer e-stamp and drying service will reward dryer e-stamp.": "Washing service will reward washer e-stamp and drying service will reward dryer e-stamp.",
  "Washing service will reward washer e-stamp.": "Washing service will reward washer e-stamp.",
  "Drying service will reward dryer e-stamp.": "Drying service will reward dryer e-stamp.",
  "E-stamps will automatically be added to your account once the condition has been fulfilled.": "E-stamps will automatically be added to your account once the condition has been fulfilled.",
  "There is no limit on how many e-stamps can be gathered in a single day. So long as you meet the requirements to collect the e-stamps.": "There is no limit on how many e-stamps can be gathered in a single day. So long as you meet the requirements to collect the e-stamps.",
  "The type of e-stamps that have been collected is final and cannot be changed.": "The type of e-stamps that have been collected is final and cannot be changed.",
  "There is no expiration for the e-stamps; only till the discontinuation of the campaign.": "There is no expiration for the e-stamps; only till the discontinuation of the campaign.",
  "E-stamps do not have any currency value, and they cannot be converted to cash or credits.": "E-stamps do not have any currency value, and they cannot be converted to cash or credits.",
  "This campaign is not applicable with any other ongoing promotions, offers, vouchers, and/or discounts provided by the participating outlets.": "This campaign is not applicable with any other ongoing promotions, offers, vouchers, and/or discounts provided by the participating outlets.",
  "These terms and conditions are subject to change at any time without prior notice.": "These terms and conditions are subject to change at any time without prior notice.",
  "Press START on the machine to begin washing.": "Press START on the machine to begin washing.",
  "Press START on the machine to begin drying.": "Press START on the machine to begin drying.",
  "Reminder": "Reminder",
  "Remember to select the same settings you have paid for on the corresponding machine.": "Remember to select the same settings you have paid for on the corresponding machine.",
  "Please ensure that your laundry is inside the machine and door is properly shut.": "Please ensure that your laundry is inside the machine and door is properly shut.",
  "voucherCreditsOff_one": "{{currentVoucherAmount}} Credit OFF",
  "voucherCreditsOff_other": "{{currentVoucherAmount}} Credits OFF",
  "voucherPercentageOff": "{{currentVoucherAmount}}% OFF",
  "cleanproReferralFlatVoucher_one": "Get free credits when you share your Cleanpro Plus referral code to your friends and family! For each successful referral who completes their first transaction, you’ll get free {{ referralVoucherAmount }} credit voucher.",
  "cleanproReferralFlatVoucher_other": "Get free credits when you share your Cleanpro Plus referral code to your friends and family! For each successful referral who completes their first transaction, you’ll get free {{ referralVoucherAmount }} credits voucher.",
  "cleanproReferralRateVoucher": "Get free credits when you share your Cleanpro Plus referral code to your friends and family! For each successful referral who completes their first transaction, you’ll get free {{ referralVoucherAmount }}% Off voucher.",
  "cleanproReferralTokenReward_one": "Get free credits when you share your Cleanpro Plus referral code to your friends and family! For each successful referral who completes their first transaction, you’ll get free {{ referralToken }} credit.",
  "cleanproReferralTokenReward_other": "Get free credits when you share your Cleanpro Plus referral code to your friends and family! For each successful referral who completes their first transaction, you’ll get free {{ referralToken }} credits.",
  "How to get & use code:": "How to get & use code:",
  'Go to menu = icon and click “Refer friends”': 'Go to menu = icon and click “Refer friends”',
  "Share with everyone who will likely to use Cleanpro Plus.": "Share with everyone who will likely to use Cleanpro Plus.",
  "Referral Terms & Conditions:": "Referral Terms & Conditions:",
  "When your friend or family register using your referral code, and completed a transaction (a minimum spending of {{ currency }}{{ referralTransactionAmount }}) , you will be rewarded automatically.": "When your friend or family register using your referral code, and completed a transaction (a minimum spending of {{ currency }}{{ referralTransactionAmount }}) , you will be rewarded automatically.",
  "cleanproNumberOfReferralReward": "You may share with as many people as you want, but each user is ONLY able to receive {{ numberOfReferral }} referral reward.",
  "cleanproNumberOfReferral_one": "You may share with as many people as you want, but each user is ONLY able to receive {{ numberOfReferral }} referral voucher.",
  "cleanproNumberOfReferral_other": "You may share with as many people as you want, but each user is ONLY able to receive {{ numberOfReferral }} referral vouchers.",
  "The voucher is valid for {{ duration }} days from the day voucher be given._one": "The voucher is valid for {{ duration }} day from the day voucher be given.",
  "The voucher is valid for {{ duration }} days from the day voucher be given._other": "The voucher is valid for {{ duration }} days from the day voucher be given.",
  "The voucher is for customer use only and not for sale.": "The voucher is for customer use only and not for sale.",
  "We believe that your special day deserves a little extra sparkle! That's why we're thrilled to introduce our exclusive birthday rewards program just for you! Starting this year, and every year thereafter, we're giving away {{ currency }}{{ voucherAmount }} credit vouchers to you on your birthday.": "We believe that your special day deserves a little extra sparkle! That's why we're thrilled to introduce our exclusive birthday rewards program just for you! Starting this year, and every year thereafter, we're giving away {{ currency }}{{ voucherAmount }} credit vouchers to you on your birthday.",
  "cleanproBirthdayRateAmount": "We believe that your special day deserves a little extra sparkle! That's why we're thrilled to introduce our exclusive birthday rewards program just for you! Starting this year, and every year thereafter, we're giving away {{ voucherAmount }}% vouchers to you on your birthday.",
  "Make sure your account details include your birthdate. This way, we'll know exactly when to send you your special birthday surprise.": "Make sure your account details include your birthdate. This way, we'll know exactly when to send you your special birthday surprise.",
  "Birthday Voucher Terms & Conditions:": "Birthday Voucher Terms & Conditions:",
  "The voucher will be given on the first day of your birthday month.": "The voucher will be given on the first day of your birthday month.",
  "When you applied the voucher during payment, it will give you {{ voucherAmount }} credits ({{ currency }}{{ voucherAmount }}) discount._one": "When you applied the voucher during payment, it will give you {{ voucherAmount }} credit ({{ currency }}{{ voucherAmount }}) discount.",
  "When you applied the voucher during payment, it will give you {{ voucherAmount }} credits ({{ currency }}{{ voucherAmount }}) discount._other": "When you applied the voucher during payment, it will give you {{ voucherAmount }} credits ({{ currency }}{{ voucherAmount }}) discount.",
  "When you applied the voucher during payment, it will give you {{ voucherAmount }}% discount.": "When you applied the voucher during payment, it will give you {{ voucherAmount }}% discount.",
  "All vouchers is not valid with other ongoing promotions, discounts and offers.": "All vouchers is not valid with other ongoing promotions, discounts and offers.",
  "Claim a complimentary wash and dry for every 10 e-stamps collected! We value your loyalty and want to reward you for choosing Cleanpro Plus. Tap to learn more.": "Claim a complimentary wash and dry for every 10 e-stamps collected! We value your loyalty and want to reward you for choosing Cleanpro Plus. Tap to learn more.",
  "cleanproStampFlat_one": "With every 10 e-stamps that you collected, you can claim up to {{ voucherAmount }} credit of free wash or dry session with Cleanpro Plus. Make your laundry routine not just hassle-free, but also rewarding.",
  "cleanproStampFlat_other": "With every 10 e-stamps that you collected, you can claim up to {{ voucherAmount }} credits of free wash or dry session with Cleanpro Plus. Make your laundry routine not just hassle-free, but also rewarding.",
  "cleanproStampRate": "With every 10 e-stamps that you collected, you can claim up to {{ voucherAmount }}% of free wash or dry session with Cleanpro Plus. Make your laundry routine not just hassle-free, but also rewarding.",
  "A voucher will be instantly added to your Rewards page when you reach 10 washes. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.": "A voucher will be instantly added to your Rewards page when you reach 10 washes. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.",
  "A voucher will be instantly added to your Rewards page when you reach 10 drys. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.": "A voucher will be instantly added to your Rewards page when you reach 10 drys. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.",
  "A voucher will be instantly added to your Rewards page when you reach 10 washes or drys. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.": "A voucher will be instantly added to your Rewards page when you reach 10 washes or drys. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.",
  "Terms & Conditions:": "Terms & Conditions:",
  "To earn ONE e-stamp, you must spend at least {{ stampTransactionAmount }} credits ({{ currency }}{{ stampTransactionAmount }}) per session._one": "To earn ONE e-stamp, you must spend at least {{ stampTransactionAmount }} credit ({{ currency }}{{ stampTransactionAmount }}) per session.",
  "To earn ONE e-stamp, you must spend at least {{ stampTransactionAmount }} credits ({{ currency }}{{ stampTransactionAmount }}) per session._other": "To earn ONE e-stamp, you must spend at least {{ stampTransactionAmount }} credits ({{ currency }}{{ stampTransactionAmount }}) per session.",
  "You will be automatically issued a voucher if you have collected 10 washer stamps.": "You will be automatically issued a voucher if you have collected 10 washer stamps.",
  "You will be automatically issued a voucher if you have collected 10 dryer stamps.": "You will be automatically issued a voucher if you have collected 10 dryer stamps.",
  "You will be automatically issued a voucher if you have collected 10 dryer stamps or 10 washer stamps.": "You will be automatically issued a voucher if you have collected 10 dryer stamps or 10 washer stamps.",
  "You will be automatically issued a voucher if you have collected 10 stamps.": "You will be automatically issued a voucher if you have collected 10 stamps.",
  "The voucher is non-refundable and cannot be exchanged for cash; in part or in full.": "The voucher is non-refundable and cannot be exchanged for cash; in part or in full.",
  "This voucher is valid for single transaction only.": "This voucher is valid for single transaction only.",
  "General questions": "General questions",
  "E-stamps collection": "E-stamps collection",
  "Coin exchange machines": "Coin exchange machines",
  "Washer machines": "Washer machines",
  "Dryer machines": "Dryer machines",
  "Paywave issues": "Paywave issues",
  "Cleanpro franchise": "Cleanpro franchise",
  "How can I wash my laundry using Cleanpro Plus?": "How can I wash my laundry using Cleanpro Plus?",
  "Here are the steps to wash your laundry using Cleanpro Plus:": "Here are the steps to wash your laundry using Cleanpro Plus:",
  "Find an unoccupied washing machine in the store, and insert your laundry in the washer.": "Find an unoccupied washing machine in the store, and insert your laundry in the washer.",
  "Select the desired temperature that you would like to use for your laundry.": "Select the desired temperature that you would like to use for your laundry.",
  "Using the scanner in the app, scan the QR code provided on the washing machine. Then select the same settings that you have pressed on the machine.": "Using the scanner in the app, scan the QR code provided on the washing machine. Then select the same settings that you have pressed on the machine.",
  'Tap on “Pay with credits” to pay the amount shown on the app.': 'Tap on “Pay with credits” to pay the amount shown on the app.',
  "Once the payment has been accepted, press the START button on the washer machine.": "Once the payment has been accepted, press the START button on the washer machine.",
  "After the washing cycle ends, you can just remove your laundry from the machine.": "After the washing cycle ends, you can just remove your laundry from the machine.",
  "How can I dry my laundry using Cleanpro Plus?": "How can I dry my laundry using Cleanpro Plus?",
  "Here are the steps to dry your laundry using Cleanpro Plus:": "Here are the steps to dry your laundry using Cleanpro Plus:",
  "Find an unoccupied drying machine in the store, and insert your laundry in the dryer.": "Find an unoccupied drying machine in the store, and insert your laundry in the dryer.",
  "Select the desired temperature and duration that you would like to use for your laundry.": "Select the desired temperature and duration that you would like to use for your laundry.",
  "Using the scanner in the app, scan the QR code provided on the drying machine. Then select the same settings that you have pressed on the machine.": "Using the scanner in the app, scan the QR code provided on the drying machine. Then select the same settings that you have pressed on the machine.",
  "Once the payment has been accepted, press the START button on the dryer machine.": "Once the payment has been accepted, press the START button on the dryer machine.",
  "After the drying cycle ends, you can just remove your laundry from the machine.": "After the drying cycle ends, you can just remove your laundry from the machine.",
  "Why does the QR code I scanned take me to a different machine?": "Why does the QR code I scanned take me to a different machine?",
  "Here are the steps to solve it:": "Here are the steps to solve it:",
  "Make sure that the number on the QR code (e.g. W1, W2, etc) is the same as the machine that you would like to use.": "Make sure that the number on the QR code (e.g. W1, W2, etc) is the same as the machine that you would like to use.",
  "If the QR code number does not match the machine you want, use the other machine. Please contact our customer service line at 03-27700100 to report this issue.": "If the QR code number does not match the machine you want, use the other machine. Please contact our customer service line at 03-27700100 to report this issue.",
  "How can I add more time to the washer that I am currently using?": "How can I add more time to the washer that I am currently using?",
  "The washing time for our washer is fixed and cannot add any additional time.": "The washing time for our washer is fixed and cannot add any additional time.",
  "How can I add more time to the dryer that I am currently using?": "How can I add more time to the dryer that I am currently using?",
  "Here are the steps to add more time to the dryer machine:": "Here are the steps to add more time to the dryer machine:",
  "By using the scanner in the app, scan the QR code provided at the dryer machine.": "By using the scanner in the app, scan the QR code provided at the dryer machine.",
  "Select the amount of time that you would like to add to the machine.": "Select the amount of time that you would like to add to the machine.",
  "After the drying cycle ends, you can just remove the clothes from the machine.": "After the drying cycle ends, you can just remove the clothes from the machine.",
  "REMINDER - It is recommended to add drying time 5 minutes before the cycle ends. And you cannot add tokens for extra times when the machine finishes the drying cycle.": "REMINDER - It is recommended to add drying time 5 minutes before the cycle ends. And you cannot add tokens for extra times when the machine finishes the drying cycle.",
  "Will the timer keep running when I have stopped the drying machine?": "Will the timer keep running when I have stopped the drying machine?",
  "Yes. Even after you stop the machine, the timer will continue to run. If you want to extend the drying time, add drying time 5 minutes before the cycle ends. This prevents the cycle from ending before your clothes are completely dried.": "Yes. Even after you stop the machine, the timer will continue to run. If you want to extend the drying time, add drying time 5 minutes before the cycle ends. This prevents the cycle from ending before your clothes are completely dried.",
  "How do I reload my credits for Cleanpro Plus?": "How do I reload my credits for Cleanpro Plus?",
  "Here are the steps to reload your credits:": "Here are the steps to reload your credits:",
  'Select the "Top Up Credit" button on the homepage.': 'Select the "Top Up Credit" button on the homepage.',
  "Choose the amount you want to reload and the payment method you want to use.": "Choose the amount you want to reload and the payment method you want to use.",
  "You will be taken to the payment page. The screen will return to Cleanpro Plus once the transaction is completed.": "You will be taken to the payment page. The screen will return to Cleanpro Plus once the transaction is completed.",
  "How many credits can I reload in one transaction?": "How many credits can I reload in one transaction?",
  "Reloading credits requires a minimum of RM 10 and a maximum of RM 50.": "Reloading credits requires a minimum of RM 10 and a maximum of RM 50.",
  "Can I pay using credits in the Cleanpro Plus app along with tokens or coins in a single transaction?": "Can I pay using credits in the Cleanpro Plus app along with tokens or coins in a single transaction?",
  "No. Only a single payment method may be chosen when performing any transactions.": "No. Only a single payment method may be chosen when performing any transactions.",
  "The amount I have reloaded in my account was not reflected in the app.": "The amount I have reloaded in my account was not reflected in the app.",
  "Please close and restart the application and check if the new balance has been updated.": "Please close and restart the application and check if the new balance has been updated.",
  "If the amount is still not updated, please contact our customer service line at 03-27700110 to receive further assistance.": "If the amount is still not updated, please contact our customer service line at 03-27700110 to receive further assistance.",
  "Can I cash out the credits I reloaded in my account for this app?": "Can I cash out the credits I reloaded in my account for this app?",
  "No. The credits that you have reloaded will remain in your Cleanpro Plus account.": "No. The credits that you have reloaded will remain in your Cleanpro Plus account.",
  "I have requested a refund, but I still have not received it.": "I have requested a refund, but I still have not received it.",
  "Please contact our customer service line at 03-27700100 to receive further assistance.": "Please contact our customer service line at 03-27700100 to receive further assistance.",
  "I have made the payment, and the app double or triple charged me.": "I have made the payment, and the app double or triple charged me.",
  "The washing machine cannot start even after I have paid using the app.": "The washing machine cannot start even after I have paid using the app.",
  "Ensure that the temperature settings you have selected and paid for on the application are the same as what you have pressed on the washing machine. If not, select the correct temperature settings on the washing machine and try again.": "Ensure that the temperature settings you have selected and paid for on the application are the same as what you have pressed on the washing machine. If not, select the correct temperature settings on the washing machine and try again.",
  "If the machine is still not able to be started, please contact our customer service line at 03-27700100 to receive further assistance.": "If the machine is still not able to be started, please contact our customer service line at 03-27700100 to receive further assistance.",
  "Can I use multiple vouchers together in the same washing or drying cycle?": "Can I use multiple vouchers together in the same washing or drying cycle?",
  "No. Only a single voucher can be used for each washing and drying cycle.": "No. Only a single voucher can be used for each washing and drying cycle.",
  "Why can't I use my free wash voucher that I received after signing up?": "Why can't I use my free wash voucher that I received after signing up?",
  "To redeem or use the voucher, you must complete at least one washing or drying cycle.": "To redeem or use the voucher, you must complete at least one washing or drying cycle.",
  "Can I change my phone number even after registering my account?": "Can I change my phone number even after registering my account?",
  "No. Once registered to an account, the phone number is locked into the account.": "No. Once registered to an account, the phone number is locked into the account.",
  "I have problems in the store, and who can I refer to for help?": "I have problems in the store, and who can I refer to for help?",
  "Do not worry, as you can contact our customer service line at 03-27700100 to receive assistance at any time.": "Do not worry, as you can contact our customer service line at 03-27700100 to receive assistance at any time.",
  "Should I add my own laundry detergent and softeners to the washing machine?": "Should I add my own laundry detergent and softeners to the washing machine?",
  "There is no need for that, as all you have to do is bring your dirty clothes and put them in the washer. The detergent and softeners will be provided by us, and you can rest assured that the laundry detergents we used are certified industrial grade.": "There is no need for that, as all you have to do is bring your dirty clothes and put them in the washer. The detergent and softeners will be provided by us, and you can rest assured that the laundry detergents we used are certified industrial grade.",
  "I would like to add my own laundry detergent and softener to the washing machine.": "I would like to add my own laundry detergent and softener to the washing machine.",
  "We do not recommend our customers add their own detergent, as the mixture of different detergents may affect the wash quality. And you can rest assured that the laundry detergent we used is certified industrial grade.": "We do not recommend our customers add their own detergent, as the mixture of different detergents may affect the wash quality. And you can rest assured that the laundry detergent we used is certified industrial grade.",
  "What time does the store close?": "What time does the store close?",
  "Our self-service laundry operation is open 24 hours a day, which means you can always be sure to go back home with a fresh set of laundry.": "Our self-service laundry operation is open 24 hours a day, which means you can always be sure to go back home with a fresh set of laundry.",
  "Are you open on weekends?": "Are you open on weekends?",
  "Yes. Cleanpro is also open on weekends and even on public holidays.": "Yes. Cleanpro is also open on weekends and even on public holidays.",
  "How many clothes can I fit in the washing or drying machine?": "How many clothes can I fit in the washing or drying machine?",
  "To ensure that your laundry is properly washed or dried, keep the amount of laundry inside the machine lower than the line of guide sticker on the machine.": "To ensure that your laundry is properly washed or dried, keep the amount of laundry inside the machine lower than the line of guide sticker on the machine.",
  "How many e-stamps can I collect in a single day?": "How many e-stamps can I collect in a single day?",
  "There is no limit to the amount of stamps that can be collected in a single day; ONE stamp will be awarded with every wash or dry.": "There is no limit to the amount of stamps that can be collected in a single day; ONE stamp will be awarded with every wash or dry.",
  "When I use the washing service, can I change the collected stamp to a dryer stamp, and vice versa?": "When I use the washing service, can I change the collected stamp to a dryer stamp, and vice versa?",
  "No, the type of stamp collected is only applied to the service that you have used.": "No, the type of stamp collected is only applied to the service that you have used.",
  "What temperatures can I choose when I claim the free washing e-stamp reward?": "What temperatures can I choose when I claim the free washing e-stamp reward?",
  "Any washing temperature is available for the e-stamp reward.": "Any washing temperature is available for the e-stamp reward.",
  "What temperatures can I choose when I claim the free drying e-stamp reward? And how long can I set the time?": "What temperatures can I choose when I claim the free drying e-stamp reward? And how long can I set the time?",
  "Any drying temperature and duration can be selected for the e-stamp reward.": "Any drying temperature and duration can be selected for the e-stamp reward.",
  "I'm unable to change the coin.": "I'm unable to change the coin.",
  "What colour is the light shown on the machine, red or green? If it is a red light, please contact our customer service line at 03-27700100 to receive further assistance.": "What colour is the light shown on the machine, red or green? If it is a red light, please contact our customer service line at 03-27700100 to receive further assistance.",
  "Green Light": "Green Light",
  "Red Light": "Red Light",
  "Machine functions as normal. Please try again.": "Machine functions as normal. Please try again.",
  "(flashing 2x) - There are no coins/tokens in the machine.": "(flashing 2x) - There are no coins/tokens in the machine.",
  "OR": "OR",
  "Note is jammed, please proceed to contact customer service": "Note is jammed, please proceed to contact customer service",
  "(flashing 3x) - Note is jammed, please proceed to contact customer service": "(flashing 3x) - Note is jammed, please proceed to contact customer service",
  "My notes are stuck in the machine.": "My notes are stuck in the machine.",
  "My detergent is not dispensing in the vending machine.": "My detergent is not dispensing in the vending machine.",
  "The water is leaking out of the washing machine.": "The water is leaking out of the washing machine.",
  "Please check the washer machine to see whether there are any clothes that are stuck at the door side.": "Please check the washer machine to see whether there are any clothes that are stuck at the door side.",
  "If yes, here are the steps to solve it:": "If yes, here are the steps to solve it:",
  "Press the emergency stop button (RED Button) for 3 seconds.": "Press the emergency stop button (RED Button) for 3 seconds.",
  "Wait for the sound to stop, and then only open the door. (If after 30 minutes the sound still has not stopped, please contact our customer service line at 03-27700100 to receive further assistance)": "Wait for the sound to stop, and then only open the door. (If after 30 minutes the sound still has not stopped, please contact our customer service line at 03-27700100 to receive further assistance)",
  "Ensure the door is closed tightly, and press the START button.": "Ensure the door is closed tightly, and press the START button.",
  "REMINDER - The emergency stop button can only be used once.": "REMINDER - The emergency stop button can only be used once.",
  "There is no detergent dispensing in the washing machine.": "There is no detergent dispensing in the washing machine.",
  "Ensure that the machine is in operation and that the START button was pressed.": "Ensure that the machine is in operation and that the START button was pressed.",
  "If the detergent is still not dispensing, please contact our customer service department at 03-27700100 for further assistance.": "If the detergent is still not dispensing, please contact our customer service department at 03-27700100 for further assistance.",
  "The price displayed on the machine's screen did not change after inserting the token.": "The price displayed on the machine's screen did not change after inserting the token.",
  "Press the small button (in the centre) to reject the tokens that have been inserted. Press a few times till the coins come out.": "Press the small button (in the centre) to reject the tokens that have been inserted. Press a few times till the coins come out.",
  "If the coins still cannot come out or the button is not functioning, please contact our customer service line at 03-27700110 to receive further assistance.": "If the coins still cannot come out or the button is not functioning, please contact our customer service line at 03-27700110 to receive further assistance.",
  "The machine is not starting, and it displays an error code.": "The machine is not starting, and it displays an error code.",
  "We would like to apologise for any inconvenience caused. Please contact our customer service line at 03-27700100 to receive further assistance.": "We would like to apologise for any inconvenience caused. Please contact our customer service line at 03-27700100 to receive further assistance.",
  "I want to wash the clothes again, but why couldn't the machine start even after I added tokens to the machine?": "I want to wash the clothes again, but why couldn't the machine start even after I added tokens to the machine?",
  "Try opening and closing the door again before starting the machine.": "Try opening and closing the door again before starting the machine.",
  "Can I change the water temperature of the machine?": "Can I change the water temperature of the machine?",
  "Yes, but the washer temperature can only be changed from hot to cold after the machine has been started, and not from cold to hot.": "Yes, but the washer temperature can only be changed from hot to cold after the machine has been started, and not from cold to hot.",
  "Can I stop the machine?": "Can I stop the machine?",
  "Here are the steps to stop the machine:": "Here are the steps to stop the machine:",
  "Wait for the sound to stop, and then only open the door. (If after 30 minutes the sound still has not stopped, please contact our customer service line at to receive further assistance)": "Wait for the sound to stop, and then only open the door. (If after 30 minutes the sound still has not stopped, please contact our customer service line at to receive further assistance)",
  "There is no water supply on the machine used.": "There is no water supply on the machine used.",
  "Why is the washing temperature cold even when I have selected HOT.": "Why is the washing temperature cold even when I have selected HOT.",
  "The water temperature is HOT or WARM only during washing. The water will turn COLD during rinsing.": "The water temperature is HOT or WARM only during washing. The water will turn COLD during rinsing.",
  "The machine has already finished washing, but it does not spin well, and there is water left on the load.": "The machine has already finished washing, but it does not spin well, and there is water left on the load.",
  "Can I put wet laundry into the dryer to dry?": "Can I put wet laundry into the dryer to dry?",
  "No, as the dripping wet laundry might cause the dryer to short circuit.": "No, as the dripping wet laundry might cause the dryer to short circuit.",
  "REMINDER - Refer to the labels on your clothes to know what kind of temperature is best for the kind of fabric that it uses. Then select the temperature on the machine accordingly.": "REMINDER - Refer to the labels on your clothes to know what kind of temperature is best for the kind of fabric that it uses. Then select the temperature on the machine accordingly.",
  "My clothes are still wet even after drying.": "My clothes are still wet even after drying.",
  "How can I stop the dryer when the machine is still running?": "How can I stop the dryer when the machine is still running?",
  "Just open the door and the machine will automatically stop.": "Just open the door and the machine will automatically stop.",
  "I added extra tokens for extra times, but the machine did not update the time.": "I added extra tokens for extra times, but the machine did not update the time.",
  "For the time to be reflected accurately, it is recommended for you to add tokens 5 minutes before the services end.": "For the time to be reflected accurately, it is recommended for you to add tokens 5 minutes before the services end.",
  "REMINDER - You cannot add tokens for extra times when the machine finishes the drying cycle.": "REMINDER - You cannot add tokens for extra times when the machine finishes the drying cycle.",
  "Why is the machine making noise?": "Why is the machine making noise?",
  "Please check the drum to see if there are any coins or other small things inside.": "Please check the drum to see if there are any coins or other small things inside.",
  "I have pressed the START button on the dryer, but it is still not working.": "I have pressed the START button on the dryer, but it is still not working.",
  "Please try to open the door and close it again. Next, press the START button again to start drying.": "Please try to open the door and close it again. Next, press the START button again to start drying.",
  "If the dryer is still not functioning, please contact our customer service line at 03-27700100 to receive further assistance.": "If the dryer is still not functioning, please contact our customer service line at 03-27700100 to receive further assistance.",
  "Paywave has deducted an amount from the card but the machine did not reflect the transaction.": "Paywave has deducted an amount from the card but the machine did not reflect the transaction.",
  "The Paywave transaction has failed but the amount has still been deducted from my card.": "The Paywave transaction has failed but the amount has still been deducted from my card.",
  "Paywave has double/triple charge but the machine cannot start.": "Paywave has double/triple charge but the machine cannot start.",
  "Paywave after double/triple charge machines can't start.": "Paywave after double/triple charge machines can't start.",
  "Paywave is offline": "Paywave is offline",
  "Do Cleanpro Express offer franchise business/selling machines/laundry products?": "Do Cleanpro Express offer franchise business/selling machines/laundry products?",
  "For further inquiries, please email to info@cleanproexpress.com": "For further inquiries, please email to info@cleanproexpress.com",
  "Step 6": "Step 6",
  "User Existed": "User Existed",
  "This email is registered": "This email is registered",
  "This phone number is registered": "This phone number is registered",
  "Invalid Email Address": "Invalid Email Address",
  "Let's get started!": "Let's get started!",
  "Enter your e-mail/mobile number": "Enter your e-mail/mobile number",
  "Enter your mobile number": "Enter your mobile number",
  "Enter your e-mail address": "Enter your e-mail address",
  "Proceed": "Proceed",
  "Enter OTP number": "Enter OTP number",
  "You will receive a six-digit OTP via SMS sent to": "You will receive a six-digit OTP via SMS sent to",
  "You will receive a six-digit OTP via e-mail sent to": "You will receive a six-digit OTP via e-mail sent to",
  "Verify": "Verify",
  "Resend OTP": "Resend OTP",
  "Welcome onboard!": "Welcome onboard!",
  "Please fill in the info below": "Please fill in the info below",
  "Enter your name*": "Enter your name*",
  "Enter your email address*": "Enter your email address*",
  "Please enter either phone number or email address": "Please enter either phone number or email address",
  "Create a password*": "Create a password*",
  "Enter referral code (if got any)": "Enter referral code (if got any)",
  "Get started": "Get started",
  "Birthday updated successfully": "Birthday updated successfully",
  "Date of birth": "Date of birth",
  "Enter your date of birth": "Enter your date of birth",
  "Select your birth date": "Select your birth date",
  "Update date of birth": "Update date of birth",
  "Update birth date": "Update birth date",
  "Already have an account?": "Already have an account?",
  "Login now": "Login now",
  "Sign in": "Sign in",
  "Sign up now": "Sign up now",
  "COMPLETED": "COMPLETED",
  "WASHING NOW": "WASHING NOW",
  "DRYING NOW": "DRYING NOW",
  "It's done!": "It's done!",
  "Please collect your fresh, clean laundry in order to leave the machine available for the next person to use!": "Please collect your fresh, clean laundry in order to leave the machine available for the next person to use!",
  "Leave a feedback": "Leave a feedback",
  "Time left": "Time left",
  "Add drying time": "Add drying time",
  "Hi": "Hi",
  "customer": "customer",
  "Thank you for your payment. Don't forget to select the <b>correct temperature</b> and press <b>START</b> when you're ready.": "Thank you for your payment. Don't forget to select the <b>correct temperature</b> and press <b>START</b> when you're ready.",
  "Select how you want to receive a notification when your laundry is finished": "Select how you want to receive a notification when your laundry is finished",
  "Sign up": "Sign up",
  "Continue to pay": "Continue to pay",
  "Register as a member to enjoy more benefits!": "Register as a member to enjoy more benefits!",
  "Get FREE {{ voucherAmount }} credits per new user_one": "Get FREE {{ voucherAmount }} credit per new user",
  "Get FREE {{ voucherAmount }} credits per new user_other": "Get FREE {{ voucherAmount }} credits per new user",
  "Get FREE {{ voucherAmount }}% per new user": "Get FREE {{ voucherAmount }}% per new user",
  "Enjoy exclusive promotions": "Enjoy exclusive promotions",
  "FREE voucher on your birthday": "FREE voucher on your birthday",
  "and more": "and more",
  "Denied camera access": "Denied camera access",
  'Permission to access the camera has been denied. Please try again and click "Allow" to scan the QR code.': 'Permission to access the camera has been denied. Please try again and click "Allow" to scan the QR code.',
  "Okay": "Okay",
  "Oops. You don't have enough balance to make the payment": "Oops. You don't have enough balance to make the payment",
  "Please confirm": "Please confirm",
  "Do you want to proceed with this feedback?": "Do you want to proceed with this feedback?",
  "Are you sure to purchase": "Are you sure to purchase",
  "credits for": "credits for",
  "Topup credit": "Topup credit",
  "Extended duration will be instantly reflected on machine after payment": "Extended duration will be instantly reflected on machine after payment",
  "Machine is currently busy": "Machine is currently busy",
  "Do you want to extend drying time?": "Do you want to extend drying time?",
  "Cuci Hari-Hari Bersama Cleanpro!": "Cuci Hari-Hari Bersama Cleanpro!",
  "Ready for a challenge?": "Ready for a challenge?",
  "Here's one for you - all you need to do is wash your laundry 10 times in 20 days at participating Cleanpro outlets. Stand a chance to win a Limited Edition Cleanpro Designer Laundry Bag worth up to RM40!": "Here's one for you - all you need to do is wash your laundry 10 times in 20 days at participating Cleanpro outlets. Stand a chance to win a Limited Edition Cleanpro Designer Laundry Bag worth up to RM40!",
  "Here's how to participate:": "Here's how to participate:",
  "Step 1: Scan the QR Code on the washer to launch Cleanpro Plus": "Step 1: Scan the QR Code on the washer to launch Cleanpro Plus",
  "Step 2: Pay for your wash with Cleanpro Plus": "Step 2: Pay for your wash with Cleanpro Plus",
  "Step 3: Complete x10 washes within 20 days to win": "Step 3: Complete x10 washes within 20 days to win",
  "Challenge Period: 01 July - 31 August 2023": "Challenge Period: 01 July - 31 August 2023",
  "Drop by your nearest Cleanpro outlet to wash your laundry with us for 20 days straight! Good luck!": "Drop by your nearest Cleanpro outlet to wash your laundry with us for 20 days straight! Good luck!",
  "TERMS & CONDITIONS": "TERMS & CONDITIONS",
  "PARTICIPATION CRITERIA": "PARTICIPATION CRITERIA",
  "1) Participant needs to register an account in Cleanpro Plus web app.": "1) Participant needs to register an account in Cleanpro Plus web app.",
  "2) Participant can use any washer of any capacity in any participating outlets to qualify for entries.": "2) Participant can use any washer of any capacity in any participating outlets to qualify for entries.",
  "3) Payment needs to be made with Cleanpro Plus web app.": "3) Payment needs to be made with Cleanpro Plus web app.",
  "4) Washes must be completed and paid (with no dispute whatsoever) within campaign period.": "4) Washes must be completed and paid (with no dispute whatsoever) within campaign period.",
  "5) Campaign period starts from 1st July 2023 at 00:00 and ends at 31st August 2023 at 23:59.": "5) Campaign period starts from 1st July 2023 at 00:00 and ends at 31st August 2023 at 23:59.",
  "WINNERS & PRIZES": "WINNERS & PRIZES",
  "1) Cleanpro will notify the winners of the campaign via registered WhatsApp number or email address provided, within 4 weeks after the campaign ends.": "1) Cleanpro will notify the winners of the campaign via registered WhatsApp number or email address provided, within 4 weeks after the campaign ends.",
  "2) Any unclaimed prizes after 2 weeks from notification date will not be entertained thereafter.": "2) Any unclaimed prizes after 2 weeks from notification date will not be entertained thereafter.",
  "3) Upon completing the ten (10) washes within twenty (20) days campaign, participants will be a winner and be eligible to win ONE (1) Cleanpro Designer Laundry Bag.": "3) Upon completing the ten (10) washes within twenty (20) days campaign, participants will be a winner and be eligible to win ONE (1) Cleanpro Designer Laundry Bag.",
  "4) Winners are eligible to win more than ONE (1) Cleanpro Designer Laundry Bag in this campaign.": "4) Winners are eligible to win more than ONE (1) Cleanpro Designer Laundry Bag in this campaign.",
  "5) Winners will be given the choice of his/her preferred laundry bag size; but only ONE (1) can be selected for each qualified win.": "5) Winners will be given the choice of his/her preferred laundry bag size; but only ONE (1) can be selected for each qualified win.",
  "A) XL Size - Blue bag with up to 20KG in capacity": "A) XL Size - Blue bag with up to 20KG in capacity",
  "B) L Size - White bag with up to 12KG in capacity": "B) L Size - White bag with up to 12KG in capacity",
  "6) Time and location for collection of prize will be informed via Whatsapp number and/or email address provided.": "6) Time and location for collection of prize will be informed via Whatsapp number and/or email address provided.",
  "OTHER": "OTHER",
  "1) Changes to the terms and conditions will be at Cleanpro's discretion; without prior notice.": "1) Changes to the terms and conditions will be at Cleanpro's discretion; without prior notice.",
  "2) Completed wash is defined by washes performed with Cleanpro's washer and payments within Cleanpro Plus web app are made with no disputes.": "2) Completed wash is defined by washes performed with Cleanpro's washer and payments within Cleanpro Plus web app are made with no disputes.",
  "3) By participating in this campaign automatically indicates that the Participant has agreed to all the campaign's Terms and Conditions as stipulated.": "3) By participating in this campaign automatically indicates that the Participant has agreed to all the campaign's Terms and Conditions as stipulated.",
  "Welcome Voucher": "Welcome Voucher",
  "Welcome to the Cleanpro Plus family! This first gift is just the beginning. Stay tuned for a series of exciting promotions and campaigns, exclusively for Cleanpro Plus users.": "Welcome to the Cleanpro Plus family! This first gift is just the beginning. Stay tuned for a series of exciting promotions and campaigns, exclusively for Cleanpro Plus users.",
  "Terms & Conditions": "Terms & Conditions",
  "Welcome Voucher is valid for one-time use only.": "Welcome Voucher is valid for one-time use only.",
  "There is no minimum spend required for the voucher to be used.": "There is no minimum spend required for the voucher to be used.",
  "Welcome Voucher is auto-credited to the user's account at the point of registration on Cleanpro Plus and valid for {{ duration }} days only._one": "Welcome Voucher is auto-credited to the user's account at the point of registration on Cleanpro Plus and valid for {{ duration }} day only.",
  "Welcome Voucher is auto-credited to the user's account at the point of registration on Cleanpro Plus and valid for {{ duration }} days only._other": "Welcome Voucher is auto-credited to the user's account at the point of registration on Cleanpro Plus and valid for {{ duration }} days only.",
  "Users are eligible to use their Welcome Voucher AFTER the completion of their first wash or dry cycle using the same Cleanpro Plus account.": "Users are eligible to use their Welcome Voucher AFTER the completion of their first wash or dry cycle using the same Cleanpro Plus account.",
  "Welcome Voucher cannot be used together with any ongoing promotions or any other promotional vouchers.": "Welcome Voucher cannot be used together with any ongoing promotions or any other promotional vouchers.",
  "The voucher is final and cannot be refunded in form of cash or credit to the user.": "The voucher is final and cannot be refunded in form of cash or credit to the user.",
  "Cleanpro reserves the right to change the terms and conditions without prior notice.": "Cleanpro reserves the right to change the terms and conditions without prior notice.",
  "Collect 10 e-stamps to earn FREE {{ voucherAmount }} credits voucher (worth {{ currency }}{{ voucherAmount }})_one": "Collect 10 e-stamps to earn FREE {{ voucherAmount }} credit voucher (worth {{ currency }}{{ voucherAmount }})",
  "Collect 10 e-stamps to earn FREE {{ voucherAmount }} credits voucher (worth {{ currency }}{{ voucherAmount }})_other": "Collect 10 e-stamps to earn FREE {{ voucherAmount }} credits voucher (worth {{ currency }}{{ voucherAmount }})",
  "Collect 10 e-stamps to earn FREE {{ voucherAmount }}% voucher (worth {{ voucherAmount }}%)": "Collect 10 e-stamps to earn FREE {{ voucherAmount }}% voucher (worth {{ voucherAmount }}%)",
  "Collect 10 e-Stamp to earn FREE Voucher": "Collect 10 e-Stamp to earn FREE Voucher",
  "With every 10 e-stamps that you collected, you will be rewarded with a FREE {{ voucherAmount }} credits voucher (worth {{ currency }}{{ voucherAmount }}). Make your laundry routine not just hassle-free, but also rewarding._one": "With every 10 e-stamps that you collected, you will be rewarded with a FREE {{ voucherAmount }} credit voucher (worth {{ currency }}{{ voucherAmount }}). Make your laundry routine not just hassle-free, but also rewarding.",
  "With every 10 e-stamps that you collected, you will be rewarded with a FREE {{ voucherAmount }} credits voucher (worth {{ currency }}{{ voucherAmount }}). Make your laundry routine not just hassle-free, but also rewarding._other": "With every 10 e-stamps that you collected, you will be rewarded with a FREE {{ voucherAmount }} credits voucher (worth {{ currency }}{{ voucherAmount }}). Make your laundry routine not just hassle-free, but also rewarding.",
  "With every 10 e-stamps that you collected, you will be rewarded with a FREE {{ voucherAmount }}% credits voucher (worth {{ voucherAmount }}%). Make your laundry routine not just hassle-free, but also rewarding.": "With every 10 e-stamps that you collected, you will be rewarded with a FREE {{ voucherAmount }}% credits voucher (worth {{ voucherAmount }}%). Make your laundry routine not just hassle-free, but also rewarding.",
  "How to participate:": "How to participate:",
  "Prizes to be won:": "Prizes to be won:",
  "RM10 OFF Welcome Voucher": "RM10 OFF Welcome Voucher",
  "Welcome to the Cleanpro family, here is an appreciation voucher from us. Thank you for attending the COPE Investors Conference.": "Welcome to the Cleanpro family, here is an appreciation voucher from us. Thank you for attending the COPE Investors Conference.",
  "Welcome Voucher | COPE Investors Conference": "Welcome Voucher | COPE Investors Conference",
  "Payment methods": "Payment methods",
  "Tap to proceed with your preferred mode of payment": "Tap to proceed with your preferred mode of payment",
  "Cleanpro Plus credits": "Cleanpro Plus credits",
  "E-wallet or credit/debit cards": "E-wallet or credit/debit cards",
  "Go to homepage": "Go to homepage",
  // backend response or error
  "Admin is unauthorized to login": "Admin is unauthorized to login",
  "Internal Error": "Internal Error",
  "Please enter the correct credentials": "Please enter the correct credentials",
  "Operator not found": "Operator not found",
  "Admin not found": "Admin not found",
  "Distributor not found": "Distributor not found",
  "Client not found": "Client not found",
  "Outlet not found": "Outlet not found",
  "Token Package not found": "Token Package not found",
  "Top up history not found": "Top up history not found",
  "Voucher not found": "Voucher not found",
  "Faq not found": "Faq not found",
  "Faq Group not found": "Faq Group not found",
  "News not found": "News not found",
  "Transaction not found": "Transaction not found",
  "Please Login Again": "Please Login Again",
  "Decoding Error": "Decoding Error",
  "Some error occured, please contact the dev team if this error continues.": "Some error occured, please contact the dev team if this error continues.",
  "Please fill in all the required fields": "Please fill in all the required fields",
  "Only success transaction can be refund": "Only success transaction can be refund",
  "Only token payment method can be refund": "Only token payment method can be refund",
  "The discount code has been used, please change to other code": "The discount code has been used, please change to other code",
  "Cannot use ewallet in sandbox": "Cannot use ewallet in sandbox",
  "Please close the door to proceed your payment": "Please close the door to proceed your payment",
  "Machine is Out of Service": "Machine is Out of Service",
  "Machine is currently running, please try again later": "Machine is currently running, please try again later",
  "Machine is currently offline, please try again later": "Machine is currently offline, please try again later",
  "Outlet not participating in Loyalty program": "Outlet not participating in Loyalty program",
  "Please log in to make the payment": "Please log in to make the payment",
  "Package not found, please refresh and try again": "Package not found, please refresh and try again",
  "You have reached the maximum number of purchase": "You have reached the maximum number of purchase",
  "Store not found, please try again later": "Store not found, please try again later",
  "Incorrect Password": "Incorrect Password",
  "Token already top up": "Token already top up",
  "Payment failed": "Payment failed",
  "Transaction Failure": "Transaction Failure",
  "Transaction Refunded": "Transaction Refunded",
  "Transaction Pending": "Transaction Pending",
  "Please enter your mobile number": "Please enter your mobile number",
  "User is unauthorized to register": "User is unauthorized to register",
  "Please proceed to sign up": "Please proceed to sign up",
  "OTP expired, please request a new OTP": "OTP expired, please request a new OTP",
  "Incorrect verification code": "Incorrect verification code",
  "This referrer ID is not valid": "This referrer ID is not valid",
  "This referrer ID is different operator": "This referrer ID is different operator",
  "This referrer ID is exceed the limit": "This referrer ID is exceed the limit",
  "User is unauthorized to login": "User is unauthorized to login",
  "Click here to register": "Click here to register",
  "User is unauthorized to login. Please contact the admin": "User is unauthorized to login. Please contact the admin",
  "User is unauthorized": "User is unauthorized",
  "This outlet does not participate in the Loyalty Program": "This outlet does not participate in the Loyalty Program",
  "This outlet is not belongs to the operator": "This outlet is not belongs to the operator",
  "Processing request": "Processing request",
  "This user is not verified": "This user is not verified",
  "Insufficient token to make payment": "Insufficient token to make payment",
  "This voucher is not available for use": "This voucher is not available for use",
  "This voucher is belongs to other user": "This voucher is belongs to other user",
  "Invalid Discount Code": "Invalid Discount Code",
  "This discount code cannot use in this outlet": "This discount code cannot use in this outlet",
  "This discount code was expired": "This discount code was expired",
  "This discount code cannot use in washer machine transaction": "This discount code cannot use in washer machine transaction",
  "This discount code cannot use in dryer machine transaction": "This discount code cannot use in dryer machine transaction",
  "This discount code was fully redeemed today, try again tomorrow": "This discount code was fully redeemed today, try again tomorrow",
  "You have fully redeemed this discount code": "You have fully redeemed this discount code",
  "This discount code have been redeemed": "This discount code have been redeemed",
  "This discount code is not applicable": "This discount code is not applicable",
  "Insufficient stamp": "Insufficient stamp",
  "Please provide at least one voucher setting": "Please provide at least one voucher setting",
  "Previous batch are still generating, please try again later": "Previous batch are still generating, please try again later",
  "Number of voucher code should be more than 0": "Number of voucher code should be more than 0",
  "Number of voucher code should be less than 50000": "Number of voucher code should be less than 50000",
  "Number of discount code should be more than 0": "Number of discount code should be more than 0",
  "Number of discount code should be less than 50000": "Number of discount code should be less than 50000",
  "No Item": "No Item",
  "Please add item": "Please add item",
  "Staff not found": "Staff not found",
  "This task already completed": "This task already completed",
  "Please complete the wash task first": "Please complete the wash task first",
  "Please complete the dry task first": "Please complete the dry task first",
  "Please select at least one outlet": "Please select at least one outlet",
}

const translationMs = {
  "LOGIN": "LOG MASUK",
  "REGISTER": "DAFTAR",
  "SKIP TO PAYMENT": "LANGKAU KE BAYARAN",
  "WELCOME": "SELAMAT DATANG",
  "Error": "Ralat",
  "Please enter the correct phone number format. <br /><br /> <small>Example: 0129465628</small>": "Sila masukkan format nombor telefon yang betul. <br /><br /> <small>Contoh: 0129465628</small>",
  "Info": "Maklumat",
  "Please scan QRCode to Login": "Sila mengimbas Kod QR untuk Log Masuk",
  "Forgot Password": "Lupa kata laluan",
  "Seems like you have forgotten your password<br />Create a temporary password?": "Nampaknya anda terlupa kata laluan anda<br />Adakah anda ingin membuat kata laluan sementara?",
  "Cancel": "Batal",
  "Confirm": "Sahkan",
  "Reset Your Password": "Reset kata laluan anda",
  "Enter Your Phone Number / Email Address": "Masukkan Nombor Telefon / Alamat E-mel Anda",
  "Please enter phone number / email address": "Sila masukkan nomber telefon / alamat e-mel",
  "Submit": "Hantar",
  "Please enter a valid phone number": "Sila masukkan nombor telefon yang sah",
  "Please enter a valid email address": "Sila masukkan alamat emel yang sah",
  "Success!": "Berjaya!",
  "You may login using your one time password now.": "Anda boleh log masuk menggunakan kata laluan sekali anda sekarang.",
  "Phone Number": "Nombor telefon",
  "Password": "Kata Laluan",
  "Don't have an account?": "Tidak mempunyai akaun?",
  "Forgot password?": "Lupa kata laluan?",
  "Unable to register without a QRCode": "Tidak dapat mendaftar tanpa Kod QR",
  "Please read and agree to the privacy policies, terms and conditions": "Sila baca dan setuju dengan polisi privasi, terma dan syarat",
  "Password length must be at least 6 characters": "Panjang kata laluan mestilah sekurang-kurangnya 6 huruf",
  "Please enter the confirmation code that was sent to your phone": "Sila masukkan kod pengesahan yang dihantar ke telefon anda",
  "Example: 012xxxxxxx": "Contoh: 012xxxxxxx",
  "Example: 09xxxxxxxx": "Contoh: 09xxxxxxxx",
  "Verification Method": "Kaedah Pengesahan",
  "Name": "Nama",
  "Email": "E-mel",
  "optional": "Tidak wajib diisi",
  "Birth Year": "Tahun lahir",
  "Year": "Tahun",
  "Birth Month": "Bulan Lahir",
  "Month": "Bulan",
  "Jan": "Jan",
  "Feb": "Feb",
  "Mar": "Mac",
  "April": "April",
  "May": "Mei",
  "June": "Jun",
  "July": "Julai",
  "Aug": "Ogos",
  "Sept": "Sept",
  "Oct": "Okt",
  "Nov": "Nov",
  "Dec": "Dis",
  "Birth Day": "Hari Lahir",
  "Day": "Hari",
  "OTP": "OTP",
  "OTP Confirmation": "Pengesahan OTP",
  "I agree to the": "Saya setuju dengan",
  "Privacy Policy": "Polisi Privasi",
  "and": "dan",
  "Terms": "Terma",
  "Conditions": "Syarat",
  "SEND CODE": "HANTAR KOD",
  "Our One-Time Password (OTP) emails might end up in your spam or junk folder. We kindly ask you to take a moment to check your spam mailbox.": "E-mel Kata Laluan Satu Masa (OTP) kami mungkin berada dalam folder spam atau sampah anda. Kami mohon anda meluangkan sedikit masa untuk menyemak peti mel spam anda.",
  "Already have an account? Login": "Sudah mempunyai akaun? Log masuk",
  "Didn't receive a code?": "Tidak menerima kod?",
  "Resend Code": "Hantar semula Kod",
  "collected": "dikumpulkan",
  "Collect": "Kumpulkan",
  "stamps": "setem",
  "get 1 voucher": "dapatkan 1 baucar",
  "Minimum": "Perbelanjaan minimum",
  "spending to collect each washer stamp": "untuk mengumpulkan setiap setem mesin basuh",
  "spending to collect each dryer stamp": "untuk mengumpulkan setiap setem pengering",
  "spending to collect each stamp": "untuk mengumpulkan setiap setem",
  "Device don't have a camera": "Peranti tidak mempunyai kamera",
  "This is not a valid QRCode": "Ini bukan Kod QR yang sah",
  "Select Camera": "Pilih Kamera",
  "Switch": "Tukar",
  "Camera": "Kamera",
  "Loading": "Memuatkan",
  "Scan the QR code on the machine.": "Imbas Kod QR pada mesin.",
  "Switch Camera": "Tukar Kamera",
  "Off torch light": "Matikan lampu suluh",
  "On torch light": "Hidupkan lampu suluh",
  "switch camera if your camera is not clear": "tukar kamera jika kamera anda tidak jelas",
  "Switch your camera if the display is not clear": "Tukar kamera anda jika paparan tidak jelas",
  "Please allow this webapp to access your camera": "Sila berikan kebenaran untuk aplikasi ini mengakses kamera anda",
  "DISCOUNT": "DISKAUN",
  "Congratulation": "Tahniah",
  "Expired Date": "Tarikh luput",
  "Voucher": "Baucar",
  "Vouchers": "Baucar",
  "Something went wrong": "Hadapi masalah",
  "Stamping Collection": "Koleksi Setem",
  "Total e-Token": "Jumlah Token",
  "Total Wash": "Jumlah Cuci",
  "Total Dry": "Jumlah Kering",
  "Reload": "Tambah nilai",
  "Washer": "Mesin basuh",
  "Dryer": "Pengering",
  "Congratulations!": "Tahniah!",
  "You have completed your Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "Anda telah melengkapkan koleksi setem anda.<br />Pemberian baucar akan diberikan dan koleksi setem anda telah di set semula",
  "You have completed Washer Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "Anda telah melengkapkan koleksi setem mesin basuh.<br />Pemberian baucar akan diberikan dan koleksi setem anda telah di set semula",
  "You have completed Dryer Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "Anda telah melengkapkan koleksi setem pengering.<br />Pemberian baucar akan diberikan dan koleksi setem anda telah di set semula",
  "Latest News": "Berita Terkini",
  "Transaction History": "Sejarah Transaksi",
  "Stamping": "Setem",
  "Transaction": "Transaksi",
  "News": "Berita",
  "Confirmation": "Pengesahan",
  "Logout Session?": "Sesi Log Keluar?",
  "NO": "TIDAK",
  "OK": "OK",
  "Account": "Akaun",
  "FAQs": "Soalan Lazim",
  "Feedback": "Maklum balas",
  "LOGOUT": "LOG KELUAR",
  "Logout": "Log Keluar",
  "Are you sure you want to submit your feedback?": "Adakah anda pasti mahu menghantar maklum balas anda?",
  "No": "Tidak",
  "Yes": "Ya",
  "Success": "Berjaya",
  "Failed": "Gagal",
  "Pending": "Belum Selesai",
  "Your feedback is submitted successfully": "Maklum balas anda berjaya dihantar",
  "Please fill in all the required fields before submit.": "Sila isi semua ruangan yang diperlukan sebelum menghantar.",
  "Give us your feedback!": "Beri kami maklum balas anda!",
  "Type your name here (optional)": "Taipkan nama anda di sini (Tidak wajib diisi)",
  "required": "diperlukan",
  "Type your number here": "Taipkan nombor anda di sini",
  "Outlet": "Kedai",
  "Select an outlet": "Pilih kedai",
  "Outlet Cleanliness": "Kebersihan Kedai",
  "Washer Machine Performance": "Prestasi Mesin Basuh",
  "Dryer Machine Performance": "Prestasi Mesin Pengering",
  "Customer Service": "Khidmat Pelanggan",
  "Comment": "Komen",
  "Type your comment here (optional)": "Taipkan komen anda di sini (Tidak wajib diisi)",
  "SUBMIT": "HANTAR",
  "Join as a member now!": "Sertailah sebagai ahli sekarang!",
  "Join as a loyalty member now to get more benefits such as vouchers and free machine usage!<br /><br /><strong>Register as loyalty member?</strong>": "Sertailah sebagai ahli sekarang untuk mendapatkan lebih banyak faedah seperti baucar dan penggunaan mesin secara percuma!<br /><br /><strong>Daftar sebagai ahli?</strong>",
  "Maybe Next Time": "Mungkin lain kali",
  "Yes, Register Now": "Ya, Daftar Sekarang",
  "Washer Payment": "Bayaran Mesin Basuh",
  "Dryer Payment": "Bayaran Pengering",
  "Vending Machine Payment": "Bayaran Mesin Layan Diri",
  "Online": "Dalam talian",
  "Offline": "Luar talian",
  "Running": "Berjalan",
  "Vacant": "Kosong",
  "Vending Machine": "Mesin Layan Diri",
  "Discount": "Diskaun",
  "No Vouchers Available": " Tidak Ada Baucar",
  "Payment Method": "Kaedah Pembayaran",
  "Would you like to pay with?": "Anda ingin membayar dengan?",
  "e-Token": "e-Token",
  "E-TOKEN": "E-TOKEN",
  "E-Wallet": "E-Dompet",
  "Duitnow QR code": "Kod QR Duitnow",
  "The Duitnow QR code will be downloaded and you need to open your banking app to make the payment. Pay {{ currency }}{{ totalPaymentAmount }} to {{ machineName }}?": "Kod QR Duitnow akan dimuat turun dan anda perlu membuka apl perbankan anda untuk membuat pembayaran. Bayar {{ currency }}{{ totalPaymentAmount }} kepada {{ machineName }}?",
  "Pay": "Bayar",
  "to": "kepada",
  "You may under utilized the voucher.": "Anda kurang menggunakan baucar.",
  "Proceed to pay": "Teruskan membayar",
  "Processing ...": "Memproses ...",
  "You can't pay with e-token if voucher is in used, please remove your voucher to pay with e-token.": "Anda tidak boleh membayar dengan penggunaan e-token jika menggunakan baucar, sila keluarkan baucar anda untuk membayar dengan e-token.",
  "Insufficient e-Tokens": "E-Token tidak mencukupi",
  "Reload your e-token now?": "Tambah nilai e-token anda sekarang?",
  "Reload now!": "Tambah nilai sekarang!",
  "You have": "Anda ada",
  "e-Tokens": "e-Token",
  "Tokens to": "Token kepada",
  "This machine is currently offline, please try again later.": "Mesin ini tiada dalam talian, sila cuba sebentar lagi.",
  "MACHINE": "MESIN",
  "Machine": "Mesin",
  "TYPE": "JENIS",
  "CAPACITY": "KAPASITI",
  "DURATION": "JANGKA MASA",
  "Select Duration": "Pilih Jangka Masa",
  "AMOUNT TO PAY": "JUMLAH BAYARAN",
  "PRICE": "HARGA",
  "VOUCHER": "BAUCAR",
  "Select Voucher >": "Pilih Baucar >",
  "TOTAL": "JUMLAH",
  "PAY": "BAYAR",
  "Remove Voucher": "Keluarkan Baucar",
  "Select Your Voucher": "Pilih Baucar Anda",
  "min": "min",
  "MIN": "MIN",
  "Select Payment Amount": "Pilih Jumlah Pembayaran",
  "Select Water Temperature": "Pilih Suhu Air",
  "Select temperature": "Pilih suhu",
  "Cold": "Sejuk",
  "Warm": "Suam",
  "Hot": "Panas",
  "e-Tokens to": "e-Token kepada",
  "No Purchase History": "Tiada Sejarah Pembelian",
  "e-tokens": "e-token",
  "No Promotions": "Tiada Promosi",
  "New Password": "Kata laluan baharu",
  "Back": "Kembali",
  "Buy this package?": "Beli pakej ini?",
  "Purchase": "Beli",
  "e-Tokens for": "e-Token dengan",
  "Reload Tokens": "Tambah nilai kepada token",
  "Are you sure you want to purchase {{ tokenAmount }} e-Token for {{ currency }}{{ paymentAmount }}?": "Adakah anda pasti mahu membeli {{ tokenAmount }} e-Token untuk {{ currency }}{{ paymentAmount }}?",
  "Current e-Token": "E-Token Sekarang",
  "History": "Sejarah",
  "Your profile picture has been updated!": "Gambar profil anda telah dikemas kini!",
  "Please only use png, jpg or jpeg file extension type": "Sila gunakan jenis sambungan fail png, jpg atau jpeg",
  "Please only use image type file": "Sila gunakan fail jenis gambar sahaja",
  "User name has updated successfully!": "Nama pengguna berjaya dikemas kini!",
  "Email Address updated successfully": "E-mel berjaya dikemas kini",
  "Phone number updated successfully": "Nombor telefon dikemas kini",
  "Password must be at least 6 characters": "Kata laluan mestilah sekurang-kurangnya 6 huruf",
  "Password updated successfully": "Kata laluan berjaya dikemas kini",
  "User Settings": "Tetapan Pengguna",
  "Profile Picture": "Gambar profil",
  "Change Password": "Tukar kata laluan",
  "Do you want to submit your feedback?": "Adakah anda ingin menghantar maklum balas anda?",
  "Thank You!": "Terima Kasih!",
  "Thank you for using our services.": "Terima kasih kerana menggunakan perkhidmatan kami.",
  "BACK TO HOMEPAGE": "KEMBALI KE HALAMAN UTAMA",
  "How to pay?": "Bagaimana untuk membayar?",
  "Step 1": "Langkah 1",
  "After scan the QR code on the machine, select either register new account, login your account or skip to payment.": "Setelah mengimbas kod QR pada mesin, pilih sama ada daftar akaun baru, log masuk akaun anda atau langkau ke pembayaran.",
  "Step 2-1": "Langkah 2-1",
  "For Dryer – Select your desired duration. Different duration will have different prices.": "Untuk Pengering - Pilih jangka masa yang anda inginkan. Tempoh yang berbeza akan mempunyai harga yang berbeza.",
  "Step 2-2": "Langkah 2-2",
  "For Washer – Select your desired water temperature. Different water temperature will have different prices.": "Untuk Mesin Basuh - Pilih suhu air yang anda inginkan. Suhu air yang berbeza akan mempunyai harga yang berbeza.",
  "Step 3": "Langkah 3",
  "For login user, Click on ‘Select Voucher’ to select your voucher.": "Untuk pengguna log masuk, Klik pada ‘Pilih Baucar’ untuk memilih baucar anda.",
  "Step 4": "Langkah 4",
  "Click on ‘PAY’ button to pay. For login user, select your payment method to pay.": "Klik butang ‘BAYAR’ untuk membayar. Untuk pengguna log masuk, pilih kaedah pembayaran anda untuk membayar.",
  "Step 5": "Langkah 5",
  "After confirm the price and machine details, click on ‘OK’ button to pay.": "Selepas mengesahkan harga dan butiran mesin, klik butang ‘OK’ untuk membayar.",
  "How to reload your e-Token?": "Bagaimana tambah nilai e-Token anda?",
  'Click on the "Reload" button.': 'Klik pada butang "Tambah Nilai".',
  "Select the package that you wish to purchase.": "Pilih pakej yang ingin anda beli.",
  'Click on "Purchase" as confirmation before payment page.': 'Klik pada "Beli" sebagai pengesahan sebelum halaman pembayaran.',
  "Is e-token can be refund/transfer back to e-wallet?": "Adakah e-token boleh dipulangkan/pindah semula ke e-dompet?",
  "Why can't I applied my voucher?": "Mengapakah saya tidak boleh menggunakan baucar saya?",
  "When there is a Happy Hour Promotion is on going, you are unable to apply your voucher temporarily.": "Setiap kali promosi sedang berlangsung, anda tidak dapat menggunakan baucar anda.",
  "How to apply my voucher?": "Bagaimanakah mengguna baucar saya?",
  'Click on the "Select Voucher" button.': 'Klik pada butang "Pilih Baucar".',
  "Select the voucher that you wish to apply.": "Pilih baucar yang ingin anda gunakan.",
  'Click on "Pay" once you have verified the voucher is successfully applied.': 'Klik "Bayar" setelah anda mengesahkan baucar berjaya digunakan.',
  "Step 2": "Langkah 2",
  "Language has updated successfully!": "Bahasa berjaya dikemas kini!",
  "Change Language": "Tukar Bahasa",
  "Language": "Bahasa",
  "Select language": "Pilih Bahasa",
  "Referral Code": "Kod Rujukan",
  "Copied!": "Disalin!",
  "Inviting friends to get more benefits!": "Jemput rakan-rakan untuk mendapatkan lebih banyak faedah!",
  "Would you like to share your referral code to your friends & family?": "Adakah anda ingin berkongsi kod rujukan anda kepada rakan & keluarga anda?",
  "Refer a friend and get": "Rujuk rakan dan dapatkan",
  "Share the love to your friends & family?": "Kongsi kasih sayang kepada rakan & keluarga anda?",
  "voucher": "baucar",
  "token": "token",
  "stamp": "setem",
  "washer stamp": "setem mesin basuh",
  "dryer stamp": "setem pengering",
  "Learn More": "Ketahui Lebih Lanjut",
  "Welcome Gift": "Hadiah Selamat Datang",
  "Share Your Referral Code Now": "Kongsi Kod Rujukan Anda Sekarang",
  "Birthday Gift for You": "Hadiah Hari Lahir untuk Anda",
  "Let's Pay with E-Token": "Marilah Bayar dengan E-Token",
  "Collect 10 Stamps and Get Voucher": "Kumpul 10 Setem dan Dapatkan Baucar",
  "The voucher will be given if you registered your account successfully.": "Baucar akan diberikan jika anda berjaya mendaftar akaun anda.",
  "There is no expiration date for this voucher.": "Tiada tarikh luput bagi baucar ini.",
  "The voucher can be used for dryer machine only.": "Baucar boleh digunakan untuk mesin pengering sahaja.",
  "The voucher can be used for washer machine only.": "Baucar boleh digunakan untuk mesin basuh sahaja.",
  "The voucher can be used for Drop n Go transaction only.": "Baucar boleh digunakan untuk transaksi Drop n Go sahaja.",
  "The voucher can be used for washer machine or Drop n Go transaction.": "Baucar boleh digunakan untuk mesin basuh atau transaksi Drop n Go.",
  "The voucher can be used for dryer machine or Drop n Go transaction.": "Baucar boleh digunakan untuk mesin pengering atau transaksi Drop n Go.",
  "The voucher can be used for dryer or washer machine.": "Baucar boleh digunakan untuk mesin pengering atau mesin basuh.",
  "The voucher can be used for washer machine, dryer machine or Drop n Go transaction.": "Baucar boleh digunakan untuk mesin basuh, mesin pengering atau transaksi Drop n Go.",
  "When you applied the voucher during payment, it will give you": "Apabila anda menggunakan baucar semasa pembayaran, ia akan memberi anda",
  "discount.": "diskaun.",
  "When promotion is on going, the voucher is not applicable.": "Apabila promosi sedang berjalan, baucar tidak boleh diguna.",
  "The voucher cannot be used if your payment method is E-Token.": "Baucar tidak boleh digunakan jika kaedah pembayaran anda ialah E-Token.",
  "Your referral code can be found in user settings page.": "Kod rujukan anda boleh didapatkan dalam halaman tetapan pengguna.",
  "You can get": "Anda boleh dapatkan",
  "stamp for the reward.": "setem sebagai ganjaran.",
  "e-token for the reward.": "e-token sebagai ganjaran.",
  "e-token based on transaction payment amount for the reward.": "e-token berdasarkan jumlah pembayaran transaksi sebagai ganjaran.",
  "for the reward.": "sebagai ganjaran.",
  "based on transaction payment amount for the reward.": "berdasarkan jumlah pembayaran transaksi sebagai ganjaran.",
  "You can get a voucher for the reward": "Anda boleh mendapatkan baucar",
  "which have": "yang mempunyai",
  "it can be used for dryer machine only.": "ia boleh digunakan untuk mesin pengering sahaja.",
  "it can be used for washer machine only.": "ia boleh digunakan untuk mesin basuh sahaja.",
  "it can be used for Drop n Go transaction only.": "ia boleh digunakan untuk transaksi Drop n Go sahaja.",
  "it can be used for dryer or washer machine.": "ia boleh digunakan untuk pengering atau mesin basuh.",
  "it can be used for dryer machine or Drop n Go transaction.": "ia boleh digunakan untuk pengering atau transaksi Drop n Go.",
  "it can be used for washer machine or Drop n Go transaction.": "ia boleh digunakan untuk mesin basuh atau transaksi Drop n Go.",
  "it can be used for washer machine, dryer machine or Drop n Go transaction.": "ia boleh digunakan untuk mesin basuh, pengering atau transaksi Drop n Go.",
  "washer stamp for the reward.": "setem mesin basuh sebagai ganjaran.",
  "dryer stamp for the reward.": "setem pengering sebagai ganjaran.",
  "washer stamp and": "setem pencuci dan",
  "cleanproBirthdayShortDesc": "Tebus baucar hari jadi bernilai {{ birthdayVoucherAmount }} kredit pada hari istimewa anda dengan Cleanpro Plus. Klik  untuk maklumat lebih lanjut.",
  'When you click on "PAY" button on the payment page, you can choose either using E-wallet or E-token to pay.': 'Apabila anda mengklik butang "BAYAR" pada halaman pembayaran, anda boleh memilih sama ada menggunakan E-dompet atau E-token untuk membayar.',
  "When you using your voucher during payment, you will be unable to pay with E-token.": "Apabila anda menggunakan baucar anda semasa pembayaran, anda tidak dapat membayar dengan E-token.",
  "You can reload your E-token by clicking the reload button in the stamping page and select the package to purchase.": "Anda boleh tambah nilai E-token anda dengan mengklik butang tambah nilai dalam halaman setem dan pilih pakej untuk dibeli.",
  "You can reload your E-token by clicking the reload button in the home page and select the package to purchase.": "Anda boleh tambah nilai E-token anda dengan mengklik butang tambah nilai dalam laman utama dan pilih pakej untuk dibeli.",
  "You can view your E-token reload history and E-token transaction history in transaction page.": "Anda boleh melihat sejarah tambah nilai E-token dan sejarah transaksi E-token anda dalam halaman transaksi.",
  "You can view your E-token transaction history in transaction page.": "Anda boleh melihat sejarah transaksi E-token anda dalam halaman transaksi.",
  "Using either dryer machine or washer machine with the minimum spending of": "Menggunakan sama ada mesin pengering atau mesin basuh dengan perbelanjaan minimum",
  "to collect one stamp.": "untuk mengumpulkan satu setem.",
  "The voucher will be given if you have collected 10 stamps.": "Baucar akan diberikan sekiranya anda telah mengumpulkan 10 keping setem.",
  "Using dryer machine with the minimum spending of": "Menggunakan mesin pengering dengan perbelanjaan minimum",
  "Using washer machine with the minimum spending of": "Menggunakan mesin basuh dengan perbelanjaan minimum",
  "to collect one dryer stamp.": "untuk mengumpulkan satu setem pengering.",
  "to collect one washer stamp.": "untuk mengumpulkan satu setem mesin basuh.",
  "The voucher will be given if you have collected 10 dryer stamps or 10 washer stamps.": "Baucar akan diberikan jika anda telah mengumpulkan 10 setem pengering atau 10 setem mesin basuh.",
  "For the voucher given by the dryer stamps,": "Bagi baucar yang diberikan oleh setem pengering,",
  "For the voucher given by the washer stamps,": "Bagi baucar yang diberikan oleh setem mesin basuh,",
  "When promotion is on going, all the vouchers is not applicable.": "Apabila promosi sedang berjalan, semua baucar tidak boleh digunakan.",
  "All the vouchers cannot be used if your payment method is E-Token.": "Semua baucar tidak boleh digunakan jika kaedah pembayaran anda adalah E-Token.",
  "Description": "Penerangan",
  "Refer Friends": "Rujuk Kawan",
  "View our guideline": "Lihat garis panduan kami",
  "When a friend / family register using your referral code, and completed a transaction with a minimum spending of": "Apabila rakan / keluarga mendaftar menggunakan kod rujukan anda, dan menyelesaikan transaksi dengan perbelanjaan minimum",
  ", you will be rewarded automatically.": ", anda akan diberi ganjaran secara automatik.",
  "One user only allow to have": "Setiap pengguna hanya membenarkan untuk memiliki",
  "referrals.": "rujukan.",
  "Confirmation Password": "Pengesahan Kata Laluan",
  "Password and confirmation password should be same.": "Kata laluan dan pengesahan kata laluan hendaklah sama.",
  "You haven't applied your voucher": "Anda mempunyai baucar yang belum digunakan",
  "Would you like to continue your payment without using your voucher?": "Adakah anda pasti ingin meneruskan pembayaran tanpa menggunakan baucar?",
  "Continue": "Teruskan",
  "Apply voucher": "Gunakan baucar",
  "Transfer": "Pemindahan",
  "Transfer Tokens": "Token Penmindahan",
  "Please fill in the phone number": "Sila isikan nombor telefon",
  "Please fill in the email": "Sila isikan emel",
  "Please enter the phone number / email that you want to transfer to": "Sila masukkan nombor telefon / e-mel yang anda ingin pindahkan",
  "Please fill in the transfer amount": "Sila isikan jumlah pemindahan",
  "Transfer amount should be more than 0 and less than your current e-Token": "Jumlah pemindahan hendaklah lebih daripada 0 dan kurang daripada e-Token anda",
  "Please enter your password": "Sila masukkan kata laluan anda",
  "Member ID": "ID ahli",
  "E-Token Transfer Amount": "Jumlah Pemindahan E-Token",
  "Please confirm the details before proceeding": "Sila sahkan butiran sebelum meneruskan",
  "Transfer From": "Pemindahan Dari",
  "Transfer To": "Pemindahan Kepada",
  "Transfer Amount": "Jumlah Pemindahan",
  "Transfer Successfully!": "Pemindahan Berjaya!",
  "Transfer Time": "Masa Pemindahan",
  "Token": "Token",
  "Done": "Selesai",
  "Next": "Seterusnya",
  "Enter Password": "Masukkan kata laluan",
  "Transfer E-Token Amount": "Pindahkan Amaun E-Token",
  "User not found": "Pengguna tidak dijumpai",
  "Password is incorrect": "Kata laluan salah",
  "Please go to your account setting and reset your password": "Sila pergi ke tetapan akaun anda dan tetapkan semula kata laluan anda",
  "Only up to last 3 months transaction history": "Hanya sejarah transaksi 3 bulan lepas",
  "Reload History": "Sejarah Tambah Nilai",
  "Transfer History": "Sejarah Pemindahan",
  "{{ point }} History": "Sejarah {{ point }}",
  "Only up to last 3 months reload history": "Hanya sejarah tambah nilai 3 bulan lepas",
  "Only up to last 3 months transfer history": "Hanya sejarah pemindahan 3 bulan lepas",
  "Only up to last 3 months {{ point }} history": "Hanya sejarah {{ point }} 3 bulan lepas",
  "If require urgent response, please contact us through whatapp": "Jika memerlukan maklum balas segera, sila hubungi kami melalui whatsapp",
  "Please scan the QR code again": "Sila imbas kod QR sekali lagi",
  "Home": "Laman Utama",
  "Quick": "Cepat",
  "Super": "Super",
  "Premium": "Premium",
  "All users will have infinite referrals.": "Semua pengguna akan mempunyai rujukan yang tidak terhingga.",
  "Collect 10 stamps to get 1 Voucher": "Kumpulkan 10 setem untuk dapatkan 1 baucar",
  "The voucher is only for customer use only, not for sale.": "Baucar itu hanya untuk kegunaan pelanggan sahaja, bukan untuk dijual.",
  "Outlet is currently offline, please try again later": "Kedai sedang di luar talian, sila cuba sebentar lagi",
  "The voucher will be given if you completed a transaction with a minimum spending of": "Baucar akan diberikan jika anda menyelesaikan transaksi dengan perbelanjaan minimum",
  "The birthday voucher will be given on the first day of your birthday month.": "Baucar hari jadi akan diberikan pada hari pertama bulan hari lahir anda.",
  "The voucher only will be given if you have exceed spending amount of": "Baucar hanya akan diberikan jika anda mempunyai jumlah perbelanjaan sebanyak",
  "Pay with KiplePay": "Bayar dengan KiplePay",
  "Pay with Scan2Pay": "Bayar dengan Scan2Pay",
  "Pay with Razer": "Bayar dengan Razer",
  "Pay with HitPay": "Bayar dengan HitPay",
  "Pay with Duitnow QR": "Bayar dengan Duitnow QR",
  "Pay with PagBank": "Bayar dengan PagBank",
  "DISCOUNT CODE": "KOD DISKAUN",
  "Enter code >": "Masukkan kod >",
  "Enter Discount Code": "Masukkan Kod Diskaun",
  "Remove Code": "Keluarkan kod",
  "Discount Code": "Kod diskaun",
  "Close": "Tutup",
  "Please fill in your email address": "Sila isikan alamat e-mel anda",
  "Notification": "Pemberitahuan",
  "Do you want to receive notification when wash cycle done?": "Adakah anda mahu menerima pemberitahuan apabila kitaran basuh selesai?",
  "pay extra": "bayar tambahan",
  "Email Address": "Alamat emel",
  "Please fill in your phone number": "Sila isikan nombor telefon anda",
  "Invalid Phone Number": "Nombor Telefon Tidak Sah",
  "Washing Complete": "Mencuci Selesai",
  "Drying Complete": "Pengeringan Selesai",
  "The voucher will be given if you registered your account successfully, but it only can be used when your total transaction spending reach": "Baucar akan diberikan jika anda berjaya mendaftar akaun anda, tetapi ia hanya boleh digunakan apabila jumlah perbelanjaan transaksi anda mencapai",
  "and above.": "dan ke atas.",
  "This voucher is not applicable": "Baucar ini tidak berkenaan",
  "Finish in": "Selesai dalam",
  "Need to refresh to update": "Perlu memuat semula untuk mengemas kini",
  "It's indicative and may change based on washer load": "Ia menunjukkan dan mungkin berubah berdasarkan beban mesin basuh",
  "We'll let you know when it's ready to pick up": "Kami akan memberitahu anda apabila ia sedia untuk diambil",
  "FREE": "PERCUMA",
  "Enter voucher code (if got any)": "Masukkan kod baucar (jika ada)",
  "Please fill in the voucher code": "Sila isikan kod baucar",
  "Available On": "Tersedia pada",
  "Apply": "Guna",
  "Enter a code": "Masukkan kod",
  "Input Promo Code": "Masukkan Kod Promosi",
  "Voucher is redeemed successfully!": "Baucar berjaya ditebus!",
  "Not available": "Tidak tersedia",
  "Unable to register": "Tidak dapat mendaftar",
  "Drop n Go Service": "Perkhidmatan Drop n Go",
  "Select Mode": "Pilih Mod",
  "Quantity": "Kuantiti",
  "Pack": "Pek",
  "Add more": "Tambah lagi",
  "Select Size": "Pilih Saiz",
  "Select Services": "Pilih Perkhidmatan",
  "Are you sure to delete the item?": "Adakah anda pasti akan memadamkan item tersebut?",
  "Delete": "Padam",
  "Your Duitnow QR code": "Kod QR Duitnow anda",
  "How to pay using Duitnow QR code?": "Bagaimanakah menggunakan kod QR Duitnow untuk membayar?",
  "Launch": "Pelancaran",
  "Launch your preferred Mobile Banking or eWallet app.": "Lancarkan aplikasi Perbankan Mudah Alih atau eWallet pilihan anda.",
  "Scan QR code from Gallery": "Imbas kod QR dari Galeri",
  "Choose DuitNow QR from your gallery.": "Pilih QR DuitNow daripada galeri anda.",
  "Confirm payment details and pay.": "Sahkan butiran pembayaran dan bayar.",
  "Receive payment confirmation.": "Terima pengesahan pembayaran.",
  "expiring on": "tamat tempoh pada",
  "based on transaction payment amount": "berdasarkan jumlah pembayaran transaksi",
  "Join as our": "Sertai sebagai",
  "Loyalty Member": "Ahli Kesetiaan",
  "now to get more reward": "kami sekarang untuk mendapatkan lebih banyak ganjaran",
  "Payment amount should be greater than 0": "Amaun pembayaran hendaklah lebih besar daripada 0",
  // admin portal
  "Dashboard": "Papan pemuka",
  "Report": "Laporan",
  "Store Performance": "Prestasi Kedai",
  "User Performance": "Prestasi Pengguna",
  "Voucher Redemption": "Penebusan Baucar",
  "Token Report": "Laporan Token",
  "SMS Report": "Laporan SMS",
  "Discount Code Report": "Laporan Kod Diskaun",
  "User Details": "Butiran Pengguna",
  "User Compensation": "Pampasan Pengguna",
  "Voucher Settings": "Tetapan Baucar",
  "Token Settings": "Tetapan Token",
  "Referral Settings": "Tetapan Rujukan",
  "Voucher Code": "Kod Baucar",
  "Customize Color": "Sesuaikan Warna",
  "User Migration": "Migrasi Pengguna",
  "Back To": "Kembali ke",
  "Eligibility": "Kelayakan",
  "If ticked, the voucher will have expired date": "Jika ditanda, baucar akan mempunyai tarikh luput",
  "Expiry": "Luput",
  "If ticked, the voucher can apply to washer machine transaction": "Jika ditanda, baucar boleh digunakan untuk transaksi mesin basuh",
  "Washer Use": "Penggunaan Pencuci",
  "If ticked, the voucher can apply to dryer machine transaction": "Jika ditanda, baucar boleh digunakan untuk transaksi mesin pengering",
  "Dryer Use": "Penggunaan Pengering",
  "If ticked, the voucher can apply to Drop n Go transaction": "Jika ditanda, baucar boleh digunakan untuk transaksi Drop n Go",
  "Package Use": "Penggunaan Pakej",
  "Voucher Lifespan": "Jangka Hayat Baucar",
  "days": "hari",
  "Discount Type": "Jenis Diskaun",
  "Select a discount type": "Pilih jenis diskaun",
  "Flat": "Rata",
  "Rate": "Kadar",
  "Voucher Title": "Tajuk Baucar",
  "Voucher Description": "Penerangan Baucar",
  "Voucher Amount": "Nilai Baucar",
  "CONFIRM": "SAHKAN",
  "If ticked, the system will give voucher to user who completed washer stamp collection": "Jika ditanda, sistem akan memberikan baucar kepada pengguna yang telah melengkapkan pengumpulan setem mesin basuh",
  "If ticked, the system will give voucher to user who completed dryer stamp collection": "Jika ditanda, sistem akan memberikan baucar kepada pengguna yang telah melengkapkan pengumpulan setem pengering",
  "If ticked, the user only can get/use the voucher after make a transaction": "Jika ditanda, pengguna hanya boleh mendapatkan/menggunakan baucar selepas membuat transaksi",
  "Pay And Get Voucher": "Bayar Untuk Dapatkan Baucar",
  "Pay And Use Code": "Bayar Untuk Guna Kod",
  "If ticked, the system will only give the voucher to user after the user make a transaction": "Jika ditanda, sistem hanya akan memberikan baucar kepada pengguna selepas pengguna membuat transaksi",
  "If ticked, the system will give voucher to user who completed registration process": "Jika ditanda, sistem akan memberikan baucar kepada pengguna yang telah menyelesaikan proses pendaftaran",
  "If ticked, the system will give voucher to user on their birthday month": "Jika ditanda, sistem akan memberikan baucar kepada pengguna pada bulan hari lahir mereka",
  "If ticked, the system will give voucher to user who completed stamp collection": "Jika ditanda, sistem akan memberikan baucar kepada pengguna yang telah melengkapkan kutipan setem",
  "Minimum Payment Amount To Get/Use Register Voucher": "Jumlah Bayaran Minimum Untuk Mendapat/Menggunakan Baucar Daftar",
  "Voucher Method": "Kaedah Baucar",
  "Select a voucher method": "Pilih kaedah baucar",
  "The user directly get the voucher after register but only can use it after make transaction": "Pengguna terus mendapat baucar selepas mendaftar tetapi hanya boleh menggunakannya selepas membuat transaksi",
  "The user only can get voucher after make a transaction": "Pengguna hanya boleh mendapatkan baucar selepas membuat transaksi",
  "Minimum Payment Amount To Get Birthday Voucher": "Jumlah Bayaran Minimum Untuk Mendapat Baucar Hari Lahir",
  "Minimum Payment Amount To Use Discount Code": "Jumlah Bayaran Minimum Untuk Menguna Kod Diskaun",
  "Outlets": "Outlet",
  "E-Payment Sales": "Jualan E-Bayaran",
  "Number of stamps granted to users": "Bilangan setem yang diberikan kepada pengguna",
  "Total number of stamps granted": "Jumlah bilangan setem yang diberikan",
  "Registered Users": "Pengguna Berdaftar",
  "Stamps": "Setem",
  "Referral": "Rujukan",
  "Register": "Daftar",
  "Top Outlets": "Outlet Teratas",
  "Users": "Pengguna",
  "Sort by": "Disusun mengikut",
  "Top Users": "Pengguna Teratas",
  "User name": "Nama pengguna",
  "Number": "Nombor",
  "Spending": "Perbelanjaan",
  "Number of registered users": "Bilangan pengguna berdaftar",
  "Number of voucher given to users": "Bilangan baucar yang diberikan kepada pengguna",
  "Total number of voucher given": "Jumlah bilangan baucar yang diberikan",
  "Voucher Type": "Jenis Baucar",
  "Voucher Value": "Nilai Baucar",
  "Date": "Tarikh",
  "Discount Code Type": "Jenis Kod Diskaun",
  "Discount Code Amount": "Nilai Kod Diskaun",
  "Number of Redemption": "Bilangan Penebusan",
  "Total Discount Amount": "Jumlah Nilai Diskaun",
  "Select Outlet": "Pilih Outlet",
  "Select Date": "Pilih Tarikh",
  "Select Transaction Date": "Pilih Tarikh Transaksi",
  "Reset": "Tetapkan semula",
  "Download Report": "Muat Turun Laporan",
  "Please fill in the remark": "Sila isikan catatan",
  "Transaction Date": "Tarikh transaksi",
  "Machine Type": "Jenis Mesin",
  "Machine Capacity": "Kapasiti Mesin",
  "Total Amount": "Jumlah amaun",
  "Payment Amount": "Amaun pembayaran",
  "Discount From": "Diskaun Dari",
  "Discount Amount": "Amaun diskaun",
  "Action": "Tindakan",
  "Remark": "Catatan",
  "Refund Date": "Tarikh Bayaran Balik",
  "Refund": "Bayaran balik",
  "Purchase Date": "Tarikh Pembelian",
  "e-Token Purchased (Tokens)": "e-Token Dibeli (Token)",
  "Bought e-Token using": "Membeli e-Token menggunakan",
  "Transaction ID": "ID Transaksi",
  "Method": "Kaedah",
  "Requery": "Pertanyaan semula",
  "Reward Date": "Tarikh Ganjaran",
  "Reward Type": "Jenis Ganjaran",
  "Notification Method": "Kaedah Pemberitahuan",
  "Email Preview": "Pratonton E-mel",
  "Message for User": "Mesej untuk Pengguna",
  "Enter the message to be sent to the user": "Masukkan mesej yang akan dihantar kepada pengguna",
  "Email Address to show on Email Template": "Alamat E-mel untuk ditunjukkan pada Templat E-mel",
  "Input Company Email Address": "Masukkan Alamat E-mel Syarikat",
  "Company Name to show on Email Template": "Nama Syarikat untuk ditunjukkan pada Templat E-mel",
  "Input Company Name": "Masukkan Nama Syarikat",
  "Company Phone No. to show on Email Template": "No. Telefon Syarikat untuk ditunjukkan pada Templat E-mel",
  "Input Company Phone No.": "Input No Telefon Syarikat.",
  "Voucher ID": "ID baucar",
  "Washer Stamp": "Setem Pencuci",
  "Dryer Stamp": "Setem Pengering",
  "e-Token Amount": "Jumlah e-Token",
  "Transfer Date": "Tarikh Pemindahan",
  "Transfer Amount (Token)": "Jumlah Pindahan (Token)",
  "Number (Transfer From)": "Nombor (Pindah Daripada)",
  "Name (Transfer From)": "Nama (Pindah Daripada)",
  "Member ID (Transfer From)": "ID Ahli (Pindah Daripada)",
  "Number (Transfer To)": "Nombor (Pindah Kepada)",
  "Name (Transfer To)": "Nama (Pindah Kepada)",
  "Member ID (Transfer To)": "ID Ahli (Pindah Kepada)",
  "Grant Date": "Tarikh Pemberian",
  "Claim Date": "Tarikh Penggunaan",
  "Member Details Report": "Laporan Butiran Ahli",
  "Transaction Status": "Status Transaksi",
  "Token Reload": "Token Tambah Nilai",
  "Token Transfer": "Pemindahan Token",
  "Voucher History": "Sejarah Baucar",
  "TOKEN": "TOKEN",
  "Message Sent": "Mesej dihantar",
  "Date Sent": "Tarikh Dihantar",
  "Remaining SMS Credit": "Baki Kredit SMS",
  "Store": "Kedai",
  "Total Registered Users": "Jumlah Pengguna Berdaftar",
  "User registered but no transaction record": "Pengguna berdaftar tetapi tiada rekod transaksi",
  "Registered": "Berdaftar",
  "User spending is less than": "Perbelanjaan pengguna kurang dari",
  "Active": "Aktif",
  "User spending is from": "Perbelanjaan pengguna adalah dari",
  "until": "sehingga",
  "Tier 1": "Tahap 1",
  "User spending is": "Perbelanjaan pengguna ialah",
  "and above": "dan ke atas",
  "Tier 2": "Tahap 2",
  "Average spending in each transaction": "Perbelanjaan purata dalam setiap transaksi",
  "Average Spending": "Perbelanjaan Purata",
  "Total number of transactions": "Jumlah bilangan transaksi",
  "Total Transactions": "Jumlah Transaksi",
  "Number of vouchers used in all the transactions": "Bilangan baucar yang digunakan dalam semua transaksi",
  "Voucher Claimed": "Baucar Diguna",
  "Total number of washer machine transactions": "Jumlah bilangan transaksi mesin basuh",
  "Washer Usage": "Penggunaan Pencuci",
  "Total number of dryer machine transactions": "Jumlah bilangan transaksi mesin pengering",
  "Dryer Usage": "Penggunaan Pengering",
  "User Name": "Nama Pengguna",
  "e-Token Spent": "e-Token Dibelanjakan",
  "e-Token Detailed Report": "Laporan Terperinci e-Token",
  "e-Token Purchase History": "Sejarah Pembelian e-Token",
  "Select Purchase Date": "Pilih Tarikh Beli",
  "Outlet Name": "Nama Outlet",
  "Total number of e-token used in all the transactions": "Jumlah bilangan e-token yang digunakan dalam semua transaksi",
  "Total e-Token Collected": "Jumlah e-Token Dikumpul",
  "Total number of e-token used in washer machine transactions": "Jumlah bilangan e-token yang digunakan dalam transaksi mesin basuh",
  "Washer e-Token Collected": "e-Token pencuci Dikumpul",
  "Total number of e-token used in dryer machine transactions": "Jumlah bilangan e-token yang digunakan dalam transaksi mesin pengering",
  "Dryer e-Token Collected": "e-Token pengering Dikumpul",
  "Purchase History": "Sejarah pembelian",
  "All": "Semua",
  "Most Bought e-Token Package": "Pakej e-Token Paling Banyak Dibeli",
  "for": "untuk",
  "Tokens": "Token",
  "Total Earned from users buying e-Tokens": "Jumlah Diperolehi daripada pengguna yang membeli e-Token",
  "Total e-Tokens Bought from you": "Jumlah e-Token yang Dibeli daripada anda",
  "Join Date": "Tarikh menyertai",
  "Channel": "Saluran",
  "Tier": "Peringkat",
  "Referrer": "Perujuk",
  "Referrals": "Rujukan",
  "Number of transactions for last 7 days": "Bilangan transaksi untuk 7 hari terkini",
  "Number of Usage (in week)": "Bilangan Penggunaan (dalam minggu)",
  "Number of transactions for last 1 month": "Bilangan transaksi untuk 1 bulan lepas",
  "Number of Usage (in month)": "Bilangan Penggunaan (dalam bulan)",
  "Total amount spent in all the transactions": "Jumlah amaun yang dibelanjakan dalam semua transaksi",
  "Total Spent": "Jumlah yang Dibelanjakan",
  "Last Transaction Date": "Tarikh Transaksi Terakhir",
  "Number of vouchers collected": "Bilangan baucar yang dikumpul",
  "Voucher Collected": "Baucar Dikumpul",
  "Number of vouchers used": "Bilangan baucar yang digunakan",
  "Voucher Spent": "Baucar Dibelanjakan",
  "Washer Used": "Pencuci Digunakan",
  "Dryer Used": "Pengering Digunakan",
  "Total number of e-Token spent in all the transactions": "Jumlah bilangan e-Token yang dibelanjakan dalam semua transaksi",
  "Total number of stamps collected": "Jumlah bilangan setem yang dikumpul",
  "Total Stamps": "Jumlah Setem",
  "Select Join Date": "Pilih Tarikh Sertai",
  "Select Grant Date": "Pilih Tarikh Pemberian",
  "Amount": "Amaun",
  "The image size is over 2MB": "Saiz imej melebihi 2MB",
  "Duplicate language selected": "Bahasa pendua dipilih",
  "Are you sure you want to add FAQ?": "Adakah anda pasti mahu menambah Soalan Lazim?",
  "FAQ added successfully": "Soalan lazim berjaya ditambahkan",
  "FAQ Group added successfully": "Kumpulan soalan lazim berjaya ditambahkan",
  "Please provide the faq with the default language": "Sila berikan soalan dengan bahasa lalai",
  "Add FAQ": "Tambah Soalan Lazim",
  "Default Language": "Bahasa Lalai",
  "Select Default Language": "Pilih Bahasa Lalai",
  "Image (Optional)": "Imej (Pilihan)",
  "max": "maks",
  "Question": "Soalan",
  "Select Language": "Pilih Bahasa",
  "Remove": "keluarkan",
  "Answer": "Jawapan",
  "Add Translation": "Tambah Terjemahan",
  "Are you sure you want to add FAQ Group?": "Adakah anda pasti mahu menambah Kumpulan Soalan Lazim?",
  "Please provide the faq group with the default language": "Sila berikan kumpulan soalan dengan bahasa lalai",
  "Add FAQ Group": "Tambah Kumpulan Soalan Lazim",
  "Title": "Tajuk",
  "Title Language": "Bahasa Tajuk",
  "Please fill in the title": "Sila isikan tajuk",
  "Please fill in the description": "Sila isi penerangan",
  "Please fill in the short description": "Sila isi penerangan ringkas",
  "Please select the end date for your news": "Sila pilih tarikh tamat untuk berita anda",
  "Please provide the news with the default language": "Sila berikan berita dengan bahasa lalai",
  "Please provide the banner image": "Sila berikan imej sepanduk",
  "Please provide the news image": "Sila berikan imej berita",
  "Are you sure you want to add news?": "Adakah anda pasti mahu menambah berita?",
  "News added successfully": "Berita berjaya ditambahkan",
  "Please fill in all the required fields before submit": "Sila isi semua medan yang diperlukan sebelum menghantar",
  "Add News": "Tambah Berita",
  "Banner Image": "Imej Sepanduk",
  "News Image": "Imej Berita",
  "Banner": "Sepanduk",
  "aspect ratio": "Nisbah aspek",
  "Image": "Imej",
  "Display in": "Paparkan dalam",
  "Inbox Page": "Halaman Peti Masuk",
  "Promotion Banner": "Sepanduk Promosi",
  "Select Period: (optional)": "Pilih Tempoh: (pilihan)",
  "Short Description": "Penerangan Ringkas",
  "Your theme has been updated successfully": "Tema anda telah berjaya dikemas kini",
  "Default Theme": "Tema Lalai",
  "Change Logo": "Tukar Logo",
  "REMOVE LOGO": "KELUARKAN LOGO",
  "Sms credit used up": "Kredit sms habis",
  "Please top up your sms credit": "Sila tambah nilai kredit sms anda",
  "Please": "Sila",
  "Again": "Semula",
  "Welcome": "Selamat datang",
  "Ranking": "Kedudukan",
  "Select Year": "Pilih Tahun",
  "Missing Field": "Medan Hilang",
  "Please select can be used for washer, dryer or package": "Sila pilih boleh digunakan untuk mesin basuh, pengering atau pakej",
  "Please fill in the start date and end date": "Sila isikan tarikh mula dan tarikh tamat",
  "Discount amount should be more than 0": "Amaun diskaun hendaklah lebih daripada 0",
  "Redeem per user should be more than 0": "Tebus setiap pengguna hendaklah lebih daripada 0",
  "Redeem per day should be more than 0": "Tebus setiap hari hendaklah lebih daripada 0",
  "Your discount code have edited successfully": "Kod diskaun anda telah berjaya diedit",
  "Your discount code have added successfully": "Kod diskaun anda telah berjaya ditambahkan",
  "Delete this entry?": "Padamkan entri ini?",
  "Your discount code have deleted successfully": "Kod diskaun anda telah berjaya dipadamkan",
  "Created Date": "Tarikh Dicipta",
  "Discount code": "Kod diskaun",
  "General Discount Code": "Kod Diskaun Am",
  "Unique Discount Code": "Kod Diskaun Unik",
  "Start Date": "Tarikh mula",
  "End Date": "Tarikh tamat",
  "Redeem Per User": "Tebus Setiap Pengguna",
  "Redeem Per Day": "Tebus Setiap hari",
  "Add Discount Code": "Tambah Kod Diskaun",
  "Generate Discount Code": "Jana Kod Diskaun",
  "No Discount Code": "Tiada Kod Diskaun",
  "Edit Discount Code": "Edit Kod Diskaun",
  "Select Period": "Pilih Tempoh",
  "Redeem per user": "Tebus setiap pengguna",
  "How many times the discount code can be used for each user": "Berapa kali kod diskaun boleh digunakan untuk setiap pengguna",
  "Redeem per day": "Tebus setiap hari",
  "How many times the discount code can be used in one day": "Berapa kali kod diskaun boleh digunakan dalam satu hari",
  "If ticked, the discount code can apply to washer machine transaction": "Jika ditanda, kod diskaun boleh digunakan untuk transaksi mesin basuh",
  "If ticked, the discount code can apply to dryer machine transaction": "Jika ditanda, kod diskaun boleh digunakan untuk transaksi mesin pengering",
  "If ticked, the discount code can apply to Drop n Go transaction": "Jika ditanda, kod diskaun boleh digunakan untuk transaksi Drop n Go",
  "The user enter the discount code": "Pengguna memasukkan kod diskaun",
  "in the payment page will get": "dalam halaman pembayaran akan mendapat",
  "discount": "diskaun",
  "The discount code only can be used start from {{ start_date }} until {{ end_date }}": "Kod diskaun hanya boleh digunakan bermula dari {{ start_date }} hingga {{ end_date }}",
  "Every user only can use the discount code {{ redeem_per_user }} times": "Setiap pengguna hanya boleh menggunakan kod diskaun {{ redeem_per_user }} kali",
  "Every user only can use {{ redeem_per_user }} discount code from this batch of code": "Setiap pengguna hanya boleh menggunakan {{ redeem_per_user }} kod diskaun daripada kumpulan kod ini",
  "Only {{ redeem_per_day }} transactions can use the discount code in one day": "Hanya {{ redeem_per_day }} transaksi boleh menggunakan kod diskaun dalam satu hari",
  "The discount code only can be used for washer machine": "Kod diskaun hanya boleh digunakan untuk mesin basuh",
  "The discount code only can be used for dryer machine": "Kod diskaun hanya boleh digunakan untuk mesin pengering",
  "The discount code only can be used for Drop n Go transaction": "Kod diskaun hanya boleh digunakan untuk transaksi Drop n Go",
  "The discount code can be used for washer machine or Drop n Go transaction": "Kod diskaun boleh digunakan untuk mesin basuh atau transaksi Drop n Go",
  "The discount code can be used for dryer machine or Drop n Go transaction": "Kod diskaun boleh digunakan untuk mesin pengering atau transaksi Drop n Go",
  "The discount code can be used for washer machine, dryer machine or Drop n Go transaction": "Kod diskaun boleh digunakan untuk mesin basuh, mesin pengering atau transaksi Drop n Go",
  "The discount code can be used for washer or dryer machine": "Kod diskaun boleh digunakan untuk mesin basuh atau pengering",
  "The discount code only can be used after user spending {{ currency }} {{ pay_and_use_amount }}": "Kod diskaun hanya boleh digunakan selepas pengguna membelanjakan {{ currency }} {{ pay_and_use_amount }}",
  "Are you sure you want to edit FAQ?": "Adakah anda pasti mahu mengedit Soalan Lazim?",
  "FAQ updated successfully": "Soalan lazim berjaya dikemas kini",
  "Edit FAQ": "Edit Soalan Lazim",
  "Are you sure you want to edit FAQ Group?": "Adakah anda pasti mahu mengedit Kumpulan Soalan Lazim?",
  "FAQ Group updated successfully": "Kumpulan Soalan Lazim berjaya dikemas kini",
  "Edit FAQ Group": "Edit Kumpulan Soalan Lazim",
  "Are you sure you want to edit news?": "Adakah anda pasti mahu mengedit berita?",
  "News edit successfully": "Pengeditan berita berjaya",
  "Edit News": "Edit Berita",
  "All the FAQ under this group will be deleted. Are you sure you want to delete FAQ Group?": "Semua Soalan Lazim di bawah kumpulan ini akan dipadamkan. Adakah anda pasti mahu memadamkan Kumpulan Soalan Lazim?",
  "FAQ Group deleted successfully": "Kumpulan Soalan Lazim berjaya dipadamkan",
  "FAQ List": "Senarai Soalan Lazim",
  "NO FAQ GROUP": "TIADA KUMPULAN FAQ",
  "FAQ Group": "Kumpulan Soalan Lazim",
  "Are you sure you want to delete FAQ?": "Adakah anda pasti mahu memadamkan Soalan Lazim?",
  "FAQ deleted successfully": "Soalan lazim berjaya dipadamkan",
  "NO FAQs": "TIADA Soalan Lazim",
  "FAQ": "Soalan Lazim",
  "Easy": "Mudah",
  "Fast Laundromate": "Cepat Kedai Dobi",
  "Smart Laundry Management System": "Sistem Pengurusan Cucian Pintar",
  "Enter your username and password to login": "Masukkan nama pengguna dan kata laluan anda untuk log masuk",
  "Username": "Nama pengguna",
  "Are you sure you want to delete news?": "Adakah anda pasti mahu memadamkan berita?",
  "News deleted successfully": "Berita berjaya dipadamkan",
  "NO NEWS": "TIADA BERITA",
  "The referral setting has been updated successfully!": "Tetapan rujukan telah berjaya dikemas kini!",
  "Number of Stamp": "Bilangan Setem",
  "Number of Washer Stamp": "Bilangan Setem Pencuci",
  "Number of Dryer Stamp": "Bilangan Setem Pengering",
  "Enable Referral Feature?": "Membolehkan Rujukan?",
  "Are you sure you want to delete the tier setting?": "Adakah anda pasti mahu memadamkan tetapan peringkat?",
  "Minimum referee transaction amount for referrer to get referral rewards": "Minimum amaun transaksi untuk perujuk untuk mendapatkan ganjaran rujukan",
  "Maximum number of reward within this tier": "Bilangan maksimum ganjaran dalam peringkat ini",
  "Maximum number of reward can get from same user": "Bilangan maksimum ganjaran boleh diperoleh daripada pengguna yang sama",
  "Point Grant Type": "Jenis Pemberian Mata",
  "Select Point Grant Type": "Pilih Jenis Pemberian Mata",
  "Token Grant Type": "Jenis Pemberian Token",
  "Select Token Grant Type": "Pilih Jenis Pemberian Token",
  "Add Tier": "Tambah Peringkat",
  "Stamp": "Setem",
  "If ticked, the system will give stamp to user": "Jika ditanda, sistem akan memberikan setem kepada pengguna",
  "If ticked, the system will give e-Token to user": "Jika ditanda, sistem akan memberikan e-Token kepada pengguna",
  "If ticked, the system will give voucher to user": "Jika ditanda, sistem akan memberikan baucar kepada pengguna",
  "Number of E-Token": "Bilangan E-Token",
  "Reward Giveaway": "Pemberian Ganjaran",
  "Which type of reward you want to give to customer?": "Apakah jenis ganjaran anda ingin berikan kepada pelanggan?",
  "For machine uses (required)": "Untuk kegunaan mesin (diperlukan)",
  "leave blank if no expiry date": "biarkan kosong jika tiada tarikh luput",
  "Remarks": "Catatan",
  "Reward": "Ganjaran",
  "Please fill in all the required field. (Title, description, and amount must be more than 0)": "Sila isi semua medan yang diperlukan. (Tajuk, penerangan dan amaun mestilah lebih daripada 0)",
  "Checkbox required": "Kotak semak diperlukan",
  "Must select voucher can be used for washer, dryer or package": "Baucar perlu dipilih boleh digunakan untuk mesin basuh, pengering atau pakej",
  "How many e-token?": "Berapa banyak e-token?",
  "Number of e-Token": "Bilangan e-Token",
  "Token value must be more than 0": "Nilai token mestilah lebih daripada 0",
  "STAMP": "SETEM",
  "Please fill in at least one of the required field": "Sila isikan sekurang-kurangnya satu daripada medan yang diperlukan",
  "The value must be more than 0": "Nilai mestilah lebih daripada 0",
  "How many times rewarded to user": "Berapa kali ganjaran kepada pengguna",
  "Rewarded Times": "Kali Ganjaran",
  "How many times rewarded voucher to user": "Berapa kali ganjaran baucar kepada pengguna",
  "Voucher Rewarded (times)": "Baucar Diberi (kali)",
  "How many times rewarded stamp to user": "Berapa kali ganjaran setem kepada pengguna",
  "Stamps Rewarded (times)": "Setem Diberi (kali)",
  "Total number of washer stamps rewarded to user": "Jumlah bilangan setem pencuci yang diberi kepada pengguna",
  "Washer Stamps Granted (stamp)": "Setem Pencuci Diberikan (setem)",
  "Total number of dryer stamps rewarded to user": "Jumlah bilangan setem pengering yang diberi kepada pengguna",
  "Dryer Stamps Granted (stamp)": "Setem Pengering Diberikan (setem)",
  "How many times rewarded e-Token to user": "Berapa kali ganjaran e-Token kepada pengguna",
  "e-Token Rewarded (times)": "Ganjaran e-Token (kali)",
  "Total number of e-Token rewarded to user": "Jumlah bilangan e-Token yang diberi kepada pengguna",
  "Total e-Token Granted (token)": "Jumlah e-Token Diberikan (token)",
  "Point Rewarded (times)": "Mata Ganjaran (kali)",
  "How many times rewarded point to user": "Berapa kali ganjaran mata kepada pengguna",
  "Total Point Granted (point)": "Jumlah Mata Diberi (mata)",
  "Total number of point rewarded to user": "Jumlah bilangan mata ganjaran kepada pengguna",
  "Point Amount": "Jumlah Mata",
  "Reward History": "Sejarah Ganjaran",
  "Are you sure you want to change how the stamping mechanics works?": "Adakah anda pasti mahu menukar cara mekanik pengecapan berfungsi?",
  "The stamping mechanics setting has updated successfully!": "Tetapan mekanik pengecap telah berjaya dikemas kini!",
  "The voucher setting has been updated successfully!": "Tetapan baucar telah berjaya dikemas kini!",
  "Mechanics Settings": "Tetapan Mekanik",
  "Minimum transaction amount for getting stamp": "Minimum amaun transaksi untuk mendapatkan setem",
  "Select User Stamping Mechanic": "Pilih Mekanik Setem Pengguna",
  "Separated Stamping": "Setem Berpisah",
  "Combined Stamping": "Setem Gabungan",
  "Stamp for?": "Setem untuk?",
  "Both": "Kedua-duanya",
  "Washer Only": "Pencuci Sahaja",
  "Dryer Only": "Pengering Sahaja",
  "Separated Stamping = Different washer and dryer stamp collection. (example: Using Washer will gain 1 washer's stamp, and Dryer will gain 1 dryer stamp)": "Setem Berpisah = Koleksi setem pencuci dan pengering yang berbeza. (contoh: Menggunakan Mesin Cuci akan mendapat 1 setem mesin basuh, dan Pengering akan mendapat 1 setem pengering)",
  "Combined Stamping = Washer and dryer has the same collection. (example: Using Washer or Dryer will gain 1 stamp)": "Setem Gabungan = Mesin basuh dan pengering mempunyai koleksi yang sama. (contoh: Menggunakan Mesin Cuci atau Pengering akan mendapat 1 setem)",
  "Stamp for": "Setem untuk",
  "User will be able to collect both washer stamp and dryer stamp": "Pengguna akan dapat mengumpul kedua-dua setem pencuci dan setem pengering",
  "User will only able to collect Washer stamp": "Pengguna hanya boleh mengumpul setem Pencuci",
  "User will only able to collect Dryer stamp": "Pengguna hanya boleh mengumpul setem Pengering",
  "Registration": "Pendaftaran",
  "Birthday": "Hari jadi",
  "Please choose at least one payment method": "Sila pilih sekurang-kurangnya satu kaedah pembayaran",
  "Please choose at least one member or non-member payment": "Sila pilih sekurang-kurangnya satu pembayaran ahli atau bukan ahli",
  "Are you sure you want to change the payment setting?": "Adakah anda pasti mahu menukar tetapan pembayaran?",
  "The payment setting has updated successfully!": "Tetapan pembayaran telah berjaya dikemas kini!",
  "Invalid input": "Input tidak sah",
  "The price and token must be more than 0": "Harga dan token mestilah lebih daripada 0",
  "Maximum number of purchase must be more than 1": "Bilangan maksimum pembelian mestilah lebih daripada 1",
  "Point amount must be more than 1": "Jumlah mata mestilah lebih daripada 1",
  "Added a new Token Bundle Package": "Menambah Pakej Token baharu",
  "Bundle deleted successfully!": "Pakej Token berjaya dipadamkan!",
  "Reload voucher setting have edited successfully!": "Tetapan baucar tambah nilai telah berjaya diedit!",
  "Added on": "Ditambah pada",
  "remove": "keluarkan",
  "edit": "edit",
  "NO PACKAGES ON SALES": "TIADA PAKEJ UNTUK JUALAN",
  "Point Settings": "Tetapan Mata",
  "Enable Loyalty Point": "Membolehkan Mata",
  "Point Name": "Nama Mata",
  "Number Of Point Equals To": "Bilangan Mata Sama Dengan",
  "If ticked, the point will have expired date": "Jika ditanda, mata itu akan mempunyai tarikh luput",
  "Point Lifespan": "Jangka Hayat Mata",
  "months": "bulan",
  "Minimum Transaction Amount To Get Point": "Jumlah Transaksi Minimum Untuk Mendapat Mata",
  "Point Grant Amount": "Jumlah Pemberian Mata",
  "Point settings updated successfully!": "Tetapan mata berjaya dikemas kini!",
  "Point Eligibility": "Kelayakan Mata",
  "If ticked, the system will give point to user when user reload token successfully": "Jika ditanda, sistem akan memberikan mata kepada pengguna apabila pengguna memuat semula token berjaya",
  "{{ point_amount }} point will be given after user reload token successfully": "{{ point_amount }} mata akan diberikan selepas pengguna memuat semula token berjaya",
  "Payment Settings": "Tetapan Pembayaran",
  "Select Payment Method": "Pilih Kaedah Pembayaran",
  "Enable E-Payment?": "Membolehkan E-Pembayaran?",
  "Enable Token Currency?": "Membolehkan E-Token?",
  "Member and Non-Member Settings": "Tetapan Ahli dan Bukan Ahli",
  "Enable Member Payment?": "Membolehkan Pembayaran Ahli?",
  "Enable Non-Member Payment?": "Membolehkan Pembayaran Bukan Ahli?",
  "Promotion Settings": "Tetapan Promosi",
  "Enable Non Member Promotion?": "Membolehkan Promosi Bukan Ahli?",
  "Enable Notification When Cycle Done?": "Membolehkan Pemberitahuan Apabila Kitaran Selesai?",
  "SMS charges": "Caj SMS",
  "SMS Settings": "Tetapan SMS",
  "Enable Free Sms For Members": "Membolehkan Sms Percuma Untuk Ahli",
  "If enable notification, user can choose either receive email or sms when complete washing or drying": "Jika membolehkan pemberitahuan, pengguna boleh memilih sama ada menerima e-mel atau sms apabila selesai mencuci atau mengeringkan",
  "If user choose sms, non member have to pay extra {{ currency }}{{ sms_notification_amount }} for the sms": "Jika pengguna memilih sms, bukan ahli perlu membayar tambahan {{ currency }}{{ sms_notification_amount }} untuk sms tersebut",
  "If user choose sms, member and non member have to pay extra {{ currency }}{{ sms_notification_amount }} for the sms": "Jika pengguna memilih sms, ahli dan bukan ahli perlu membayar tambahan {{ currency }}{{ sms_notification_amount }} untuk sms tersebut",
  "Add Package": "Tambah Pakej",
  "Edit e-Token Package": "Edit Pakej e-Token",
  "Add e-Token Package": "Tambah Pakej e-Token",
  "Please only input numberic value to prevent any bugs.": "Sila hanya masukkan nilai nombor untuk mengelakkan sebarang pepijat.",
  "Price": "Harga",
  "If ticked, the system will limit the number of purchase": "Jika ditanda, sistem akan mengehadkan bilangan pembelian",
  "Limit Number of Purchase": "Hadkan Bilangan Pembelian",
  "Number of maximum purchase": "Bilangan pembelian maksimum",
  "Reload Voucher": "Baucar Tambah Nilai",
  "If ticked, the system will give voucher to user when user reload token successfully": "Jika ditanda, sistem akan memberikan baucar kepada pengguna apabila pengguna tambah nilai token berjaya",
  "If ticked, the system will give voucher repeatly": "Jika ditanda, sistem akan memberikan baucar berulang kali",
  "Recurring": "Berulang",
  "Total number of voucher given to user": "Jumlah bilangan baucar yang diberikan kepada pengguna",
  "Period for giving next voucher": "Tempoh untuk memberikan baucar seterusnya",
  "Token package {{ currency }}{{ price }} for {{ numberOfToken }} tokens": "Pakej token {{ currency }}{{ price }} untuk {{ numberOfToken }} token",
  "Each user only can purchase {{ max_purchase }} times for the token package": "Setiap pengguna hanya boleh membeli {{ max_purchase }} kali untuk pakej token",
  "Voucher will be given after user reload token successfully": "Baucar akan diberikan selepas pengguna berjaya tambah nilai token",
  "Voucher will not given after user reload tokens successfully": "Baucar tidak akan diberikan selepas pengguna berjaya tambah nilai token",
  "The voucher will be expired after {{ voucher_duration }} days": "Baucar akan tamat tempoh selepas {{ voucher_duration }} hari",
  "The voucher will not expired": "Baucar tidak akan tamat tempoh",
  "Total {{ recurring_number }} vouchers will be given to user and each voucher will discount": "Jumlah {{ recurring_number }} baucar akan diberikan kepada pengguna dan setiap baucar akan diskaun",
  "Edit Package": "Edit Pakej",
  "Age": "Umur",
  "Total number of vouchers collected": "Jumlah baucar yang dikumpul",
  "Vouchers Acquired": "Baucar Diperolehi",
  "Total amount spent in washer machine transactions": "Jumlah amaun yang dibelanjakan dalam transaksi mesin basuh",
  "Total Washer Payment": "Jumlah Bayaran Mesin Cuci",
  "Total amount spent in dryer machine transactions": "Jumlah amaun yang dibelanjakan dalam transaksi mesin pengering",
  "Total Dryer Payment": "Jumlah Bayaran Pengering",
  "Total amount spent in all transactions": "Jumlah amaun yang dibelanjakan dalam semua transaksi",
  "Total Payment": "Jumlah bayaran",
  "report": "laporan",
  "Total amount spent for buying e-Token": "Jumlah amaun yang dibelanjakan untuk membeli e-Token",
  "Total number of e-Token purchased": "Jumlah bilangan e-Token yang dibeli",
  "Total e-Token Purchased (Tokens)": "Jumlah e-Token yang Dibeli (Token)",
  "Total number of e-Token used in washer machine transactions": "Jumlah bilangan e-Token yang digunakan dalam transaksi mesin basuh",
  "Washer Payment (Tokens)": "Bayaran Pencuci (Token)",
  "Total number of e-Token used in dryer machine transactions": "Jumlah bilangan e-Token yang digunakan dalam transaksi mesin pengering",
  "Dryer Payment (Tokens)": "Bayaran Pengering (Token)",
  "Total number of e-Token used in all the transactions": "Jumlah bilangan e-Token yang digunakan dalam semua transaksi",
  "Total Payment (Tokens)": "Jumlah Bayaran (Token)",
  "Number of washer stamp collected currently": "Bilangan setem mesin basuh yang dikumpul sekarang",
  "Number of dryer stamp collected currently": "Bilangan setem pengering yang dikumpul sekarang",
  "User Phone Number / Member ID / Email": "Nombor Telefon Pengguna / ID Ahli / E-mel",
  "Example: 0123456789,A123456": "Contoh: 0123456789,A123456",
  "SEARCH": "CARIAN",
  "Total Users Registered": "Jumlah Pengguna Berdaftar",
  "Total Active Outlets": "Jumlah Outlet Aktif",
  "Total Vouchers Given": "Jumlah Baucar Diberi",
  "Total Transaction Counts": "Jumlah Transaksi",
  "Total User Stamps Collected": "Jumlah Setem Pengguna Dikumpul",
  "Distributor Login": "Log Masuk Pengedar",
  "Client Login": "Log Masuk Pelanggan",
  "Kiosk Login": "Log Masuk Kiosk",
  "Promotion": "Promosi",
  "Please select operator": "Sila pilih operator",
  "Please select distributor": "Sila pilih pengedar",
  "Select Operator": "Pilih Operatorr",
  "Select Distributor": "Pilih Pengedar",
  "User can login or skip to payment without login to make the payment": "Pengguna boleh log masuk atau melangkau ke pembayaran tanpa log masuk untuk membuat pembayaran",
  "User have to login only can make the payment": "Pengguna perlu log masuk baru boleh membuat pembayaran",
  "User only can skip to payment without login to make the payment": "Pengguna hanya boleh melangkau ke pembayaran tanpa log masuk untuk membuat pembayaran",
  "Only CSV file is accepted": "Hanya fail CSV diterima",
  "The user and the balance have updated": "Pengguna dan baki telah dikemas kini",
  "Register Time": "Masa Daftar",
  "Upload User CSV file": "Muat naik fail CSV Pengguna",
  "Sample CSV file": "Contoh fail CSV",
  "Please follow the CSV file format": "Sila ikut format fail CSV",
  "User CSV file": "Fail CSV pengguna",
  "Only CSV file (comma delimited) is support now": "Hanya fail CSV (dibataskan koma) disokong sekarang",
  "Confirm uploaded data": "Sahkan data yang dimuat naik",
  "Operator": "Operator",
  "Distributor": "Pengedar",
  "Refund By": "Bayaran Balik Oleh",
  "Reward By": "Ganjaran Oleh",
  "Number Of Voucher Code": "Bilangan Kod Baucar",
  "Number Of Discount Code": "Bilangan Kod Diskaun",
  "New User Only": "Pengguna Baru Sahaja",
  "Minimum Transaction Amount To Redeem": "Jumlah Minimum Transaksi Untuk Menebus",
  "Download": "Muat turun",
  "Generate Voucher Code": "Jana Kod Baucar",
  "Your voucher code is generating": "Kod baucar anda sedang dijana",
  "Your discount code is generating": "Kod diskaun anda sedang dijana",
  "Voucher Code Settings": "Tetapan Kod Baucar",
  "If ticked, the voucher code only can redeem in register page": "Jika ditanda, kod baucar hanya boleh ditebus di halaman daftar",
  "If ticked, the user only can use the voucher after make a transaction": "Jika ditanda, pengguna hanya boleh menggunakan baucar selepas membuat transaksi",
  "If ticked, the user only can use the discount code after make a transaction": "Jika ditanda, pengguna hanya boleh menggunakan kod diskaun selepas membuat transaksi",
  "Expired Date Of Voucher Code": "Tarikh Luput Kod Baucar",
  "Minimum Transaction Amount To Use Voucher": "Jumlah Transaksi Minimum Untuk Menggunakan Baucar",
  "Minimum Transaction Amount To Use Code": "Jumlah Transaksi Minimum Untuk Guna Kod",
  "Max redemption per user in this batch voucher code": "Penebusan maksimum bagi setiap pengguna dalam kod baucar kelompok ini",
  "Max redemption per user in this batch discount code": "Penebusan maksimum bagi setiap pengguan dalam kod diskaun kelompok ini",
  "Add Voucher": "Tambah Baucar",
  "Completed": "Selesai",
  "Voucher Code Report": "Laporan Kod Baucar",
  "Floating Token Report": "Laporan Token Terapung",
  "Floating Point Report": "Laporan Mata Terapung",
  "E-Payment Report": "Laporan E-Payment",
  "Referral Reward History Report": "Laporan Sejarah Ganjaran Rujukan",
  "Redemption Date": "Tarikh Penebusan",
  "Redemption Code": "Kod Penebusan",
  "Select Redemption Date": "Pilih Tarikh Penebusan",
  "Select Voucher Type": "Pilih Jenis Baucar",
  "Giveaway": "Pampasan",
  "Select Name": "Pilih Nama",
  "Amount must be more than 1": "Jumlah mestilah lebih daripada 1",
  "Payment method setting have updated successfully!": "Tetapan kaedah pembayaran telah berjaya dikemas kini!",
  "Edit Payment Method": "Edit Kaedah Pembayaran",
  "Minimum payment amount to include all the payment methods": "Jumlah pembayaran minimum untuk memasukkan semua kaedah pembayaran",
  "Exclude Payment Method in Revenue Monster": "Kecualikan Kaedah Pembayaran dalam Revenue Monster",
  "Welcome to": "Selamat datang ke",
  "Instruction": "Arahan",
  "Please select the machine": "Sila pilih mesin",
  "Login your account": "Log masuk akaun anda",
  "Select the setting and make the payment": "Pilih tetapan dan buat pembayaran",
  "Phone Number / Email": "Nombor Telefon / E-mel",
  "Reload & Transfer In": "Muat Semula & Pemindahan Masuk",
  "Usage & Transfer Out": "Penggunaan & Pemindahan Keluar",
  "Usage": "Penggunaan",
  "Remaining": "Tinggal",
  "Gain": "Keuntungan",
  "Type": "Jenis",
  "Order Date": "Tarikh pesanan",
  "Pack Number": "Nombor Pek",
  "Pack Details": "Butiran Pek",
  "Size": "Saiz",
  "Mode": "Mod",
  "Machine Payment": "Bayaran Mesin",
  "DNG History": "Sejarah DNG",
  "Token History": "Sejarah Token",
  "Stamp History": "Sejarah Setem",
  "Staff Login": "Log Masuk Kakitangan",
  "Drop And Go Portal": "Portal Drop And Go",
  "Available Task": "Tugasan Yang Ada",
  "Completed Task": "Tugasan Yang Selesai",
  "Task ID": "ID Tugas",
  "Start Task": "Mulakan Tugas",
  "Select Machine": "Pilih Mesin",
  "Start Machine": "Mulakan Mesin",
  "Reward To": "Ganjaran Kepada",
  "Reward From": "Ganjaran Daripada",
  "Transaction Account": "Akaun Transaksi",
  "Point": "Mata",
  "POINT": "Mata",
  "Number of Point": "Bilangan Mata",
  "Point History": "Sejarah Mata",
  "Customer Name": "Nama Pelanggan",
  "Number of Washer Transactions": "Bilangan Transaksi Pencuci",
  "Number of Dryer Transactions": "Bilangan Transaksi Pengering",
  "Vouchers Redeemed": "Baucar Ditebus",
  "Discount Code Redemption": "Penebusan Kod Diskaun",
  "Total Spend": "Jumlah Perbelanjaan",
  "Select Outlet(s)": "Pilih Outlet",
  "User Usage Report": "Laporan Penggunaan Pengguna",
  // cleanpro plus bm
  "Wash": "Basuh",
  "Dry": "Kering",
  "Fold": "Lipat",
  "Scan QR": "Imbas QR",
  "Capacity": "Kapasiti",
  "Duration": "Tempoh",
  "Custom duration": "Ubahsuai masa",
  "Choose duration": "Pilih masa",
  "Select the correct amount on the machine after payment.": "Pilih jumlah masa yang betul pada mesin selepas membuat pembayaran",
  "Offers": "Tawaran",
  "An easier way to pay": "Cara pembayaran yang lebih mudah",
  "Create account": "Buka akaun",
  "Login": "Log masuk",
  "By signing up, you agree to": "Dengan mendaftar anda bersetuju untuk",
  "Amount to Pay": "Jumlah yang Perlu Dibayar",
  "1 voucher selected": "1 Baucar Dipilih",
  "No voucher selected": "Tiada baucar dipilih",
  "Credits": "Kredit",
  "credits": "kredit",
  "credit": "kredit",
  "Credit balance": "Baki kredit",
  "Please ensure that the machine door is shut & your laundry is inside.": "Pastikan pintu mesin ditutup & pakaian anda ada di dalam.",
  "Insufficient balance": "Baki tidak mencukupi",
  "Topup now": "Topup sekarang",
  "Copyright © 2023 Cleanpro Laundry Holdings Sdn Bhd (821842-P) All rights reserved.": "Hak Cipta © 2023 Cleanpro Laundry Holdings Sdn Bhd (821842-P) Hak cipta terpelihara.",
  "Good Evening!": "Selamat petang!",
  "Hello": "Halo",
  "Wallet Balance": "Baki Wallet",
  "Topup": "Topup",
  "More details": "Maklumat lanjut",
  "Collect 10 e-stamps to earn x1 FREE wash or dry voucher": "Kumpul 10 e-setem untuk dapatkan x1 baucar basuhan atau keringan secara PERCUMA",
  "For every wash or dry cycle you complete with us, you'll earn one e-stamp. Collect 10 and you'll be able to redeem a FREE wash or dry cycle. It's our way of saying thank you for choosing Cleanpro!": "Anda akan dapat satu e-setem selepas setiap penggunaan basuh atau kering dengan Cleanpro Plus. Kumpul 10 e-setem dan dapatkan 1 baucar basuhan atau keringan secara percuma! Ini adalah tanda penghargaan kami kerana menggunakan Cleanpro.",
  "For every wash cycle you complete with us, you'll earn one e-stamp. Collect 10 and you'll be able to redeem a FREE wash or dry cycle. It's our way of saying thank you for choosing Cleanpro!": "Anda akan dapat satu e-setem selepas setiap penggunaan basuh dengan Cleanpro Plus. Kumpul 10 e-setem dan dapatkan 1 baucar basuhan atau keringan secara percuma! Ini adalah tanda penghargaan kami kerana menggunakan Cleanpro.",
  "For every dry cycle you complete with us, you'll earn one e-stamp. Collect 10 and you'll be able to redeem a FREE wash or dry cycle. It's our way of saying thank you for choosing Cleanpro!": "Anda akan dapat satu e-setem selepas setiap penggunaan kering dengan Cleanpro Plus. Kumpul 10 e-setem dan dapatkan 1 baucar basuhan atau keringan secara percuma! Ini adalah tanda penghargaan kami kerana menggunakan Cleanpro.",
  "Credits OFF": "Kredit Potongan",
  "% OFF": "% Potongan",
  "Valid Till": "Sah sehingga",
  "Used On": "Digunakan Pada",
  "Expired On": "Tamat Tempoh Pada",
  "Available": "Tersedia",
  "Expired": "Tamat tempoh",
  "Used": "Terpakai",
  "Promotions": "Promosi",
  "Rewards": "Ganjaran",
  "Inbox": "Peti masuk",
  "Activities": "Aktiviti",
  "Support": "Sokongan",
  "Active Rewards": "Ganjaran Aktif",
  "Past Rewards": "Ganjaran Terdahulu",
  "Get rewarded for your loyalty with Cleanpro Plus Rewards!": "Dapatkan ganjaran kesetiaan anda dengan Cleanpro Plus Rewards!",
  "Earn vouchers and redeem them for discounts, freebies and more. The more you use our services the more you save!": "Dapatkan baucar dan tebus diskaun, hadiah percuma dan banyak lagi. Lebih banyak anda menggunakan servis kami, lebih banyak penjimatan!",
  "Up to 3 months history": "Sejarah 3 bulan lepas",
  "Filter": "Penapis",
  "All Transactions": "Semua Urus Niaga",
  "Transactions": "Transaksi",
  "Select amount to topup": "Pilih jumlah untuk topup",
  "Balance": "Baki",
  "You've just paid": "Awak baru bayar",
  "Need help?": "Perlukan bantuan?",
  "24-Hour Careline Number": "Talian Careline 24 jam",
  "Email Us": "Emel Kami",
  "Call Now": "Hubungi Sekarang",
  "Email Now": "E-mel Sekarang",
  "Update name": "Kemas kini nama",
  "Update email address": "Kemas kini alamat e-mel",
  "Update phone number": "Kemas kini nombor telefon",
  "Change password": "Tukar kata laluan",
  "Change language": "Tukar bahasa",
  "User ID": "ID Pengguna",
  "Let us know your feedback": "Beritahu kami maklum balas anda",
  "Poor": "Teruk",
  "Good": "Baik",
  "Leave addtional feedback here (optional)": "Tinggalkan maklum balas tambahan di sini (Tidak wajib diisi)",
  "Submit feedback": "Hantar Maklum Balas",
  "Pay as guest": "Bayar sebagai tetamu",
  "Pay now": "Bayar sekarang",
  "Select voucher to use": "Pilih baucar untuk digunakan",
  "Use voucher": "Gunakan baucar",
  "Total": "Jumlah",
  "Transaction Type": "Jenis Transaksi",
  "Credit Topup": "Topup Kredit",
  "Credit topup": "Topup Kredit",
  "Credit Transfer": "Pemindahan Kredit",
  "Date/Time": "Tarikh/Masa",
  "Reference ID": "ID Rujukan",
  "Status": "Status",
  "Share Your Referral Code": "Kongsi Kod Rujukan Anda",
  "cleanproReferralShortDesc": "Dapatkan {{ voucherAmount }} kredit percuma apabila anda berkongsi kod rujukan anda kepada rakan dan keluarga selepas transaksi yang berjaya. Klik untuk maklumat lebih lanjut.",
  "Use": "Gunakan",
  "dryer or washer": "pengering atau mesin basuh",
  "washer": "mesin basuh",
  "dryer": "pengering",
  "dryer and washer": "pengering dan mesin basuh",
  "machine with the minimum spending of": "dengan perbelanjaan minimum",
  "Welcome to Cleanpro Plus": "Selamat datang ke Cleanpro Plus",
  "Only scan, set & pay! An easier, more convenient way to pay with Cleanpro Plus! No need to download an app. Enjoy more rewards when you use Cleanpro Plus today! Tap to learn more.": "Hanya imbas, tetap dan bayar! Cara yang lebih mudah dan senang untuk membayar dengan Cleanpro Plus! Tidak perlu memuat turun aplikasi. Nikmati lebih banyak ganjaran apabila anda menggunakan Cleanpro Plus hari ini! Klik untuk maklumat lebih lanjut.",
  "Cleanpro Pay Migration Notice": "Notis Penghijrahan Cleanpro Pay",
  "Dear valued Cleanpro customers, please be informed that as of 22 June 2023, the Cleanpro Pay app will be upgraded and and no longer available for use. Tap to learn more.": "Pelanggan Cleanpro yang dihormati, sila ambil maklum bahawa mulai 22 Jun 2023, aplikasi Cleanpro Pay akan dinaik taraf dan tidak lagi boleh untuk digunakan. Klik untuk maklumat lebih lanjut.",
  "Answer simple questions and win credits for your next wash or dry! This contest is only available for selected outlets only. Tap to learn more.": "Jawab soalan mudah dan menangi kredit untuk cucian atau pengeringan! Peraduan ini hanya tersedia untuk cawangan terpilih sahaja. Klik untuk maklumat lebih lanjut.",
  "The all-new Cleanpro Plus is a new payment method that includes loyalty features built into the app. The more you use our services and pay using Cleanpro Plus, the more you can save. With every transaction, you can earn rewards that will help you save on your next laundry expenses.": "Cleanpro Plus adalah kaedah pembayaran baharu yang mempunyai program kesetiaan terbina dalam aplikasi. Lebih banyak anda menggunakan dan membayar melalui Cleanpro Plus, lebih banyak anda boleh berjimat. Dengan setiap transaksi, anda akan memperoleh ganjaran yang boleh menjimatkan perbelanjaan dobi anda yang seterusnya.",
  "To use Cleanpro Plus, simply scan the QR code on the machines and pay using your preferred credit/debit card or e-wallet. We hope that Cleanpro Plus will help simplify your laundry routine and give you more time for the things that matter.": "Untuk menggunakan Cleanpro Plus, hanya imbas kod QR pada mesin dan bayar menggunakan kad kredit/debit atau e-dompet pilihan anda. Kami berharap Cleanpro Plus akan membantu memudahkan rutin cucian dan memberi anda lebih masa untuk perkara yang penting.",
  "If you have any questions about Cleanpro Plus, please read our FAQ in the menu or contact our careline at +603 2770 0100 or email us at info@cleanprolaundry.com. Thank you for choosing Cleanpro.": "Jika anda mempunyai sebarang soalan tentang Cleanpro Plus, sila baca FAQ kami di menu atau hubungi talian khidmat pelanggan kami di +603 2770 0100 atau e-mel kami di info@cleanprolaundry.com. Terima kasih kerana memilih Cleanpro.",
  "Dear valued Cleanpro customers, please be informed that as of 22 June 2023, the existing Cleanpro Pay mobile app will be upgraded and no longer available for use.": "Pelanggan Cleanpro yang dihormati, sila ambil maklum bahawa mulai 22 Jun 2023, Cleanpro Pay akan dinaik taraf dan tidak lagi boleh untuk digunakan.",
  "We are migrating to the all-new Cleanpro Plus. It is a new cashless payment method, without the need to download apps.": "Kami akan bertukar ke Cleanpro Plus yang serba baharu. Ini adalah kaedah baru pembayaran tanpa tunai, tanpa perlu memuat turun aplikasi.",
  "If you are the existing user of Cleanpro Pay, any of your remaining balance in the app will be transferred to your Cleanpro Plus account. You are required to use the same mobile number to sign up for Cleanpro Plus for balance transfer.": "Jika anda adalah pengguna Cleanpro Pay, sebarang baki yang tinggal dalam aplikasi  akan dipindahkan ke akaun Cleanpro Plus anda. Anda dikehendaki menggunakan nombor telefon yang sama untuk mendaftar Cleanpro Plus bagi pemindahan baki.",
  "If you need help on Cleanpro Plus, please feel free to call our careline at +603 2770 0100 or email us at info@cleanprolaundry.com": "Jika anda memerlukan bantuan mengenai Cleanpro Plus, sila hubungi talian penjagaan kami di +603 2770 0100 atau e-mel kami di info@cleanprolaundry.com",
  "Answer simple questions and win credits for your next wash or dry!": "Jawab soalan mudah dan menangi kredit untuk cucian atau pengeringan seterusnya!",
  "Step 1 : Scan the Jawab & Gerenti Menang QR code in the participated outlets.": "Langkah 1 : Imbas kod QR Jawab & Gerenti Menang di mana-mana cawangan yang mengambil bahagian.",
  "Step 2 : Answer 3 simple questions.": "Langkah 2 : Jawab 3 soalan mudah.",
  "Step 3 : Submit and stand a chance to win prizes.": "Langkah 3 : Hantar dan anda berpeluang memenangi hadiah.",
  "Grand Prize :": "Hadiah utama :",
  "x10 per outlet - 100 credits worth of vouchers in Cleanpro Plus": "x10 setiap outlet - Kredit bernilai RM100 dalam Cleanpro Plus",
  "Consolation Prize :": "Hadiah saguhati :",
  "X25 per outlet - 50 credits worth of vouchers in Cleanpro Plus": "X25 setiap outlet - Kredit bernilai RM50 dalam Cleanpro Plus",
  "Participation prize:": "Hadiah penyertaan:",
  "Every participants are eligible to receive a FREE 3 credits voucher (worth RM3) in Cleanpro Plus.": "Kredit PERCUMA bernilai RM3 dalam Cleanpro Plus untuk setiap peserta.",
  "If you have any questions please don't hesitate to contact our careline at +603 2770 0100 or email us at info@cleanprolaundry.com. Thank you for choosing Cleanpro.": "Jika anda mempunyai sebarang pertanyaan sila hubungi talian penjagaan kami di +603 2770 0100 atau e-mel kami di info@cleanprolaundry.com. Terima kasih kerana memilih Cleanpro.",
  "Payment Confirmation": "Pengesahan Pembayaran",
  "This campaign is only eligible for users who use the all-new Cleanpro Plus web application.": "Kempen ini hanya layak untuk pengguna yang menggunakan aplikasi web Cleanpro Plus serba baharu.",
  "ONE e-stamp will be rewarded for each wash or dry cycle.": "SATU e-cop akan diberi ganjaran untuk setiap kitaran basuh atau kering.",
  "ONE e-stamp will be rewarded for each wash cycle.": "SATU e-cop akan diberi ganjaran untuk setiap kitaran cucian.",
  "ONE e-stamp will be rewarded for each dry cycle.": "SATU e-cop akan diberi ganjaran untuk setiap kitaran kering.",
  "Regardless of the washer or dryer settings, the number of e-stamps being collected is the same.": "Tidak kira tetapan mesin basuh atau pengering, bilangan e-setem yang dikumpul adalah sama.",
  "The type of e-stamp collected is based on the services that you have used.": "Jenis e-setem yang dikumpul adalah berdasarkan perkhidmatan yang telah anda gunakan.",
  "Washing service will reward washer e-stamp and drying service will reward dryer e-stamp.": "Perkhidmatan mencuci akan memberi ganjaran kepada e-cop mesin basuh dan perkhidmatan pengeringan akan memberi ganjaran kepada e-cop pengering.",
  "Washing service will reward washer e-stamp.": "Perkhidmatan mencuci akan memberi ganjaran kepada e-cop mesin basuh.",
  "Drying service will reward dryer e-stamp.": "Perkhidmatan pengeringan akan memberi ganjaran kepada e-cop pengering.",
  "E-stamps will automatically be added to your account once the condition has been fulfilled.": "E-setem akan ditambahkan secara automatik ke akaun anda setelah syarat telah dipenuhi.",
  "There is no limit on how many e-stamps can be gathered in a single day. So long as you meet the requirements to collect the e-stamps.": "Tiada had berapa banyak e-setem boleh dikumpulkan dalam satu hari. Selagi anda memenuhi syarat untuk mengumpul e-setem.",
  "The type of e-stamps that have been collected is final and cannot be changed.": "Jenis e-setem yang telah dikumpul adalah muktamad dan tidak boleh diubah.",
  "There is no expiration for the e-stamps; only till the discontinuation of the campaign.": "Tiada tamat tempoh untuk e-setem; hanya sehingga pemberhentian kempen.",
  "E-stamps do not have any currency value, and they cannot be converted to cash or credits.": "E-setem tidak mempunyai sebarang nilai mata wang, dan ia tidak boleh ditukar kepada tunai atau kredit.",
  "This campaign is not applicable with any other ongoing promotions, offers, vouchers, and/or discounts provided by the participating outlets.": "Kempen ini tidak terpakai dengan mana-mana promosi, tawaran, baucar dan/atau diskaun berterusan lain yang disediakan oleh cawangan yang mengambil bahagian.",
  "These terms and conditions are subject to change at any time without prior notice.": "Terma dan syarat ini tertakluk kepada perubahan pada bila-bila masa tanpa notis terlebih dahulu.",
  "Press START on the machine to begin washing.": "Tekan START pada mesin untuk mula membasuh.",
  "Press START on the machine to begin drying.": "Tekan START pada mesin untuk mula mengeringkan.",
  "Reminder": "Peringatan",
  "Remember to select the same settings you have paid for on the corresponding machine.": "Ingat untuk memilih tetapan yang sama yang telah anda bayar pada mesin yang sepadan.",
  "Please ensure that your laundry is inside the machine and door is properly shut.": "Sila pastikan pakaian anda berada di dalam mesin dan pintu ditutup dengan betul.",
  "voucherCreditsOff_one": "Potongan {{currentVoucherAmount}} kredit",
  "voucherCreditsOff_other": "Potongan {{currentVoucherAmount}} kredit",
  "voucherPercentageOff": "Potongan {{currentVoucherAmount}}%",
  "cleanproReferralFlatVoucher_one": "Dapatkan kredit percuma apabila anda berkongsi kod rujukan Cleanpro Plus kepada rakan dan keluarga anda! Bagi setiap rujukan yang berjaya selepas transaksi pertama mereka, anda akan mendapat baucar {{ referralVoucherAmount }} kredit percuma.",
  "cleanproReferralFlatVoucher_other": "Dapatkan kredit percuma apabila anda berkongsi kod rujukan Cleanpro Plus kepada rakan dan keluarga anda! Bagi setiap rujukan yang berjaya selepas transaksi pertama mereka, anda akan mendapat baucar {{ referralVoucherAmount }} kredit percuma.",
  "cleanproReferralRateVoucher": "Dapatkan kredit percuma apabila anda berkongsi kod rujukan Cleanpro Plus kepada rakan dan keluarga anda! Bagi setiap rujukan yang berjaya selepas transaksi pertama mereka, anda akan mendapat baucar {{ referralVoucherAmount }}% percuma.",
  "cleanproReferralTokenReward_one": "Dapatkan kredit percuma apabila anda berkongsi kod rujukan Cleanpro Plus kepada rakan dan keluarga anda! Bagi setiap rujukan yang berjaya selepas transaksi pertama mereka, anda akan mendapat {{ referralToken }} kredit percuma.",
  "cleanproReferralTokenReward_other": "Dapatkan kredit percuma apabila anda berkongsi kod rujukan Cleanpro Plus kepada rakan dan keluarga anda! Bagi setiap rujukan yang berjaya selepas transaksi pertama mereka, anda akan mendapat {{ referralToken }} kredit percuma.",
  "How to get & use code:": "Cara dapatkan & gunakan kod:",
  'Go to menu = icon and click “Refer friends”': 'Pergi ke ikon menu = dan klik "Rujuk rakan"',
  "Share with everyone who will likely to use Cleanpro Plus.": "Kongsikan kepada mereka yang mungkin akan menggunakan Cleanpro Plus.",
  "Referral Terms & Conditions:": "Terma & Syarat Rujukan:",
  "When your friend or family register using your referral code, and completed a transaction (a minimum spending of {{ currency }}{{ referralTransactionAmount }}) , you will be rewarded automatically.": "Apabila rakan atau keluarga anda mendaftar menggunakan kod rujukan anda, dan menyelesaikan transaksi (perbelanjaan minimum {{ currency }}{{ referralTransactionAmount }}), anda akan diberi ganjaran secara automatik.",
  "cleanproNumberOfReferralReward": "Anda boleh berkongsi dengan seberapa ramai yang anda mahu, tetapi setiap pengguna HANYA terhad untuk mendapat {{ numberOfReferral }} ganjaran rujukan sahaja.",
  "cleanproNumberOfReferral_one": "Anda boleh berkongsi dengan seberapa ramai yang anda mahu, tetapi setiap pengguna HANYA terhad untuk mendapat {{ numberOfReferral }} baucar rujukan sahaja.",
  "cleanproNumberOfReferral_other": "Anda boleh berkongsi dengan seberapa ramai yang anda mahu, tetapi setiap pengguna HANYA terhad untuk mendapat {{ numberOfReferral }} baucar rujukan sahaja.",
  "The voucher is valid for {{ duration }} days from the day voucher be given.": "Baucar sah selama {{ duration }} hari dari hari baucar diberikan.",
  "The voucher is for customer use only and not for sale.": "Baucar adalah untuk kegunaan pelanggan sahaja dan bukan untuk dijual.",
  "We believe that your special day deserves a little extra sparkle! That's why we're thrilled to introduce our exclusive birthday rewards program just for you! Starting this year, and every year thereafter, we're giving away {{ currency }}{{ voucherAmount }} credit vouchers to you on your birthday.": "Kami percaya hari istimewa anda perlu diraikan dengan penuh bermakna. Kami teruja untuk memperkenalkan program ganjaran ekslusif hari lahir hanya untuk anda! Mulai tahun ini, dan tahun berikutnya, kami akan berikan baucar kredit {{ currency }}{{ voucherAmount }} pada hari lahir anda.",
  "cleanproBirthdayRateAmount": "Kami percaya hari istimewa anda perlu diraikan dengan penuh bermakna. Kami teruja untuk memperkenalkan program ganjaran ekslusif hari lahir hanya untuk anda! Mulai tahun ini, dan tahun berikutnya, kami akan berikan baucar {{ voucherAmount }}% pada hari lahir anda.",
  "Make sure your account details include your birthdate. This way, we'll know exactly when to send you your special birthday surprise.": "Pastikan tarikh lahir diisi dalam butiran akaun anda. Dengan cara ini, kami akan mengetahui dengan tepat hari untuk memberi baucar istimewa hari jadi anda.",
  "Birthday Voucher Terms & Conditions:": "Terma & Syarat Baucar Hari Lahir:",
  "The voucher will be given on the first day of your birthday month.": "Baucar akan diberikan pada hari pertama bulan lahir anda.",
  "When you applied the voucher during payment, it will give you {{ voucherAmount }} credits ({{ currency }}{{ voucherAmount }}) discount.": "Apabila anda menggunakan baucar semasa pembayaran, ia akan memberi anda diskaun {{ voucherAmount }} kredit ({{ currency }}{{ voucherAmount }}).",
  "When you applied the voucher during payment, it will give you {{ voucherAmount }}% discount.": "Apabila anda menggunakan baucar semasa pembayaran, ia akan memberi anda diskaun {{ voucherAmount }}%.",
  "All vouchers is not valid with other ongoing promotions, discounts and offers.": "Baucar ini tidak boleh digunakan ketika promosi lain sedang berlangsung.",
  "Claim a complimentary wash and dry for every 10 e-stamps collected! We value your loyalty and want to reward you for choosing Cleanpro Plus. Tap to learn more.": "Tebus basuhan dan keringan percuma untuk setiap 10 e-setem yang terkumpul! Kami menghargai kesetiaan anda dan ingin memberi ganjaran kerana setia memilih Cleanpro Plus. Klik untuk maklumat lebih lanjut.",
  "cleanproStampFlat_one": "Dengan setiap 10 e-setem yang anda kumpulkan, anda boleh menebus sehingga {{ voucherAmount }} kredit untuk sesi basuhan atau keringan secara percuma dengan Cleanpro Plus. Jadikan rutin cucian anda bukan sahaja mudah, tetapi juga bermanfaat.",
  "cleanproStampFlat_other": "Dengan setiap 10 e-setem yang anda kumpulkan, anda boleh menebus sehingga {{ voucherAmount }} kredit untuk sesi basuhan atau keringan secara percuma dengan Cleanpro Plus. Jadikan rutin cucian anda bukan sahaja mudah, tetapi juga bermanfaat.",
  "cleanproStampRate": "Dengan setiap 10 e-setem yang anda kumpulkan, anda boleh menebus sehingga {{ voucherAmount }}% untuk sesi basuhan atau keringan secara percuma dengan Cleanpro Plus. Jadikan rutin cucian anda bukan sahaja mudah, tetapi juga bermanfaat.",
  "A voucher will be instantly added to your Rewards page when you reach 10 washes. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.": "Baucar akan ditambah serta-merta pada halaman Ganjaran anda apabila anda mencapai 10 basuhan. Meter e-setem anda akan kembali kepada sifar untuk koleksi seterusnya. Dapatkan baucar dalam halaman Imbas QR apabila anda melakukan cucian seterusnya.",
  "A voucher will be instantly added to your Rewards page when you reach 10 drys. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.": "Baucar akan ditambah serta-merta pada halaman Ganjaran anda apabila anda mencapai 10 keringan. Meter e-setem anda akan kembali kepada sifar untuk koleksi seterusnya. Dapatkan baucar dalam halaman Imbas QR apabila anda melakukan cucian seterusnya.",
  "A voucher will be instantly added to your Rewards page when you reach 10 washes or drys. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.": "Baucar akan ditambah serta-merta pada halaman Ganjaran anda apabila anda mencapai 10 basuhan atau keringan. Meter e-setem anda akan kembali kepada sifar untuk koleksi seterusnya. Dapatkan baucar dalam halaman Imbas QR apabila anda melakukan cucian seterusnya.",
  "Terms & Conditions:": "Terma & Syarat:",
  "To earn ONE e-stamp, you must spend at least {{ stampTransactionAmount }} credits ({{ currency }}{{ stampTransactionAmount }}) per session.": "Untuk mendapatkan SATU e-setem, anda harus berbelanja sekurang-kurangnya {{ stampTransactionAmount }} kredit ({{ currency }}{{ stampTransactionAmount }}) untuk setiap sesi.",
  "You will be automatically issued a voucher if you have collected 10 washer stamps.": "Anda akan diberikan baucar secara automatik jika anda berjaya mengumpul 10 setem mesin basuh.",
  "You will be automatically issued a voucher if you have collected 10 dryer stamps.": "Anda akan diberikan baucar secara automatik jika anda berjaya mengumpul 10 e-setem pengering",
  "You will be automatically issued a voucher if you have collected 10 dryer stamps or 10 washer stamps.": "Anda akan diberikan baucar secara automatik jika anda berjaya mengumpul 10 e-setem pengering atau 10 setem mesin basuh.",
  "You will be automatically issued a voucher if you have collected 10 stamps.": "Anda akan diberikan baucar secara automatik jika anda berjaya mengumpul 10 e-setem.",
  "The voucher is non-refundable and cannot be exchanged for cash; in part or in full.": "Baucar tidak boleh dikembalikan dan tidak boleh ditukar dengan wang tunai; sebahagian atau sepenuhnya.",
  "This voucher is valid for single transaction only.": "Baucar ini sah untuk satu transaksi sahaja.",
  "General questions": "Soalan umum",
  "E-stamps collection": "Koleksi e-setem",
  "Coin exchange machines": "Mesin tukar syiling",
  "Washer machines": "Mesin basuh",
  "Dryer machines": "Mesin pengering",
  "Paywave issues": "Isu paywave",
  "Cleanpro franchise": "Francais Cleanpro",
  "How can I wash my laundry using Cleanpro Plus?": "Bagaimanakah saya boleh membasuh pakaian saya menggunakan Cleanpro Plus?",
  "Here are the steps to wash your laundry using Cleanpro Plus:": "Berikut ialah langkah-langkah untuk mencuci pakaian anda menggunakan Cleanpro Plus:",
  "Find an unoccupied washing machine in the store, and insert your laundry in the washer.": "Cari mesin basuh yang tidak berpenghuni di kedai, dan masukkan pakaian anda ke dalam mesin basuh.",
  "Select the desired temperature that you would like to use for your laundry.": "Pilih suhu yang diingini yang anda ingin gunakan untuk cucian anda.",
  "Using the scanner in the app, scan the QR code provided on the washing machine. Then select the same settings that you have pressed on the machine.": "Menggunakan pengimbas dalam apl, imbas kod QR yang disediakan pada mesin basuh. Kemudian pilih tetapan yang sama yang telah anda tekan pada mesin.",
  'Tap on “Pay with credits” to pay the amount shown on the app.': 'Ketik pada "Bayar dengan kredit" untuk membayar jumlah yang ditunjukkan pada apl.',
  "Once the payment has been accepted, press the START button on the washer machine.": "Setelah pembayaran diterima, tekan butang MULA pada mesin basuh.",
  "After the washing cycle ends, you can just remove your laundry from the machine.": "Selepas kitaran basuh tamat, anda hanya boleh mengeluarkan pakaian anda dari mesin.",
  "How can I dry my laundry using Cleanpro Plus?": "Bagaimanakah saya boleh mengeringkan pakaian saya menggunakan Cleanpro Plus?",
  "Here are the steps to dry your laundry using Cleanpro Plus:": "Berikut ialah langkah untuk mengeringkan pakaian anda menggunakan Cleanpro Plus:",
  "Find an unoccupied drying machine in the store, and insert your laundry in the dryer.": "Cari mesin pengering yang tidak berpenghuni di kedai, dan masukkan pakaian anda ke dalam mesin pengering.",
  "Select the desired temperature and duration that you would like to use for your laundry.": "Pilih suhu dan tempoh yang diingini yang anda ingin gunakan untuk cucian anda.",
  "Using the scanner in the app, scan the QR code provided on the drying machine. Then select the same settings that you have pressed on the machine.": "Menggunakan pengimbas dalam apl, imbas kod QR yang disediakan pada mesin pengering. Kemudian pilih tetapan yang sama yang telah anda tekan pada mesin.",
  "Once the payment has been accepted, press the START button on the dryer machine.": "Setelah pembayaran diterima, tekan butang MULA pada mesin pengering.",
  "After the drying cycle ends, you can just remove your laundry from the machine.": "Selepas kitaran pengeringan tamat, anda hanya boleh mengeluarkan pakaian anda dari mesin.",
  "Why does the QR code I scanned take me to a different machine?": "Mengapa kod QR yang saya imbas membawa saya ke mesin lain?",
  "Here are the steps to solve it:": "Berikut adalah langkah-langkah untuk menyelesaikannya:",
  "Make sure that the number on the QR code (e.g. W1, W2, etc) is the same as the machine that you would like to use.": "Pastikan nombor pada kod QR (cth. W1, W2, dll) adalah sama dengan mesin yang anda ingin gunakan.",
  "If the QR code number does not match the machine you want, use the other machine. Please contact our customer service line at 03-27700100 to report this issue.": "Jika nombor kod QR tidak sepadan dengan mesin yang anda mahu, gunakan mesin yang lain. Sila hubungi talian khidmat pelanggan kami di 03-27700100 untuk melaporkan isu ini.",
  "How can I add more time to the washer that I am currently using?": "Bagaimanakah saya boleh menambah lebih banyak masa pada mesin basuh yang sedang saya gunakan?",
  "The washing time for our washer is fixed and cannot add any additional time.": "Masa basuh untuk mesin basuh kami adalah tetap dan tidak boleh menambah sebarang masa tambahan.",
  "How can I add more time to the dryer that I am currently using?": "Bagaimanakah saya boleh menambah lebih banyak masa pada pengering yang sedang saya gunakan?",
  "Here are the steps to add more time to the dryer machine:": "Berikut ialah langkah-langkah untuk menambahkan lebih banyak masa pada mesin pengering:",
  "By using the scanner in the app, scan the QR code provided at the dryer machine.": "Dengan menggunakan pengimbas dalam apl, imbas kod QR yang disediakan di mesin pengering.",
  "Select the amount of time that you would like to add to the machine.": "Pilih jumlah masa yang anda ingin tambahkan pada mesin.",
  "After the drying cycle ends, you can just remove the clothes from the machine.": "Selepas kitaran pengeringan tamat, anda hanya boleh mengeluarkan pakaian dari mesin.",
  "REMINDER - It is recommended to add drying time 5 minutes before the cycle ends. And you cannot add tokens for extra times when the machine finishes the drying cycle.": "PERINGATAN - Adalah disyorkan untuk menambah masa pengeringan 5 minit sebelum kitaran tamat. Dan anda tidak boleh menambah token untuk masa tambahan apabila mesin menamatkan kitaran pengeringan.",
  "Will the timer keep running when I have stopped the drying machine?": "Adakah pemasa akan terus berjalan apabila saya telah menghentikan mesin pengering?",
  "Yes. Even after you stop the machine, the timer will continue to run. If you want to extend the drying time, add drying time 5 minutes before the cycle ends. This prevents the cycle from ending before your clothes are completely dried.": "Ya. Walaupun selepas anda menghentikan mesin, pemasa akan terus berjalan. Jika anda ingin memanjangkan masa pengeringan, tambahkan masa pengeringan 5 minit sebelum kitaran tamat. Ini menghalang kitaran daripada berakhir sebelum pakaian anda kering sepenuhnya.",
  "How do I reload my credits for Cleanpro Plus?": "Bagaimanakah saya boleh memuatkan semula kredit saya untuk Cleanpro Plus?",
  "Here are the steps to reload your credits:": "Berikut ialah langkah-langkah untuk memuat semula kredit anda:",
  'Select the "Top Up Credit" button on the homepage.': 'Pilih butang "Top Up Credit" di halaman utama.',
  "Choose the amount you want to reload and the payment method you want to use.": "Pilih amaun yang ingin anda muat semula dan kaedah pembayaran yang ingin anda gunakan.",
  "You will be taken to the payment page. The screen will return to Cleanpro Plus once the transaction is completed.": "Anda akan dibawa ke halaman pembayaran. Skrin akan kembali ke Cleanpro Plus setelah transaksi selesai.",
  "How many credits can I reload in one transaction?": "Berapa banyak kredit yang boleh saya muatkan semula dalam satu transaksi?",
  "Reloading credits requires a minimum of RM 10 and a maximum of RM 50.": "Muat semula kredit memerlukan minimum RM 10 dan maksimum RM 50.",
  "Can I pay using credits in the Cleanpro Plus app along with tokens or coins in a single transaction?": "Bolehkah saya membayar menggunakan kredit dalam apl Cleanpro Plus bersama-sama dengan token atau syiling dalam satu transaksi?",
  "No. Only a single payment method may be chosen when performing any transactions.": "Tidak. Hanya satu kaedah pembayaran boleh dipilih semasa melakukan sebarang transaksi.",
  "The amount I have reloaded in my account was not reflected in the app.": "Jumlah yang saya muatkan semula dalam akaun saya tidak ditunjukkan dalam apl.",
  "Please close and restart the application and check if the new balance has been updated.": "Sila tutup dan mulakan semula aplikasi dan semak sama ada baki baharu telah dikemas kini.",
  "If the amount is still not updated, please contact our customer service line at 03-27700110 to receive further assistance.": "Jika jumlah masih tidak dikemas kini, sila hubungi talian khidmat pelanggan kami di 03-27700110 untuk menerima bantuan lanjut.",
  "Can I cash out the credits I reloaded in my account for this app?": "Bolehkah saya tunaikan kredit yang saya muatkan semula dalam akaun saya untuk apl ini?",
  "No. The credits that you have reloaded will remain in your Cleanpro Plus account.": "Tidak. Kredit yang telah anda muatkan semula akan kekal dalam akaun Cleanpro Plus anda.",
  "I have requested a refund, but I still have not received it.": "Saya telah meminta bayaran balik, tetapi saya masih belum menerimanya.",
  "Please contact our customer service line at 03-27700100 to receive further assistance.": "Sila hubungi talian khidmat pelanggan kami di 03-27700100 untuk menerima bantuan lanjut.",
  "I have made the payment, and the app double or triple charged me.": "Saya telah membuat pembayaran dan apl itu mengenakan caj dua kali ganda atau tiga kali ganda kepada saya.",
  "The washing machine cannot start even after I have paid using the app.": "Mesin basuh tidak boleh dimulakan walaupun saya telah membayar menggunakan aplikasinya.",
  "Ensure that the temperature settings you have selected and paid for on the application are the same as what you have pressed on the washing machine. If not, select the correct temperature settings on the washing machine and try again.": "Pastikan tetapan suhu yang telah anda pilih dan bayar pada aplikasi adalah sama dengan apa yang telah anda tekan pada mesin basuh. Jika tidak, pilih tetapan suhu yang betul pada mesin basuh dan cuba lagi.",
  "If the machine is still not able to be started, please contact our customer service line at 03-27700100 to receive further assistance.": "Jika mesin masih tidak dapat dihidupkan, sila hubungi talian khidmat pelanggan kami di 03-27700100 untuk menerima bantuan lanjut.",
  "Can I use multiple vouchers together in the same washing or drying cycle?": "Bolehkah saya menggunakan berbilang baucar bersama-sama dalam kitaran basuh atau pengeringan yang sama?",
  "No. Only a single voucher can be used for each washing and drying cycle.": "Tidak. Hanya satu baucar boleh digunakan untuk setiap kitaran pencucian dan pengeringan.",
  "Why can't I use my free wash voucher that I received after signing up?": "Mengapa saya tidak boleh menggunakan baucar basuh percuma saya yang saya terima selepas mendaftar?",
  "To redeem or use the voucher, you must complete at least one washing or drying cycle.": "Untuk menebus atau menggunakan baucar, anda mesti melengkapkan sekurang-kurangnya satu kitaran basuh atau pengeringan.",
  "Can I change my phone number even after registering my account?": "Bolehkah saya menukar nombor telefon saya walaupun selepas mendaftar akaun saya?",
  "No. Once registered to an account, the phone number is locked into the account.": "Tidak. Setelah mendaftar ke akaun, nombor telefon dikunci ke dalam akaun.",
  "I have problems in the store, and who can I refer to for help?": "Saya mempunyai masalah di kedai, dan siapa yang boleh saya rujuk untuk mendapatkan bantuan?",
  "Do not worry, as you can contact our customer service line at 03-27700100 to receive assistance at any time.": "Jangan risau, kerana anda boleh menghubungi talian khidmat pelanggan kami di 03-27700100 untuk menerima bantuan pada bila-bila masa.",
  "Should I add my own laundry detergent and softeners to the washing machine?": "Perlukah saya menambah detergen dan pelembut pakaian saya sendiri pada mesin basuh?",
  "There is no need for that, as all you have to do is bring your dirty clothes and put them in the washer. The detergent and softeners will be provided by us, and you can rest assured that the laundry detergents we used are certified industrial grade.": "Tidak perlu untuk itu, kerana anda hanya perlu membawa pakaian kotor anda dan memasukkannya ke dalam mesin basuh. Detergen dan pelembut akan disediakan oleh kami, dan anda boleh yakin bahawa detergen dobi yang kami gunakan adalah gred industri yang disahkan.",
  "I would like to add my own laundry detergent and softener to the washing machine.": "Saya ingin menambah detergen dan pelembut pakaian saya sendiri pada mesin basuh.",
  "We do not recommend our customers add their own detergent, as the mixture of different detergents may affect the wash quality. And you can rest assured that the laundry detergent we used is certified industrial grade.": "Kami tidak mengesyorkan pelanggan kami menambah detergen mereka sendiri, kerana campuran detergen yang berbeza boleh menjejaskan kualiti cucian. Dan anda boleh yakin bahawa detergen pakaian yang kami gunakan adalah gred industri yang disahkan.",
  "What time does the store close?": "Pukul berapa kedai tutup?",
  "Our self-service laundry operation is open 24 hours a day, which means you can always be sure to go back home with a fresh set of laundry.": "Operasi dobi layan diri kami dibuka 24 jam sehari, yang bermakna anda sentiasa yakin untuk pulang ke rumah dengan set pakaian yang segar.",
  "Are you open on weekends?": "Adakah anda buka pada hujung minggu?",
  "Yes. Cleanpro is also open on weekends and even on public holidays.": "Ya. Cleanpro juga dibuka pada hujung minggu dan juga pada cuti umum.",
  "How many clothes can I fit in the washing or drying machine?": "Berapa banyak pakaian yang boleh saya masukkan ke dalam mesin basuh atau pengering?",
  "To ensure that your laundry is properly washed or dried, keep the amount of laundry inside the machine lower than the line of guide sticker on the machine.": "Untuk memastikan pakaian anda dibasuh atau dikeringkan dengan betul, pastikan jumlah cucian di dalam mesin lebih rendah daripada garis pelekat panduan pada mesin.",
  "How many e-stamps can I collect in a single day?": "Berapa banyak e-setem yang boleh saya kumpulkan dalam satu hari?",
  "There is no limit to the amount of stamps that can be collected in a single day; ONE stamp will be awarded with every wash or dry.": "Tiada had kepada jumlah setem yang boleh dikutip dalam satu hari; SATU cop akan diberikan dengan setiap cucian atau pengeringan.",
  "When I use the washing service, can I change the collected stamp to a dryer stamp, and vice versa?": "Apabila saya menggunakan perkhidmatan mencuci, bolehkah saya menukar setem yang dikumpul kepada setem pengering, dan sebaliknya?",
  "No, the type of stamp collected is only applied to the service that you have used.": "Tidak, jenis setem yang dikumpul hanya digunakan pada perkhidmatan yang telah anda gunakan.",
  "What temperatures can I choose when I claim the free washing e-stamp reward?": "Apakah suhu yang boleh saya pilih apabila saya menuntut ganjaran e-setem cucian percuma?",
  "Any washing temperature is available for the e-stamp reward.": "Sebarang suhu basuhan tersedia untuk ganjaran e-setem.",
  "What temperatures can I choose when I claim the free drying e-stamp reward? And how long can I set the time?": "Apakah suhu yang boleh saya pilih apabila saya menuntut ganjaran e-setem pengeringan percuma? Dan berapa lama saya boleh menetapkan masa?",
  "Any drying temperature and duration can be selected for the e-stamp reward.": "Sebarang suhu dan tempoh pengeringan boleh dipilih untuk ganjaran e-setem.",
  "I'm unable to change the coin.": "Saya tidak dapat menukar syiling.",
  "What colour is the light shown on the machine, red or green? If it is a red light, please contact our customer service line at 03-27700100 to receive further assistance.": "Apakah warna cahaya yang ditunjukkan pada mesin, merah atau hijau? Jika lampu merah, sila hubungi talian khidmat pelanggan kami di 03-27700100 untuk menerima bantuan lanjut.",
  "Green Light": "Lampu hijau",
  "Red Light": "Lampu merah",
  "Machine functions as normal. Please try again.": "Mesin berfungsi seperti biasa. Sila cuba lagi.",
  "(flashing 2x) - There are no coins/tokens in the machine.": "(berkelip 2x) - Tiada syiling/token dalam mesin.",
  "OR": "ATAU",
  "Note is jammed, please proceed to contact customer service": "Nota tersekat, sila teruskan untuk menghubungi perkhidmatan pelanggan",
  "(flashing 3x) - Note is jammed, please proceed to contact customer service": "(berkelip 3x) - Nota tersekat, sila teruskan untuk menghubungi khidmat pelanggan",
  "My notes are stuck in the machine.": "Nota saya tersangkut dalam mesin.",
  "My detergent is not dispensing in the vending machine.": "Detergen saya tidak mendispens dalam mesin layan diri.",
  "The water is leaking out of the washing machine.": "Air keluar dari mesin basuh.",
  "Please check the washer machine to see whether there are any clothes that are stuck at the door side.": "Sila periksa mesin basuh untuk melihat sama ada terdapat pakaian yang tersangkut di bahagian pintu.",
  "If yes, here are the steps to solve it:": "Jika ya, berikut adalah langkah-langkah untuk menyelesaikannya:",
  "Press the emergency stop button (RED Button) for 3 seconds.": "Tekan butang berhenti kecemasan (Butang MERAH) selama 3 saat.",
  "Wait for the sound to stop, and then only open the door. (If after 30 minutes the sound still has not stopped, please contact our customer service line at 03-27700100 to receive further assistance)": "Tunggu bunyi berhenti, dan kemudian buka pintu sahaja. (Jika selepas 30 minit bunyi masih tidak berhenti, sila hubungi talian khidmat pelanggan kami di 03-27700100 untuk menerima bantuan lanjut)",
  "Ensure the door is closed tightly, and press the START button.": "Pastikan pintu ditutup rapat, dan tekan butang MULA.",
  "REMINDER - The emergency stop button can only be used once.": "PERINGATAN - Butang berhenti kecemasan hanya boleh digunakan sekali sahaja.",
  "There is no detergent dispensing in the washing machine.": "Tiada pendispensan detergen dalam mesin basuh.",
  "Ensure that the machine is in operation and that the START button was pressed.": "Pastikan mesin beroperasi dan butang START ditekan.",
  "If the detergent is still not dispensing, please contact our customer service department at 03-27700100 for further assistance.": "Jika detergen masih tidak mengeluarkan, sila hubungi bahagian perkhidmatan pelanggan kami di 03-27700100 untuk bantuan lanjut.",
  "The price displayed on the machine's screen did not change after inserting the token.": "Harga yang dipaparkan pada skrin mesin tidak berubah selepas memasukkan token.",
  "Press the small button (in the centre) to reject the tokens that have been inserted. Press a few times till the coins come out.": "Tekan butang kecil (di tengah) untuk menolak token yang telah dimasukkan. Tekan beberapa kali sehingga syiling keluar.",
  "If the coins still cannot come out or the button is not functioning, please contact our customer service line at 03-27700110 to receive further assistance.": "Jika syiling masih tidak dapat keluar atau butang tidak berfungsi, sila hubungi talian khidmat pelanggan kami di 03-27700110 untuk menerima bantuan lanjut.",
  "The machine is not starting, and it displays an error code.": "Mesin tidak dimulakan, dan ia memaparkan kod ralat.",
  "We would like to apologise for any inconvenience caused. Please contact our customer service line at 03-27700100 to receive further assistance.": "Kami ingin memohon maaf atas sebarang kesulitan yang berlaku. Sila hubungi talian khidmat pelanggan kami di 03-27700100 untuk menerima bantuan lanjut.",
  "I want to wash the clothes again, but why couldn't the machine start even after I added tokens to the machine?": "Saya ingin membasuh pakaian sekali lagi, tetapi mengapa mesin tidak dapat dihidupkan walaupun selepas saya menambah token pada mesin?",
  "Try opening and closing the door again before starting the machine.": "Cuba buka dan tutup pintu sekali lagi sebelum menghidupkan mesin.",
  "Can I change the water temperature of the machine?": "Bolehkah saya menukar suhu air mesin?",
  "Yes, but the washer temperature can only be changed from hot to cold after the machine has been started, and not from cold to hot.": "Ya, tetapi suhu mesin basuh hanya boleh ditukar daripada panas kepada sejuk selepas mesin dihidupkan, dan bukan dari sejuk kepada panas.",
  "Can I stop the machine?": "Bolehkah saya menghentikan mesin?",
  "Here are the steps to stop the machine:": "Berikut ialah langkah-langkah untuk menghentikan mesin:",
  "Wait for the sound to stop, and then only open the door. (If after 30 minutes the sound still has not stopped, please contact our customer service line at to receive further assistance)": "Tunggu bunyi berhenti, dan kemudian buka pintu sahaja. (Jika selepas 30 minit bunyi masih tidak berhenti, sila hubungi talian khidmat pelanggan kami di untuk menerima bantuan lanjut)",
  "There is no water supply on the machine used.": "Tiada bekalan air pada mesin yang digunakan.",
  "Why is the washing temperature cold even when I have selected HOT.": "Mengapa suhu basuh sejuk walaupun saya telah memilih PANAS.",
  "The water temperature is HOT or WARM only during washing. The water will turn COLD during rinsing.": "Suhu air adalah PANAS atau SUAM hanya semasa mencuci. Air akan menjadi SEJUK semasa dibilas.",
  "The machine has already finished washing, but it does not spin well, and there is water left on the load.": "Mesin telah selesai mencuci, tetapi ia tidak berputar dengan baik, dan terdapat air yang tertinggal pada beban.",
  "Can I put wet laundry into the dryer to dry?": "Bolehkah saya memasukkan pakaian basah ke dalam pengering untuk dikeringkan?",
  "No, as the dripping wet laundry might cause the dryer to short circuit.": "Tidak, kerana cucian basah yang menitis mungkin menyebabkan pengering litar pintas.",
  "REMINDER - Refer to the labels on your clothes to know what kind of temperature is best for the kind of fabric that it uses. Then select the temperature on the machine accordingly.": "PERINGATAN - Rujuk label pada pakaian anda untuk mengetahui jenis suhu yang terbaik untuk jenis fabrik yang digunakan. Kemudian pilih suhu pada mesin dengan sewajarnya.",
  "My clothes are still wet even after drying.": "Pakaian saya masih basah walaupun sudah dijemur.",
  "How can I stop the dryer when the machine is still running?": "Bagaimanakah saya boleh menghentikan pengering apabila mesin masih berjalan?",
  "Just open the door and the machine will automatically stop.": "Buka sahaja pintu dan mesin akan berhenti secara automatik.",
  "I added extra tokens for extra times, but the machine did not update the time.": "Saya menambah token tambahan untuk masa tambahan, tetapi mesin tidak mengemas kini masa.",
  "For the time to be reflected accurately, it is recommended for you to add tokens 5 minutes before the services end.": "Untuk masa yang dapat dilihat dengan tepat, anda disyorkan untuk menambah token 5 minit sebelum perkhidmatan tamat.",
  "REMINDER - You cannot add tokens for extra times when the machine finishes the drying cycle.": "PERINGATAN - Anda tidak boleh menambah token untuk masa tambahan apabila mesin menamatkan kitaran pengeringan.",
  "Why is the machine making noise?": "Mengapa mesin membuat bising?",
  "Please check the drum to see if there are any coins or other small things inside.": "Sila periksa dram untuk melihat sama ada terdapat syiling atau benda kecil lain di dalamnya.",
  "I have pressed the START button on the dryer, but it is still not working.": "Saya telah menekan butang MULA pada pengering, tetapi ia masih tidak berfungsi.",
  "Please try to open the door and close it again. Next, press the START button again to start drying.": "Sila cuba buka pintu dan tutup semula. Seterusnya, tekan butang MULA sekali lagi untuk mula mengeringkan.",
  "If the dryer is still not functioning, please contact our customer service line at 03-27700100 to receive further assistance.": "Jika mesin pengering masih tidak berfungsi, sila hubungi talian khidmat pelanggan kami di 03-27700100 untuk menerima bantuan lanjut.",
  "Paywave has deducted an amount from the card but the machine did not reflect the transaction.": "Paywave telah menolak amaun daripada kad tetapi mesin tidak menggambarkan transaksi tersebut.",
  "The Paywave transaction has failed but the amount has still been deducted from my card.": "Transaksi Paywave telah gagal tetapi jumlahnya masih ditolak daripada kad saya.",
  "Paywave has double/triple charge but the machine cannot start.": "Paywave mempunyai caj dua kali/tiga tetapi mesin tidak boleh dihidupkan.",
  "Paywave after double/triple charge machines can't start.": "Gelombang berbayar selepas mesin caj dua/tiga kali ganda tidak boleh dimulakan.",
  "Paywave is offline": "Paywave berada di luar talian",
  "Do Cleanpro Express offer franchise business/selling machines/laundry products?": "Adakah Cleanpro Express menawarkan perniagaan francais/menjual mesin/produk dobi?",
  "For further inquiries, please email to info@cleanproexpress.com": "Untuk pertanyaan lanjut, sila e-mel ke info@cleanproexpress.com",
  "Step 6": "Langkah 6",
  "User Existed": "Pengguna Wujud",
  "This email is registered": "E-mel ini telah didaftarkan",
  "This phone number is registered": "Nombor telefon ini telah didaftarkan",
  "Invalid Email Address": "Alamat email tidak sah",
  "Let's get started!": "Jom bermula sekarang!",
  "Enter your e-mail/mobile number": "Masukkan e-mel/no telefon anda",
  "Enter your mobile number": "Masukkan no telefon anda",
  "Enter your e-mail address": "Masukkan emel anda",
  "Proceed": "Teruskan",
  "Enter OTP number": "Masukkan nombor OTP",
  "You will receive a six-digit OTP via SMS sent to": "Anda akan menerima OTP enam digit melalui SMS yang dihantar kepada",
  "You will receive a six-digit OTP via e-mail sent to": "Anda akan menerima OTP enam digit melalui emel yang dihantar kepada",
  "Verify": "Sahkan",
  "Resend OTP": "Hantar semula OTP",
  "Welcome onboard!": "Selamat datang!",
  "Please fill in the info below": "Sila isikan maklumat dibawah",
  "Enter your name*": "Masukkan nama anda*",
  "Enter your email address*": "Masukkan alamat emel anda*",
  "Please enter either phone number or email address": "Sila masukkan sama ada nombor telefon atau alamat e-mel",
  "Create a password*": "Cipta kata laluan*",
  "Enter referral code (if got any)": "Masukkan kod rujukan (jika ada)",
  "Get started": "Mulakan",
  "Birthday updated successfully": "Hari lahir berjaya dikemas kini",
  "Date of birth": "Tarikh lahir",
  "Enter your date of birth": "Masukkan tarikh lahir anda",
  "Select your birth date": "Pilih tarikh lahir anda",
  "Update date of birth": "Kemas kini tarikh lahir",
  "Update birth date": "Kemas kini tarikh lahir",
  "Already have an account?": "Sudah mempunyai akaun?",
  "Login now": "Log masuk",
  "Sign in": "Log masuk",
  "Sign up now": "Daftar sekarang",
  "COMPLETED": "SELESAI",
  "WASHING NOW": "MENCUCI SEKARANG",
  "DRYING NOW": "KERING SEKARANG",
  "It's done!": "Sudah selesai!",
  "Please collect your fresh, clean laundry in order to leave the machine available for the next person to use!": "Sila kumpulkan pakaian anda yang segar dan bersih untuk membiarkan mesin tersedia untuk digunakan oleh orang seterusnya!",
  "Leave a feedback": "Tinggalkan maklum balas",
  "Time left": "Masa tinggal",
  "Add drying time": "Tambah masa",
  "Hi": "Hai",
  "customer": "pelanggan",
  "Thank you for your payment. Don't forget to select the <b>correct temperature</b> and press <b>START</b> when you're ready.": "Terima kasih atas pembayaran anda. Jangan lupa untuk memilih <b>suhu yang betul</b> dan tekan <b>MULA</b> apabila anda sudah bersedia.",
  "Select how you want to receive a notification when your laundry is finished": "Pilih cara anda mahu menerima pemberitahuan apabila pakaian anda selesai",
  "Sign up": "Daftar",
  "Continue to pay": "Terus bayar",
  "Register as a member to enjoy more benefits!": "Daftar sebagai ahli untuk menikmati lebih banyak faedah!",
  "Get FREE {{ voucherAmount }} credits per new user": "Dapatkan {{ voucherAmount }} kredit PERCUMA bagi setiap pengguna baharu",
  "Get FREE {{ voucherAmount }}% per new user": "Dapatkan {{ voucherAmount }}% PERCUMA bagi setiap pengguna baharu",
  "Enjoy exclusive promotions": "Nikmati promosi eksklusif",
  "FREE voucher on your birthday": "baucar PERCUMA pada hari lahir anda",
  "and more": "dan banyak lagi",
  "Denied camera access": "Akses kamera dinafikan",
  'Permission to access the camera has been denied. Please try again and click "Allow" to scan the QR code.': 'Kebenaran untuk mengakses kamera telah ditolak. Sila cuba lagi dan klik "Benarkan" untuk mengimbas kod QR.',
  "Okay": "Okay",
  "Oops. You don't have enough balance to make the payment": "Anda tidak mempunyai baki yang mencukupi untuk membuat pembayaran",
  "Please confirm": "Tolong sahkan",
  "Do you want to proceed with this feedback?": "Adakah anda mahu meneruskan maklum balas ini?",
  "Are you sure to purchase": "Adakah anda pasti untuk membeli",
  "credits for": "kredit dengan harga",
  "Topup credit": "Kredit tambah nilai",
  "Extended duration will be instantly reflected on machine after payment": "Tempoh lanjutan akan ditunjukkan serta-merta pada mesin selepas pembayaran",
  "Machine is currently busy": "Mesin sedang sibuk",
  "Do you want to extend drying time?": "Adakah anda ingin memanjangkan masa pengeringan?",
  "Cuci Hari-Hari Bersama Cleanpro!": "Cuci Hari-Hari Bersama Cleanpro!",
  "Ready for a challenge?": "Bersedia untuk menyahut cabaran?",
  "Here's one for you - all you need to do is wash your laundry 10 times in 20 days at participating Cleanpro outlets. Stand a chance to win a Limited Edition Cleanpro Designer Laundry Bag worth up to RM40!": "Anda hanya perlu mencuci pakaian anda 10 kali dalam tempoh 20 hari di cawangan Cleanpro yang terlibat. Sertai sekarang dan menangi Beg Dobi Jenama Cleanpro Edisi Terhad bernilai sehingga RM40!",
  "Here's how to participate:": "Cara-cara penyertaan:",
  "Step 1: Scan the QR Code on the washer to launch Cleanpro Plus": "Langkah 1: Imbas Kod QR pada mesin basuh untuk melancarkan Cleanpro Plus",
  "Step 2: Pay for your wash with Cleanpro Plus": "Langkah 2: Bayar cucian anda dengan Cleanpro Plus",
  "Step 3: Complete x10 washes within 20 days to win": "Langkah 3: Ulang 10 cucian dalam masa 20 hari untuk menang",
  "Challenge Period: 01 July - 31 August 2023": "Tempoh penyertaan: 01 Julai - 31 Ogos 2023",
  "Drop by your nearest Cleanpro outlet to wash your laundry with us for 20 days straight! Good luck!": "Cuci pakaian anda di outlet Cleanpro yang berdekatan selama 20 hari berturut-turut! Semoga berjaya!",
  "TERMS & CONDITIONS": "TERMA & SYARAT",
  "PARTICIPATION CRITERIA": "KRITERIA PENYERTAAN",
  "1) Participant needs to register an account in Cleanpro Plus web app.": "1) Peserta perlu mendaftar akaun dalam aplikasi web Cleanpro Plus.",
  "2) Participant can use any washer of any capacity in any participating outlets to qualify for entries.": "2) Peserta boleh menggunakan mana-mana mesin basuh dalam apa jua kapasiti di mana-mana cawangan yang mengambil bahagian untuk melayakkan diri untuk penyertaan.",
  "3) Payment needs to be made with Cleanpro Plus web app.": "3) Pembayaran perlu dibuat dengan aplikasi web Cleanpro Plus.",
  "4) Washes must be completed and paid (with no dispute whatsoever) within campaign period.": "4) Cucian mesti disiapkan dan dibayar (tanpa apa-apa pertikaian) dalam tempoh kempen berlangsung.",
  "5) Campaign period starts from 1st July 2023 at 00:00 and ends at 31st August 2023 at 23:59.": "5) Tempoh kempen bermula dari 1 Julai 2023 jam 00:00 dan berakhir pada 31 Ogos 2023 jam 23:59.",
  "WINNERS & PRIZES": "PEMENANG & HADIAH",
  "1) Cleanpro will notify the winners of the campaign via registered WhatsApp number or email address provided, within 4 weeks after the campaign ends.": "1) Cleanpro akan menghubungi pemenang kempen melalui nombor WhatsApp berdaftar atau alamat e-mel yang diberikan, dalam tempoh 4 minggu selepas kempen tamat.",
  "2) Any unclaimed prizes after 2 weeks from notification date will not be entertained thereafter.": "2) Sebarang hadiah yang tidak dituntut selepas 2 minggu dari tarikh pemberitahuan tidak akan dilayan selepas itu.",
  "3) Upon completing the ten (10) washes within twenty (20) days campaign, participants will be a winner and be eligible to win ONE (1) Cleanpro Designer Laundry Bag.": "3) Setelah menyelesaikan sepuluh (10) cucian dalam tempoh dua puluh (20) hari kempen, peserta akan menjadi pemenang dan layak memenangi SATU (1) Beg Dobi Cleanpro Designer.",
  "4) Winners are eligible to win more than ONE (1) Cleanpro Designer Laundry Bag in this campaign.": "4) Pemenang layak memenangi lebih daripada SATU (1) Beg Dobi Cleanpro Designer dalam kempen ini.",
  "5) Winners will be given the choice of his/her preferred laundry bag size; but only ONE (1) can be selected for each qualified win.": "5) Pemenang akan diberi pilihan saiz beg pakaian pilihannya; tetapi hanya SATU (1) boleh dipilih untuk setiap kemenangan yang layak.",
  "A) XL Size - Blue bag with up to 20KG in capacity": "A) Saiz XL - Beg biru dengan kapasiti sehingga 20KG",
  "B) L Size - White bag with up to 12KG in capacity": "B) Saiz L - Beg putih dengan kapasiti sehingga 12KG",
  "6) Time and location for collection of prize will be informed via Whatsapp number and/or email address provided.": "6) Masa dan lokasi pengambilan hadiah akan dimaklumkan melalui nombor Whatsapp dan/atau alamat emel yang diberikan.",
  "OTHER": "LAIN",
  "1) Changes to the terms and conditions will be at Cleanpro's discretion; without prior notice.": "1) Perubahan kepada terma dan syarat adalah atas budi bicara Cleanpro; tanpa notis awal.",
  "2) Completed wash is defined by washes performed with Cleanpro's washer and payments within Cleanpro Plus web app are made with no disputes.": "2) Kiraan cucian hanya diterima sah oleh cucian dengan mesin basuh Cleanpro dan pembayaran dalam aplikasi web Cleanpro Plus, tanpa sebarang pertikaian.",
  "3) By participating in this campaign automatically indicates that the Participant has agreed to all the campaign's Terms and Conditions as stipulated.": "3) Dengan menyertai kempen ini secara automatik menunjukkan bahawa Peserta telah bersetuju dengan semua Terma dan Syarat kempen seperti yang ditetapkan.",
  "Welcome Voucher": "Baucar Selamat Datang",
  "Welcome to the Cleanpro Plus family! This first gift is just the beginning. Stay tuned for a series of exciting promotions and campaigns, exclusively for Cleanpro Plus users.": "Selamat datang ke keluarga Cleanpro Plus! Ini hanyalah hadiah permulaan. Nantikan lain-lain promosi dan kempen yang menarik, istimewa untuk pengguna Cleanpro Plus.",
  "Terms & Conditions": "Terma & Syarat",
  "Welcome Voucher is valid for one-time use only.": "Baucar Selamat Datang ini hanya sah untuk digunakan sekali sahaja.",
  "There is no minimum spend required for the voucher to be used.": "Tiada perbelanjaan minimum diperlukan untuk menggunakan baucar ini.",
  "Welcome Voucher is auto-credited to the user's account at the point of registration on Cleanpro Plus and valid for {{ duration }} days only.": "Baucar Selamat Datang ini dikreditkan secara automatik ke akaun pengguna semasa pendaftaran di Cleanpro Plus dan sah selama {{ duration }} hari sahaja.",
  "Users are eligible to use their Welcome Voucher AFTER the completion of their first wash or dry cycle using the same Cleanpro Plus account.": "Pengguna layak menggunakan Baucar Selamat Datang mereka SETELAH selesai kitaran basuh atau kering pertama mereka menggunakan akaun Cleanpro Plus yang sama.",
  "Welcome Voucher cannot be used together with any ongoing promotions or any other promotional vouchers.": "Baucar Selamat Datang ini tidak boleh digunakan bersama-sama dengan mana-mana promosi yang sedang berjalan atau mana-mana baucar promosi lain.",
  "The voucher is final and cannot be refunded in form of cash or credit to the user.": "Baucar adalah muktamad dan tidak boleh dikembalikan dalam bentuk tunai atau kredit kepada pengguna.",
  "Cleanpro reserves the right to change the terms and conditions without prior notice.": "Cleanpro berhak untuk menukar terma dan syarat tanpa notis awal.",
  "Collect 10 e-stamps to earn FREE {{ voucherAmount }} credits voucher (worth {{ currency }}{{ voucherAmount }})": "Kumpul 10 e-setem untuk mendapatkan baucar {{ voucherAmount }} kredit PERCUMA (bernilai {{ currency }}{{ voucherAmount }})",
  "Collect 10 e-stamps to earn FREE {{ voucherAmount }}% voucher (worth {{ voucherAmount }}%)": "Kumpul 10 e-setem untuk mendapatkan baucar {{ voucherAmount }}% PERCUMA (bernilai {{ voucherAmount }}%)",
  "Collect 10 e-Stamp to earn FREE Voucher": "Kumpul 10 e-setem untuk mendapatkan baucar PERCUMA",
  "With every 10 e-stamps that you collected, you will be rewarded with a FREE {{ voucherAmount }} credits voucher (worth {{ currency }}{{ voucherAmount }}). Make your laundry routine not just hassle-free, but also rewarding.": "Dengan setiap 10 e-setem yang terkumpul, anda akan menerima baucar PERCUMA {{ voucherAmount }} kredit (bernilai {{ currency }}{{ voucherAmount }}). Jadikan rutin cucian bukan sahaja mudah, tetapi juga bermanfaat untuk anda.",
  "With every 10 e-stamps that you collected, you will be rewarded with a FREE {{ voucherAmount }}% credits voucher (worth {{ voucherAmount }}%). Make your laundry routine not just hassle-free, but also rewarding.": "Dengan setiap 10 e-setem yang terkumpul, anda akan menerima baucar PERCUMA {{ voucherAmount }}% (bernilai {{ voucherAmount }}%). Jadikan rutin cucian bukan sahaja mudah, tetapi juga bermanfaat untuk anda.",
  "How to participate:": "Cara-cara penyertaan:",
  "Prizes to be won:": "Hadiah untuk dimenangi:",
  "RM10 OFF Welcome Voucher": "DISKAUN RM10 Baucar Selamat Datang",
  "Welcome to the Cleanpro family, here is an appreciation voucher from us. Thank you for attending the COPE Investors Conference.": "Selamat datang ke keluarga Cleanpro, berikut adalah baucar penghargaan daripada kami. Terima kasih kerana menghadiri Persidangan Pelabur COPE.",
  "Welcome Voucher | COPE Investors Conference": "Baucar Selamat Datang | Persidangan Pelabur COPE",
  "Payment methods": "Kaedah pembayaran",
  "Tap to proceed with your preferred mode of payment": "Pilih kaedah yang anda inginkan untuk meneruskan pembayaran",
  "Cleanpro Plus credits": "Kredit Cleanpro Plus",
  "E-wallet or credit/debit cards": "E-dompet atau kad debit/kredit",
  "Go to homepage": "Pergi ke laman utama",
  // backend response or error
  "Admin is unauthorized to login": "Admin tidak dibenarkan untuk log masuk",
  "Internal Error": "Masalah dalaman",
  "Please enter the correct credentials": "Sila masukkan bukti kelayakan yang betul",
  "Operator not found": "Operator tidak ditemui",
  "Admin not found": "Admin tidak ditemui",
  "Distributor not found": "Pengedar tidak ditemui",
  "Client not found": "Pelanggan tidak ditemui",
  "Outlet not found": "Outlet tidak ditemui",
  "Token Package not found": "Pakej Token tidak ditemui",
  "Top up history not found": "Sejarah tambah nilai tidak ditemui",
  "Voucher not found": "Baucar tidak ditemui",
  "Faq not found": "Soalan Lazim tidak ditemui",
  "Faq Group not found": "Kumpulan Soalan Lazim tidak ditemui",
  "News not found": "Berita tidak ditemui",
  "Transaction not found": "Transaksi tidak ditemui",
  "Please Login Again": "Sila Log Masuk Semula",
  "Decoding Error": "Ralat Penyahkodan",
  "Some error occured, please contact the dev team if this error continues.": "Beberapa ralat telah berlaku, sila hubungi pasukan pembangun jika ralat ini berterusan.",
  "Please fill in all the required fields": "Sila isi semua medan yang diperlukan",
  "Only success transaction can be refund": "Hanya transaksi yang berjaya boleh dikembalikan",
  "Only token payment method can be refund": "Hanya kaedah pembayaran token boleh dikembalikan",
  "The discount code has been used, please change to other code": "Kod diskaun telah digunakan, sila tukar kepada kod lain",
  "Cannot use ewallet in sandbox": "Tidak boleh menggunakan ewallet dalam sandbox",
  "Please close the door to proceed your payment": "Sila tutup pintu untuk meneruskan pembayaran anda",
  "Machine is Out of Service": "Mesin Tiada Servis",
  "Machine is currently running, please try again later": "Mesin sedang berjalan, sila cuba sebentar lagi",
  "Machine is currently offline, please try again later": "Mesin sedang di luar talian, sila cuba sebentar lagi",
  "Outlet not participating in Loyalty program": "Outlet tidak menyertai program Kesetiaan",
  "Please log in to make the payment": "Sila log masuk untuk membuat pembayaran",
  "Package not found, please refresh and try again": "Pakej tidak ditemui, sila muat semula dan cuba lagi",
  "You have reached the maximum number of purchase": "Anda telah mencapai bilangan maksimum pembelian",
  "Store not found, please try again later": "Kedai tidak ditemui, sila cuba sebentar lagi",
  "Incorrect Password": "Kata laluan salah",
  "Token already top up": "Token sudah tambah nilai",
  "Payment failed": "Pembayaran gagal",
  "Transaction Failure": "Kegagalan Transaksi",
  "Transaction Refunded": "Transaksi telah dibayar balik",
  "Transaction Pending": "Transaksi Belum Selesai",
  "Please enter your mobile number": "Sila masukkan nombor telefon bimbit anda",
  "User is unauthorized to register": "Pengguna tidak dibenarkan mendaftar",
  "Please proceed to sign up": "Sila teruskan untuk mendaftar",
  "OTP expired, please request a new OTP": "OTP tamat tempoh, sila minta OTP baharu",
  "Incorrect verification code": "Kod pengesahan yang salah",
  "This referrer ID is not valid": "ID perujuk ini tidak sah",
  "This referrer ID is different operator": "ID perujuk ini adalah pengendali yang berbeza",
  "This referrer ID is exceed the limit": "ID perujuk ini melebihi had",
  "User is unauthorized to login": "Pengguna tidak dibenarkan untuk log masuk",
  "Click here to register": "Klik di sini untuk mendaftar",
  "User is unauthorized to login. Please contact the admin": "Pengguna tidak dibenarkan untuk log masuk. Sila hubungi admin",
  "User is unauthorized": "Pengguna tidak dibenarkan",
  "This outlet does not participate in the Loyalty Program": "Cawangan ini tidak menyertai Program Kesetiaan",
  "This outlet is not belongs to the operator": "Cawangan ini bukan milik pengendali",
  "Processing request": "Permintaan sedang memproses",
  "This user is not verified": "Pengguna ini tidak disahkan",
  "Insufficient token to make payment": "Token tidak mencukupi untuk membuat pembayaran",
  "This voucher is not available for use": "Baucar ini tidak tersedia untuk digunakan",
  "This voucher is belongs to other user": "Baucar ini adalah milik pengguna lain",
  "Invalid Discount Code": "Kod Diskaun Tidak Sah",
  "This discount code cannot use in this outlet": "Kod diskaun ini tidak boleh digunakan di kedai ini",
  "This discount code was expired": "Kod diskaun ini telah tamat tempoh",
  "This discount code cannot use in washer machine transaction": "Kod diskaun ini tidak boleh digunakan dalam transaksi mesin basuh",
  "This discount code cannot use in dryer machine transaction": "Kod diskaun ini tidak boleh digunakan dalam transaksi mesin pengering",
  "This discount code was fully redeemed today, try again tomorrow": "Kod diskaun ini telah ditebus sepenuhnya hari ini, esok cuba lagi",
  "You have fully redeemed this discount code": "Anda telah menebus sepenuhnya kod diskaun ini",
  "This discount code have been redeemed": "Kod diskaun ini telah ditebus",
  "This discount code is not applicable": "Kod diskaun ini tidak berkenaan",
  "Insufficient stamp": "Setem tidak mencukupi",
  "Please provide at least one voucher setting": "Sila berikan sekurang-kurangnya satu tetapan baucar",
  "Previous batch are still generating, please try again later": "Kumpulan sebelumnya masih menjana, sila cuba lagi kemudian",
  "Number of voucher code should be more than 0": "Bilangan kod baucar hendaklah lebih daripada 0",
  "Number of voucher code should be less than 50000": "Bilangan kod baucar hendaklah kurang daripada 50000",
  "Number of discount code should be more than 0": "Bilangan kod diskaun hendaklah lebih daripada 0",
  "Number of discount code should be less than 50000": "Bilangan kod diskaun hendaklah kurang daripada 50000",
  "No Item": "Tiada item",
  "Please add item": "Sila tambah item",
  "Staff not found": "Kakitangan tidak ditemui",
  "This task already completed": "Tugasan ini telah selesai",
  "Please complete the wash task first": "Sila selesaikan tugas mencuci dahulu",
  "Please complete the dry task first": "Sila selesaikan tugas kering dahulu",
  "Please select at least one outlet": "Sila pilih sekurang-kurangnya satu kedai",
}

const translationZh = {
  "LOGIN": "登录",
  "REGISTER": "注册",
  "SKIP TO PAYMENT": "直接付款",
  "WELCOME": "欢迎",
  "Error": "错误",
  "Please enter the correct phone number format. <br /><br /> <small>Example: 0129465628</small>": "请输入正确的电话号码格式。 <br /><br /> <small>例如: 0129465628</small>",
  "Info": "信息",
  "Please scan QRCode to Login": "请扫描二维码登录",
  "Forgot Password": "忘记密码",
  "Seems like you have forgotten your password<br />Create a temporary password?": "好像忘记密码了<br />创建临时密码？",
  "Cancel": "取消",
  "Confirm": "确认",
  "Reset Your Password": "重置您的密码",
  "Enter Your Phone Number / Email Address": "输入您的电话号码/电子邮件地址",
  "Please enter phone number / email address": "请输入电话号码/电子邮件地址",
  "Submit": "提交",
  "Please enter a valid phone number": "请输入正确的电话号码格式",
  "Please enter a valid email address": "请输入有效的电子邮件地址",
  "Success!": "成功！",
  "You may login using your one time password now.": "您现在可以使用一次性密码登录。",
  "Phone Number": "电话号码",
  "Password": "密码",
  "Don't have an account?": "还未有账户？",
  "Forgot password?": "忘记密码？",
  "Unable to register without a QRCode": "没有二维码无法注册",
  "Please read and agree to the privacy policies, terms and conditions": "请阅读并同意隐私政策、条款和条件",
  "Password length must be at least 6 characters": "密码长度必须至少为 6 个字符",
  "Please enter the confirmation code that was sent to your phone": "请输入发送到您手机的验证码",
  "Example: 012xxxxxxx": "例如: 012xxxxxxx",
  "Example: 09xxxxxxxx": "例如: 09xxxxxxxx",
  "Verification Method": "验证方法",
  "Name": "姓名",
  "Email": "电子邮件",
  "optional": "选择性",
  "Birth Year": "出生年份",
  "Year": "年",
  "Birth Month": "出生月份",
  "Month": "月",
  "Jan": "一月",
  "Feb": "二月",
  "Mar": "三月",
  "April": "四月",
  "May": "五月",
  "June": "六月",
  "July": "七月",
  "Aug": "八月",
  "Sept": "九月",
  "Oct": "十月",
  "Nov": "十一月",
  "Dec": "十二月",
  "Birth Day": "出生日期",
  "Day": "日",
  "OTP": "验证码",
  "OTP Confirmation": "确认验证码",
  "I agree to the": "我同意",
  "Privacy Policy": "隐私政策",
  "and": "和",
  "Terms": "条款",
  "Conditions": "条件",
  "SEND CODE": "发送验证码",
  "Our One-Time Password (OTP) emails might end up in your spam or junk folder. We kindly ask you to take a moment to check your spam mailbox.": "我们的一次性密码 (OTP) 电子邮件可能最终会进入您的垃圾邮件或垃圾文件夹。 我们恳请您花点时间检查您的垃圾邮件邮箱。",
  "Already have an account? Login": "已经有账户？登录",
  "Didn't receive a code?": "没有收到验证码？",
  "Resend Code": "重新发送验证码",
  "collected": "已收集",
  "Collect": "收集",
  "stamps": "印章",
  "get 1 voucher": "获得1张优惠券",
  "Minimum": "最低消费",
  "spending to collect each washer stamp": "以收集每个洗衣机印章",
  "spending to collect each dryer stamp": "以收集每个烘干机印章",
  "spending to collect each stamp": "以收集每个印章",
  "Device don't have a camera": "设备没有摄像头",
  "This is not a valid QRCode": "这不是有效的二维码",
  "Select Camera": "选择相机",
  "Switch": "切换",
  "Camera": "相机",
  "Loading": "加载中",
  "Scan the QR code on the machine.": "扫描机器上的二维码。",
  "Switch Camera": "切换相机",
  "Off torch light": "关闭手电筒",
  "On torch light": "开启手电筒",
  "switch camera if your camera is not clear": "如果您的相机不清晰，请切换相机",
  "Switch your camera if the display is not clear": "如果显示不清晰请切换相机",
  "Please allow this webapp to access your camera": "请允许此网络应用访问您的相机",
  "DISCOUNT": "折扣",
  "Congratulation": "恭喜",
  "Expired Date": "过期日期",
  "Voucher": "优惠券",
  "Vouchers": "优惠券",
  "Something went wrong": "出了些问题",
  "Stamping Collection": "印章收集",
  "Total e-Token": "总电子代币",
  "Total Wash": "洗衣机次数",
  "Total Dry": "烘干机次数",
  "Reload": "充值",
  "Washer": "洗衣机",
  "Dryer": "烘干机",
  "Congratulations!": "恭喜！",
  "You have completed your Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "您已完成印章收集。<br />优惠券已给出和印章收集已刷新",
  "You have completed Washer Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "您已完成洗衣机印章收集。<br />优惠券已给出和印章收集已刷新",
  "You have completed Dryer Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "您已完成烘干机印章收集。<br />优惠券已给出和印章收集已刷新",
  "Latest News": "最新消息",
  "Transaction History": "交易记录",
  "Stamping": "印章",
  "Transaction": "交易",
  "News": "消息",
  "Confirmation": "确认",
  "Logout Session?": "登出？",
  "NO": "否",
  "OK": "确定",
  "Account": "账户",
  "FAQs": "常见问题",
  "Feedback": "反馈",
  "LOGOUT": "登出",
  "Logout": "登出",
  "Are you sure you want to submit your feedback?": "您确定要提交反馈吗？",
  "No": "否",
  "Yes": "是",
  "Success": "成功",
  "Failed": "失败",
  "Pending": "待定",
  "Your feedback is submitted successfully": "您的反馈已成功提交",
  "Please fill in all the required fields before submit.": "请在提交前填写所有必填内容。",
  "Give us your feedback!": "您好，请对我们的服务提出宝贵建议，谢谢！",
  "Type your name here (optional)": "在此处输入您的姓名（选择性）",
  "required": "必填",
  "Type your number here": "在此处输入您的电话号码",
  "Outlet": "店铺",
  "Select an outlet": "选择店铺",
  "Outlet Cleanliness": "店铺清洁度",
  "Washer Machine Performance": "洗衣机性能",
  "Dryer Machine Performance": "烘干机性能",
  "Customer Service": "客户服务",
  "Comment": "评论",
  "Type your comment here (optional)": "在此处输入您的评论（选择性）",
  "SUBMIT": "提交",
  "Join as a member now!": "立即加入成为会员！",
  "Join as a loyalty member now to get more benefits such as vouchers and free machine usage!<br /><br /><strong>Register as loyalty member?</strong>": "现在加入会员，即可免费获得优惠券和免费机器使用等福利！<br /><br /><strong>注册成为会员？</strong>",
  "Maybe Next Time": "下一次吧",
  "Yes, Register Now": "是的，立即注册",
  "Washer Payment": "洗衣机支付",
  "Dryer Payment": "烘干机支付",
  "Vending Machine Payment": "自动贩卖机支付",
  "Online": "在线",
  "Offline": "离线",
  "Running": "运行",
  "Vacant": "空置",
  "Vending Machine": "自动贩卖机",
  "Discount": "折扣",
  "No Vouchers Available": "没有可用的优惠券",
  "Payment Method": "付款方法",
  "Would you like to pay with?": "您愿意使用哪种方式付款?",
  "e-Token": "电子代币",
  "E-TOKEN": "电子代币",
  "E-Wallet": "电子钱包",
  "Duitnow QR code": "Duitnow二维码",
  "The Duitnow QR code will be downloaded and you need to open your banking app to make the payment. Pay {{ currency }}{{ totalPaymentAmount }} to {{ machineName }}?": "Duitnow 二维码将会下载，您需要打开银行应用程序进行付款。向 {{ machineName }} 支付 {{ currency }}{{ totalPaymentAmount }} 吗？",
  "Pay": "付",
  "to": "给",
  "You may under utilized the voucher.": "您尚未充分利用您的优惠券。",
  "Proceed to pay": "继续支付",
  "Processing ...": "支付中 ...",
  "You can't pay with e-token if voucher is in used, please remove your voucher to pay with e-token.": "您不能同时使用优惠劵和电子代币付款，请移除您的优惠卷以使用电子代币付款。",
  "Insufficient e-Tokens": "电子代币不足",
  "Reload your e-token now?": "现在充值您的电子代币？",
  "Reload now!": "现在充值！",
  "You have": "您有",
  "e-Tokens": "电子代币",
  "Tokens to": "代币给",
  "This machine is currently offline, please try again later.": "本机器当前离线，请稍后重试。",
  "MACHINE": "机器",
  "Machine": "机器",
  "TYPE": "类型",
  "CAPACITY": "容量",
  "DURATION": "时长",
  "Select Duration": "选择时长",
  "AMOUNT TO PAY": "支付总额",
  "PRICE": "价格",
  "VOUCHER": "优惠券",
  "Select Voucher >": "选择优惠券 >",
  "TOTAL": "总额",
  "PAY": "付款",
  "Remove Voucher": "移除优惠券",
  "Select Your Voucher": "选择您的优惠券",
  "min": "分钟",
  "MIN": "分钟",
  "Select Payment Amount": "选择支付总额",
  "Select Water Temperature": "选择水温",
  "Select temperature": "选择水温",
  "Cold": "冷",
  "Warm": "温",
  "Hot": "热",
  "e-Tokens to": "电子代币给",
  "No Purchase History": "无购买记录",
  "e-tokens": "电子代币",
  "No Promotions": "没有促销",
  "New Password": "新密码",
  "Back": "返回",
  "Buy this package?": "买这个配套？",
  "Purchase": "购买",
  "e-Tokens for": "电子代币以",
  "Reload Tokens": "充值电子代币",
  "Are you sure you want to purchase {{ tokenAmount }} e-Token for {{ currency }}{{ paymentAmount }}?": "您确定要以 {{ currency }}{{ paymentAmount }} 购买 {{ tokenAmount }} 电子代币吗？",
  "Current e-Token": "当前电子代币",
  "History": "历史记录",
  "Your profile picture has been updated!": "您的头像已更新！",
  "Please only use png, jpg or jpeg file extension type": "请仅使用 png、jpg 或 jpeg 类型文件",
  "Please only use image type file": "请仅使用图像类型文件",
  "User name has updated successfully!": "用户名更新成功！",
  "Email Address updated successfully": "电子邮件更新成功",
  "Phone number updated successfully": "电话号码更新成功",
  "Password must be at least 6 characters": "密码必须至少6个字符",
  "Password updated successfully": "密码更新成功",
  "User Settings": "用户设置",
  "Profile Picture": "个人头像",
  "Change Password": "更改密码",
  "Do you want to submit your feedback?": "您想提交您的反馈吗？",
  "Thank You!": "谢谢你！",
  "Thank you for using our services.": "感谢您使用我们的服务。",
  "BACK TO HOMEPAGE": "返回主页",
  "How to pay?": "如何付款？",
  "Step 1": "步骤 1",
  "After scan the QR code on the machine, select either register new account, login your account or skip to payment.": "扫描机器上的二维码后，选择注册新帐户、登录您的帐户或直接付款。",
  "Step 2-1": "步骤 2-1",
  "For Dryer – Select your desired duration. Different duration will have different prices.": "对于烘干机 - 选择所需的时长。不同的时间会有不同的价格。",
  "Step 2-2": "步骤 2-2",
  "For Washer – Select your desired water temperature. Different water temperature will have different prices.": "对于洗衣机 - 选择您想要的水温。不同的水温会有不同的价格。",
  "Step 3": "步骤 3",
  "For login user, Click on ‘Select Voucher’ to select your voucher.": "对于所有登录用户，点击“选择优惠劵”以选择您的优惠劵。",
  "Step 4": "步骤 4",
  "Click on ‘PAY’ button to pay. For login user, select your payment method to pay.": "点击“付款”按钮进行支付。对于登录用户，选择您的支付方式进行支付。",
  "Step 5": "步骤 5",
  "After confirm the price and machine details, click on ‘OK’ button to pay.": "确认价格和机器详情后，点击“确定”按钮进行支付。",
  "How to reload your e-Token?": "如何充值您的电子代币？",
  'Click on the "Reload" button.': '点击“充值”按钮。',
  "Select the package that you wish to purchase.": "选择您要购买的配套。",
  'Click on "Purchase" as confirmation before payment page.': '在前往付款页面之前点击“购买”作为确认。',
  "Is e-token can be refund/transfer back to e-wallet?": "电子代币可以退款/转回电子钱包吗？",
  "Why can't I applied my voucher?": "为何不能使用我的优惠券？",
  "When there is a Happy Hour Promotion is on going, you are unable to apply your voucher temporarily.": "每当特别促销正在进行时，您暂时无法使用您的优惠劵。",
  "How to apply my voucher?": "如何使用我的优惠券？",
  'Click on the "Select Voucher" button.': '点击“选择优惠券”按钮。',
  "Select the voucher that you wish to apply.": "选择您要使用的优惠券。",
  'Click on "Pay" once you have verified the voucher is successfully applied.': '确认优惠劵已成功使用后，请点击“付款”。',
  "Step 2": "步骤 2",
  "Language has updated successfully!": "语言更新成功！",
  "Change Language": "更改语言",
  "Language": "语言",
  "Select language": "选择语言",
  "Referral Code": "邀请码",
  "Copied!": "已复制!",
  "Inviting friends to get more benefits!": "邀请好友以获取更多福利！",
  "Would you like to share your referral code to your friends & family?": "您要分享您的邀请码给朋友&家人吗？",
  "Refer a friend and get": "推荐朋友并获得",
  "Share the love to your friends & family?": "将爱分享给您的朋友和家人？",
  "voucher": "优惠劵",
  "token": "电子代币",
  "stamp": "印章",
  "washer stamp": "洗衣机印章",
  "dryer stamp": "烘干机印章",
  "Learn More": "了解更多",
  "Welcome Gift": "欢迎礼物",
  "Share Your Referral Code Now": "立即分享您的邀请码",
  "Birthday Gift for You": "给您的生日礼物",
  "Let's Pay with E-Token": "使用电子代币支付",
  "Collect 10 Stamps and Get Voucher": "收集 10 个印章并获得优惠劵",
  "The voucher will be given if you registered your account successfully.": "如果您成功注册了您的帐户，您将获得优惠券。",
  "There is no expiration date for this voucher.": "此优惠券没有过期日期。",
  "The voucher can be used for dryer machine only.": "优惠券仅可用于烘干机。",
  "The voucher can be used for washer machine only.": "优惠券仅可用于洗衣机。",
  "The voucher can be used for Drop n Go transaction only.": "优惠券仅可用于 Drop n Go 交易。",
  "The voucher can be used for washer machine or Drop n Go transaction.": "优惠券仅可用于洗衣机或 Drop n Go 交易。",
  "The voucher can be used for dryer machine or Drop n Go transaction.": "优惠券仅可用于烘干机或 Drop n Go 交易。",
  "The voucher can be used for dryer or washer machine.": "优惠券可用于烘干机或洗衣机。",
  "The voucher can be used for washer machine, dryer machine or Drop n Go transaction.": "优惠券可用于洗衣机、烘干机或 Drop n Go 交易。",
  "When you applied the voucher during payment, it will give you": "当您在付款期间使用您的优惠券时，它会给您",
  "discount.": "折扣。",
  "When promotion is on going, the voucher is not applicable.": "促销期间，优惠券不可使用。",
  "The voucher cannot be used if your payment method is E-Token.": "如果您的付款方式是电子代币，则不能使用该优惠券。",
  "Your referral code can be found in user settings page.": "您的邀请码可以在用户设置页面中找到。",
  "You can get": "你可以得到",
  "stamp for the reward.": "印章作为奖励。",
  "e-token for the reward.": "电子代币作为奖励。",
  "e-token based on transaction payment amount for the reward.": "电子代币根据交易的支付金额作为奖励。",
  "for the reward.": "以作为奖励。",
  "based on transaction payment amount for the reward.": "根据交易支付金额获得奖励。",
  "You can get a voucher for the reward": "您可以获得优惠券作为奖励",
  "which have": "此优惠劵将有",
  "it can be used for dryer machine only.": "它只能用于烘干机。",
  "it can be used for washer machine only.": "它只能用于洗衣机。",
  "it can be used for Drop n Go transaction only.": "它只能用于Drop n Go 交易。",
  "it can be used for dryer or washer machine.": "它可用于烘干机或洗衣机。",
  "it can be used for dryer machine or Drop n Go transaction.": "它可用于烘干机或Drop n Go 交易。",
  "it can be used for washer machine or Drop n Go transaction.": "它可用于洗衣机或Drop n Go 交易。",
  "it can be used for washer machine, dryer machine or Drop n Go transaction.": "它可用于洗衣机、烘干机或Drop n Go 交易。",
  "washer stamp for the reward.": "洗衣机印章作为奖励。",
  "dryer stamp for the reward.": "烘干机印章作为奖励。",
  "washer stamp and": "洗衣机印章和",
  "cleanproBirthdayShortDesc": "使用 Cleanpro Plus 在您的特殊日子兑换 {{ birthdayVoucherAmount }} 货币的生日礼券。点击以了解更多信息。",
  'When you click on "PAY" button on the payment page, you can choose either using E-wallet or E-token to pay.': '当您在支付页面点击"付款"按钮时，您可以选择使用电子钱包或电子代币进行支付。',
  "When you using your voucher during payment, you will be unable to pay with E-token.": "当您在付款期间使用您的优惠券时，您将无法使用电子代币付款。",
  "You can reload your E-token by clicking the reload button in the stamping page and select the package to purchase.": "您可以通过点击印章页面中的充值按钮并选择要购买的配套来充值您的电子代币。",
  "You can reload your E-token by clicking the reload button in the home page and select the package to purchase.": "您可以通过点击主页中的充值按钮并选择要购买的配套来充值您的电子代币。",
  "You can view your E-token reload history and E-token transaction history in transaction page.": "您可以在交易页面查看您电子代币的充值记录和交易记录。",
  "You can view your E-token transaction history in transaction page.": "您可以在交易页面查看您电子代币的交易记录。",
  "Using either dryer machine or washer machine with the minimum spending of": "使用烘干机或洗衣机，最低消费",
  "to collect one stamp.": "即可收集一个印章。",
  "The voucher will be given if you have collected 10 stamps.": "如果您收集了 10 个印章，将获得优惠券。",
  "Using dryer machine with the minimum spending of": "使用烘干机最低消费",
  "Using washer machine with the minimum spending of": "使用洗衣机最低消费",
  "to collect one dryer stamp.": "即可获得一个烘干机印章。",
  "to collect one washer stamp.": "即可获得一个洗衣机印章。",
  "The voucher will be given if you have collected 10 dryer stamps or 10 washer stamps.": "如果您收集了 10 个烘干机印章或 10 个洗衣机印章，将获得优惠券。",
  "For the voucher given by the dryer stamps,": "对于烘干机印章给出的优惠劵，",
  "For the voucher given by the washer stamps,": "对于洗衣机印章给出的优惠劵，",
  "When promotion is on going, all the vouchers is not applicable.": "促销期间，所有优惠券均不可使用。",
  "All the vouchers cannot be used if your payment method is E-Token.": "如果您的付款方式是电子代币，则所有优惠券都不能使用。",
  "Description": "简介",
  "Refer Friends": "推荐给朋友",
  "View our guideline": "查看我们的指南",
  "When a friend / family register using your referral code, and completed a transaction with a minimum spending of": "当朋友/家人使用您的邀请码注册并完成最低消费",
  ", you will be rewarded automatically.": "的交易时，您将自动获得奖励。",
  "One user only allow to have": "一个用户只允许拥有",
  "referrals.": "邀请者。",
  "Confirmation Password": "确认密码",
  "Password and confirmation password should be same.": "密码和确认密码必须相同。",
  "You haven't applied your voucher": "您还没使用优惠劵",
  "Would you like to continue your payment without using your voucher?": "您想在不使用优惠券的情况下继续付款吗？",
  "Continue": "继续",
  "Apply voucher": "使用优惠劵",
  "Transfer": "转账",
  "Transfer Tokens": "转账电子代币",
  "Please fill in the phone number": "请填写电话号码",
  "Please fill in the email": "请填写电子邮件",
  "Please enter the phone number / email that you want to transfer to": "请输入您要转账的电话号码/电子邮件",
  "Please fill in the transfer amount": "请填写转账总额",
  "Transfer amount should be more than 0 and less than your current e-Token": "转账总额需大于0且小于您当前的电子代币",
  "Please enter your password": "请输入您的密码",
  "Member ID": "会员ID",
  "E-Token Transfer Amount": "电子代币转账总额",
  "Please confirm the details before proceeding": "请在继续之前确认详细信息",
  "Transfer From": "转账从",
  "Transfer To": "转账至",
  "Transfer Amount": "转账总额",
  "Transfer Successfully!": "转账成功！",
  "Transfer Time": "转账时间",
  "Token": "电子代币",
  "Done": "完成",
  "Next": "继续",
  "Enter Password": "输入密码",
  "Transfer E-Token Amount": "电子代币转账金额",
  "User not found": "找不到用户",
  "Password is incorrect": "密码不正确",
  "Please go to your account setting and reset your password": "请前往您的用户设置并重置您的密码",
  "Only up to last 3 months transaction history": "仅最近3个月的交易记录",
  "Reload History": "充值记录",
  "Transfer History": "转账记录",
  "{{ point }} History": "{{ point }}记录",
  "Only up to last 3 months reload history": "仅最近3个月的充值记录",
  "Only up to last 3 months transfer history": "仅最近3个月的转账记录",
  "Only up to last 3 months {{ point }} history": "仅最近3个月的{{ point }}记录",
  "If require urgent response, please contact us through whatapp": "如果需要紧急回应，请通过whatsapp与我们联系",
  "Please scan the QR code again": "请再次扫描二维码",
  "Home": "主页",
  "Quick": "快速",
  "Super": "超级",
  "Premium": "高级",
  "All users will have infinite referrals.": "所有用户都将获得无限邀请者。",
  "Collect 10 stamps to get 1 Voucher": "收集10个印章获得1张优惠劵",
  "The voucher is only for customer use only, not for sale.": "优惠券仅供客户使用，不用于销售。",
  "Outlet is currently offline, please try again later": "店铺目前处于离线状态，请稍后再试",
  "The voucher will be given if you completed a transaction with a minimum spending of": "您将获得优惠劵如果您完成交易以最低消费",
  "The birthday voucher will be given on the first day of your birthday month.": "生日券将在您生日月份的第一天发放。",
  "The voucher only will be given if you have exceed spending amount of": "优惠劵只会发放当您的消费超过",
  "Pay with KiplePay": "使用 KiplePay 付款",
  "Pay with Scan2Pay": "使用 Scan2Pay 付款",
  "Pay with Razer": "使用 Razer 付款",
  "Pay with HitPay": "使用 HitPay 付款",
  "Pay with Duitnow QR": "使用 Duitnow QR 付款",
  "Pay with PagBank": "使用 PagBank 付款",
  "DISCOUNT CODE": "优惠码",
  "Enter code >": "输入优惠码 >",
  "Enter Discount Code": "输入优惠码",
  "Remove Code": "移除优惠码",
  "Discount Code": "优惠码",
  "Close": "关闭",
  "Please fill in your email address": "请填写您的电子邮件地址",
  "Notification": "通知",
  "Do you want to receive notification when wash cycle done?": "您想在洗涤周期完成时收到通知吗？",
  "pay extra": "额外付费",
  "Email Address": "电子邮件地址",
  "Please fill in your phone number": "请填写您的电话号码",
  "Invalid Phone Number": "无效的电话号码",
  "Washing Complete": "洗涤完成",
  "Drying Complete": "烘干完成",
  "The voucher will be given if you registered your account successfully, but it only can be used when your total transaction spending reach": "成功注册账户即可获赠礼券，但仅能使用当交易总额达",
  "and above.": "或以上。",
  "This voucher is not applicable": "此优惠券不适用",
  "Finish in": "完成于",
  "Need to refresh to update": "需要刷新才能更新",
  "It's indicative and may change based on washer load": "这是指示性的，可能会根据洗衣机负载而变化",
  "We'll let you know when it's ready to pick up": "当准备好取衣物时，我们会通知您",
  "FREE": "免费",
  "Enter voucher code (if got any)": "输入优惠券兑换码（如果有）",
  "Please fill in the voucher code": "请输入优惠券兑换码",
  "Available On": "可用于",
  "Apply": "使用",
  "Enter a code": "输入兑换码",
  "Input Promo Code": "输入促销代码",
  "Voucher is redeemed successfully!": "优惠券兑换成功！",
  "Not available": "无法使用",
  "Unable to register": "无法注册",
  "Drop n Go Service": "Drop n Go 服务",
  "Select Mode": "选择模式",
  "Quantity": "数量",
  "Pack": "包裹",
  "Add more": "添加更多",
  "Select Size": "选择尺寸",
  "Select Services": "选择服务",
  "Are you sure to delete the item?": "您确定要删除该配套吗？",
  "Delete": "删除",
  "Your Duitnow QR code": "您的 Duitnow 二维码",
  "How to pay using Duitnow QR code?": "如何使用 Duitnow 二维码付款？",
  "Launch": "运行",
  "Launch your preferred Mobile Banking or eWallet app.": "启动您喜欢的手机银行或电子钱包应用程序。",
  "Scan QR code from Gallery": "从图库扫描二维码",
  "Choose DuitNow QR from your gallery.": "从您的图库中选择 DuitNow 二维码。",
  "Confirm payment details and pay.": "确认付款细节并付款。",
  "Receive payment confirmation.": "收到付款确认。",
  "expiring on": "过期于",
  "based on transaction payment amount": "根据交易支付金额",
  "Join as our": "立即加入我们的",
  "Loyalty Member": "忠诚会员",
  "now to get more reward": "以获得更多奖励",
  "Payment amount should be greater than 0": "付款金额应大于0",
  // admin portal
  "Dashboard": "概括",
  "Report": "报告",
  "Store Performance": "商店表现",
  "User Performance": "用户表现",
  "Voucher Redemption": "优惠券兑换",
  "Token Report": "电子代币报告",
  "SMS Report": "短信报告",
  "Discount Code Report": "优惠码报告",
  "User Details": "用户详细信息",
  "User Compensation": "用户补偿",
  "Voucher Settings": "优惠券设置",
  "Token Settings": "代币设置",
  "Referral Settings": "邀請碼设置",
  "Voucher Code": "优惠券兑换码",
  "Customize Color": "更换主题颜色",
  "User Migration": "用户迁移",
  "Back To": "返回",
  "Eligibility": "资格",
  "If ticked, the voucher will have expired date": "如果勾选，优惠券将有过期日期",
  "Expiry": "期限",
  "If ticked, the voucher can apply to washer machine transaction": "如果勾选，优惠劵可用于洗衣机交易",
  "Washer Use": "应用于洗衣机",
  "If ticked, the voucher can apply to dryer machine transaction": "如果勾选，优惠劵可用于烘干机交易",
  "Dryer Use": "应用于烘干机",
  "If ticked, the voucher can apply to Drop n Go transaction": "如果勾选，优惠劵可用于Drop n Go交易",
  "Package Use": "应用于配套",
  "Voucher Lifespan": "优惠券有效期限",
  "days": "天",
  "Discount Type": "折扣类型",
  "Select a discount type": "选择折扣类型",
  "Flat": "扁平",
  "Rate": "率",
  "Voucher Title": "优惠券标题",
  "Voucher Description": "优惠券说明",
  "Voucher Amount": "优惠券金额",
  "CONFIRM": "确认",
  "If ticked, the system will give voucher to user who completed washer stamp collection": "如果勾选，系统将向完成洗衣机印章收集的用户发放优惠劵",
  "If ticked, the system will give voucher to user who completed dryer stamp collection": "如果勾选，系统将向完成烘干机印章收集的用户发放优惠劵",
  "If ticked, the user only can get/use the voucher after make a transaction": "如果勾选，用户只有在进行交易后才能获取/使用优惠券",
  "Pay And Get Voucher": "付款后获取优惠券",
  "Pay And Use Code": "消费后才能使用优惠码",
  "If ticked, the system will only give the voucher to user after the user make a transaction": "如果勾选，只有在用户进行交易后，系统才会将优惠券发放给用户",
  "If ticked, the system will give voucher to user who completed registration process": "如果勾选，系统将向完成注册的用户赠送优惠劵",
  "If ticked, the system will give voucher to user on their birthday month": "如果勾选，系统将在用户生日当月向用户赠送优惠券",
  "If ticked, the system will give voucher to user who completed stamp collection": "如果勾选，系统将赠送优惠劵给完成印章收集的用户",
  "Minimum Payment Amount To Get/Use Register Voucher": "获取/使用注册优惠券的最低付款金额",
  "Voucher Method": "优惠劵赠送方式",
  "Select a voucher method": "选择优惠券赠送方式",
  "The user directly get the voucher after register but only can use it after make transaction": "用户注册后直接获得优惠券，但需进行交易后才能使用",
  "The user only can get voucher after make a transaction": "用户只有在进行交易后才能获得优惠券",
  "Minimum Payment Amount To Get Birthday Voucher": "获得生日优惠劵的最低付款金额",
  "Minimum Payment Amount To Use Discount Code": "使用优惠码的最低交易金额",
  "Outlets": "商店",
  "E-Payment Sales": "电子支付销售",
  "Number of stamps granted to users": "赠送用户的印章数量",
  "Total number of stamps granted": "赠送印章总数",
  "Registered Users": "注册用户",
  "Stamps": "印章",
  "Referral": "邀请码",
  "Register": "注册",
  "Top Outlets": "热门商店",
  "Users": "用户",
  "Sort by": "排序方式",
  "Top Users": "热门用户",
  "User name": "用户名",
  "Number": "电话号码",
  "Spending": "花费",
  "Number of registered users": "注册用户数",
  "Number of voucher given to users": "给予用户的优惠券数量",
  "Total number of voucher given": "给予的优惠券总数",
  "Voucher Type": "优惠券类型",
  "Voucher Value": "优惠券的价值",
  "Date": "日期",
  "Discount Code Type": "优惠码类型",
  "Discount Code Amount": "优惠码金额",
  "Number of Redemption": "兑换次数",
  "Total Discount Amount": "总折扣金额",
  "Select Outlet": "选择商店",
  "Select Date": "选择日期",
  "Select Transaction Date": "选择交易日期",
  "Reset": "重置",
  "Download Report": "下载报告",
  "Please fill in the remark": "请填写备注",
  "Transaction Date": "交易日期",
  "Machine Type": "机器类型",
  "Machine Capacity": "机器容量",
  "Total Amount": "总金额",
  "Payment Amount": "支付金额",
  "Discount From": "折扣从",
  "Discount Amount": "折扣金额",
  "Action": "行动",
  "Remark": "备注",
  "Refund Date": "退款日期",
  "Refund": "退款",
  "Purchase Date": "购买日期",
  "e-Token Purchased (Tokens)": "购买的电子代币（电子代币）",
  "Bought e-Token using": "购买的电子代币",
  "Transaction ID": "交易ID",
  "Method": "方式",
  "Requery": "重新查询",
  "Reward Date": "奖励日期",
  "Reward Type": "奖励类型",
  "Notification Method": "通知方式",
  "Email Preview": "电子邮件预览",
  "Message for User": "给用户的留言",
  "Enter the message to be sent to the user": "输入要发送给用户的留言",
  "Email Address to show on Email Template": "电子邮件模板上显示的电子邮件地址",
  "Input Company Email Address": "输入公司电子邮件地址",
  "Company Name to show on Email Template": "电子邮件模板上显示的公司名称",
  "Input Company Name": "输入公司名称",
  "Company Phone No. to show on Email Template": "电子邮件模板上显示的公司电话号码",
  "Input Company Phone No.": "输入公司电话号码",
  "Voucher ID": "优惠券ID",
  "Washer Stamp": "洗衣机印章",
  "Dryer Stamp": "烘干机印章",
  "e-Token Amount": "电子代币金额",
  "Transfer Date": "转账日期",
  "Transfer Amount (Token)": "转账金额（电子代币）",
  "Number (Transfer From)": "电话号码（转自）",
  "Name (Transfer From)": "姓名（转自）",
  "Member ID (Transfer From)": "会员ID（转自）",
  "Number (Transfer To)": "电话号码（转给）",
  "Name (Transfer To)": "姓名（转给）",
  "Member ID (Transfer To)": "会员ID（转给）",
  "Grant Date": "给予日期",
  "Claim Date": "使用日期",
  "Member Details Report": "会员详情报告",
  "Transaction Status": "交易状态",
  "Token Reload": "充值电子代币",
  "Token Transfer": "电子代币转账",
  "Voucher History": "优惠券历史记录",
  "TOKEN": "电子代币",
  "Message Sent": "发送的信息",
  "Date Sent": "发送日期",
  "Remaining SMS Credit": "短信余额",
  "Store": "商店",
  "Total Registered Users": "注册用户总数",
  "User registered but no transaction record": "用户已注册但无交易记录",
  "Registered": "已注册",
  "User spending is less than": "用户花费小于",
  "Active": "活跃",
  "User spending is from": "用户花费从",
  "until": "至",
  "Tier 1": "1 级",
  "User spending is": "用户花费",
  "and above": "或以上",
  "Tier 2": "2 级",
  "Average spending in each transaction": "每笔交易的平均花费",
  "Average Spending": "平均花费",
  "Total number of transactions": "交易总数",
  "Total Transactions": "总交易量",
  "Number of vouchers used in all the transactions": "所有交易中使用的优惠劵数量",
  "Voucher Claimed": "使用优惠劵数",
  "Total number of washer machine transactions": "洗衣机交易总数",
  "Washer Usage": "洗衣机使用次数",
  "Total number of dryer machine transactions": "烘干机交易总数",
  "Dryer Usage": "烘干机使用次数",
  "User Name": "用户名",
  "e-Token Spent": "花费的电子代币",
  "e-Token Detailed Report": "电子代币详细报告",
  "e-Token Purchase History": "电子代币购买历史记录",
  "Select Purchase Date": "选择购买日期",
  "Outlet Name": "店铺名称",
  "Total number of e-token used in all the transactions": "所有交易中使用的电子代币总数",
  "Total e-Token Collected": "收集到的电子代币总额",
  "Total number of e-token used in washer machine transactions": "所有洗衣机交易中使用的电子代币总数",
  "Washer e-Token Collected": "收集到的洗衣机电子代币总额",
  "Total number of e-token used in dryer machine transactions": "所有烘干机交易中使用的电子代币总数",
  "Dryer e-Token Collected": "收集到的洗烘干机电子代币总额",
  "Purchase History": "购买记录",
  "All": "全部",
  "Most Bought e-Token Package": "最受欢迎的电子代币配套",
  "for": "对于",
  "Tokens": "电子代币",
  "Total Earned from users buying e-Tokens": "用户购买电子代币的总收入",
  "Total e-Tokens Bought from you": "从您购买的电子代币总数",
  "Join Date": "加入日期",
  "Channel": "频道",
  "Tier": "等级",
  "Referrer": "邀请者",
  "Referrals": "邀请人数",
  "Number of transactions for last 7 days": "近7天的交易数",
  "Number of Usage (in week)": "使用次数（周）",
  "Number of transactions for last 1 month": "近1个月的交易数",
  "Number of Usage (in month)": "使用次数（月）",
  "Total amount spent in all the transactions": "所有交易花费的总金额",
  "Total Spent": "总花费",
  "Last Transaction Date": "最后交易日期",
  "Number of vouchers collected": "领取优惠券数量",
  "Voucher Collected": "优惠劵领取数",
  "Number of vouchers used": "使用优惠券数量",
  "Voucher Spent": "优惠券使用数",
  "Washer Used": "洗衣机使用次数",
  "Dryer Used": "烘干机使用次数",
  "Total number of e-Token spent in all the transactions": "所有交易中花费的电子代币总数",
  "Total number of stamps collected": "收集的印章总数",
  "Total Stamps": "印章总数",
  "Select Join Date": "选择加入日期",
  "Select Grant Date": "选择给予日期",
  "Amount": "价值",
  "The image size is over 2MB": "图片大小超过2MB",
  "Duplicate language selected": "您选择了重复的语言",
  "Are you sure you want to add FAQ?": "您确定要添加常见问题吗？",
  "FAQ added successfully": "常见问题添加成功",
  "FAQ Group added successfully": "常见问题组添加成功",
  "Please provide the faq with the default language": "请提供默认语言的常见问题",
  "Add FAQ": "添加常见问题",
  "Default Language": "默认语言",
  "Select Default Language": "选择默认语言",
  "Image (Optional)": "图片（可选）",
  "max": "最大",
  "Question": "问题",
  "Select Language": "选择语言",
  "Remove": "移除",
  "Answer": "回答",
  "Add Translation": "添加翻译",
  "Are you sure you want to add FAQ Group?": "您确定要添加常见问题组吗？",
  "Please provide the faq group with the default language": "请为常见问题组提供默认语言",
  "Add FAQ Group": "添加常见问题组",
  "Title": "标题",
  "Title Language": "标题语言",
  "Please fill in the title": "请填写标题",
  "Please fill in the description": "请填写描述",
  "Please fill in the short description": "请填写简短的描述",
  "Please select the end date for your news": "请选择消息的结束日期",
  "Please provide the news with the default language": "请提供默认语言的消息",
  "Please provide the banner image": "请提供横幅图片",
  "Please provide the news image": "请提供消息图片",
  "Are you sure you want to add news?": "您确定要添加消息吗？",
  "News added successfully": "消息添加成功",
  "Please fill in all the required fields before submit": "请在提交前填写所有必填选项",
  "Add News": "添加消息",
  "Banner Image": "横幅图片",
  "News Image": "消息图片",
  "Banner": "横幅",
  "aspect ratio": "纵横比",
  "Image": "图片",
  "Display in": "显示于",
  "Inbox Page": "收件箱页面",
  "Promotion Banner": "促销横幅",
  "Select Period: (optional)": "选择期间：（可选）",
  "Short Description": "简短的介绍",
  "Your theme has been updated successfully": "您的主题已成功更新",
  "Default Theme": "默认主题",
  "Change Logo": "更改标志",
  "REMOVE LOGO": "移除标志",
  "Sms credit used up": "短信额度已用完",
  "Please top up your sms credit": "请充值您的短信余额",
  "Please": "请",
  "Again": "多一次",
  "Welcome": "欢迎",
  "Ranking": "排行榜",
  "Select Year": "选择年份",
  "Missing Field": "选项未填",
  "Please select can be used for washer, dryer or package": "请选择可用于洗衣机、烘干机或配套",
  "Please fill in the start date and end date": "请填写开始日期和结束日期",
  "Discount amount should be more than 0": "折扣金额应大于0",
  "Redeem per user should be more than 0": "每个用户兑换次数应大于0",
  "Redeem per day should be more than 0": "每日兑换次数必须大于0",
  "Your discount code have edited successfully": "您的优惠码已编辑成功",
  "Your discount code have added successfully": "您的优惠码已添加成功",
  "Delete this entry?": "删除此条目？",
  "Your discount code have deleted successfully": "您的优惠码已删除成功",
  "Created Date": "创建日期",
  "Discount code": "优惠码",
  "General Discount Code": "通用优惠码",
  "Unique Discount Code": "独特优惠码",
  "Start Date": "开始日期",
  "End Date": "结束日期",
  "Redeem Per User": "每个用户兑换次数",
  "Redeem Per Day": "每日兑换次数",
  "Add Discount Code": "添加优惠码",
  "Generate Discount Code": "生成优惠码",
  "No Discount Code": "没有优惠码",
  "Edit Discount Code": "编辑优惠码",
  "Select Period": "选择期间",
  "Redeem per user": "每个用户兑换次数",
  "How many times the discount code can be used for each user": "每个用户可以使用优惠码的次数",
  "Redeem per day": "每日兑换次数",
  "How many times the discount code can be used in one day": "优惠码一天内可以使用多少次",
  "If ticked, the discount code can apply to washer machine transaction": "如果勾选，优惠码可应用于洗衣机交易",
  "If ticked, the discount code can apply to dryer machine transaction": "如果勾选，优惠码可应用于烘干机交易",
  "If ticked, the discount code can apply to Drop n Go transaction": "如果勾选，优惠码可应用于Drop n Go交易",
  "The user enter the discount code": "用户输入优惠码",
  "in the payment page will get": "在支付页面会得到",
  "discount": "折扣",
  "The discount code only can be used start from {{ start_date }} until {{ end_date }}": "优惠码只能在 {{ start_date }} 至 {{ end_date }} 期间使用",
  "Every user only can use the discount code {{ redeem_per_user }} times": "每个用户只能使用优惠码 {{ redeem_per_user }} 次",
  "Every user only can use {{ redeem_per_user }} discount code from this batch of code": "每个用户只能使用这批优惠码中的 {{ redeem_per_user }} 个优惠码",
  "Only {{ redeem_per_day }} transactions can use the discount code in one day": "一天内只有 {{ redeem_per_day }} 笔交易可以使用优惠码",
  "The discount code only can be used for washer machine": "优惠码仅适用于洗衣机",
  "The discount code only can be used for dryer machine": "优惠码仅适用于烘干机",
  "The discount code only can be used for Drop n Go transaction": "优惠码仅适用于Drop n Go交易",
  "The discount code can be used for washer machine or Drop n Go transaction": "优惠码可用于洗衣机或 Drop n Go 交易",
  "The discount code can be used for dryer machine or Drop n Go transaction": "优惠码可用于烘干机或 Drop n Go 交易",
  "The discount code can be used for washer machine, dryer machine or Drop n Go transaction": "优惠码可用于洗衣机、烘干机或 Drop n Go 交易",
  "The discount code can be used for washer or dryer machine": "优惠码可用于洗衣机或烘干机",
  "The discount code only can be used after user spending {{ currency }} {{ pay_and_use_amount }}": "优惠码必须在消费满{{ currency }} {{ pay_and_use_amount }}后才能使用",
  "Are you sure you want to edit FAQ?": "您确定要编辑常见问题吗？",
  "FAQ updated successfully": "常见问题更新成功",
  "Edit FAQ": "编辑常见问题",
  "Are you sure you want to edit FAQ Group?": "您确定要编辑常见问题组吗？",
  "FAQ Group updated successfully": "常见问题组更新成功",
  "Edit FAQ Group": "编辑常见问题组",
  "Are you sure you want to edit news?": "您确定要编辑消息吗？",
  "News edit successfully": "消息编辑成功",
  "Edit News": "编辑消息",
  "All the FAQ under this group will be deleted. Are you sure you want to delete FAQ Group?": "该组下的所有常见问题将被删除。您确定要删除常见问题组吗？",
  "FAQ Group deleted successfully": "常见问题组删除成功",
  "FAQ List": "常见问题列表",
  "NO FAQ GROUP": "没有常见问题组",
  "FAQ Group": "常见问题组",
  "Are you sure you want to delete FAQ?": "您确定要删除常见问题吗？",
  "FAQ deleted successfully": "常见问题删除成功",
  "NO FAQs": "没有常见问题",
  "FAQ": "常见问题",
  "Easy": "便利",
  "Fast Laundromate": "快速洗衣店",
  "Smart Laundry Management System": "智能洗衣管理系统",
  "Enter your username and password to login": "请输入登录用户名和密码",
  "Username": "用户名",
  "Are you sure you want to delete news?": "您确定要删除消息吗？",
  "News deleted successfully": "消息删除成功",
  "NO NEWS": "没有消息",
  "The referral setting has been updated successfully!": "邀请码设置已更新成功！",
  "Number of Stamp": "印章数量",
  "Number of Washer Stamp": "洗衣机印章数量",
  "Number of Dryer Stamp": "烘干機印章數量",
  "Enable Referral Feature?": "开启邀请码功能吗？",
  "Are you sure you want to delete the tier setting?": "您确实要删除此等级设置吗？",
  "Minimum referee transaction amount for referrer to get referral rewards": "邀请者获得邀请奖励的最低交易金额",
  "Maximum number of reward within this tier": "此等级内的最大奖励数量",
  "Maximum number of reward can get from same user": "从同一用户可获得的最大奖励数量",
  "Point Grant Type": "积分授予类型",
  "Select Point Grant Type": "选择积分授予类型",
  "Token Grant Type": "电子代币授予类型",
  "Select Token Grant Type": "选择电子代币授予类型",
  "Add Tier": "添加等级",
  "Stamp": "印章",
  "If ticked, the system will give stamp to user": "如果勾选，系统将会给用户印章",
  "If ticked, the system will give e-Token to user": "如果勾选，系统将会给用户电子代币",
  "If ticked, the system will give voucher to user": "如果勾选，系统将会给用户优惠券",
  "Number of E-Token": "电子代币数量",
  "Reward Giveaway": "奖励赠品",
  "Which type of reward you want to give to customer?": "您想给予客户哪种类型的奖励？",
  "For machine uses (required)": "用于机器用途（必选）",
  "leave blank if no expiry date": "如果没有到期日则留空",
  "Remarks": "备注",
  "Reward": "奖励",
  "Please fill in all the required field. (Title, description, and amount must be more than 0)": "请填写所有必填选项。 （标题、描述、金额必须大于0）",
  "Checkbox required": "需要勾选复选框",
  "Must select voucher can be used for washer, dryer or package": "必须选择优惠券可用于洗衣机，烘干机或配套",
  "How many e-token?": "多少个电子代币？",
  "Number of e-Token": "电子代币数量",
  "Token value must be more than 0": "电子代币金额必须大于0",
  "STAMP": "印章",
  "Please fill in at least one of the required field": "请填写至少一项必填选项",
  "The value must be more than 0": "该值必须大于 0",
  "How many times rewarded to user": "给用户多少次奖励",
  "Rewarded Times": "奖励次数",
  "How many times rewarded voucher to user": "给用户多少次优惠券",
  "Voucher Rewarded (times)": "优惠券奖励（次）",
  "How many times rewarded stamp to user": "给用户多少次印章",
  "Stamps Rewarded (times)": "印章奖励（次）",
  "Total number of washer stamps rewarded to user": "奖励给用户的洗衣机印章总数",
  "Washer Stamps Granted (stamp)": "给予的洗衣机印章（印章）",
  "Total number of dryer stamps rewarded to user": "奖励给用户的烘干机印章总数",
  "Dryer Stamps Granted (stamp)": "给予的烘干机印章（印章）",
  "How many times rewarded e-Token to user": "给用户多少次电子代币",
  "e-Token Rewarded (times)": "电子代币奖励（次）",
  "Total number of e-Token rewarded to user": "奖励给用户的电子代币总数",
  "Total e-Token Granted (token)": "给予的电子代币总数（电子代币）",
  "Point Rewarded (times)": "积分奖励（次）",
  "How many times rewarded point to user": "给用户奖励积分多少次",
  "Total Point Granted (point)": "总积分奖励（积分）",
  "Total number of point rewarded to user": "奖励给用户的积分总数",
  "Point Amount": "积分数量",
  "Reward History": "奖励历史",
  "Are you sure you want to change how the stamping mechanics works?": "您确定要更改印章机制的运作方式吗？",
  "The stamping mechanics setting has updated successfully!": "印章机制设置已更新成功！",
  "The voucher setting has been updated successfully!": "优惠券设置已更新成功！",
  "Mechanics Settings": "印章机制设置",
  "Minimum transaction amount for getting stamp": "获得印章的最低交易金额",
  "Select User Stamping Mechanic": "选择用户的印章机制",
  "Separated Stamping": "分开印章",
  "Combined Stamping": "综合印章",
  "Stamp for?": "印章方式？",
  "Both": "两者",
  "Washer Only": "仅洗衣机",
  "Dryer Only": "仅烘干机",
  "Separated Stamping = Different washer and dryer stamp collection. (example: Using Washer will gain 1 washer's stamp, and Dryer will gain 1 dryer stamp)": "分开印章 = 不同的洗衣机和烘干机印章收集。 （例如：使用洗衣机将获得1个洗衣机印章，使用烘干机将获得1个烘干机印章）",
  "Combined Stamping = Washer and dryer has the same collection. (example: Using Washer or Dryer will gain 1 stamp)": "综合印章 = 洗衣机和烘干机有相同的印章收集。 （例如：使用洗衣机或烘干机将获得1个印章）",
  "Stamp for": "印章方式",
  "User will be able to collect both washer stamp and dryer stamp": "用户将能够收集洗衣机印章和烘干机印章",
  "User will only able to collect Washer stamp": "用户只能收集洗衣机印章",
  "User will only able to collect Dryer stamp": "用户只能收集烘干机印章",
  "Registration": "注册",
  "Birthday": "生日",
  "Please choose at least one payment method": "请至少选择一种付款方式",
  "Please choose at least one member or non-member payment": "请选择至少一个会员或非会员付款",
  "Are you sure you want to change the payment setting?": "您确定要更改付款设置吗？",
  "The payment setting has updated successfully!": "付款设置已更新成功！",
  "Invalid input": "不正确输入",
  "The price and token must be more than 0": "价格和电子代币必须大于0",
  "Maximum number of purchase must be more than 1": "最多购买的次数必须大于1",
  "Point amount must be more than 1": "积分数量必须大于 1",
  "Added a new Token Bundle Package": "添加了新的电子代币配套",
  "Bundle deleted successfully!": "配套删除成功！",
  "Reload voucher setting have edited successfully!": "充值优惠劵设置已编辑成功！",
  "Added on": "添加于",
  "remove": "移除",
  "edit": "编辑",
  "NO PACKAGES ON SALES": "没有销售配套",
  "Point Settings": "积分设置",
  "Enable Loyalty Point": "启用积分",
  "Point Name": "积分名称",
  "Number Of Point Equals To": "积分数量同等于",
  "If ticked, the point will have expired date": "如果勾选，积分将过期",
  "Point Lifespan": "积分寿命",
  "months": "月",
  "Minimum Transaction Amount To Get Point": "获取积分的最低交易金额",
  "Point Grant Amount": "积分赠予数量",
  "Point settings updated successfully!": "积分设置更新成功！",
  "Point Eligibility": "积分资格",
  "If ticked, the system will give point to user when user reload token successfully": "如果勾选，当用户成功充值电子代币时系统会给用户积分",
  "{{ point_amount }} point will be given after user reload token successfully": "用户成功充值电子代币后将获得 {{ point_amount }} 积分",
  "Payment Settings": "付款设置",
  "Select Payment Method": "选择付款方式",
  "Enable E-Payment?": "启用电子支付吗？",
  "Enable Token Currency?": "启用电子代币吗？",
  "Member and Non-Member Settings": "会员和非会员设置",
  "Enable Member Payment?": "启用会员支付？",
  "Enable Non-Member Payment?": "启用非会员支付？",
  "Promotion Settings": "促销设置",
  "Enable Non Member Promotion?": "启用非会员促销吗？",
  "Enable Notification When Cycle Done?": "循环完成后启用通知吗？",
  "SMS charges": "短信收费",
  "SMS Settings": "短信设置",
  "Enable Free Sms For Members": "启用会员免费短信",
  "If enable notification, user can choose either receive email or sms when complete washing or drying": "如果启用通知，用户可以选择在完成洗涤或烘干时接收电子邮件或短信",
  "If user choose sms, non member have to pay extra {{ currency }}{{ sms_notification_amount }} for the sms": "如果用户选择短信，非会员必须支付额外的{{ currency }}{{ sms_notification_amount }}短信费用",
  "If user choose sms, member and non member have to pay extra {{ currency }}{{ sms_notification_amount }} for the sms": "如果用户选择短信，会员和非会员必须支付额外的{{ currency }}{{ sms_notification_amount }}短信费用",
  "Add Package": "添加配套",
  "Edit e-Token Package": "编辑电子代币配套",
  "Add e-Token Package": "添加电子代币配套",
  "Please only input numberic value to prevent any bugs.": "请仅输入数字值，以防止任何错误。",
  "Price": "价格",
  "If ticked, the system will limit the number of purchase": "如果勾选，系统会限制购买次数",
  "Limit Number of Purchase": "限制购买次数",
  "Number of maximum purchase": "最多的购买次数",
  "Reload Voucher": "充值优惠券",
  "If ticked, the system will give voucher to user when user reload token successfully": "如果勾选，当用户充值成功后，系统将向用户赠送优惠券",
  "If ticked, the system will give voucher repeatly": "如果勾选，系统会重复赠送优惠券",
  "Recurring": "反复",
  "Total number of voucher given to user": "给予用户的优惠券总数",
  "Period for giving next voucher": "发放下一张优惠券的期限",
  "Token package {{ currency }}{{ price }} for {{ numberOfToken }} tokens": "{{ currency }}{{ price }} 的电子代币配套将获得 {{ numberOfToken }} 个电子代币",
  "Each user only can purchase {{ max_purchase }} times for the token package": "每个用户只能购买{{ max_purchase }}次此电子代币配套",
  "Voucher will be given after user reload token successfully": "用户充值成功后将获得优惠券",
  "Voucher will not given after user reload tokens successfully": "用户充值成功后将不会获得优惠券",
  "The voucher will be expired after {{ voucher_duration }} days": "优惠券将于 {{ voucher_duration }} 天后过期",
  "The voucher will not expired": "优惠券不会过期",
  "Total {{ recurring_number }} vouchers will be given to user and each voucher will discount": "总共 {{ recurring_number }} 张优惠券将赠送给用户，每张优惠券将有折扣",
  "Edit Package": "编辑配套",
  "Age": "年龄",
  "Total number of vouchers collected": "已领取优惠券总数",
  "Vouchers Acquired": "已获得优惠券",
  "Total amount spent in washer machine transactions": "洗衣机交易总金额",
  "Total Washer Payment": "洗衣机总花费",
  "Total amount spent in dryer machine transactions": "烘干机交易总金额",
  "Total Dryer Payment": "烘干机总花费",
  "Total amount spent in all transactions": "所有交易花费的总金额",
  "Total Payment": "付款总额",
  "report": "报告",
  "Total amount spent for buying e-Token": "购买电子代币的总金额",
  "Total number of e-Token purchased": "购买的电子代币总数",
  "Total e-Token Purchased (Tokens)": "购买的电子代币总数（电子代币）",
  "Total number of e-Token used in washer machine transactions": "洗衣机交易中使用的电子代币总数",
  "Washer Payment (Tokens)": "洗衣机付款金额（电子代币）",
  "Total number of e-Token used in dryer machine transactions": "烘干机交易中使用的电子代币总数",
  "Dryer Payment (Tokens)": "烘干机付款金额（电子代币）",
  "Total number of e-Token used in all the transactions": "所有交易中使用的电子代币总数",
  "Total Payment (Tokens)": "付款总金额（电子代币）",
  "Number of washer stamp collected currently": "目前收集的洗衣机印章数量",
  "Number of dryer stamp collected currently": "目前收集的烘干机印章数量",
  "User Phone Number / Member ID / Email": "用户电话号码/会员ID/电子邮件",
  "Example: 0123456789,A123456": "例如: 0123456789,A123456",
  "SEARCH": "搜索",
  "Total Users Registered": "注册用户总数",
  "Total Active Outlets": "店铺总数",
  "Total Vouchers Given": "给予的优惠券总数",
  "Total Transaction Counts": "总交易量",
  "Total User Stamps Collected": "用户收集的印章总数",
  "Distributor Login": "经销商登录",
  "Client Login": "客户登录",
  "Kiosk Login": "自助服务终端登录",
  "Promotion": "促销",
  "Please select operator": "请选择运营商",
  "Please select distributor": "请选择经销商",
  "Select Operator": "选择运营商",
  "Select Distributor": "选择经销商",
  "User can login or skip to payment without login to make the payment": "用户可以登录或者无需登录即可跳转至支付页面进行支付",
  "User have to login only can make the payment": "用户必须登录才可以付款",
  "User only can skip to payment without login to make the payment": "用户只能直接跳至付款页面进行付款（无登录功能）",
  "Only CSV file is accepted": "仅接受 CSV 文件",
  "The user and the balance have updated": "用户和余额已更新",
  "Register Time": "注册时间",
  "Upload User CSV file": "上传用户 CSV 文件",
  "Sample CSV file": "示例 CSV 文件",
  "Please follow the CSV file format": "请遵循 CSV 文件格式",
  "User CSV file": "用户 CSV 文件",
  "Only CSV file (comma delimited) is support now": "现在仅支持 CSV 文件（逗号分隔）",
  "Confirm uploaded data": "确认上传的数据",
  "Operator": "运营商",
  "Distributor": "经销商",
  "Refund By": "退款者",
  "Reward By": "奖励者",
  "Number Of Voucher Code": "优惠券兑换码数量",
  "Number Of Discount Code": "折扣码数量",
  "New User Only": "仅限新用户",
  "Minimum Transaction Amount To Redeem": "兑换最低交易金额",
  "Download": "下载",
  "Generate Voucher Code": "生成优惠券兑换码",
  "Your voucher code is generating": "您的优惠券兑换码正在生成",
  "Your discount code is generating": "您的折扣码正在生成",
  "Voucher Code Settings": "优惠券兑换码设置",
  "If ticked, the voucher code only can redeem in register page": "如果勾选，兑换码只能在注册页面兑换",
  "If ticked, the user only can use the voucher after make a transaction": "如果勾选，用户只能在交易后使用该优惠券",
  "If ticked, the user only can use the discount code after make a transaction": "如果勾选，用户只能在交易后使用该优惠码",
  "Expired Date Of Voucher Code": "优惠券兑换码的失效日期",
  "Minimum Transaction Amount To Use Voucher": "使用优惠券的最低交易金额",
  "Minimum Transaction Amount To Use Code": "使用优惠码的最低交易金额",
  "Max redemption per user in this batch voucher code": "此批次优惠券兑换码中每个用户的最大兑换次数",
  "Max redemption per user in this batch discount code": "此批次优惠码中每个用户的最大兑换次数",
  "Add Voucher": "增加优惠券",
  "Completed": "完成",
  "Voucher Code Report": "优惠券兑换码报告",
  "Floating Token Report": "浮动电子代币报告",
  "Floating Point Report": "浮动积分报告",
  "E-Payment Report": "电子支付报告",
  "Referral Reward History Report": "邀请码奖励历史报告",
  "Redemption Date": "兑换日期",
  "Redemption Code": "兑换码",
  "Select Redemption Date": "选择兑换日期",
  "Select Voucher Type": "选择优惠券类型",
  "Giveaway": "补偿",
  "Select Name": "选择名称",
  "Amount must be more than 1": "金额必须大于1",
  "Payment method setting have updated successfully!": "付款方式设置已成功更新！",
  "Edit Payment Method": "编辑付款方式",
  "Minimum payment amount to include all the payment methods": "包含所有付款方式的最低付款金额",
  "Exclude Payment Method in Revenue Monster": "在 Revenue Monster 中排除的付款方式",
  "Welcome to": "欢迎来到",
  "Instruction": "操作说明",
  "Please select the machine": "请选择机器",
  "Login your account": "登录您的帐户",
  "Select the setting and make the payment": "选择设置并付款",
  "Phone Number / Email": "电话号码/电子邮件",
  "Reload & Transfer In": "充值及转入",
  "Usage & Transfer Out": "使用及转出",
  "Usage": "使用",
  "Remaining": "剩余",
  "Gain": "获得",
  "Type": "类型",
  "Order Date": "日期",
  "Pack Number": "包裹号码",
  "Pack Details": "包裹详情",
  "Size": "尺寸",
  "Mode": "模式",
  "Machine Payment": "机器支付总额",
  "DNG History": "DNG历史",
  "Token History": "电子代币历史记录",
  "Stamp History": "印章历史记录",
  "Staff Login": "员工登录",
  "Drop And Go Portal": "Drop And Go Portal",
  "Available Task": "可执行任务",
  "Completed Task": "已完成任务",
  "Task ID": "任务ID",
  "Start Task": "开始任务",
  "Select Machine": "选择机器",
  "Start Machine": "启动机器",
  "Reward To": "奖励给",
  "Reward From": "奖励来自",
  "Transaction Account": "交易账户",
  "Point": "积分",
  "POINT": "积分",
  "Number of Point": "积分数量",
  "Point History": "积分历史",
  "Customer Name": "客户姓名",
  "Number of Washer Transactions": "洗衣交易数量",
  "Number of Dryer Transactions": "烘干交易数量",
  "Vouchers Redeemed": "兑换的优惠券",
  "Discount Code Redemption": "折扣码兑换",
  "Total Spend": "总支出",
  "Select Outlet(s)": "选择店铺",
  "User Usage Report": "用户使用报告",
  // cleanpro plus zh
  "Wash": "洗涤",
  "Dry": "烘干",
  "Fold": "折叠",
  "Scan QR": "扫描二维码",
  "Capacity": "容量",
  "Duration": "时长",
  "Custom duration": "自定义持续时间",
  "Choose duration": "选择持续时间",
  "Select the correct amount on the machine after payment.": "付款后在机器上选择正确的金额。",
  "Offers": "优惠",
  "An easier way to pay": "更简单的支付方式",
  "Create account": "创建账户",
  "Login": "登录",
  "By signing up, you agree to": "注册即表示您同意",
  "Amount to Pay": "支付总额",
  "1 voucher selected": "已选择 1 张代金券",
  "No voucher selected": "未选择代金券",
  "credit": "点数",
  "Credits": "点数",
  "credits": "点数",
  "Credit balance": "点数余额",
  "Please ensure that the machine door is shut & your laundry is inside.": "请确保机器门已关闭并且您的衣物在里面。",
  "Insufficient balance": "余额不足",
  "Topup now": "立即充值",
  "Copyright © 2023 Cleanpro Laundry Holdings Sdn Bhd (821842-P) All rights reserved.": "版权所有 © 2023 Cleanpro Laundry Holdings Sdn Bhd (821842-P) 保留所有权利。",
  "Good Evening!": "下午好！",
  "Hello": "你好",
  "Wallet Balance": "钱包余额",
  "Topup": "充值",
  "More details": "更多细节",
  "Collect 10 e-stamps to earn x1 FREE wash or dry voucher": "集齐 10 个电子印章即可获得 x1 免费洗或干代金券",
  "For every wash or dry cycle you complete with us, you'll earn one e-stamp. Collect 10 and you'll be able to redeem a FREE wash or dry cycle. It's our way of saying thank you for choosing Cleanpro!": "您在我们这里完成的每一次洗涤或烘干循环，都将获得一个电子印章。集齐 10 个，您就可以兑换一次免费的洗涤或烘干周期。这是我们感谢您选择 Cleanpro 的方式！",
  "For every wash cycle you complete with us, you'll earn one e-stamp. Collect 10 and you'll be able to redeem a FREE wash or dry cycle. It's our way of saying thank you for choosing Cleanpro!": "您在我们这里完成的每一次洗涤循环，都将获得一个电子印章。集齐 10 个，您就可以兑换一次免费的洗涤或烘干周期。这是我们感谢您选择 Cleanpro 的方式！",
  "For every dry cycle you complete with us, you'll earn one e-stamp. Collect 10 and you'll be able to redeem a FREE wash or dry cycle. It's our way of saying thank you for choosing Cleanpro!": "您在我们这里完成的每一次烘干循环，都将获得一个电子印章。集齐 10 个，您就可以兑换一次免费的洗涤或烘干周期。这是我们感谢您选择 Cleanpro 的方式！",
  "Credits OFF": "点数折扣",
  "% OFF": "％ 折扣",
  "Valid Till": "有效期至",
  "Used On": "用于",
  "Expired On": "过期日期",
  "Available": "可用的",
  "Expired": "已过期",
  "Used": "已使用",
  "Promotions": "促销活动",
  "Rewards": "奖励",
  "Inbox": "收件箱",
  "Activities": "活动",
  "Support": "支持",
  "Active Rewards": "活跃奖励",
  "Past Rewards": "过往奖励",
  "Get rewarded for your loyalty with Cleanpro Plus Rewards!": "通过 Cleanpro Plus 奖励获得忠诚奖励！",
  "Earn vouchers and redeem them for discounts, freebies and more. The more you use our services the more you save!": "赚取代金券并将其兑换为折扣、免费赠品等。您使用我们的服务越多，您节省的越多！",
  "Up to 3 months history": "最近3个月的历史记录",
  "Filter": "筛选",
  "All Transactions": "所有交易",
  "Transactions": "交易",
  "Select amount to topup": "选择充值金额",
  "Balance": "余额",
  "You've just paid": "你刚刚付款",
  "Need help?": "需要帮忙？",
  "24-Hour Careline Number": "24 小时服务热线号码",
  "Email Us": "给我们发电子邮件",
  "Call Now": "现在打电话",
  "Email Now": "立即发送电子邮件",
  "Update name": "更新名称",
  "Update email address": "更新电子邮件地址",
  "Update phone number": "更新电话号码",
  "Change password": "更改密码",
  "Change language": "改变语言",
  "User ID": "用户号",
  "Let us know your feedback": "让我们知道您的反馈",
  "Poor": "糟糕",
  "Good": "好的",
  "Leave addtional feedback here (optional)": "在此处留下其他反馈（可选)",
  "Submit feedback": "提交反馈",
  "Pay as guest": "以访客身份付款",
  "Pay now": "现在付款",
  "Select voucher to use": "选择要使用的代金券",
  "Use voucher": "使用代金券",
  "Total": "总共",
  "Transaction Type": "交易类型",
  "Credit Topup": "点数充值",
  "Credit topup": "点数充值",
  "Credit Transfer": "点数转账",
  "Date/Time": "日期/时间",
  "Reference ID": "参考编号",
  "Status": "状态",
  "Share Your Referral Code": "分享您的邀请码",
  "cleanproReferralShortDesc": "将您的邀请码分享给亲朋好友，当完成交易后可免费获得 {{ voucherAmount }} 积分。点击以了解更多信息。",
  "Use": "使用",
  "dryer or washer": "烘干机或洗衣机",
  "washer": "洗衣机",
  "dryer": "烘干机",
  "dryer and washer": "烘干机和洗衣机",
  "machine with the minimum spending of": "以最低消费",
  "Welcome to Cleanpro Plus": "欢迎来到 Cleanpro Plus",
  "Only scan, set & pay! An easier, more convenient way to pay with Cleanpro Plus! No need to download an app. Enjoy more rewards when you use Cleanpro Plus today! Tap to learn more.": "只需扫描、设置和支付！使用 Cleanpro Plus 支付更简单、更方便的方式！无需下载应用程序。立即使用 Cleanpro Plus，享受更多奖励！点击以了解更多信息。",
  "Cleanpro Pay Migration Notice": "Cleanpro Pay 迁移通知",
  "Dear valued Cleanpro customers, please be informed that as of 22 June 2023, the Cleanpro Pay app will be upgraded and and no longer available for use. Tap to learn more.": "尊敬的 Cleanpro 客户，请注意，自 2023 年 6 月 22 日起，Cleanpro Pay 应用程序将升级并不再可用。点击以了解更多信息。",
  "Answer simple questions and win credits for your next wash or dry! This contest is only available for selected outlets only. Tap to learn more.": "回答简单的问题并为您的下一次洗涤或烘干赢得点数！此竞赛仅适用于指定的商店。点击以了解更多信息。",
  "The all-new Cleanpro Plus is a new payment method that includes loyalty features built into the app. The more you use our services and pay using Cleanpro Plus, the more you can save. With every transaction, you can earn rewards that will help you save on your next laundry expenses.": "全新的 Cleanpro Plus 是一种新的支付方式，包括内置于应用程序中的忠诚度功能。您使用我们的服务和使用 Cleanpro Plus 支付的次数越多，您节省的费用就越多。每笔交易，您都可以获得奖励，帮助您节省下一次洗衣费用。",
  "To use Cleanpro Plus, simply scan the QR code on the machines and pay using your preferred credit/debit card or e-wallet. We hope that Cleanpro Plus will help simplify your laundry routine and give you more time for the things that matter.": "要使用 Cleanpro Plus，只需扫描机器上的二维码并使用您首选的信用卡/借记卡或电子钱包付款。我们希望 Cleanpro Plus 能够帮助简化您的洗衣程序，让您有更多时间处理重要的事情。",
  "If you have any questions about Cleanpro Plus, please read our FAQ in the menu or contact our careline at +603 2770 0100 or email us at info@cleanprolaundry.com. Thank you for choosing Cleanpro.": "如果您对 Cleanpro Plus 有任何疑问，请阅读菜单中的常见问题解答或致电 +603 2770 0100 联系我们的客服热线或发送电子邮件至 info@cleanprolaundry.com。感谢您选择 Cleanpro。",
  "Dear valued Cleanpro customers, please be informed that as of 22 June 2023, the existing Cleanpro Pay mobile app will be upgraded and no longer available for use.": "尊敬的 Cleanpro 客户，请注意，自 2023 年 6 月 22 日起，现有的 Cleanpro Pay 移动应用程序将升级并不再可用。",
  "We are migrating to the all-new Cleanpro Plus. It is a new cashless payment method, without the need to download apps.": "我们正在迁移到全新的 Cleanpro Plus。这将是一种新的无现金支付方式，无需下载应用程序。",
  "If you are the existing user of Cleanpro Pay, any of your remaining balance in the app will be transferred to your Cleanpro Plus account. You are required to use the same mobile number to sign up for Cleanpro Plus for balance transfer.": "如果您是 Cleanpro Pay 的现有用户，您在应用程序中的任何余额都将转移到您的 Cleanpro Plus 帐户。您需要使用相同的手机号码注册 Cleanpro Plus 进行余额转移。",
  "If you need help on Cleanpro Plus, please feel free to call our careline at +603 2770 0100 or email us at info@cleanprolaundry.com": "如果您需要有关 Cleanpro Plus 的帮助，请随时拨打我们的服务热线 +603 2770 0100 或发送电子邮件至 info@cleanprolaundry.com",
  "Answer simple questions and win credits for your next wash or dry!": "回答简单的问题并为您的下一次洗涤或烘干赢得点数！",
  "Step 1 : Scan the Jawab & Gerenti Menang QR code in the participated outlets.": "第 1 步：在指定的商店里扫描Jawab & Gerenti Menang 二维码。",
  "Step 2 : Answer 3 simple questions.": "第 2 步：回答 3 个简单问题。",
  "Step 3 : Submit and stand a chance to win prizes.": "第 3 步：提交并有机会赢取奖品。",
  "Grand Prize :": "大奖：",
  "x10 per outlet - 100 credits worth of vouchers in Cleanpro Plus": "每个分店 x10 - Cleanpro Plus 中的 RM100 点数优惠劵",
  "Consolation Prize :": "安慰奖：",
  "X25 per outlet - 50 credits worth of vouchers in Cleanpro Plus": "每个分店 X25 - Cleanpro Plus 中的 RM50 点数优惠劵",
  "Participation prize:": "参与奖：",
  "Every participants are eligible to receive a FREE 3 credits voucher (worth RM3) in Cleanpro Plus.": "每位参与者都有资格在 Cleanpro Plus 中免费获得 3 个点数代金券（价值 RM3）。",
  "If you have any questions please don't hesitate to contact our careline at +603 2770 0100 or email us at info@cleanprolaundry.com. Thank you for choosing Cleanpro.": "如果您有任何疑问，请随时拨打我们的服务热线 +603 2770 0100 或发送电子邮件至 info@cleanprolaundry.com。感谢您选择 Cleanpro。",
  "Payment Confirmation": "付款确认",
  "This campaign is only eligible for users who use the all-new Cleanpro Plus web application.": "此活动仅适用于使用全新 Cleanpro Plus 网络应用程序的用户。",
  "ONE e-stamp will be rewarded for each wash or dry cycle.": "每次洗涤或干燥循环将奖励一个电子印章。",
  "ONE e-stamp will be rewarded for each wash cycle.": "每个洗涤循环将奖励一个电子印章。",
  "ONE e-stamp will be rewarded for each dry cycle.": "每个干燥循环将奖励一个电子印章。",
  "Regardless of the washer or dryer settings, the number of e-stamps being collected is the same.": "无论洗衣机或烘干机设置如何，收集的电子印章数量都是相同的。",
  "The type of e-stamp collected is based on the services that you have used.": "您所收集的电子印章种类取决于你所使用的服务而定。",
  "Washing service will reward washer e-stamp and drying service will reward dryer e-stamp.": "洗涤服务将奖励洗衣机电子印章，烘干服务将奖励烘干机电子印章。",
  "Washing service will reward washer e-stamp.": "洗涤服务将奖励洗衣机电子印章。",
  "Drying service will reward dryer e-stamp.": "烘干服务将奖励烘干机电子印章。",
  "E-stamps will automatically be added to your account once the condition has been fulfilled.": "满足条件后，电子印章将自动添加到您的帐户中。",
  "There is no limit on how many e-stamps can be gathered in a single day. So long as you meet the requirements to collect the e-stamps.": "只要您符合收集电子印章的条件，您将获得电子印章，一天内无收集电子印章数量限制",
  "The type of e-stamps that have been collected is final and cannot be changed.": "已收集的电子印章类型是最终的，不能更改。",
  "There is no expiration for the e-stamps; only till the discontinuation of the campaign.": "电子印章不会过期；直到活动停止为止。",
  "E-stamps do not have any currency value, and they cannot be converted to cash or credits.": "电子印章没有任何货币价值，也不能兑换成现金或点数。",
  "This campaign is not applicable with any other ongoing promotions, offers, vouchers, and/or discounts provided by the participating outlets.": "此活动不适用于参与商店提供的任何其他正在进行的促销、优惠、代金券和/或折扣。",
  "These terms and conditions are subject to change at any time without prior notice.": "这些条款和条件可能随时更改，恕不另行通知。",
  "Press START on the machine to begin washing.": "按机器上的START开始洗涤。",
  "Press START on the machine to begin drying.": "按机器上的START开始烘干。",
  "Reminder": "提醒",
  "Remember to select the same settings you have paid for on the corresponding machine.": "请记住在相应的机器上选择您已支付的相同设置。",
  "Please ensure that your laundry is inside the machine and door is properly shut.": "请确保您的衣物在机器内并且门已正确关闭。",
  "voucherCreditsOff_one": "折扣 {{currentVoucherAmount}} 点数",
  "voucherCreditsOff_other": "折扣 {{currentVoucherAmount}} 点数",
  "voucherPercentageOff": "折扣 {{currentVoucherAmount}}%",
  "cleanproReferralFlatVoucher_one": "将您的 Cleanpro Plus 邀请码分享给您的朋友和家人，即可获得免费优惠劵！对于每个完成第一笔交易的成功推荐，您将获得一张价值折扣 {{ referralVoucherAmount }} 货币的优惠劵。",
  "cleanproReferralFlatVoucher_other": "将您的 Cleanpro Plus 邀请码分享给您的朋友和家人，即可获得免费优惠劵！对于每个完成第一笔交易的成功推荐，您将获得一张价值折扣 {{ referralVoucherAmount }} 货币的优惠劵。",
  "cleanproReferralRateVoucher": "将您的 Cleanpro Plus 邀请码分享给您的朋友和家人，即可获得免费优惠劵！对于每个完成第一笔交易的成功推荐，您将获得一张价值 {{ referralVoucherAmount }}% 折扣的优惠劵。",
  "cleanproReferralTokenReward_one": "将您的 Cleanpro Plus 邀请码分享给您的朋友和家人，即可获得免费货币！对于每个完成第一笔交易的成功推荐，您将获得免费的 {{ referralToken }} 货币。",
  "cleanproReferralTokenReward_other": "将您的 Cleanpro Plus 邀请码分享给您的朋友和家人，即可获得免费货币！对于每个完成第一笔交易的成功推荐，您将获得免费的 {{ referralToken }} 货币。",
  "How to get & use code:": "如何获取和使用邀请码：",
  'Go to menu = icon and click “Refer friends”': '在菜单 = 图标，然后单击“推荐给朋友”',
  "Share with everyone who will likely to use Cleanpro Plus.": "与所有可能使用 Cleanpro Plus 的人分享。",
  "Referral Terms & Conditions:": "推荐条款和条件：",
  "When your friend or family register using your referral code, and completed a transaction (a minimum spending of {{ currency }}{{ referralTransactionAmount }}) , you will be rewarded automatically.": "当您的朋友或家人使用您的邀请码注册并完成交易（最低消费 {{ currency }}{{ referralTransactionAmount }}）时，您将自动获得奖励。",
  "cleanproNumberOfReferralReward": "您可以与任意数量的人分享，但每个用户只能获得 {{ numberOfReferral }} 次推荐奖励。",
  "cleanproNumberOfReferral_one": "您可以与任意数量的人分享，但每个用户只能收到 {{ numberOfReferral }} 张推荐优惠券。",
  "cleanproNumberOfReferral_other": "您可以与任意数量的人分享，但每个用户只能收到 {{ numberOfReferral }} 张推荐优惠券。",
  "The voucher is valid for {{ duration }} days from the day voucher be given.": "优惠券自发放之日起 {{ duration }} 天内有效。",
  "The voucher is for customer use only and not for sale.": "优惠券仅供客户使用，不得出售。",
  "We believe that your special day deserves a little extra sparkle! That's why we're thrilled to introduce our exclusive birthday rewards program just for you! Starting this year, and every year thereafter, we're giving away {{ currency }}{{ voucherAmount }} credit vouchers to you on your birthday.": "我们相信您的特殊日子值得额外闪耀！这就是为什么我们很高兴为您推出独家生日奖励计划！从今年开始，之后的每一年，我们都会在您生日月份第一天赠送 {{ currency }}{{ voucherAmount }} 优惠券。",
  "cleanproBirthdayRateAmount": "我们相信您的特殊日子值得额外闪耀！这就是为什么我们很高兴为您推出独家生日奖励计划！从今年开始，之后的每一年，我们都会在您生日月份第一天赠送 {{ voucherAmount }}% 优惠券。",
  "Make sure your account details include your birthdate. This way, we'll know exactly when to send you your special birthday surprise.": "请确保您的账户已填写详细信息包括您的生日。这样，我们就能准确知道何时向您发送特别的生日惊喜。",
  "Birthday Voucher Terms & Conditions:": "生日礼券条款和条件：",
  "The voucher will be given on the first day of your birthday month.": "优惠券将在您生日月份的第一天发放。",
  "When you applied the voucher during payment, it will give you {{ voucherAmount }} credits ({{ currency }}{{ voucherAmount }}) discount.": "当您在付款时使用优惠劵，它会给您 {{ voucherAmount }} 货币（{{ currency }}{{ voucherAmount }}）折扣。",
  "When you applied the voucher during payment, it will give you {{ voucherAmount }}% discount.": "当您在付款时使用优惠劵，它会给您 {{ voucherAmount }} %折扣。",
  "All vouchers is not valid with other ongoing promotions, discounts and offers.": "所有优惠券均不适用于当正在进行的促销、折扣和优惠。",
  "Claim a complimentary wash and dry for every 10 e-stamps collected! We value your loyalty and want to reward you for choosing Cleanpro Plus. Tap to learn more.": "每收集 10 张电子印章，即可获得免费清洗和烘干服务！我们重视您的忠诚度，并希望奖励您选择 Cleanpro Plus。点击以了解更多信息。",
  "cleanproStampFlat_one": "每收集 10 个电子印章，您就可以使用 Cleanpro Plus 申请高达 {{ voucherAmount }} 的免费洗或烘干货币。让您的洗衣程序不仅轻松无忧，而且收获颇丰。",
  "cleanproStampFlat_other": "每收集 10 个电子印章，您就可以使用 Cleanpro Plus 申请高达 {{ voucherAmount }} 的免费洗或烘干货币。让您的洗衣程序不仅轻松无忧，而且收获颇丰。",
  "cleanproStampRate": "每收集 10 个电子印章，您就可以使用 Cleanpro Plus 申请高达 {{ voucherAmount }}% 的折扣。让您的洗衣程序不仅轻松无忧，而且收获颇丰。",
  "A voucher will be instantly added to your Rewards page when you reach 10 washes. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.": "当您收集到 10 个洗涤印章时，优惠券将立即添加到您的奖励页面。您的电子印章计价器将在下次收集时归零。下次洗衣时，在扫描二维码页面领取优惠券。",
  "A voucher will be instantly added to your Rewards page when you reach 10 drys. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.": "当您收集到 10 个烘干印章时，优惠券将立即添加到您的奖励页面。您的电子印章计价器将在下次收集时归零。下次洗衣时，在扫描二维码页面领取优惠券。",
  "A voucher will be instantly added to your Rewards page when you reach 10 washes or drys. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.": "当您收集到 10 个洗涤或10个烘干印章时，优惠券将立即添加到您的奖励页面。您的电子印章计价器将在下次收集时归零。下次洗衣时，在扫描二维码页面领取优惠券。",
  "Terms & Conditions:": "条款和条件：",
  "To earn ONE e-stamp, you must spend at least {{ stampTransactionAmount }} credits ({{ currency }}{{ stampTransactionAmount }}) per session.": "要赚取一个电子印章，您必须在每次会话中花费至少 {{ stampTransactionAmount }} 货币（{{ currency }}{{ stampTransactionAmount }}）。",
  "You will be automatically issued a voucher if you have collected 10 washer stamps.": "如果您收集了 10 张洗衣机印章，您将自动获得一张优惠券。",
  "You will be automatically issued a voucher if you have collected 10 dryer stamps.": "如果您收集了 10 张烘干机印章，您将自动获得一张优惠券。",
  "You will be automatically issued a voucher if you have collected 10 dryer stamps or 10 washer stamps.": "如果您收集了 10 张洗衣机或10 张烘干机印章，您将自动获得一张优惠券。",
  "You will be automatically issued a voucher if you have collected 10 stamps.": "如果您收集了 10 张印章，您将自动获得一张优惠券。",
  "The voucher is non-refundable and cannot be exchanged for cash; in part or in full.": "此礼券不可退款，亦不可兑换现金；部分或全部。",
  "This voucher is valid for single transaction only.": "此优惠劵仅适用于单次交易。",
  "General questions": "常见问题",
  "E-stamps collection": "电子印章",
  "Coin exchange machines": "硬币兑换机",
  "Washer machines": "洗衣机",
  "Dryer machines": "烘干机",
  "Paywave issues": "Paywave问题",
  "Cleanpro franchise": "Cleanpro特许经营权",
  "How can I wash my laundry using Cleanpro Plus?": "如何使用 Cleanpro Plus 洗涤衣物？",
  "Here are the steps to wash your laundry using Cleanpro Plus:": "以下是使用 Cleanpro Plus 洗涤衣物的步骤：",
  "Find an unoccupied washing machine in the store, and insert your laundry in the washer.": "在商店里找一台无人使用的洗衣机，然后将要洗的衣服放入洗衣机。",
  "Select the desired temperature that you would like to use for your laundry.": "选择您想用于洗衣的所需温度。",
  "Using the scanner in the app, scan the QR code provided on the washing machine. Then select the same settings that you have pressed on the machine.": "使用应用程序中的扫描仪，扫描洗衣机上提供的二维码。然后选择您在机器上按下的相同设置。",
  'Tap on “Pay with credits” to pay the amount shown on the app.': '点击“使用点数支付”以支付应用程序上显示的金额。',
  "Once the payment has been accepted, press the START button on the washer machine.": "付款被接受后，按下洗衣机上的开始按钮。",
  "After the washing cycle ends, you can just remove your laundry from the machine.": "洗涤周期结束后，您只需将衣物从机器中取出即可。",
  "How can I dry my laundry using Cleanpro Plus?": "如何使用 Cleanpro Plus 烘干衣物？",
  "Here are the steps to dry your laundry using Cleanpro Plus:": "以下是使用 Cleanpro Plus 烘干衣物的步骤：",
  "Find an unoccupied drying machine in the store, and insert your laundry in the dryer.": "在商店里找一台无人使用的干衣机，然后将要烘干的衣服放入干衣机中。",
  "Select the desired temperature and duration that you would like to use for your laundry.": "选择您想用于洗衣的所需温度和持续时间。",
  "Using the scanner in the app, scan the QR code provided on the drying machine. Then select the same settings that you have pressed on the machine.": "使用应用程序中的扫描仪，扫描干衣机上提供的二维码。然后选择您在机器上按下的相同设置。",
  "Once the payment has been accepted, press the START button on the dryer machine.": "付款被接受后，按下干衣机上的开始按钮。",
  "After the drying cycle ends, you can just remove your laundry from the machine.": "烘干周期结束后，您只需将衣物从机器中取出即可。",
  "Why does the QR code I scanned take me to a different machine?": "为什么我扫描的二维码会将我带到另一台机器上？",
  "Here are the steps to solve it:": "以下是解决它的步骤：",
  "Make sure that the number on the QR code (e.g. W1, W2, etc) is the same as the machine that you would like to use.": "确保二维码上的数字（例如 W1、W2 等）与您要使用的机器相同。",
  "If the QR code number does not match the machine you want, use the other machine. Please contact our customer service line at 03-27700100 to report this issue.": "如果 QR 代码编号与您想要的机器不匹配，请使用其他机器。请联系我们的客户服务热线03-27700100报告此问题。",
  "How can I add more time to the washer that I am currently using?": "如何为当前使用的洗衣机增加更多使用时间？",
  "The washing time for our washer is fixed and cannot add any additional time.": "我们洗衣机的洗涤时间是固定的，不能增加任何额外的时间。",
  "How can I add more time to the dryer that I am currently using?": "如何为当前使用的干衣机增加更多使用时间？",
  "Here are the steps to add more time to the dryer machine:": "以下是为烘干机增加更多时间的步骤：",
  "By using the scanner in the app, scan the QR code provided at the dryer machine.": "使用应用程序中的扫描仪，扫描干衣机上提供的二维码。",
  "Select the amount of time that you would like to add to the machine.": "选择您要增加的时间到机器上。",
  "After the drying cycle ends, you can just remove the clothes from the machine.": "烘干周期结束后，您可以将衣物从机器中取出。",
  "REMINDER - It is recommended to add drying time 5 minutes before the cycle ends. And you cannot add tokens for extra times when the machine finishes the drying cycle.": "提醒 - 建议在循环结束前 5 分钟增加干燥时间。当机器完成干燥周期时，您不能增加额外时间。",
  "Will the timer keep running when I have stopped the drying machine?": "当我停止烘干机时，定时器会继续运行吗？",
  "Yes. Even after you stop the machine, the timer will continue to run. If you want to extend the drying time, add drying time 5 minutes before the cycle ends. This prevents the cycle from ending before your clothes are completely dried.": "是的。即使在您停止机器后，计时器仍会继续运行。如果您想延长干燥时间，请在循环结束前 5 分钟增加干燥时间。这可以防止您的衣服完全干燥之前结束烘干。",
  "How do I reload my credits for Cleanpro Plus?": "我如何为 Cleanpro Plus 充值？",
  "Here are the steps to reload your credits:": "以下是充值点数的步骤：",
  'Select the "Top Up Credit" button on the homepage.': '选择主页上的“充值”按钮。',
  "Choose the amount you want to reload and the payment method you want to use.": "选择您要充值的金额和您要使用的付款方式。",
  "You will be taken to the payment page. The screen will return to Cleanpro Plus once the transaction is completed.": "您将被带到付款页面。交易完成后，屏幕将返回 Cleanpro Plus。",
  "How many credits can I reload in one transaction?": "我可以在一次交易中充值多少点数？",
  "Reloading credits requires a minimum of RM 10 and a maximum of RM 50.": "充值点数需要最低 RM 10 和最高 RM 50。",
  "Can I pay using credits in the Cleanpro Plus app along with tokens or coins in a single transaction?": "我可以在单笔交易中使用 Cleanpro Plus 应用程序中的点数以及代币或硬币进行支付吗？",
  "No. Only a single payment method may be chosen when performing any transactions.": "不能。进行任何交易时只能选择一种付款方式。",
  "The amount I have reloaded in my account was not reflected in the app.": "我在我的账户中充值的金额没有反映在应用程序中。",
  "Please close and restart the application and check if the new balance has been updated.": "请关闭并重新启动应用程序并检查新余额是否已更新。",
  "If the amount is still not updated, please contact our customer service line at 03-27700110 to receive further assistance.": "如果金额仍未更新，请联系我们的客户服务热线03-27700110以获得进一步的帮助。",
  "Can I cash out the credits I reloaded in my account for this app?": "我可以兑现我为这个应用程序中充值的点数吗？",
  "No. The credits that you have reloaded will remain in your Cleanpro Plus account.": "不能。您充值的点数将保留在您的 Cleanpro Plus 账户中。",
  "I have requested a refund, but I still have not received it.": "我已申请退款，但仍未收到。",
  "Please contact our customer service line at 03-27700100 to receive further assistance.": "请联系我们的客户服务热线03-27700100以获得进一步的帮助。",
  "I have made the payment, and the app double or triple charged me.": "我已经付款，但应用程序向我收取了两倍或三倍的费用。",
  "The washing machine cannot start even after I have paid using the app.": "使用应用程序付款后洗衣机无法启动。",
  "Ensure that the temperature settings you have selected and paid for on the application are the same as what you have pressed on the washing machine. If not, select the correct temperature settings on the washing machine and try again.": "确保您在应用程序上选择和支付的温度设置与您在洗衣机上按下的温度设置相同。如果不是，请在洗衣机上选择正确的温度设置并重试。",
  "If the machine is still not able to be started, please contact our customer service line at 03-27700100 to receive further assistance.": "若机器仍无法启动，请联络我们的客服热线03-27700100以获得进一步协助。",
  "Can I use multiple vouchers together in the same washing or drying cycle?": "我可以在同一个洗涤或烘干程序中同时使用多张优惠券吗？",
  "No. Only a single voucher can be used for each washing and drying cycle.": "不能。每个洗涤和烘干周期只能使用一张优惠券。",
  "Why can't I use my free wash voucher that I received after signing up?": "为什么我不能使用注册后收到的免费洗涤券？",
  "To redeem or use the voucher, you must complete at least one washing or drying cycle.": "要兑换或使用优惠券，您必须至少完成一次洗涤或烘干程序。",
  "Can I change my phone number even after registering my account?": "我可以在注册账户后更改我的电话号码吗？",
  "No. Once registered to an account, the phone number is locked into the account.": "不能。一旦注册到帐户，电话号码就会被锁定到该账户中。",
  "I have problems in the store, and who can I refer to for help?": "我在店里遇到问题，我可以向谁寻求帮助？",
  "Do not worry, as you can contact our customer service line at 03-27700100 to receive assistance at any time.": "别担心，您可以随时拨打我们的客服热线03-27700100寻求帮助。",
  "Should I add my own laundry detergent and softeners to the washing machine?": "我应该在洗衣机中添加自己的洗衣液和柔顺剂吗？",
  "There is no need for that, as all you have to do is bring your dirty clothes and put them in the washer. The detergent and softeners will be provided by us, and you can rest assured that the laundry detergents we used are certified industrial grade.": "没有必要，因为您只需带上脏衣服并将它们放入洗衣机即可。洗涤剂和柔顺剂由我们提供，您可以放心，我们使用的洗衣液都是经过认证的工业级洗涤剂。",
  "I would like to add my own laundry detergent and softener to the washing machine.": "我想在洗衣机中添加自己的洗衣液和柔顺剂。",
  "We do not recommend our customers add their own detergent, as the mixture of different detergents may affect the wash quality. And you can rest assured that the laundry detergent we used is certified industrial grade.": "我们不建议客户自行添加洗涤剂，因为不同洗涤剂的混合可能会影响洗涤质量。您可以放心，我们使用的洗衣液是经过认证的工业级洗涤剂。",
  "What time does the store close?": "商店几点关门？",
  "Our self-service laundry operation is open 24 hours a day, which means you can always be sure to go back home with a fresh set of laundry.": "我们的自助洗衣店全天 24 小时开放，这意味着您回家时一定可以洗干净一整套新衣服。",
  "Are you open on weekends?": "你们周末营业吗？",
  "Yes. Cleanpro is also open on weekends and even on public holidays.": "是的。 Cleanpro 在周末甚至公共假期也营业。",
  "How many clothes can I fit in the washing or drying machine?": "我可以在洗衣机或烘干机中放入多少件衣服？",
  "To ensure that your laundry is properly washed or dried, keep the amount of laundry inside the machine lower than the line of guide sticker on the machine.": "为确保您的衣物得到正确洗涤或烘干，请使机器内的衣物量低于机器上的引导标签线。",
  "How many e-stamps can I collect in a single day?": "我一天可以收集多少个电子印章？",
  "There is no limit to the amount of stamps that can be collected in a single day; ONE stamp will be awarded with every wash or dry.": "一天内可以收集的印章数量没有限制；每次洗涤或烘干都会获得一枚印章。",
  "When I use the washing service, can I change the collected stamp to a dryer stamp, and vice versa?": "当我使用洗涤服务时，我可以将收集的印章换成烘干机印章，反之亦然吗？",
  "No, the type of stamp collected is only applied to the service that you have used.": "不能，所收集的印章类型只适用于您使用过的服务。",
  "What temperatures can I choose when I claim the free washing e-stamp reward?": "领取免费洗印章奖励时，我可以选择什么温度？",
  "Any washing temperature is available for the e-stamp reward.": "任何洗涤温度均可获得电子印章奖励。",
  "What temperatures can I choose when I claim the free drying e-stamp reward? And how long can I set the time?": "领取免费烘干印章奖励时，我可以选择什么温度？我可以设置多长时间？",
  "Any drying temperature and duration can be selected for the e-stamp reward.": "可以选择任何干燥温度和持续时间作为电子印章奖励。",
  "I'm unable to change the coin.": "我无法兑换硬币。",
  "What colour is the light shown on the machine, red or green? If it is a red light, please contact our customer service line at 03-27700100 to receive further assistance.": "机器上显示的灯是什么颜色，红色还是绿色？如果是红灯，请联系我们的客户服务热线03-27700100以获得进一步的帮助。",
  "Green Light": "绿灯",
  "Red Light": "红灯",
  "Machine functions as normal. Please try again.": "机器功能正常。请再试一次。",
  "(flashing 2x) - There are no coins/tokens in the machine.": "（闪烁 2 次）- 机器中没有硬币/代币。",
  "OR": "或者",
  "Note is jammed, please proceed to contact customer service": "纸币卡纸，请联系客服",
  "(flashing 3x) - Note is jammed, please proceed to contact customer service": "（闪烁 3 次）- 纸币卡住，请继续联系客户服务",
  "My notes are stuck in the machine.": "我的纸币卡在机器里了。",
  "My detergent is not dispensing in the vending machine.": "我的洗涤剂没有在自动售货机中分配。",
  "The water is leaking out of the washing machine.": "水从洗衣机中漏出。",
  "Please check the washer machine to see whether there are any clothes that are stuck at the door side.": "请检查洗衣机是否有衣服卡在门边。",
  "If yes, here are the steps to solve it:": "如果是，这里是解决它的步骤：",
  "Press the emergency stop button (RED Button) for 3 seconds.": "按下紧急停止按钮（红色按钮）3 秒钟。",
  "Wait for the sound to stop, and then only open the door. (If after 30 minutes the sound still has not stopped, please contact our customer service line at 03-27700100 to receive further assistance)": "等声音停止，才打开门。 (若30分钟后声音仍未停止，请联络我们的客服热线03-27700100以获得进一步协助)",
  "Ensure the door is closed tightly, and press the START button.": "确保门紧闭，然后按下启动按钮。",
  "REMINDER - The emergency stop button can only be used once.": "提醒 - 紧急停止按钮只能使用一次。",
  "There is no detergent dispensing in the washing machine.": "洗衣机中没有洗涤剂配给。",
  "Ensure that the machine is in operation and that the START button was pressed.": "确保机器正在运行并且按下了启动按钮。",
  "If the detergent is still not dispensing, please contact our customer service department at 03-27700100 for further assistance.": "如果洗涤剂仍未配出，请致电03-27700100与我们的客户服务部联系以获得进一步的帮助。",
  "The price displayed on the machine's screen did not change after inserting the token.": "插入硬币后，机器屏幕上显示的价格没有变化。",
  "Press the small button (in the centre) to reject the tokens that have been inserted. Press a few times till the coins come out.": "按小按钮（在中心）拒绝已插入的硬币。按几次直到硬币出来。",
  "If the coins still cannot come out or the button is not functioning, please contact our customer service line at 03-27700110 to receive further assistance.": "若仍无法出币或按钮无法使用，请联络我们的客服热线03-27700110以获得进一步协助。",
  "The machine is not starting, and it displays an error code.": "机器未启动，并显示错误代码。",
  "We would like to apologise for any inconvenience caused. Please contact our customer service line at 03-27700100 to receive further assistance.": "对于由此造成的任何不便，我们深表歉意。请联系我们的客户服务热线03-27700100以获得进一步的帮助。",
  "I want to wash the clothes again, but why couldn't the machine start even after I added tokens to the machine?": "我又想洗衣服，为什么我给机器加了硬币后机器还是启动不了？",
  "Try opening and closing the door again before starting the machine.": "在启动机器之前再次尝试打开和关闭门。",
  "Can I change the water temperature of the machine?": "我可以改变机器的水温吗？",
  "Yes, but the washer temperature can only be changed from hot to cold after the machine has been started, and not from cold to hot.": "是的，但洗衣机温度只能在机器启动后由热变冷，而不能由冷变热。",
  "Can I stop the machine?": "我可以停止机器吗？",
  "Here are the steps to stop the machine:": "以下是停止机器的步骤：",
  "Wait for the sound to stop, and then only open the door. (If after 30 minutes the sound still has not stopped, please contact our customer service line at to receive further assistance)": "等声音停止，才打开门。 （若30分钟后声音仍未停止，请联系我们的客服热线获取进一步帮助）",
  "There is no water supply on the machine used.": "使用的机器上没有供水。",
  "Why is the washing temperature cold even when I have selected HOT.": "为什么我选择了热，洗涤温度还是很冷。",
  "The water temperature is HOT or WARM only during washing. The water will turn COLD during rinsing.": "水温仅在洗涤期间为热或温。冲洗时水会变冷。",
  "The machine has already finished washing, but it does not spin well, and there is water left on the load.": "机器已经完成洗涤，但旋转不畅，衣物上还有水。",
  "Can I put wet laundry into the dryer to dry?": "我可以将湿衣物放入烘干机中烘干吗？",
  "No, as the dripping wet laundry might cause the dryer to short circuit.": "不能，因为滴水的湿衣物可能会导致干衣机短路。",
  "REMINDER - Refer to the labels on your clothes to know what kind of temperature is best for the kind of fabric that it uses. Then select the temperature on the machine accordingly.": "提醒 - 请参阅衣服上的标签，了解哪种温度最适合所使用的面料。然后相应地选择机器上的温度。",
  "My clothes are still wet even after drying.": "我的衣服烘干后还是湿的。",
  "How can I stop the dryer when the machine is still running?": "机器仍在运行时如何停止烘干机？",
  "Just open the door and the machine will automatically stop.": "只要打开门，机器就会自动停止。",
  "I added extra tokens for extra times, but the machine did not update the time.": "我为额外的时间添加了额外的货币，但机器没有更新时间。",
  "For the time to be reflected accurately, it is recommended for you to add tokens 5 minutes before the services end.": "为了准确反映时间，建议您在服务结束前5分钟添加货币。",
  "REMINDER - You cannot add tokens for extra times when the machine finishes the drying cycle.": "提醒 - 当机器完成烘干循环时，您不能为额外时间添加货币。",
  "Why is the machine making noise?": "为什么机器会发出噪音？",
  "Please check the drum to see if there are any coins or other small things inside.": "请检查滚筒，看看里面是否有任何硬币或其他小东西。",
  "I have pressed the START button on the dryer, but it is still not working.": "我已经按下烘干机上的启动按钮，但它仍然无法正常工作。",
  "Please try to open the door and close it again. Next, press the START button again to start drying.": "请尝试打开并再次关闭门。接下来，再次按下开始按钮开始干燥。",
  "If the dryer is still not functioning, please contact our customer service line at 03-27700100 to receive further assistance.": "如果干衣机仍然无法正常工作，请联系我们的客户服务热线 03-27700100 以获得进一步的帮助。",
  "Paywave has deducted an amount from the card but the machine did not reflect the transaction.": "Paywave 已从卡中扣除一笔金额，但机器未反映这笔交易。",
  "The Paywave transaction has failed but the amount has still been deducted from my card.": "Paywave 交易失败，但金额仍从我的卡中扣除。",
  "Paywave has double/triple charge but the machine cannot start.": "Paywave 有双倍/三倍收费但机器无法启动。",
  "Paywave after double/triple charge machines can't start.": "双倍/三倍收费后的 Paywave 机器无法启动。",
  "Paywave is offline": "Paywave 已离线",
  "Do Cleanpro Express offer franchise business/selling machines/laundry products?": "Cleanpro Express 是否提供特许经营/销售机器/洗衣产品？",
  "For further inquiries, please email to info@cleanproexpress.com": "如需进一步查询，请发送电子邮件至 info@cleanproexpress.com",
  "Step 6": "步骤 6",
  "User Existed": "用户已存在",
  "This email is registered": "该邮箱已注册",
  "This phone number is registered": "该电话号码已注册",
  "Invalid Email Address": "无效的邮件地址",
  "Let's get started!": "让我们开始吧！",
  "Enter your e-mail/mobile number": "输入您的电子邮件/手机号码",
  "Enter your mobile number": "输入你的手机号码",
  "Enter your e-mail address": "输入你的电子邮箱地址",
  "Proceed": "继续",
  "Enter OTP number": "输入一次性密码",
  "You will receive a six-digit OTP via SMS sent to": "您将收到一个六位数的一次性密码短信在",
  "You will receive a six-digit OTP via e-mail sent to": "您将收到一个六位数的一次性密码邮件在",
  "Verify": "验证",
  "Resend OTP": "重新发送一次性密码",
  "Welcome onboard!": "欢迎！",
  "Please fill in the info below": "请填写以下资料",
  "Enter your name*": "输入你的名字*",
  "Enter your email address*": "输入你的电子邮箱地址*",
  "Please enter either phone number or email address": "请输入电话号码或电子邮件地址",
  "Create a password*": "创建密码*",
  "Enter referral code (if got any)": "输入推荐码（如果有）",
  "Get started": "开始",
  "Birthday updated successfully": "生日更新成功",
  "Date of birth": "出生日期",
  "Enter your date of birth": "输入你的出生日期",
  "Select your birth date": "选择您的出生日期",
  "Update date of birth": "更新出生日期",
  "Update birth date": "更新出生日期",
  "Already have an account?": "已经有账户？",
  "Login now": "立即登录",
  "Sign in": "登入",
  "Sign up now": "立即注册",
  "COMPLETED": "完成",
  "WASHING NOW": "洗涤中",
  "DRYING NOW": "烘干中",
  "It's done!": "完成了！",
  "Please collect your fresh, clean laundry in order to leave the machine available for the next person to use!": "请收集您清新、干净的衣物，以便让机器可供下一个人使用！",
  "Leave a feedback": "留下反馈",
  "Time left": "剩余时间",
  "Add drying time": "增加烘干时间",
  "Hi": "你好",
  "customer": "顾客",
  "Thank you for your payment. Don't forget to select the <b>correct temperature</b> and press <b>START</b> when you're ready.": "谢谢您的付款。不要忘记选择<b>正确的温度</b>，并在准备就绪后按<b>开始</b>。",
  "Select how you want to receive a notification when your laundry is finished": "选择洗衣完成后您希望如何接收通知",
  "Sign up": "注册",
  "Continue to pay": "继续付款",
  "Register as a member to enjoy more benefits!": "注册成为会员，享受更多优惠！",
  "Get FREE {{ voucherAmount }} credits per new user": "每个新用户免费获得 {{ voucherAmount }} 个货币",
  "Get FREE {{ voucherAmount }}% per new user": "每个新用户免费获得 {{ voucherAmount }}%",
  "Enjoy exclusive promotions": "享受独家促销",
  "FREE voucher on your birthday": "生日当天可获赠免费优惠券",
  "and more": "和更多",
  "Denied camera access": "拒绝摄像头访问",
  'Permission to access the camera has been denied. Please try again and click "Allow" to scan the QR code.': '访问摄像头的权限已被拒绝。请重试并点击“允许”扫描二维码。',
  "Okay": "好的",
  "Oops. You don't have enough balance to make the payment": "您没有足够的余额来付款",
  "Please confirm": "请确认",
  "Do you want to proceed with this feedback?": "您想继续处理此反馈吗？",
  "Are you sure to purchase": "您确定购买",
  "credits for": "货币以",
  "Topup credit": "充值货币",
  "Extended duration will be instantly reflected on machine after payment": "付款后，延长的时间将立即反映在机器上",
  "Machine is currently busy": "机器当前正忙",
  "Do you want to extend drying time?": "您想延长烘干时间吗？",
  "Cuci Hari-Hari Bersama Cleanpro!": "Cuci Hari-Hari Bersama Cleanpro!",
  "Ready for a challenge?": "准备好迎接挑战了吗？",
  "Here's one for you - all you need to do is wash your laundry 10 times in 20 days at participating Cleanpro outlets. Stand a chance to win a Limited Edition Cleanpro Designer Laundry Bag worth up to RM40!": "挑战如下 - 你只需在20天内于任何一家Cleanpro门店里洗衣10次，即可赢得一款价值高达RM40的限量版Cleanpro设计师系列洗衣袋。",
  "Here's how to participate:": "参与方式：",
  "Step 1: Scan the QR Code on the washer to launch Cleanpro Plus": "步骤1：扫描QR Code来启动Cleanpro Plus应用并注册账户。",
  "Step 2: Pay for your wash with Cleanpro Plus": "步骤2：选择您需要充值的额度并支付。",
  "Step 3: Complete x10 washes within 20 days to win": "步骤3：在20天内完成10次洗衣。",
  "Challenge Period: 01 July - 31 August 2023": "挑战期：2023年7月1日 - 8月31日",
  "Drop by your nearest Cleanpro outlet to wash your laundry with us for 20 days straight! Good luck!": "前往最近的Cleanpro门店，与我们一起“天天喜衣”！祝您好运！",
  "TERMS & CONDITIONS": "条款及细则",
  "PARTICIPATION CRITERIA": "参与条件",
  "1) Participant needs to register an account in Cleanpro Plus web app.": "1）参与者需要在Cleanpro Plus应用上注册一个账户。",
  "2) Participant can use any washer of any capacity in any participating outlets to qualify for entries.": "2）参与者在任何一间指定门店使用任何容量的洗衣机即符合资格。",
  "3) Payment needs to be made with Cleanpro Plus web app.": "3）款项需通过Cleanpro Plus进行支付。",
  "4) Washes must be completed and paid (with no dispute whatsoever) within campaign period.": "4）清洗及结账必须在活动期间内完成（绝无争议）。",
  "5) Campaign period starts from 1st July 2023 at 00:00 and ends at 31st August 2023 at 23:59.": "5）活动将从2023年7月1日00:00开始，至2023年8月31日23:59结束。",
  "WINNERS & PRIZES": "获奖者及奖品",
  "1) Cleanpro will notify the winners of the campaign via registered WhatsApp number or email address provided, within 4 weeks after the campaign ends.": "1）Cleanpro将在活动结束后的4周内，通过注册的WhatsApp号码或所提供的电子邮件箱通知获奖者。",
  "2) Any unclaimed prizes after 2 weeks from notification date will not be entertained thereafter.": "2）从通知日期起直至2周后仍未前来领取的奖品将不予理会。",
  "3) Upon completing the ten (10) washes within twenty (20) days campaign, participants will be a winner and be eligible to win ONE (1) Cleanpro Designer Laundry Bag.": "3）参与者若在20天的活动期间内完成十（10）次洗衣次数后，将赢得一（1）款Cleanpro设计师系列洗衣袋。",
  "4) Winners are eligible to win more than ONE (1) Cleanpro Designer Laundry Bag in this campaign.": "4）获奖者有资格在此活动中赢得多于一（1）个Cleanpro设计师系列洗衣袋。",
  "5) Winners will be given the choice of his/her preferred laundry bag size; but only ONE (1) can be selected for each qualified win.": "5）获奖者将可选择自己喜欢的洗衣袋尺寸；但每次完成挑战只能在奖品中选择一（1）个。",
  "A) XL Size - Blue bag with up to 20KG in capacity": "A）XL尺寸 - 蓝色洗衣袋，容量高达20公斤",
  "B) L Size - White bag with up to 12KG in capacity": "B）L尺寸 - 白色洗衣袋，容量高达12公斤",
  "6) Time and location for collection of prize will be informed via Whatsapp number and/or email address provided.": "6）奖品领取的时间和地点将通过参与者所提供的WhatsApp号码和/或电子邮件箱通知。",
  "OTHER": "其他",
  "1) Changes to the terms and conditions will be at Cleanpro's discretion; without prior notice.": "1）Cleanpro有权不经通知修改本条款和细则。",
  "2) Completed wash is defined by washes performed with Cleanpro's washer and payments within Cleanpro Plus web app are made with no disputes.": "2）完整的洗衣过程意指使用Cleanpro的洗衣机进行洗涤，并在Cleanpro Plus网页应用程序内进行付款 (绝无争议)。",
  "3) By participating in this campaign automatically indicates that the Participant has agreed to all the campaign's Terms and Conditions as stipulated.": "3）参与此活动即表示参与者已同意所有活动条款及细则。",
  "Welcome Voucher": "“欢迎”礼券",
  "Welcome to the Cleanpro Plus family! This first gift is just the beginning. Stay tuned for a series of exciting promotions and campaigns, exclusively for Cleanpro Plus users.": "欢迎加入 Cleanpro Plus的大家庭！ 这份见面礼只是一个开始。 请继续关注 Cleanpro Plus以获得我们独家提供给用户的一系列激动人心的促销和活动。",
  "Terms & Conditions": "条款及细则",
  "Welcome Voucher is valid for one-time use only.": "“欢迎”礼券仅供一次性使用。",
  "There is no minimum spend required for the voucher to be used.": "使用礼券时，没有最低消费要求。",
  "Welcome Voucher is auto-credited to the user's account at the point of registration on Cleanpro Plus and valid for {{ duration }} days only.": "“欢迎”礼券在 Cleanpro Plus 注册时将会自动存入用户的帐户，有效期仅为 {{ duration }} 天。",
  "Users are eligible to use their Welcome Voucher AFTER the completion of their first wash or dry cycle using the same Cleanpro Plus account.": "用户需使用同一个 Cleanpro Plus 帐户完成第一次洗涤或烘干后，才能使用“欢迎”礼券。",
  "Welcome Voucher cannot be used together with any ongoing promotions or any other promotional vouchers.": "“欢迎”礼券不能与任何正在进行的促销活动或任何其他促销礼券一起使用。",
  "The voucher is final and cannot be refunded in form of cash or credit to the user.": "该礼券不能以现金或点数形式退还给用户。",
  "Cleanpro reserves the right to change the terms and conditions without prior notice.": "Cleanpro 保留更改条款和条件的权利，恕不另行通知。",
  "Collect 10 e-stamps to earn FREE {{ voucherAmount }} credits voucher (worth {{ currency }}{{ voucherAmount }})": "收集 10 个电子邮票即可免费获得 {{ voucherAmount }} 点数礼券（价值 {{ currency }}{{ voucherAmount }}）",
  "Collect 10 e-stamps to earn FREE {{ voucherAmount }}% voucher (worth {{ voucherAmount }}%)": "收集 10 个电子邮票即可免费获得 {{ voucherAmount }}% 礼券（价值 {{ voucherAmount }}%）",
  "Collect 10 e-Stamp to earn FREE Voucher": "收集 10 个电子邮票即可免费获得礼券",
  "With every 10 e-stamps that you collected, you will be rewarded with a FREE {{ voucherAmount }} credits voucher (worth {{ currency }}{{ voucherAmount }}). Make your laundry routine not just hassle-free, but also rewarding.": "每收集 10 个电子印章，您将获得一张免费 {{ voucherAmount }} 点数礼券（价值 {{ currency }}{{ voucherAmount }}）。让您的洗衣程序不仅轻松无忧，而且收获颇丰。",
  "With every 10 e-stamps that you collected, you will be rewarded with a FREE {{ voucherAmount }}% credits voucher (worth {{ voucherAmount }}%). Make your laundry routine not just hassle-free, but also rewarding.": "每收集 10 个电子印章，您将获得一张免费 {{ voucherAmount }}% 礼券（价值 {{ voucherAmount }}%）。让您的洗衣程序不仅轻松无忧，而且收获颇丰。",
  "How to participate:": "参与方式：",
  "Prizes to be won:": "将赢得的奖品：",
  "RM10 OFF Welcome Voucher": "RM10 折扣欢迎礼券",
  "Welcome to the Cleanpro family, here is an appreciation voucher from us. Thank you for attending the COPE Investors Conference.": "欢迎加入 Cleanpro 大家庭，这是我们的感谢券。感谢您参加 COPE 投资者大会。",
  "Welcome Voucher | COPE Investors Conference": "欢迎券 | COPE投资者大会",
  "Payment methods": "付款方式",
  "Tap to proceed with your preferred mode of payment": "选择您喜欢的付款方式",
  "Cleanpro Plus credits": "Cleanpro Plus 点数",
  "E-wallet or credit/debit cards": "电子钱包或信用卡",
  "Go to homepage": "前往主页",
  // backend response or error
  "Admin is unauthorized to login": "管理员没有权限登录",
  "Internal Error": "内部错误",
  "Please enter the correct credentials": "请输入正确的密码",
  "Operator not found": "未找到运营商",
  "Admin not found": "未找到管理员",
  "Distributor not found": "未找到经销商",
  "Client not found": "未找到客户",
  "Outlet not found": "未找到店铺",
  "Token Package not found": "未找到电子代币配套",
  "Top up history not found": "未找到充值记录",
  "Voucher not found": "未找到优惠劵",
  "Faq not found": "未找到常见问题",
  "Faq Group not found": "未找到常见问题组",
  "News not found": "未找到新闻",
  "Transaction not found": "未找到交易",
  "Please Login Again": "请重新登录",
  "Decoding Error": "解码错误",
  "Some error occured, please contact the dev team if this error continues.": "发生了一些错误，如果此错误仍然存​​在，请联系开发团队。",
  "Please fill in all the required fields": "请填写所有必填选项",
  "Only success transaction can be refund": "只有成功的交易才能退款",
  "Only token payment method can be refund": "只有电子代币支付方式才能退款",
  "The discount code has been used, please change to other code": "该优惠码已被使用，请更换其他优惠码",
  "Cannot use ewallet in sandbox": "无法在沙盒中使用电子钱包",
  "Please close the door to proceed your payment": "请关上门以继续付款",
  "Machine is Out of Service": "机器停止运行",
  "Machine is currently running, please try again later": "机器当前正在运行，请稍后重试",
  "Machine is currently offline, please try again later": "机器当前离线，请稍后重试",
  "Outlet not participating in Loyalty program": "商店未参与忠诚度计划",
  "Please log in to make the payment": "请登录后进行支付",
  "Package not found, please refresh and try again": "找不到配套，请刷新并重试",
  "You have reached the maximum number of purchase": "您已达到最大购买数量",
  "Store not found, please try again later": "找不到商店，请稍后重试",
  "Incorrect Password": "密码错误",
  "Token already top up": "电子代币已充值",
  "Payment failed": "支付失败",
  "Transaction Failure": "交易失败",
  "Transaction Refunded": "交易已退款",
  "Transaction Pending": "交易待处理",
  "Please enter your mobile number": "请输入您的手机号码",
  "User is unauthorized to register": "用户无权注册",
  "Please proceed to sign up": "请继续注册",
  "OTP expired, please request a new OTP": "OTP 已过期，请申请新的 OTP",
  "Incorrect verification code": "验证码不正确",
  "This referrer ID is not valid": "该推荐人 ID 无效",
  "This referrer ID is different operator": "该推荐人 ID 是不同的运营商",
  "This referrer ID is exceed the limit": "该推荐人 ID 超出限制",
  "User is unauthorized to login": "用户无权登录",
  "Click here to register": "点击此处注册",
  "User is unauthorized to login. Please contact the admin": "用户无权登录。请联系管理员",
  "User is unauthorized": "用户未经授权",
  "This outlet does not participate in the Loyalty Program": "该商店不参与忠诚度计划",
  "This outlet is not belongs to the operator": "该店铺不属于此运营商",
  "Processing request": "处理请求",
  "This user is not verified": "该用户未验证",
  "Insufficient token to make payment": "电子代币不足，无法付款",
  "This voucher is not available for use": "此优惠券不可使用",
  "This voucher is belongs to other user": "此优惠券属于其他用户",
  "Invalid Discount Code": "优惠码无效",
  "This discount code cannot use in this outlet": "该优惠码不能在该分店使用",
  "This discount code was expired": "该优惠码已过期",
  "This discount code cannot use in washer machine transaction": "此优惠码不能用于洗衣机交易",
  "This discount code cannot use in dryer machine transaction": "此优惠码不能用于烘干机交易",
  "This discount code was fully redeemed today, try again tomorrow": "该优惠码今天已完全兑换，明天再试",
  "You have fully redeemed this discount code": "您已完全兑换此优惠码",
  "This discount code have been redeemed": "此优惠码已兑换",
  "This discount code is not applicable": "此优惠码不适用",
  "Insufficient stamp": "印章不足",
  "Please provide at least one voucher setting": "请提供至少一项优惠券设置",
  "Previous batch are still generating, please try again later": "上一批仍在生成中，请稍后重试",
  "Number of voucher code should be more than 0": "兑换码数量必须大于0",
  "Number of voucher code should be less than 50000": "兑换码数量必须小于50000",
  "Number of discount code should be more than 0": "优惠码数量必须大于0",
  "Number of discount code should be less than 50000": "优惠码数量必须小于50000",
  "No Item": "没有配套",
  "Please add item": "请添加配套",
  "Staff not found": "未找到工作人员",
  "This task already completed": "此任务已经完成",
  "Please complete the wash task first": "请先完成洗涤任务",
  "Please complete the dry task first": "请先完成烘干任务",
  "Please select at least one outlet": "请至少选择一个店铺",
}

const translationZhtw = {
  "LOGIN": "登錄",
  "REGISTER": "註冊",
  "SKIP TO PAYMENT": "直接付款",
  "WELCOME": "歡迎",
  "Error": "錯誤",
  "Please enter the correct phone number format. <br /><br /> <small>Example: 0129465628</small>": "請輸入正確的電話號碼格式。 <br /><br /> <small>例如: 0129465628</small>",
  "Info": "資訊",
  "Please scan QRCode to Login": "請掃描二維碼登錄",
  "Forgot Password": "忘記密碼",
  "Seems like you have forgotten your password<br />Create a temporary password?": "好像忘記密碼了<br />創建臨時密碼？",
  "Cancel": "取消",
  "Confirm": "确认",
  "Reset Your Password": "重置您的密碼",
  "Enter Your Phone Number / Email Address": "輸入您的電話號碼/電子郵件地址",
  "Please enter phone number / email address": "請輸入電話號碼/電子郵件地址",
  "Submit": "提交",
  "Please enter a valid phone number": "請輸入正確的電話號碼格式",
  "Please enter a valid email address": "請輸入有效的電子郵件地址",
  "Success!": "成功！",
  "You may login using your one time password now.": "您現在可以使用一次性密碼登錄。",
  "Phone Number": "電話號碼",
  "Password": "密碼",
  "Don't have an account?": "還未有賬戶？",
  "Forgot password?": "忘記密碼？",
  "Unable to register without a QRCode": "沒有二維碼無法註冊",
  "Please read and agree to the privacy policies, terms and conditions": "請閱讀並同意隱私政策、條款和條件",
  "Password length must be at least 6 characters": "密碼長度必須至少為 6 個字符",
  "Please enter the confirmation code that was sent to your phone": "請輸入發送到您手機的驗證碼",
  "Example: 012xxxxxxx": "例如: 012xxxxxxx",
  "Example: 09xxxxxxxx": "例如: 09xxxxxxxx",
  "Verification Method": "驗證方法",
  "Name": "姓名",
  "Email": "電子郵件",
  "optional": "選擇性",
  "Birth Year": "出生年份",
  "Year": "年",
  "Birth Month": "出生月份",
  "Month": "月",
  "Jan": "一月",
  "Feb": "二月",
  "Mar": "三月",
  "April": "四月",
  "May": "五月",
  "June": "六月",
  "July": "七月",
  "Aug": "八月",
  "Sept": "九月",
  "Oct": "十月",
  "Nov": "十一月",
  "Dec": "十二月",
  "Birth Day": "出生日期",
  "Day": "日",
  "OTP": "驗證碼",
  "OTP Confirmation": "確認驗證碼",
  "I agree to the": "我同意",
  "Privacy Policy": "隱私政策",
  "and": "和",
  "Terms": "條款",
  "Conditions": "條件",
  "SEND CODE": "發送驗證碼",
  "Our One-Time Password (OTP) emails might end up in your spam or junk folder. We kindly ask you to take a moment to check your spam mailbox.": "我們的一次性密碼 (OTP) 電子郵件可能最終會進入您的垃圾郵件或垃圾郵件資料夾。 我們懇請您花點時間檢查您的垃圾郵件信箱。",
  "Already have an account? Login": "已經有賬戶？登錄",
  "Didn't receive a code?": "沒有收到驗證碼？",
  "Resend Code": "重新發送驗證碼",
  "collected": "已收集",
  "Collect": "收集",
  "stamps": "印章",
  "get 1 voucher": "獲得1張優惠券",
  "Minimum": "最低消費",
  "spending to collect each washer stamp": "以收集每個洗衣機印章",
  "spending to collect each dryer stamp": "以收集每個烘乾機印章",
  "spending to collect each stamp": "以收集每個印章",
  "Device don't have a camera": "設備沒有攝像頭",
  "This is not a valid QRCode": "這不是有效的二維碼",
  "Select Camera": "選擇相機",
  "Switch": "切換",
  "Camera": "相機",
  "Loading": "加載中",
  "Scan the QR code on the machine.": "掃描機器上的二維碼。",
  "Switch Camera": "切換相機",
  "Off torch light": "關閉手電筒",
  "On torch light": "開啟手電筒",
  "switch camera if your camera is not clear": "如果您的相機不清晰，請切換相機",
  "Switch your camera if the display is not clear": "如果顯示不清晰請切換相機",
  "Please allow this webapp to access your camera": "請允許此網絡應用訪問您的相機",
  "DISCOUNT": "折扣",
  "Congratulation": "恭喜",
  "Expired Date": "過期日期",
  "Voucher": "優惠券",
  "Vouchers": "優惠券",
  "Something went wrong": "出了些問題",
  "Stamping Collection": "印章收集",
  "Total e-Token": "總電子代幣",
  "Total Wash": "洗衣機次數",
  "Total Dry": "烘乾機次數",
  "Reload": "充值",
  "Washer": "洗衣機",
  "Dryer": "烘乾機",
  "Congratulations!": "恭喜！",
  "You have completed your Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "您已完成印章收集。<br />優惠券已給出和印章收集已刷新",
  "You have completed Washer Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "您已完成洗衣機印章收集。<br />優惠券已給出和印章收集已刷新",
  "You have completed Dryer Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "您已完成烘乾機印章收集。<br />優惠券已給出和印章收集已刷新",
  "Latest News": "最新消息",
  "Transaction History": "交易記錄",
  "Stamping": "印章",
  "Transaction": "交易",
  "News": "消息",
  "Confirmation": "確認",
  "Logout Session?": "登出？",
  "NO": "否",
  "OK": "確定",
  "Account": "賬戶",
  "FAQs": "常見問題",
  "Feedback": "反饋",
  "LOGOUT": "登出",
  "Logout": "登出",
  "Are you sure you want to submit your feedback?": "您確定要提交反饋嗎？",
  "No": "否",
  "Yes": "是",
  "Success": "成功",
  "Failed": "失敗",
  "Pending": "待定",
  "Your feedback is submitted successfully": "您的反饋已成功提交",
  "Please fill in all the required fields before submit.": "請在提交前填寫所有必填內容。",
  "Give us your feedback!": "您好，請對我們的服務提出寶貴建議，謝謝！",
  "Type your name here (optional)": "在此處輸入您的姓名（選擇性）",
  "required": "必填",
  "Type your number here": "在此處輸入您的電話號碼",
  "Outlet": "店鋪",
  "Select an outlet": "選擇店鋪",
  "Outlet Cleanliness": "店鋪清潔度",
  "Washer Machine Performance": "洗衣機性能",
  "Dryer Machine Performance": "烘乾機性能",
  "Customer Service": "客戶服務",
  "Comment": "評論",
  "Type your comment here (optional)": "在此處輸入您的評論（選擇性）",
  "SUBMIT": "提交",
  "Join as a member now!": "立即加入成為會員！",
  "Join as a loyalty member now to get more benefits such as vouchers and free machine usage!<br /><br /><strong>Register as loyalty member?</strong>": "現在加入會員，即可免費獲得優惠券和免費機器使用等福利！<br /><br /><strong>註冊成為會員？</strong>",
  "Maybe Next Time": "下一次吧",
  "Yes, Register Now": "是的，立即註冊",
  "Washer Payment": "洗衣機支付",
  "Dryer Payment": "烘乾機支付",
  "Vending Machine Payment": "自動販賣機支付",
  "Online": "在線",
  "Offline": "離線",
  "Running": "運行",
  "Vacant": "空置",
  "Vending Machine": "自動販賣機",
  "Discount": "折扣",
  "No Vouchers Available": "沒有可用的優惠券",
  "Payment Method": "付款方法",
  "Would you like to pay with?": "您願意使用哪種方式付款?",
  "e-Token": "電子代幣",
  "E-TOKEN": "電子代幣",
  "E-Wallet": "電子錢包",
  "Duitnow QR code": "Duitnow二維碼",
  "The Duitnow QR code will be downloaded and you need to open your banking app to make the payment. Pay {{ currency }}{{ totalPaymentAmount }} to {{ machineName }}?": "Duitnow 二維碼會將下載，您需要打開銀行應用程式進行付款。向 {{ machineName }} 支付 {{ currency }}{{ totalPaymentAmount }} 嗎？",
  "Pay": "付",
  "to": "給",
  "You may under utilized the voucher.": "您尚未充分利用您的優惠券。",
  "Proceed to pay": "繼續支付",
  "Processing ...": "支付中 ...",
  "You can't pay with e-token if voucher is in used, please remove your voucher to pay with e-token.": "您不能同時使用優惠劵和電子代幣付款，請移除您的優惠卷以使用電子代幣付款。",
  "Insufficient e-Tokens": "電子代幣不足",
  "Reload your e-token now?": "現在充值您的電子代幣？",
  "Reload now!": "現在充值！",
  "You have": "您有",
  "e-Tokens": "電子代幣",
  "Tokens to": "代幣給",
  "This machine is currently offline, please try again later.": "本機器當前離線，請稍後重試。",
  "MACHINE": "機器",
  "Machine": "機器",
  "TYPE": "類型",
  "CAPACITY": "容量",
  "DURATION": "時長",
  "Select Duration": "選擇時長",
  "AMOUNT TO PAY": "支付總額",
  "PRICE": "價格",
  "VOUCHER": "優惠券",
  "Select Voucher >": "選擇優惠券 >",
  "TOTAL": "總額",
  "PAY": "付款",
  "Remove Voucher": "移除優惠券",
  "Select Your Voucher": "選擇您的優惠券",
  "min": "分鐘",
  "MIN": "分鐘",
  "Select Payment Amount": "選擇支付總額",
  "Select Water Temperature": "選擇水溫",
  "Select temperature": "選擇水溫",
  "Cold": "冷",
  "Warm": "溫",
  "Hot": "熱",
  "e-Tokens to": "電子代幣給",
  "No Purchase History": "無購買記錄",
  "e-tokens": "電子代幣",
  "No Promotions": "沒有促銷",
  "New Password": "新密碼",
  "Back": "返回",
  "Buy this package?": "買這個配套？",
  "Purchase": "購買",
  "e-Tokens for": "電子代幣以",
  "Reload Tokens": "充值電子代幣",
  "Are you sure you want to purchase {{ tokenAmount }} e-Token for {{ currency }}{{ paymentAmount }}?": "您確定要以 {{ currency }}{{ paymentAmount }} 購買 {{ tokenAmount }} 電子代幣嗎？",
  "Current e-Token": "當前電子代幣",
  "History": "歷史記錄",
  "Your profile picture has been updated!": "您的頭像已更新！",
  "Please only use png, jpg or jpeg file extension type": "請僅使用 png、jpg 或 jpeg 類型文件",
  "Please only use image type file": "請僅使用圖像類型文件",
  "User name has updated successfully!": "用戶名更新成功！",
  "Email Address updated successfully": "電子郵件更新成功",
  "Phone number updated successfully": "電話號碼更新成功",
  "Password must be at least 6 characters": "密碼必須至少6個字符",
  "Password updated successfully": "密碼更新成功",
  "User Settings": "用戶設置",
  "Profile Picture": "個人頭像",
  "Change Password": "更改密碼",
  "Do you want to submit your feedback?": "您想提交您的反饋嗎？",
  "Thank You!": "謝謝你！",
  "Thank you for using our services.": "感謝您使用我們的服務。",
  "BACK TO HOMEPAGE": "返回主頁",
  "How to pay?": "如何付款？",
  "Step 1": "步驟 1",
  "After scan the QR code on the machine, select either register new account, login your account or skip to payment.": "掃描機器上的二維碼後，選擇註冊新帳戶、登錄您的帳戶或直接付款。",
  "Step 2-1": "步驟 2-1",
  "For Dryer – Select your desired duration. Different duration will have different prices.": "對於烘乾機 - 選擇所需的時長。不同的時間會有不同的價格。",
  "Step 2-2": "步驟 2-2",
  "For Washer – Select your desired water temperature. Different water temperature will have different prices.": "對於洗衣機 - 選擇您想要的水溫。不同的水溫會有不同的價格。",
  "Step 3": "步驟 3",
  "For login user, Click on ‘Select Voucher’ to select your voucher.": "對於所有登錄用戶，點擊“選擇優惠劵”以選擇您的優惠劵。",
  "Step 4": "步驟 4",
  "Click on ‘PAY’ button to pay. For login user, select your payment method to pay.": "點擊“付款”按鈕進行支付。對於登錄用戶，選擇您的支付方式進行支付。",
  "Step 5": "步驟 5",
  "After confirm the price and machine details, click on ‘OK’ button to pay.": "確認價格和機器詳情后，點擊“確定”按鈕進行支付。",
  "How to reload your e-Token?": "如何充值您的電子代幣？",
  'Click on the "Reload" button.': '點擊“充值”按鈕。',
  "Select the package that you wish to purchase.": "選擇您要購買的配套。",
  'Click on "Purchase" as confirmation before payment page.': '在前往付款頁面之前點擊“購買”作為確認。',
  "Is e-token can be refund/transfer back to e-wallet?": "電子代幣可以退款/轉回電子錢包嗎？",
  "Why can't I applied my voucher?": "為何不能使用我的優惠券？",
  "When there is a Happy Hour Promotion is on going, you are unable to apply your voucher temporarily.": "每當特別促銷正在進行時，您暫時無法使用您的優惠劵。",
  "How to apply my voucher?": "如何使用我的優惠券？",
  'Click on the "Select Voucher" button.': '點擊“選擇優惠券”按鈕。',
  "Select the voucher that you wish to apply.": "選擇您要使用的優惠券。",
  'Click on "Pay" once you have verified the voucher is successfully applied.': '確認優惠劵已成功使用後，請點擊“付款”。',
  "Step 2": "步驟 2",
  "Language has updated successfully!": "語言更新成功！",
  "Change Language": "更改語言",
  "Language": "語言",
  "Select language": "選擇語言",
  "Referral Code": "邀請碼",
  "Copied!": "已復制!",
  "Inviting friends to get more benefits!": "邀請好友以獲取更多福利！",
  "Would you like to share your referral code to your friends & family?": "您要分享您的邀請碼給朋友&家人嗎？",
  "Refer a friend and get": "推薦朋友並獲得",
  "Share the love to your friends & family?": "將愛分享給您的朋友和家人？",
  "voucher": "優惠劵",
  "token": "電子代幣",
  "stamp": "印章",
  "washer stamp": "洗衣機印章",
  "dryer stamp": "烘乾機印章",
  "Learn More": "了解更多",
  "Welcome Gift": "歡迎禮物",
  "Share Your Referral Code Now": "立即分享您的邀請碼",
  "Birthday Gift for You": "給您的生日禮物",
  "Let's Pay with E-Token": "使用電子代幣支付",
  "Collect 10 Stamps and Get Voucher": "收集 10 個印章並獲得優惠劵",
  "The voucher will be given if you registered your account successfully.": "如果您成功註冊了您的帳戶，您將獲得優惠券。",
  "There is no expiration date for this voucher.": "此優惠券沒有過期日期。",
  "The voucher can be used for dryer machine only.": "優惠券僅可用於烘乾機。",
  "The voucher can be used for washer machine only.": "優惠券僅可用於洗衣機。",
  "The voucher can be used for Drop n Go transaction only.": "優惠券僅可用於 Drop n Go 交易。",
  "The voucher can be used for washer machine or Drop n Go transaction.": "優惠券僅可用於洗衣機或 Drop n Go 交易。",
  "The voucher can be used for dryer machine or Drop n Go transaction.": "優惠券僅可用於烘乾機或 Drop n Go 交易。",
  "The voucher can be used for dryer or washer machine.": "優惠券可用於烘乾機或洗衣機。",
  "The voucher can be used for washer machine, dryer machine or Drop n Go transaction.": "優惠券可用於洗衣機、烘乾機或 Drop n Go 交易。",
  "When you applied the voucher during payment, it will give you": "當您在付款期間使用您的優惠券時，它會給您",
  "discount.": "折扣。",
  "When promotion is on going, the voucher is not applicable.": "促銷期間，優惠券不可使用。",
  "The voucher cannot be used if your payment method is E-Token.": "如果您的付款方式是電子代幣，則不能使用該優惠券。",
  "Your referral code can be found in user settings page.": "您的邀請碼可以在用戶設置頁面中找到。",
  "You can get": "你可以得到",
  "stamp for the reward.": "印章作為獎勵。",
  "e-token for the reward.": "電子代幣作為獎勵。",
  "e-token based on transaction payment amount for the reward.": "電子代幣根據交易的支付金額作為獎勵。",
  "for the reward.": "以作為獎勵。",
  "based on transaction payment amount for the reward.": "根據交易支付金額獲得獎勵。",
  "You can get a voucher for the reward": "您可以獲得優惠券作為獎勵",
  "which have": "此優惠劵將有",
  "it can be used for dryer machine only.": "它只能用於烘乾機。",
  "it can be used for washer machine only.": "它只能用於洗衣機。",
  "it can be used for Drop n Go transaction only.": "它只能用於Drop n Go交易。",
  "it can be used for dryer or washer machine.": "它可用於烘乾機或洗衣機。",
  "it can be used for dryer machine or Drop n Go transaction.": "它可用於烘乾機或Drop n Go交易。",
  "it can be used for washer machine or Drop n Go transaction.": "它可用於洗衣機或Drop n Go 交易。",
  "it can be used for washer machine, dryer machine or Drop n Go transaction.": "它可用於洗衣機、烘乾機或Drop n Go 交易。",
  "washer stamp for the reward.": "洗衣機印章作為獎勵。",
  "dryer stamp for the reward.": "烘乾機印章作為獎勵。",
  "washer stamp and": "洗衣機印章和",
  "cleanproBirthdayShortDesc": "使用 Cleanpro Plus 在您的特殊日子兌換 {{ birthdayVoucherAmount }} 貨幣的生日禮券。點擊以了解更多信息。",
  'When you click on "PAY" button on the payment page, you can choose either using E-wallet or E-token to pay.': '當您在支付頁麵點擊"付款"按鈕時，您可以選擇使用電子錢包或電子代幣進行支付。',
  "When you using your voucher during payment, you will be unable to pay with E-token.": "當您在付款期間使用您的優惠券時，您將無法使用電子代幣付款。",
  "You can reload your E-token by clicking the reload button in the stamping page and select the package to purchase.": "您可以通過點擊印章頁面中的充值按鈕並選擇要購買的配套來充值您的電子代幣。",
  "You can reload your E-token by clicking the reload button in the home page and select the package to purchase.": "您可以通過點擊主頁中的充值按鈕並選擇要購買的配套來充值您的電子代幣。",
  "You can view your E-token reload history and E-token transaction history in transaction page.": "您可以在交易頁面查看您電子代幣的儲值記錄和交易記錄。",
  "You can view your E-token transaction history in transaction page.": "您可以在交易頁面查看您電子代幣的交易記錄。",
  "Using either dryer machine or washer machine with the minimum spending of": "使用烘乾機或洗衣機，最低消費",
  "to collect one stamp.": "即可收集一個印章。",
  "The voucher will be given if you have collected 10 stamps.": "如果您收集了 10 個印章，將獲得優惠券。",
  "Using dryer machine with the minimum spending of": "使用烘乾機最低消費",
  "Using washer machine with the minimum spending of": "使用洗衣機最低消費",
  "to collect one dryer stamp.": "即可獲得一個烘乾機印章。",
  "to collect one washer stamp.": "即可獲得一個洗衣機印章。",
  "The voucher will be given if you have collected 10 dryer stamps or 10 washer stamps.": "如果您收集了 10 個烘乾機印章或 10 個洗衣機印章，將獲得優惠券。",
  "For the voucher given by the dryer stamps,": "對於烘乾機印章給出的優惠劵，",
  "For the voucher given by the washer stamps,": "對於洗衣機印章給出的優惠劵，",
  "When promotion is on going, all the vouchers is not applicable.": "促銷期間，所有優惠券均不可使用。",
  "All the vouchers cannot be used if your payment method is E-Token.": "如果您的付款方式是電子代幣，則所有優惠券都不能使用。",
  "Description": "簡介",
  "Refer Friends": "推薦給朋友",
  "View our guideline": "查看我們的指南",
  "When a friend / family register using your referral code, and completed a transaction with a minimum spending of": "當朋友/家人使用您的邀請碼註冊並完成最低消費",
  ", you will be rewarded automatically.": "的交易時，您將自動獲得獎勵。",
  "One user only allow to have": "一個用戶只允許擁有",
  "referrals.": "邀請者。",
  "Confirmation Password": "確認密碼",
  "Password and confirmation password should be same.": "密碼和確認密碼必須相同。",
  "You haven't applied your voucher": "您還沒使用優惠券",
  "Would you like to continue your payment without using your voucher?": "您想在不使用優惠券的情況下繼續付款嗎？",
  "Continue": "繼續",
  "Apply voucher": "使用優惠券",
  "Transfer": "轉賬",
  "Transfer Tokens": "轉賬電子代幣",
  "Please fill in the phone number": "請填寫電話號碼",
  "Please fill in the email": "請填寫電子郵件",
  "Please enter the phone number / email that you want to transfer to": "請輸入您要轉賬的電話號碼/電子郵件",
  "Please fill in the transfer amount": "請填寫轉賬總額",
  "Transfer amount should be more than 0 and less than your current e-Token": "轉賬總額需大於0且小於您當前的電子代幣",
  "Please enter your password": "請輸入您的密碼",
  "Member ID": "會員ID",
  "E-Token Transfer Amount": "電子代幣轉賬總額",
  "Please confirm the details before proceeding": "請在繼續之前確認詳細信息",
  "Transfer From": "轉賬從",
  "Transfer To": "轉賬至",
  "Transfer Amount": "轉賬總額",
  "Transfer Successfully!": "轉賬成功！",
  "Transfer Time": "轉賬時間",
  "Token": "電子代幣",
  "Done": "完成",
  "Next": "繼續",
  "Enter Password": "輸入密碼",
  "Transfer E-Token Amount": "電子代幣轉賬金額",
  "User not found": "找不到用戶",
  "Password is incorrect": "密碼不正確",
  "Please go to your account setting and reset your password": "請前往您的用戶設置並重置您的密碼",
  "Only up to last 3 months transaction history": "僅最近3個月的交易記錄",
  "Reload History": "充值記錄",
  "Transfer History": "轉賬記錄",
  "{{ point }} History": "{{ point }}記錄",
  "Only up to last 3 months reload history": "僅最近3個月的充值記錄",
  "Only up to last 3 months transfer history": "僅最近3個月的轉賬記錄",
  "Only up to last 3 months {{ point }} history": "僅最近3個月的{{ point }}記錄",
  "If require urgent response, please contact us through whatapp": "如果需要緊急回應，請通過whatsapp與我們聯繫",
  "Please scan the QR code again": "請再次掃描二維碼",
  "Home": "主頁",
  "Quick": "快速",
  "Super": "超級",
  "Premium": "高級",
  "All users will have infinite referrals.": "所有用戶都將獲得無限邀請者。",
  "Collect 10 stamps to get 1 Voucher": "收集10個印章獲得1張優惠劵",
  "The voucher is only for customer use only, not for sale.": "優惠券僅供客戶使用，不用於銷售。",
  "Outlet is currently offline, please try again later": "店鋪目前處於離線狀態，請稍後再試",
  "The voucher will be given if you completed a transaction with a minimum spending of": "您將獲得優惠劵如果您完成交易以最低消費",
  "The birthday voucher will be given on the first day of your birthday month.": "生日券將在您生日月份的第一天發放。",
  "The voucher only will be given if you have exceed spending amount of": "優惠劵只會發放當您的消費超過",
  "Pay with KiplePay": "使用 KiplePay 付款",
  "Pay with Scan2Pay": "使用 Scan2Pay 付款",
  "Pay with Razer": "使用 Razer 付款",
  "Pay with HitPay": "使用 HitPay 付款",
  "Pay with Duitnow QR": "使用 Duitnow QR 付款",
  "Pay with PagBank": "使用 PagBank 付款",
  "DISCOUNT CODE": "優惠碼",
  "Enter code >": "輸入優惠碼 >",
  "Enter Discount Code": "輸入優惠碼",
  "Remove Code": "移除優惠碼",
  "Discount Code": "優惠碼",
  "Close": "關閉",
  "Please fill in your email address": "請填寫您的電子郵件地址",
  "Notification": "通知",
  "Do you want to receive notification when wash cycle done?": "您想在洗滌週期完成時收到通知嗎？",
  "pay extra": "額外付費",
  "Email Address": "電子郵件地址",
  "Please fill in your phone number": "請填寫您的電話號碼",
  "Invalid Phone Number": "無效的電話號碼",
  "Washing Complete": "洗滌完成",
  "Drying Complete": "烘乾完成",
  "The voucher will be given if you registered your account successfully, but it only can be used when your total transaction spending reach": "成功註冊賬戶即可獲贈禮券，但僅能使用當交易總額達",
  "and above.": "或以上。",
  "This voucher is not applicable": "此優惠券不適用",
  "Finish in": "完成於",
  "Need to refresh to update": "需要刷新才能更新",
  "It's indicative and may change based on washer load": "這是指示性的，可能會根據洗衣機負載而變化",
  "We'll let you know when it's ready to pick up": "當準備好取衣物時，我們會通知您",
  "FREE": "免費",
  "Enter voucher code (if got any)": "輸入優惠券兌換碼（如果有）",
  "Please fill in the voucher code": "請輸入優惠券兌換碼",
  "Available On": "可用於",
  "Apply": "使用",
  "Enter a code": "輸入兌換碼",
  "Input Promo Code": "輸入促銷代碼",
  "Voucher is redeemed successfully!": "優惠券兌換成功！",
  "Not available": "無法使用",
  "Unable to register": "無法註冊",
  "Drop n Go Service": "Drop n Go 服務",
  "Select Mode": "選擇模式",
  "Quantity": "數量",
  "Pack": "包裹",
  "Add more": "添加更多",
  "Select Size": "選擇尺寸",
  "Select Services": "選擇服務",
  "Are you sure to delete the item?": "您確定要刪除該配套嗎？",
  "Delete": "刪除",
  "Your Duitnow QR code": "您的 Duitnow 二維碼",
  "How to pay using Duitnow QR code?": "如何使用 Duitnow 二維碼付款？",
  "Launch": "運行",
  "Launch your preferred Mobile Banking or eWallet app.": "啟動您喜歡的手機銀行或電子錢包應用程式。",
  "Scan QR code from Gallery": "從圖庫掃描二維碼",
  "Choose DuitNow QR from your gallery.": "從您的圖庫中選擇 DuitNow 二維碼。",
  "Confirm payment details and pay.": "確認付款細節並付款。",
  "Receive payment confirmation.": "收到付款確認。",
  "expiring on": "過期於",
  "based on transaction payment amount": "根據交易支付金額",
  "Join as our": "立即加入我們的",
  "Loyalty Member": "忠誠會員",
  "now to get more reward": "以獲得更多獎勵",
  "Payment amount should be greater than 0": "付款金額必須大於0",
  // admin portal
  "Dashboard": "概括",
  "Report": "報告",
  "Store Performance": "商店表現",
  "User Performance": "用戶表現",
  "Voucher Redemption": "優惠券兌換",
  "Token Report": "電子代幣報告",
  "SMS Report": "簡訊報告",
  "Discount Code Report": "優惠碼報告",
  "User Details": "用戶詳細資訊",
  "User Compensation": "用戶補償",
  "Voucher Settings": "優惠券設定",
  "Token Settings": "代幣設定",
  "Referral Settings": "邀請碼設定",
  "Voucher Code": "優惠券兌換碼",
  "Customize Color": "更換主題顏色",
  "User Migration": "用戶遷移",
  "Back To": "返回",
  "Eligibility": "資格",
  "If ticked, the voucher will have expired date": "若勾選，優惠券將有過期日期",
  "Expiry": "期限",
  "If ticked, the voucher can apply to washer machine transaction": "若勾選，優惠劵可用於洗衣機交易",
  "Washer Use": "應用於洗衣機",
  "If ticked, the voucher can apply to dryer machine transaction": "若勾選，優惠劵可用於烘乾機交易",
  "Dryer Use": "應用於烘乾機",
  "If ticked, the voucher can apply to Drop n Go transaction": "若勾選，優惠劵可用於Drop n Go交易",
  "Package Use": "應用於配套",
  "Voucher Lifespan": "優惠券有效期限",
  "days": "天",
  "Discount Type": "折扣類型",
  "Select a discount type": "選擇折扣類型",
  "Flat": "扁平",
  "Rate": "率",
  "Voucher Title": "優惠券標題",
  "Voucher Description": "優惠券說明",
  "Voucher Amount": "優惠券金額",
  "CONFIRM": "確認",
  "If ticked, the system will give voucher to user who completed washer stamp collection": "如果勾選，系統將向完成洗衣機印章收集的用戶發放優惠劵",
  "If ticked, the system will give voucher to user who completed dryer stamp collection": "如果勾選，系統將向完成烘乾機印章收集的用戶發放優惠劵",
  "If ticked, the user only can get/use the voucher after make a transaction": "如果勾選，用戶只有在進行交易後才能取得/使用優惠券",
  "Pay And Get Voucher": "付款後取得優惠券",
  "Pay And Use Code": "消費後才能使用優惠碼",
  "If ticked, the system will only give the voucher to user after the user make a transaction": "如果勾選，只有在用戶進行交易後，系統才會將優惠券發放給用戶",
  "If ticked, the system will give voucher to user who completed registration process": "如果勾選，系統將會向完成註冊的用戶贈送優惠劵",
  "If ticked, the system will give voucher to user on their birthday month": "如果勾選，系統將在用戶生日當月向用戶贈送優惠券",
  "If ticked, the system will give voucher to user who completed stamp collection": "如果勾選，系統將贈送優惠劵給完成印章收集的用戶",
  "Minimum Payment Amount To Get/Use Register Voucher": "取得/使用註冊優惠券的最低付款金額",
  "Voucher Method": "優惠券贈送方式",
  "Select a voucher method": "選擇優惠券贈送方式",
  "The user directly get the voucher after register but only can use it after make transaction": "用戶註冊後直接獲得優惠券，但需進行交易後才能使用",
  "The user only can get voucher after make a transaction": "用戶只有在進行交易後才能獲得優惠券",
  "Minimum Payment Amount To Get Birthday Voucher": "獲得生日優惠劵的最低付款金額",
  "Minimum Payment Amount To Use Discount Code": "使用優惠碼的最低交易金額",
  "Outlets": "商店",
  "E-Payment Sales": "電子支付銷售",
  "Number of stamps granted to users": "贈送用戶的印章數量",
  "Total number of stamps granted": "贈送印章總數",
  "Registered Users": "註冊用戶",
  "Stamps": "印章",
  "Referral": "邀請碼",
  "Register": "註冊",
  "Top Outlets": "熱門商店",
  "Users": "用戶",
  "Sort by": "排序方式",
  "Top Users": "熱門用戶",
  "User name": "用戶名",
  "Number": "電話號碼",
  "Spending": "花費",
  "Number of registered users": "註冊用戶數",
  "Number of voucher given to users": "給予用戶的優惠券數量",
  "Total number of voucher given": "給予的優惠券總數",
  "Voucher Type": "優惠券類型",
  "Voucher Value": "優惠券的價值",
  "Date": "日期",
  "Discount Code Type": "優惠碼類型",
  "Discount Code Amount": "優惠碼金額",
  "Number of Redemption": "兌換次數",
  "Total Discount Amount": "總折扣金額",
  "Select Outlet": "選擇商店",
  "Select Date": "選擇日期",
  "Select Transaction Date": "選擇交易日期",
  "Reset": "重置",
  "Download Report": "下載報告",
  "Please fill in the remark": "請填寫備註",
  "Transaction Date": "交易日期",
  "Machine Type": "機器類型",
  "Machine Capacity": "機器容量",
  "Total Amount": "總金額",
  "Payment Amount": "支付金額",
  "Discount From": "折扣從",
  "Discount Amount": "折扣金額",
  "Action": "行動",
  "Remark": "備註",
  "Refund Date": "退款日期",
  "Refund": "退款",
  "Purchase Date": "購買日期",
  "e-Token Purchased (Tokens)": "購買的電子代幣（電子代幣）",
  "Bought e-Token using": "購買的電子代幣",
  "Transaction ID": "交易ID",
  "Method": "方式",
  "Requery": "重新查詢",
  "Reward Date": "獎勵日期",
  "Reward Type": "獎勵類型",
  "Notification Method": "通知方式",
  "Email Preview": "电子邮件预览",
  "Message for User": "給用戶的留言",
  "Enter the message to be sent to the user": "輸入要傳送給用戶的留言",
  "Email Address to show on Email Template": "電子郵件範本上顯示的電子郵件地址",
  "Input Company Email Address": "輸入公司電子郵件地址",
  "Company Name to show on Email Template": "電子郵件範本上顯示的公司名稱",
  "Input Company Name": "輸入公司名稱",
  "Company Phone No. to show on Email Template": "電子郵件範本上顯示的公司電話號碼",
  "Input Company Phone No.": "輸入公司電話號碼",
  "Voucher ID": "優惠券ID",
  "Washer Stamp": "洗衣機印章",
  "Dryer Stamp": "烘乾機印章",
  "e-Token Amount": "電子代幣金額",
  "Transfer Date": "轉帳日期",
  "Transfer Amount (Token)": "轉帳金額（電子代幣）",
  "Number (Transfer From)": "電話號碼（轉自）",
  "Name (Transfer From)": "姓名（轉自）",
  "Member ID (Transfer From)": "會員ID（轉自）",
  "Number (Transfer To)": "電話號碼（轉給）",
  "Name (Transfer To)": "姓名（轉給）",
  "Member ID (Transfer To)": "會員ID（轉給）",
  "Grant Date": "給予日期",
  "Claim Date": "使用日期",
  "Member Details Report": "會員詳情報告",
  "Transaction Status": "交易狀態",
  "Token Reload": "儲值電子代幣",
  "Token Transfer": "電子代幣轉賬",
  "Voucher History": "優惠券歷史記錄",
  "TOKEN": "電子代幣",
  "Message Sent": "發送的訊息",
  "Date Sent": "發送日期",
  "Remaining SMS Credit": "簡訊餘額",
  "Store": "商店",
  "Total Registered Users": "註冊用戶總數",
  "User registered but no transaction record": "用戶已註冊但無交易紀錄",
  "Registered": "已註冊",
  "User spending is less than": "用戶花費小於",
  "Active": "活躍",
  "User spending is from": "用戶花費從",
  "until": "至",
  "Tier 1": "1 級",
  "User spending is": "用戶花費",
  "and above": "或以上",
  "Tier 2": "2 級",
  "Average spending in each transaction": "每筆交易的平均花費",
  "Average Spending": "平均花費",
  "Total number of transactions": "交易總數",
  "Total Transactions": "總交易量",
  "Number of vouchers used in all the transactions": "所有交易中使用的優惠劵數量",
  "Voucher Claimed": "使用優惠券數",
  "Total number of washer machine transactions": "洗衣機交易總數",
  "Washer Usage": "洗衣機使用次數",
  "Total number of dryer machine transactions": "烘乾機交易總數",
  "Dryer Usage": "烘乾機使用次數",
  "User Name": "用戶名",
  "e-Token Spent": "花費的電子代幣",
  "e-Token Detailed Report": "電子代幣詳細報告",
  "e-Token Purchase History": "電子代幣購買歷史記錄",
  "Select Purchase Date": "選擇購買日期",
  "Outlet Name": "店家名稱",
  "Total number of e-token used in all the transactions": "所有交易中使用的電子代幣總數",
  "Total e-Token Collected": "收集到的電子代幣總額",
  "Total number of e-token used in washer machine transactions": "所有洗衣機交易中使用的電子代幣總數",
  "Washer e-Token Collected": "收集到的洗衣機電子代幣總額",
  "Total number of e-token used in dryer machine transactions": "所有烘乾機交易中使用的電子代幣總數",
  "Dryer e-Token Collected": "收集到的烘乾機電子代幣總額",
  "Purchase History": "購買記錄",
  "All": "全部",
  "Most Bought e-Token Package": "最受歡迎的電子代幣配套",
  "for": "對於",
  "Tokens": "電子代幣",
  "Total Earned from users buying e-Tokens": "用戶購買電子代幣的總收入",
  "Total e-Tokens Bought from you": "從您購買的電子代幣總數",
  "Join Date": "加入日期",
  "Channel": "頻道",
  "Tier": "等級",
  "Referrer": "邀請者",
  "Referrals": "邀請人数",
  "Number of transactions for last 7 days": "近7天的交易數",
  "Number of Usage (in week)": "使用次數（週）",
  "Number of transactions for last 1 month": "近1個月的交易數",
  "Number of Usage (in month)": "使用次數（月）",
  "Total amount spent in all the transactions": "所有交易花費的總金額",
  "Total Spent": "總花費",
  "Last Transaction Date": "最後交易日期",
  "Number of vouchers collected": "領取優惠券數量",
  "Voucher Collected": "優惠劵領取數",
  "Number of vouchers used": "使用優惠券數量",
  "Voucher Spent": "優惠券使用數",
  "Washer Used": "洗衣機使用次數",
  "Dryer Used": "烘乾機使用次數",
  "Total number of e-Token spent in all the transactions": "所有交易中花費的電子代幣總數",
  "Total number of stamps collected": "收集的印章總數",
  "Total Stamps": "印章總數",
  "Select Join Date": "選擇加入日期",
  "Select Grant Date": "選擇給予日期",
  "Amount": "價值",
  "The image size is over 2MB": "圖片大小超過2MB",
  "Duplicate language selected": "您選擇了重複的語言",
  "Are you sure you want to add FAQ?": "您確定要新增常見問題嗎？",
  "FAQ added successfully": "常見問題添加成功",
  "FAQ Group added successfully": "常見問題組添加成功",
  "Please provide the faq with the default language": "請提供預設語言的常見問題",
  "Add FAQ": "新增常見問題",
  "Default Language": "預設語言",
  "Select Default Language": "選擇預設語言",
  "Image (Optional)": "圖片（可選）",
  "max": "最大",
  "Question": "問題",
  "Select Language": "選擇語言",
  "Remove": "移除",
  "Answer": "回答",
  "Add Translation": "新增翻譯",
  "Are you sure you want to add FAQ Group?": "您確定要新增常見問題組嗎？",
  "Please provide the faq group with the default language": "請為常見問題組提供預設語言",
  "Add FAQ Group": "新增常見問題組",
  "Title": "標題",
  "Title Language": "標題語言",
  "Please fill in the title": "請填寫標題",
  "Please fill in the description": "請填寫描述",
  "Please fill in the short description": "請填寫簡短的描述",
  "Please select the end date for your news": "請選擇消息的結束日期",
  "Please provide the news with the default language": "請提供預設語言的消息",
  "Please provide the banner image": "請提供橫幅圖片",
  "Please provide the news image": "請提供消息圖片",
  "Are you sure you want to add news?": "您確定要新增消息嗎？",
  "News added successfully": "消息添加成功",
  "Please fill in all the required fields before submit": "請在提交前填寫所有必填選項",
  "Add News": "新增消息",
  "Banner Image": "橫幅圖片",
  "News Image": "消息圖片",
  "Banner": "橫幅",
  "aspect ratio": "縱橫比",
  "Image": "圖片",
  "Display in": "顯示於",
  "Inbox Page": "收件箱頁面",
  "Promotion Banner": "促銷橫幅",
  "Select Period: (optional)": "選擇期間：（可選）",
  "Short Description": "簡短的介紹",
  "Your theme has been updated successfully": "您的主題已成功更新",
  "Default Theme": "預設主題",
  "Change Logo": "更改標誌",
  "REMOVE LOGO": "移除標誌",
  "Sms credit used up": "簡訊額度已用完",
  "Please top up your sms credit": "請儲值您的簡訊餘額",
  "Please": "請",
  "Again": "多一次",
  "Welcome": "歡迎",
  "Ranking": "排行榜",
  "Select Year": "選擇年份",
  "Missing Field": "選項未填",
  "Please select can be used for washer, dryer or package": "請選擇可用於洗衣機、烘乾機或配套",
  "Please fill in the start date and end date": "請填寫開始日期和結束日期",
  "Discount amount should be more than 0": "折扣金額應大於0",
  "Redeem per user should be more than 0": "每個用戶兌換次數應大於0",
  "Redeem per day should be more than 0": "每日兌換次數必須大於0",
  "Your discount code have edited successfully": "您的優惠碼已編輯成功",
  "Your discount code have added successfully": "您的優惠碼已添加成功",
  "Delete this entry?": "刪除此條目？",
  "Your discount code have deleted successfully": "您的優惠碼已刪除成功",
  "Created Date": "創建日期",
  "Discount code": "優惠碼",
  "General Discount Code": "通用優惠碼",
  "Unique Discount Code": "獨特優惠碼",
  "Start Date": "開始日期",
  "End Date": "結束日期",
  "Redeem Per User": "每個用戶兌換次數",
  "Redeem Per Day": "每日兌換次數",
  "Add Discount Code": "新增優惠碼",
  "Generate Discount Code": "生成优惠码",
  "No Discount Code": "没有優惠碼",
  "Edit Discount Code": "編輯優惠碼",
  "Select Period": "選擇期間",
  "Redeem per user": "每個用戶兌換次數",
  "How many times the discount code can be used for each user": "每個使用者可以使用優惠碼的次數",
  "Redeem per day": "每日兌換次數",
  "How many times the discount code can be used in one day": "優惠碼一天內可以使用幾次",
  "If ticked, the discount code can apply to washer machine transaction": "如果勾選，優惠碼可應用於洗衣機交易",
  "If ticked, the discount code can apply to dryer machine transaction": "如果勾選，優惠碼可應用於烘乾機交易",
  "If ticked, the discount code can apply to Drop n Go transaction": "如果勾選，優惠碼可應用於Drop n Go交易",
  "The user enter the discount code": "用戶輸入優惠碼",
  "in the payment page will get": "在支付頁面會得到",
  "discount": "折扣",
  "The discount code only can be used start from {{ start_date }} until {{ end_date }}": "優惠碼只能在 {{ start_date }} 至 {{ end_date }} 期間使用",
  "Every user only can use the discount code {{ redeem_per_user }} times": "每個用戶只能使用優惠碼 {{ redeem_per_user }} 次",
  "Every user only can use {{ redeem_per_user }} discount code from this batch of code": "每個使用者只能使用這批優惠碼中的 {{ redeem_per_user }} 個優惠碼",
  "Only {{ redeem_per_day }} transactions can use the discount code in one day": "一天內只有 {{ redeem_per_day }} 筆交易可以使用優惠碼",
  "The discount code only can be used for washer machine": "優惠碼僅適用於洗衣機",
  "The discount code only can be used for dryer machine": "優惠碼僅適用於烘乾機",
  "The discount code only can be used for Drop n Go transaction": "優惠碼僅適用於Drop n Go交易",
  "The discount code can be used for washer machine or Drop n Go transaction": "優惠碼可用於洗衣機或 Drop n Go 交易",
  "The discount code can be used for dryer machine or Drop n Go transaction": "優惠碼可用於烘乾機或 Drop n Go 交易",
  "The discount code can be used for washer machine, dryer machine or Drop n Go transaction": "優惠碼可用於洗衣機、烘乾機或 Drop n Go 交易",
  "The discount code can be used for washer or dryer machine": "優惠碼可用於洗衣機或烘乾機",
  "The discount code only can be used after user spending {{ currency }} {{ pay_and_use_amount }}": "優惠碼必須在消費滿{{ currency }} {{ pay_and_use_amount }}後才能使用",
  "Are you sure you want to edit FAQ?": "您確定要編輯常見問題嗎？",
  "FAQ updated successfully": "常見問題更新成功",
  "Edit FAQ": "編輯常見問題",
  "Are you sure you want to edit FAQ Group?": "您確定要編輯常見問題組嗎？",
  "FAQ Group updated successfully": "常見問題組更新成功",
  "Edit FAQ Group": "編輯常見問題組",
  "Are you sure you want to edit news?": "您確定要編輯消息嗎？",
  "News edit successfully": "消息編輯成功",
  "Edit News": "編輯消息",
  "All the FAQ under this group will be deleted. Are you sure you want to delete FAQ Group?": "該組下的所有常見問題將被刪除。您確定要刪除常見問題組嗎？",
  "FAQ Group deleted successfully": "常見問題組刪除成功",
  "FAQ List": "常見問題列表",
  "NO FAQ GROUP": "沒有常見問題組",
  "FAQ Group": "常見問題組",
  "Are you sure you want to delete FAQ?": "您確定要刪除常見問題嗎？",
  "FAQ deleted successfully": "常見問題刪除成功",
  "NO FAQs": "沒有常見問題",
  "FAQ": "常見問題",
  "Easy": "便利",
  "Fast Laundromate": "快速洗衣店",
  "Smart Laundry Management System": "智慧洗衣管理系統",
  "Enter your username and password to login": "請輸入登入用戶名和密碼",
  "Username": "用戶名",
  "Are you sure you want to delete news?": "您確定要刪除消息嗎？",
  "News deleted successfully": "消息刪除成功",
  "NO NEWS": "沒有消息",
  "The referral setting has been updated successfully!": "邀請碼設定已更新成功！",
  "Number of Stamp": "印章數量",
  "Number of Washer Stamp": "洗衣機印章數量",
  "Number of Dryer Stamp": "烘乾機印章數量",
  "Enable Referral Feature?": "開啟邀請碼功能嗎？",
  "Are you sure you want to delete the tier setting?": "您確實要刪除此等級設定嗎？",
  "Minimum referee transaction amount for referrer to get referral rewards": "邀請者獲得邀請獎勵的最低交易金額",
  "Maximum number of reward within this tier": "此等級內的最大獎勵數量",
  "Maximum number of reward can get from same user": "從同一用戶可獲得的最大獎勵數量",
  "Point Grant Type": "積分授予類型",
  "Select Point Grant Type": "選擇積分授予類型",
  "Token Grant Type": "電子代幣授予類型",
  "Select Token Grant Type": "選擇電子代幣授予類型",
  "Add Tier": "添加等級",
  "Stamp": "印章",
  "If ticked, the system will give stamp to user": "如果勾選，系統將會給用戶印章",
  "If ticked, the system will give e-Token to user": "如果勾選，系統將給用戶電子代幣",
  "If ticked, the system will give voucher to user": "如果勾選，系統將給用戶優惠券",
  "Number of E-Token": "電子代幣數量",
  "Reward Giveaway": "獎勵贈品",
  "Which type of reward you want to give to customer?": "您想給予客戶哪種類型的獎勵？",
  "For machine uses (required)": "用於機器用途（必選）",
  "leave blank if no expiry date": "如果沒有到期日則留空",
  "Remarks": "備註",
  "Reward": "獎勵",
  "Please fill in all the required field. (Title, description, and amount must be more than 0)": "請填寫所有必填選項。 （標題、描述、金額必須大於0）",
  "Checkbox required": "需要勾選複選框",
  "Must select voucher can be used for washer, dryer or package": "必須選擇優惠券可用於洗衣機，烘乾機或配套",
  "How many e-token?": "多少個電子代幣？",
  "Number of e-Token": "電子代幣數量",
  "Token value must be more than 0": "電子代幣金額必須大於0",
  "STAMP": "印章",
  "Please fill in at least one of the required field": "請填寫至少一個必填選項",
  "The value must be more than 0": "該值必須大於 0",
  "How many times rewarded to user": "給用戶多少次獎勵",
  "Rewarded Times": "獎勵次數",
  "How many times rewarded voucher to user": "給用戶多少次優惠券",
  "Voucher Rewarded (times)": "優惠券獎勵（次）",
  "How many times rewarded stamp to user": "給用戶多少次印章",
  "Stamps Rewarded (times)": "印章獎勵（次）",
  "Total number of washer stamps rewarded to user": "獎勵給用戶的洗衣機印章總數",
  "Washer Stamps Granted (stamp)": "給予的洗衣機印章（印章）",
  "Total number of dryer stamps rewarded to user": "獎勵給用戶的烘乾機印章總數",
  "Dryer Stamps Granted (stamp)": "給予的烘乾機印章（印章）",
  "How many times rewarded e-Token to user": "給用戶多少次電子代幣",
  "e-Token Rewarded (times)": "電子代幣獎勵（次）",
  "Total number of e-Token rewarded to user": "獎勵給用戶的電子代幣總數",
  "Total e-Token Granted (token)": "給予的電子代幣總數（電子代幣）",
  "Point Rewarded (times)": "積分獎勵（次）",
  "How many times rewarded point to user": "給用戶獎勵積分幾次",
  "Total Point Granted (point)": "總積分獎勵（積分）",
  "Total number of point rewarded to user": "獎勵給用戶的積分總數",
  "Point Amount": "積分數量",
  "Reward History": "獎勵歷史",
  "Are you sure you want to change how the stamping mechanics works?": "您確定要更改印章機制的運作方式嗎？",
  "The stamping mechanics setting has updated successfully!": "印章機制設定已更新成功！",
  "The voucher setting has been updated successfully!": "優惠券設定已更新成功！",
  "Mechanics Settings": "印章機制設置",
  "Minimum transaction amount for getting stamp": "獲得印章的最低交易金額",
  "Select User Stamping Mechanic": "選擇使用者的印章機制",
  "Separated Stamping": "分開印章",
  "Combined Stamping": "綜合印章",
  "Stamp for?": "印章方式？",
  "Both": "兩者",
  "Washer Only": "僅洗衣機",
  "Dryer Only": "僅烘乾機",
  "Separated Stamping = Different washer and dryer stamp collection. (example: Using Washer will gain 1 washer's stamp, and Dryer will gain 1 dryer stamp)": "分開印章 = 不同的洗衣機和烘乾機印章收集。 （例如：使用洗衣機將獲得1個洗衣機印章，使用烘乾機將獲得1個烘乾機印章）",
  "Combined Stamping = Washer and dryer has the same collection. (example: Using Washer or Dryer will gain 1 stamp)": "綜合印章 = 洗衣機和烘乾機有相同的印章收集。 （例如：使用洗衣機或烘乾機將可獲得1個印章）",
  "Stamp for": "印章方式",
  "User will be able to collect both washer stamp and dryer stamp": "用戶將能夠收集洗衣機印章和烘乾機印章",
  "User will only able to collect Washer stamp": "用戶只能收集洗衣機印章",
  "User will only able to collect Dryer stamp": "用戶只能收集烘乾機印章",
  "Registration": "註冊",
  "Birthday": "生日",
  "Please choose at least one payment method": "請至少選擇一種付款方式",
  "Please choose at least one member or non-member payment": "請至少選擇一位會員或非會員付款",
  "Are you sure you want to change the payment setting?": "您確定要更改付款設定嗎？",
  "The payment setting has updated successfully!": "付款設定已更新成功！",
  "Invalid input": "不正確輸入",
  "The price and token must be more than 0": "價格和電子代幣必須大於0",
  "Maximum number of purchase must be more than 1": "最多購買的次數必須大於1",
  "Point amount must be more than 1": "積分數量必須大於 1",
  "Added a new Token Bundle Package": "新增了新的電子代幣配套",
  "Bundle deleted successfully!": "配套刪除成功！",
  "Reload voucher setting have edited successfully!": "儲值優惠劵設定已編輯成功！",
  "Added on": "添加於",
  "remove": "移除",
  "edit": "編輯",
  "NO PACKAGES ON SALES": "沒有銷售配套",
  "Point Settings": "積分設定",
  "Enable Loyalty Point": "啟用積分",
  "Point Name": "積分名稱",
  "Number Of Point Equals To": "積分數量同等於",
  "If ticked, the point will have expired date": "如果勾選，積分將過期",
  "Point Lifespan": "積分壽命",
  "months": "月",
  "Minimum Transaction Amount To Get Point": "取得積分的最低交易金額",
  "Point Grant Amount": "積分贈予數量",
  "Point settings updated successfully!": "積分設定更新成功！",
  "Point Eligibility": "積分資格",
  "If ticked, the system will give point to user when user reload token successfully": "如果勾選，當用戶成功儲值電子代幣時系統會給用戶積分",
  "{{ point_amount }} point will be given after user reload token successfully": "用戶成功充值電子代幣後將獲得 {{ point_amount }} 積分",
  "Payment Settings": "付款設定",
  "Select Payment Method": "選擇付款方式",
  "Enable E-Payment?": "啟用電子支付嗎？",
  "Enable Token Currency?": "啟用電子代幣嗎？",
  "Member and Non-Member Settings": "會員和非會員設置",
  "Enable Member Payment?": "啟用會員支付？",
  "Enable Non-Member Payment?": "啟用非會員支付？",
  "Promotion Settings": "促銷設定",
  "Enable Non Member Promotion?": "啟用非會員促銷嗎？",
  "Enable Notification When Cycle Done?": "循環完成後啟用通知嗎？",
  "SMS charges": "簡訊收費",
  "SMS Settings": "簡訊設定",
  "Enable Free Sms For Members": "启用会员免费短信",
  "If enable notification, user can choose either receive email or sms when complete washing or drying": "如果啟用通知，用戶可以選擇在完成洗滌或烘乾時接收電子郵件或簡訊",
  "If user choose sms, non member have to pay extra {{ currency }}{{ sms_notification_amount }} for the sms": "如果用戶選擇短信，非會員必須支付額外的{{ currency }}{{ sms_notification_amount }}短信費用",
  "If user choose sms, member and non member have to pay extra {{ currency }}{{ sms_notification_amount }} for the sms": "如果用戶選擇短信，會員和非會員必須支付額外的{{ currency }}{{ sms_notification_amount }}短信費用",
  "Add Package": "添加配套",
  "Edit e-Token Package": "編輯電子代幣配套",
  "Add e-Token Package": "添加電子代幣配套",
  "Please only input numberic value to prevent any bugs.": "請僅輸入數字值，以防止任何錯誤。",
  "Price": "價格",
  "If ticked, the system will limit the number of purchase": "如果勾選，系統會限制購買次數",
  "Limit Number of Purchase": "限制購買次數",
  "Number of maximum purchase": "最多的購買次數",
  "Reload Voucher": "儲值優惠券",
  "If ticked, the system will give voucher to user when user reload token successfully": "如果勾選，當用戶儲值成功後，系統將會贈送使用者優惠券",
  "If ticked, the system will give voucher repeatly": "如果勾選，系統會重複贈送優惠券",
  "Recurring": "反覆",
  "Total number of voucher given to user": "給予用戶的優惠券總數",
  "Period for giving next voucher": "發放下一張優惠券的期限",
  "Token package {{ currency }}{{ price }} for {{ numberOfToken }} tokens": "{{ currency }}{{ price }} 的電子代幣配對將獲得 {{ numberOfToken }} 個電子代幣",
  "Each user only can purchase {{ max_purchase }} times for the token package": "每個用戶只能購買{{ max_purchase }}次此電子代幣配套",
  "Voucher will be given after user reload token successfully": "用戶儲值成功後將獲得優惠券",
  "Voucher will not given after user reload tokens successfully": "用戶儲值成功後將不會獲得優惠券",
  "The voucher will be expired after {{ voucher_duration }} days": "優惠券將於 {{ voucher_duration }} 天後過期",
  "The voucher will not expired": "優惠券不會過期",
  "Total {{ recurring_number }} vouchers will be given to user and each voucher will discount": "總共 {{ recurring_number }} 張優惠券將贈送給用戶，每張優惠券將有折扣",
  "Edit Package": "編輯配套",
  "Age": "年齡",
  "Total number of vouchers collected": "已領取優惠券總數",
  "Vouchers Acquired": "已獲得優惠券",
  "Total amount spent in washer machine transactions": "洗衣機交易總金額",
  "Total Washer Payment": "洗衣機總花費",
  "Total amount spent in dryer machine transactions": "烘乾機交易總金額",
  "Total Dryer Payment": "烘乾機總花費",
  "Total amount spent in all transactions": "所有交易花費的總金額",
  "Total Payment": "付款總額",
  "report": "報告",
  "Total amount spent for buying e-Token": "購買電子代幣的總金額",
  "Total number of e-Token purchased": "購買的電子代幣總數",
  "Total e-Token Purchased (Tokens)": "購買的電子代幣總數（電子代幣）",
  "Total number of e-Token used in washer machine transactions": "洗衣機交易中使用的電子代幣總數",
  "Washer Payment (Tokens)": "洗衣機付款金額（電子代幣）",
  "Total number of e-Token used in dryer machine transactions": "烘乾機交易中使用的電子代幣總數",
  "Dryer Payment (Tokens)": "烘乾機付款金額（電子代幣）",
  "Total number of e-Token used in all the transactions": "所有交易中使用的電子代幣總數",
  "Total Payment (Tokens)": "付款總金額（電子代幣）",
  "Number of washer stamp collected currently": "目前收集的洗衣機印章數量",
  "Number of dryer stamp collected currently": "目前收集的烘乾機印章數量",
  "User Phone Number / Member ID / Email": "用戶電話號碼/會員ID/電子郵件",
  "Example: 0123456789,A123456": "例如: 0123456789,A123456",
  "SEARCH": "搜尋",
  "Total Users Registered": "註冊用戶總數",
  "Total Active Outlets": "店家總數",
  "Total Vouchers Given": "給予的優惠券總數",
  "Total Transaction Counts": "總交易量",
  "Total User Stamps Collected": "用戶收集的印章總數",
  "Distributor Login": "經銷商登入",
  "Client Login": "客戶登入",
  "Kiosk Login": "自助服務終端登入",
  "Promotion": "促銷",
  "Please select operator": "請選擇營運商",
  "Please select distributor": "請選擇經銷商",
  "Select Operator": "選擇營運商",
  "Select Distributor": "選擇經銷商",
  "User can login or skip to payment without login to make the payment": "用戶可以登入或無需登入即可跳轉至付款頁面進行支付",
  "User have to login only can make the payment": "用戶必須登入才可以付款",
  "User only can skip to payment without login to make the payment": "用戶只能直接跳至付款頁面進行付款（無登入功能）",
  "Only CSV file is accepted": "僅接受 CSV 文件",
  "The user and the balance have updated": "用戶和餘額已更新",
  "Register Time": "註冊時間",
  "Upload User CSV file": "上傳用戶 CSV 文件",
  "Sample CSV file": "範例 CSV 文件",
  "Please follow the CSV file format": "請遵循 CSV 檔案格式",
  "User CSV file": "用戶 CSV 檔案",
  "Only CSV file (comma delimited) is support now": "現在僅支援 CSV 檔案（逗號分隔）",
  "Confirm uploaded data": "確認上傳的數據",
  "Operator": "營運商",
  "Distributor": "經銷商",
  "Refund By": "退款者",
  "Reward By": "獎勵者",
  "Number Of Voucher Code": "優惠券兌換碼數量",
  "Number Of Discount Code": "折扣碼數量",
  "New User Only": "僅限新用戶",
  "Minimum Transaction Amount To Redeem": "兌換最低交易金額",
  "Download": "下載",
  "Generate Voucher Code": "產生優惠券兌換碼",
  "Your voucher code is generating": "您的優惠券兌換碼正在生成",
  "Your discount code is generating": "您的折扣碼正在生成",
  "Voucher Code Settings": "優惠券兌換碼設定",
  "If ticked, the voucher code only can redeem in register page": "如果勾選，兌換碼只能在註冊頁面兌換",
  "If ticked, the user only can use the voucher after make a transaction": "如果勾選，用戶只能在交易後使用該優惠券",
  "If ticked, the user only can use the discount code after make a transaction": "如果勾選，用戶只能在交易後使用該優惠碼",
  "Expired Date Of Voucher Code": "優惠券兌換碼的失效日期",
  "Minimum Transaction Amount To Use Voucher": "使用優惠券的最低交易金額",
  "Minimum Transaction Amount To Use Code": "使用優惠碼的最低交易金額",
  "Max redemption per user in this batch voucher code": "此批次優惠券兌換碼中每位用戶的最大兌換次數",
  "Max redemption per user in this batch discount code": "此批次優惠碼中每位用戶的最大兌換次數",
  "Add Voucher": "增加優惠券",
  "Completed": "完成",
  "Voucher Code Report": "優惠券兌換碼報告",
  "Floating Token Report": "浮動電子代幣報告",
  "Floating Point Report": "浮動積分報告",
  "E-Payment Report": "電子支付報告",
  "Referral Reward History Report": "邀請碼獎勵歷史報告",
  "Redemption Date": "兌換日期",
  "Redemption Code": "兌換碼",
  "Select Redemption Date": "選擇兌換日期",
  "Select Voucher Type": "選擇優惠券類型",
  "Giveaway": "補償",
  "Select Name": "選擇名稱",
  "Amount must be more than 1": "金額必須大於1",
  "Payment method setting have updated successfully!": "付款方式設定已成功更新！",
  "Edit Payment Method": "編輯付款方式",
  "Minimum payment amount to include all the payment methods": "包含所有付款方式的最低付款金額",
  "Exclude Payment Method in Revenue Monster": "在 Revenue Monster 中排除的付款方式",
  "Welcome to": "歡迎來到",
  "Instruction": "操作說明",
  "Please select the machine": "請選擇機器",
  "Login your account": "登入您的帳戶",
  "Select the setting and make the payment": "選擇設定並付款",
  "Phone Number / Email": "電話號碼/電子郵件",
  "Reload & Transfer In": "儲值及轉入",
  "Usage & Transfer Out": "使用及轉出",
  "Usage": "使用",
  "Remaining": "剩余",
  "Gain": "獲得",
  "Type": "類型",
  "Order Date": "日期",
  "Pack Number": "包裹號碼",
  "Pack Details": "包裹詳情",
  "Size": "尺寸",
  "Mode": "模式",
  "Machine Payment": "機器支付總額",
  "DNG History": "DNG歷史",
  "Token History": "電子代幣歷史記錄",
  "Stamp History": "印章歷史記錄",
  "Staff Login": "員工登入",
  "Drop And Go Portal": "Drop And Go Portal",
  "Available Task": "可執行任務",
  "Completed Task": "已完成任務",
  "Task ID": "任務ID",
  "Start Task": "開始任務",
  "Select Machine": "選擇機器",
  "Start Machine": "啟動機器",
  "Reward To": "獎勵給",
  "Reward From": "獎勵來自",
  "Transaction Account": "交易帳戶",
  "Point": "積分",
  "POINT": "積分",
  "Number of Point": "積分數量",
  "Point History": "積分歷史",
  "Customer Name": "客戶姓名",
  "Number of Washer Transactions": "洗衣交易數量",
  "Number of Dryer Transactions": "烘乾交易數量",
  "Vouchers Redeemed": "兌換的優惠券",
  "Discount Code Redemption": "折扣碼兌換",
  "Total Spend": "總支出",
  "Select Outlet(s)": "選擇店鋪",
  "User Usage Report": "用戶使用報告",
  // cleanpro plus zh
  "Wash": "洗滌",
  "Dry": "烘乾",
  "Fold": "折疊",
  "Scan QR": "掃描二維碼",
  "Capacity": "容量",
  "Duration": "時長",
  "Custom duration": "自定義持續時間",
  "Choose duration": "選擇持續時間",
  "Select the correct amount on the machine after payment.": "付款後在機器上選擇正確的金額。",
  "Offers": "優惠",
  "An easier way to pay": "更簡單的支付方式",
  "Create account": "創建賬戶",
  "Login": "登錄",
  "By signing up, you agree to": "註冊即表示您同意",
  "Amount to Pay": "支付總額",
  "1 voucher selected": "已選擇 1 張代金券",
  "No voucher selected": "未選擇代金券",
  "credit": "點數",
  "Credits": "點數",
  "credits": "點數",
  "Credit balance": "點數餘額",
  "Please ensure that the machine door is shut & your laundry is inside.": "請確保機器門已關閉並且您的衣物在裡面。",
  "Insufficient balance": "餘額不足",
  "Topup now": "立即充值",
  "Copyright © 2023 Cleanpro Laundry Holdings Sdn Bhd (821842-P) All rights reserved.": "版權所有 © 2023 Cleanpro Laundry Holdings Sdn Bhd (821842-P) 保留所有權利。",
  "Good Evening!": "午安!",
  "Hello": "你好",
  "Wallet Balance": "錢包餘額",
  "Topup": "充值",
  "More details": "更多細節",
  "Collect 10 e-stamps to earn x1 FREE wash or dry voucher": "集齊 10 個電子印章即可獲得 x1 免費洗或乾代金券",
  "For every wash or dry cycle you complete with us, you'll earn one e-stamp. Collect 10 and you'll be able to redeem a FREE wash or dry cycle. It's our way of saying thank you for choosing Cleanpro!": "您在我們這裡完成的每一次洗滌或烘乾循環，都將獲得一個電子印章。集齊 10 個，您就可以兌換一次免費的洗滌或烘乾週期。這是我們感謝您選擇 Cleanpro 的方式！",
  "For every wash cycle you complete with us, you'll earn one e-stamp. Collect 10 and you'll be able to redeem a FREE wash or dry cycle. It's our way of saying thank you for choosing Cleanpro!": "您在我們這裡完成的每一次洗滌循環，都將獲得一個電子印章。集齊 10 個，您就可以兌換一次免費的洗滌或烘乾週期。這是我們感謝您選擇 Cleanpro 的方式！",
  "For every dry cycle you complete with us, you'll earn one e-stamp. Collect 10 and you'll be able to redeem a FREE wash or dry cycle. It's our way of saying thank you for choosing Cleanpro!": "您在我們這裡完成的每一次烘乾循環，都將獲得一個電子印章。集齊 10 個，您就可以兌換一次免費的洗滌或烘乾週期。這是我們感謝您選擇 Cleanpro 的方式！",
  "Credits OFF": "點數折扣",
  "% OFF": "％ 折扣",
  "Valid Till": "有效期至",
  "Used On": "用於",
  "Expired On": "過期日期",
  "Available": "可用的",
  "Expired": "已過期",
  "Used": "已使用",
  "Promotions": "促銷活動",
  "Rewards": "獎勵",
  "Inbox": "收件箱",
  "Activities": "活動",
  "Support": "支持",
  "Active Rewards": "活躍獎勵",
  "Past Rewards": "過往獎勵",
  "Get rewarded for your loyalty with Cleanpro Plus Rewards!": "通過 Cleanpro Plus 獎勵獲得忠誠獎勵！",
  "Earn vouchers and redeem them for discounts, freebies and more. The more you use our services the more you save!": "賺取代金券並將其兌換為折扣、免費贈品等。您使用我們的服務越多，您節省的越多！",
  "Up to 3 months history": "最近3個月的歷史記錄",
  "Filter": "篩選",
  "All Transactions": "所有交易",
  "Transactions": "交易",
  "Select amount to topup": "選擇充值金額",
  "Balance": "餘額",
  "You've just paid": "你剛剛付款",
  "Need help?": "需要幫忙？",
  "24-Hour Careline Number": "24 小時服務熱線號碼",
  "Email Us": "給我們發電子郵件",
  "Call Now": "現在打電話",
  "Email Now": "立即發送電子郵件",
  "Update name": "更新名稱",
  "Update email address": "更新電子郵件地址",
  "Update phone number": "更新電話號碼",
  "Change password": "更改密碼",
  "Change language": "改變語言",
  "User ID": "用戶號",
  "Let us know your feedback": "讓我們知道您的反饋",
  "Poor": "糟糕",
  "Good": "好的",
  "Leave addtional feedback here (optional)": "在此處留下其他反饋（可選)",
  "Submit feedback": "提交反饋",
  "Pay as guest": "以訪客身份付款",
  "Pay now": "現在付款",
  "Select voucher to use": "選擇要使用的代金券",
  "Use voucher": "使用代金券",
  "Total": "總共",
  "Transaction Type": "交易類型",
  "Credit Topup": "點數充值",
  "Credit topup": "點數充值",
  "Credit Transfer": "點數轉賬",
  "Date/Time": "日期/時間",
  "Reference ID": "參考編號",
  "Status": "狀態",
  "Share Your Referral Code": "分享您的邀請碼",
  "cleanproReferralShortDesc": "將您的邀請碼分享給親朋好友，當完成交易後可免費獲得 {{ voucherAmount }} 積分。點擊以了解更多信息。",
  "Use": "使用",
  "dryer or washer": "烘乾機或洗衣機",
  "washer": "洗衣機",
  "dryer": "烘乾機",
  "dryer and washer": "烘乾機和洗衣機",
  "machine with the minimum spending of": "以最低消費",
  "Welcome to Cleanpro Plus": "歡迎來到 Cleanpro Plus",
  "Only scan, set & pay! An easier, more convenient way to pay with Cleanpro Plus! No need to download an app. Enjoy more rewards when you use Cleanpro Plus today! Tap to learn more.": "只需掃描、設置和支付！使用 Cleanpro Plus 支付更簡單、更方便的方式！無需下載應用程序。立即使用 Cleanpro Plus，享受更多獎勵！點擊以了解更多信息。",
  "Cleanpro Pay Migration Notice": "Cleanpro Pay 遷移通知",
  "Dear valued Cleanpro customers, please be informed that as of 22 June 2023, the Cleanpro Pay app will be upgraded and and no longer available for use. Tap to learn more.": "尊敬的 Cleanpro 客戶，請注意，自 2023 年 6 月 22 日起，Cleanpro Pay 應用程序將升級並不再可用。點擊以了解更多信息。",
  "Answer simple questions and win credits for your next wash or dry! This contest is only available for selected outlets only. Tap to learn more.": "回答簡單的問題並為您的下一次洗滌或烘乾贏得點數！此競賽僅適用於指定的商店。點擊以了解更多信息。",
  "The all-new Cleanpro Plus is a new payment method that includes loyalty features built into the app. The more you use our services and pay using Cleanpro Plus, the more you can save. With every transaction, you can earn rewards that will help you save on your next laundry expenses.": "全新的 Cleanpro Plus 是一種新的支付方式，包括內置於應用程序中的忠誠度功能。您使用我們的服務和使用 Cleanpro Plus 支付的次數越多，您節省的費用就越多。每筆交易，您都可以獲得獎勵，幫助您節省下一次洗衣費用。",
  "To use Cleanpro Plus, simply scan the QR code on the machines and pay using your preferred credit/debit card or e-wallet. We hope that Cleanpro Plus will help simplify your laundry routine and give you more time for the things that matter.": "要使用 Cleanpro Plus，只需掃描機器上的二維碼並使用您首選的信用卡/借記卡或電子錢包付款。我們希望 Cleanpro Plus 能夠幫助簡化您的洗衣程序，讓您有更多時間處理重要的事情。",
  "If you have any questions about Cleanpro Plus, please read our FAQ in the menu or contact our careline at +603 2770 0100 or email us at info@cleanprolaundry.com. Thank you for choosing Cleanpro.": "如果您對 Cleanpro Plus 有任何疑問，請閱讀菜單中的常見問題解答或致電 +603 2770 0100 聯繫我們的客服熱線或發送電子郵件至 info@cleanprolaundry.com。感謝您選擇 Cleanpro。",
  "Dear valued Cleanpro customers, please be informed that as of 22 June 2023, the existing Cleanpro Pay mobile app will be upgraded and no longer available for use.": "尊敬的 Cleanpro 客戶，請注意，自 2023 年 6 月 22 日起，現有的 Cleanpro Pay 移動應用程序將升級並不再可用。",
  "We are migrating to the all-new Cleanpro Plus. It is a new cashless payment method, without the need to download apps.": "我們正在遷移到全新的 Cleanpro Plus。這將是一種新的無現金支付方式，無需下載應用程序。",
  "If you are the existing user of Cleanpro Pay, any of your remaining balance in the app will be transferred to your Cleanpro Plus account. You are required to use the same mobile number to sign up for Cleanpro Plus for balance transfer.": "如果您是 Cleanpro Pay 的現有用戶，您在應用程序中的任何餘額都將轉移到您的 Cleanpro Plus 帳戶。您需要使用相同的手機號碼註冊 Cleanpro Plus 進行餘額轉移。",
  "If you need help on Cleanpro Plus, please feel free to call our careline at +603 2770 0100 or email us at info@cleanprolaundry.com": "如果您需要有關 Cleanpro Plus 的幫助，請隨時撥打我們的服務熱線 +603 2770 0100 或發送電子郵件至 info@cleanprolaundry.com",
  "Answer simple questions and win credits for your next wash or dry!": "回答簡單的問題並為您的下一次洗滌或烘乾贏得點數！",
  "Step 1 : Scan the Jawab & Gerenti Menang QR code in the participated outlets.": "第 1 步：在指定的商店裡掃描Jawab & Gerenti Menang 二維碼。",
  "Step 2 : Answer 3 simple questions.": "第 2 步：回答 3 個簡單問題。",
  "Step 3 : Submit and stand a chance to win prizes.": "第 3 步：提交並有機會贏取獎品。",
  "Grand Prize :": "大獎：",
  "x10 per outlet - 100 credits worth of vouchers in Cleanpro Plus": "每個分店 x10 - Cleanpro Plus 中的 RM100 點數優惠劵",
  "Consolation Prize :": "安慰獎：",
  "X25 per outlet - 50 credits worth of vouchers in Cleanpro Plus": "每個分店 X25 - Cleanpro Plus 中的 RM50 點數優惠劵",
  "Participation prize:": "參與獎：",
  "Every participants are eligible to receive a FREE 3 credits voucher (worth RM3) in Cleanpro Plus.": "每位參與者都有資格在 Cleanpro Plus 中免費獲得 3 個點數代金券（價值 RM3）。",
  "If you have any questions please don't hesitate to contact our careline at +603 2770 0100 or email us at info@cleanprolaundry.com. Thank you for choosing Cleanpro.": "如果您有任何疑問，請隨時撥打我們的服務熱線 +603 2770 0100 或發送電子郵件至 info@cleanprolaundry.com。感謝您選擇 Cleanpro。",
  "Payment Confirmation": "付款確認",
  "This campaign is only eligible for users who use the all-new Cleanpro Plus web application.": "此活動僅適用於使用全新 Cleanpro Plus 網絡應用程序的用戶。",
  "ONE e-stamp will be rewarded for each wash or dry cycle.": "每次洗滌或乾燥循環將獎勵一個電子印章。",
  "ONE e-stamp will be rewarded for each wash cycle.": "每個洗滌循環將獎勵一個電子印章。",
  "ONE e-stamp will be rewarded for each dry cycle.": "每個乾燥循環將獎勵一個電子印章。",
  "Regardless of the washer or dryer settings, the number of e-stamps being collected is the same.": "無論洗衣機或烘乾機設置如何，收集的電子印章數量都是相同的。",
  "The type of e-stamp collected is based on the services that you have used.": "您所收集的電子印章種類取決於你所使用的服務而定。",
  "Washing service will reward washer e-stamp and drying service will reward dryer e-stamp.": "洗滌服務將獎勵洗衣機電子印章，烘乾服務將獎勵烘乾機電子印章。",
  "Washing service will reward washer e-stamp.": "洗滌服務將獎勵洗衣機電子印章。",
  "Drying service will reward dryer e-stamp.": "烘乾服務將獎勵烘乾機電子印章。",
  "E-stamps will automatically be added to your account once the condition has been fulfilled.": "滿足條件後，電子印章將自動添加到您的帳戶中。",
  "There is no limit on how many e-stamps can be gathered in a single day. So long as you meet the requirements to collect the e-stamps.": "只要您符合收集電子印章的條件，您將獲得電子印章，一天內無收集電子印章數量限制",
  "The type of e-stamps that have been collected is final and cannot be changed.": "已收集的電子印章類型是最終的，不能更改。",
  "There is no expiration for the e-stamps; only till the discontinuation of the campaign.": "電子印章不會過期；直到活動停止為止。",
  "E-stamps do not have any currency value, and they cannot be converted to cash or credits.": "電子印章沒有任何貨幣價值，也不能兌換成現金或點數。",
  "This campaign is not applicable with any other ongoing promotions, offers, vouchers, and/or discounts provided by the participating outlets.": "此活動不適用於參與商店提供的任何其他正在進行的促銷、優惠、代金券和/或折扣。",
  "These terms and conditions are subject to change at any time without prior notice.": "這些條款和條件可能隨時更改，恕不另行通知。",
  "Press START on the machine to begin washing.": "按機器上的START開始洗滌。",
  "Press START on the machine to begin drying.": "按機器上的START開始烘乾。",
  "Reminder": "提醒",
  "Remember to select the same settings you have paid for on the corresponding machine.": "請記住在相應的機器上選擇您已支付的相同設置。",
  "Please ensure that your laundry is inside the machine and door is properly shut.": "請確保您的衣物在機器內並且門已正確關閉。",
  "voucherCreditsOff": "折扣 {{currentVoucherAmount}} 點數",
  "voucherPercentageOff": "折扣 {{currentVoucherAmount}}%",
  "cleanproReferralFlatVoucher": "將您的 Cleanpro Plus 邀請碼分享給您的朋友和家人，即可獲得免費優惠劵！對於每個完成第一筆交易的成功推薦，您將獲得一張價值折扣 {{ referralVoucherAmount }} 貨幣的優惠劵。",
  "cleanproReferralRateVoucher": "將您的 Cleanpro Plus 邀請碼分享給您的朋友和家人，即可獲得免費優惠劵！對於每個完成第一筆交易的成功推薦，您將獲得一張價值 {{ referralVoucherAmount }}% 折扣的優惠劵。",
  "cleanproReferralTokenReward": "將您的 Cleanpro Plus 邀請碼分享給您的朋友和家人，即可獲得免費貨幣！對於每個完成第一筆交易的成功推薦，您將獲得免費的 {{ referralToken }} 貨幣。",
  "How to get & use code:": "如何獲取和使用邀請碼：",
  'Go to menu = icon and click “Refer friends”': '在菜單 = 圖標，然後單擊“推薦給朋友”',
  "Share with everyone who will likely to use Cleanpro Plus.": "與所有可能使用 Cleanpro Plus 的人分享。",
  "Referral Terms & Conditions:": "推薦條款和條件：",
  "When your friend or family register using your referral code, and completed a transaction (a minimum spending of {{ currency }}{{ referralTransactionAmount }}) , you will be rewarded automatically.": "當您的朋友或家人使用您的邀請碼註冊並完成交易（最低消費 {{ currency }}{{ referralTransactionAmount }}）時，您將自動獲得獎勵。",
  "cleanproNumberOfReferralReward": "您可以與任意數量的人分享，但每個用戶只能獲得 {{ numberOfReferral }} 次推薦獎勵。",
  "cleanproNumberOfReferral": "您可以與任意數量的人分享，但每個用戶只能收到 {{ numberOfReferral }} 張推薦優惠券。",
  "The voucher is valid for {{ duration }} days from the day voucher be given.": "優惠券自發放之日起 {{ duration }} 天內有效。",
  "The voucher is for customer use only and not for sale.": "優惠券僅供客戶使用，不得出售。",
  "We believe that your special day deserves a little extra sparkle! That's why we're thrilled to introduce our exclusive birthday rewards program just for you! Starting this year, and every year thereafter, we're giving away {{ currency }}{{ voucherAmount }} credit vouchers to you on your birthday.": "我們相信您的特殊日子值得額外閃耀！這就是為什麼我們很高興為您推出獨家生日獎勵計劃！從今年開始，之後的每一年，我們都會在您生日月份第一天贈送 {{ currency }}{{ voucherAmount }} 優惠券。",
  "cleanproBirthdayRateAmount": "我們相信您的特殊日子值得額外閃耀！這就是為什麼我們很高興為您推出獨家生日獎勵計劃！從今年開始，之後的每一年，我們都會在您生日月份第一天贈送 {{ voucherAmount }}% 優惠券。",
  "Make sure your account details include your birthdate. This way, we'll know exactly when to send you your special birthday surprise.": "請確保您的賬戶已填寫詳細信息包括您的生日。這樣，我們就能準確知道何時向您發送特別的生日驚喜。",
  "Birthday Voucher Terms & Conditions:": "生日禮券條款和條件：",
  "The voucher will be given on the first day of your birthday month.": "優惠券將在您生日月份的第一天發放。",
  "When you applied the voucher during payment, it will give you {{ voucherAmount }} credits ({{ currency }}{{ voucherAmount }}) discount.": "當您在付款時使用優惠劵，它會給您 {{ voucherAmount }} 貨幣（{{ currency }}{{ voucherAmount }}）折扣。",
  "When you applied the voucher during payment, it will give you {{ voucherAmount }}% discount.": "當您在付款時使用優惠劵，它會給您 {{ voucherAmount }} %折扣。",
  "All vouchers is not valid with other ongoing promotions, discounts and offers.": "所有優惠券均不適用於當正在進行的促銷、折扣和優惠。",
  "Claim a complimentary wash and dry for every 10 e-stamps collected! We value your loyalty and want to reward you for choosing Cleanpro Plus. Tap to learn more.": "每收集 10 張電子印章，即可獲得免費清洗和烘乾服務！我們重視您的忠誠度，並希望獎勵您選擇 Cleanpro Plus。點擊以了解更多信息。",
  "cleanproStampFlat": "每收集 10 個電子印章，您就可以使用 Cleanpro Plus 申請高達 {{ voucherAmount }} 的免費洗或烘乾貨幣。讓您的洗衣程序不僅輕鬆無憂，而且收穫頗豐。",
  "cleanproStampRate": "每收集 10 個電子印章，您就可以使用 Cleanpro Plus 申請高達 {{ voucherAmount }}% 的折扣。讓您的洗衣程序不僅輕鬆無憂，而且收穫頗豐。",
  "A voucher will be instantly added to your Rewards page when you reach 10 washes. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.": "當您收集到 10 個洗滌印章時，優惠券將立即添加到您的獎勵頁面。您的電子印章計價器將在下次收集時歸零。下次洗衣時，在掃描二維碼頁面領取優惠券。",
  "A voucher will be instantly added to your Rewards page when you reach 10 drys. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.": "當您收集到 10 個烘乾印章時，優惠券將立即添加到您的獎勵頁面。您的電子印章計價器將在下次收集時歸零。下次洗衣時，在掃描二維碼頁面領取優惠券。",
  "A voucher will be instantly added to your Rewards page when you reach 10 washes or drys. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.": "當您收集到 10 個洗滌或10個烘乾印章時，優惠券將立即添加到您的獎勵頁面。您的電子印章計價器將在下次收集時歸零。下次洗衣時，在掃描二維碼頁面領取優惠券。",
  "Terms & Conditions:": "條款和條件：",
  "To earn ONE e-stamp, you must spend at least {{ stampTransactionAmount }} credits ({{ currency }}{{ stampTransactionAmount }}) per session.": "要賺取一個電子印章，您必須在每次會話中花費至少 {{ stampTransactionAmount }} 貨幣（{{ currency }}{{ stampTransactionAmount }}）。",
  "You will be automatically issued a voucher if you have collected 10 washer stamps.": "如果您收集了 10 張洗衣機印章，您將自動獲得一張優惠券。",
  "You will be automatically issued a voucher if you have collected 10 dryer stamps.": "如果您收集了 10 張烘乾機印章，您將自動獲得一張優惠券。",
  "You will be automatically issued a voucher if you have collected 10 dryer stamps or 10 washer stamps.": "如果您收集了 10 張洗衣機或10 張烘乾機印章，您將自動獲得一張優惠券。",
  "You will be automatically issued a voucher if you have collected 10 stamps.": "如果您收集了 10 張印章，您將自動獲得一張優惠券。",
  "The voucher is non-refundable and cannot be exchanged for cash; in part or in full.": "此禮券不可退款，亦不可兌換現金；部分或全部。",
  "This voucher is valid for single transaction only.": "此優惠劵僅適用於單次交易。",
  "General questions": "常見問題",
  "E-stamps collection": "電子印章",
  "Coin exchange machines": "硬幣兌換機",
  "Washer machines": "洗衣機",
  "Dryer machines": "烘乾機",
  "Paywave issues": "Paywave問題",
  "Cleanpro franchise": "Cleanpro特許經營權",
  "How can I wash my laundry using Cleanpro Plus?": "如何使用 Cleanpro Plus 洗滌衣物？",
  "Here are the steps to wash your laundry using Cleanpro Plus:": "以下是使用 Cleanpro Plus 洗滌衣物的步驟：",
  "Find an unoccupied washing machine in the store, and insert your laundry in the washer.": "在商店裡找一台無人使用的洗衣機，然後將要洗的衣服放入洗衣機。",
  "Select the desired temperature that you would like to use for your laundry.": "選擇您想用於洗衣的所需溫度。",
  "Using the scanner in the app, scan the QR code provided on the washing machine. Then select the same settings that you have pressed on the machine.": "使用應用程序中的掃描儀，掃描洗衣機上提供的二維碼。然後選擇您在機器上按下的相同設置。",
  'Tap on “Pay with credits” to pay the amount shown on the app.': '點擊“使用點數支付”以支付應用程序上顯示的金額。',
  "Once the payment has been accepted, press the START button on the washer machine.": "付款被接受後，按下洗衣機上的開始按鈕。",
  "After the washing cycle ends, you can just remove your laundry from the machine.": "洗滌週期結束後，您只需將衣物從機器中取出即可。",
  "How can I dry my laundry using Cleanpro Plus?": "如何使用 Cleanpro Plus 烘乾衣物？",
  "Here are the steps to dry your laundry using Cleanpro Plus:": "以下是使用 Cleanpro Plus 烘乾衣物的步驟：",
  "Find an unoccupied drying machine in the store, and insert your laundry in the dryer.": "在商店裡找一台無人使用的干衣機，然後將要烘乾的衣服放入乾衣機中。",
  "Select the desired temperature and duration that you would like to use for your laundry.": "選擇您想用於洗衣的所需溫度和持續時間。",
  "Using the scanner in the app, scan the QR code provided on the drying machine. Then select the same settings that you have pressed on the machine.": "使用應用程序中的掃描儀，掃描乾衣機上提供的二維碼。然後選擇您在機器上按下的相同設置。",
  "Once the payment has been accepted, press the START button on the dryer machine.": "付款被接受後，按下乾衣機上的開始按鈕。",
  "After the drying cycle ends, you can just remove your laundry from the machine.": "烘乾週期結束後，您只需將衣物從機器中取出即可。",
  "Why does the QR code I scanned take me to a different machine?": "為什麼我掃描的二維碼會將我帶到另一台機器上？",
  "Here are the steps to solve it:": "以下是解決它的步驟：",
  "Make sure that the number on the QR code (e.g. W1, W2, etc) is the same as the machine that you would like to use.": "確保二維碼上的數字（例如 W1、W2 等）與您要使用的機器相同。",
  "If the QR code number does not match the machine you want, use the other machine. Please contact our customer service line at 03-27700100 to report this issue.": "如果 QR 代碼編號與您想要的機器不匹配，請使用其他機器。請聯繫我們的客戶服務熱線03-27700100報告此問題。",
  "How can I add more time to the washer that I am currently using?": "如何為當前使用的洗衣機增加更多使用時間？",
  "The washing time for our washer is fixed and cannot add any additional time.": "我們洗衣機的洗滌時間是固定的，不能增加任何額外的時間。",
  "How can I add more time to the dryer that I am currently using?": "如何為當前使用的干衣機增加更多使用時間？",
  "Here are the steps to add more time to the dryer machine:": "以下是為烘乾機增加更多時間的步驟：",
  "By using the scanner in the app, scan the QR code provided at the dryer machine.": "使用應用程序中的掃描儀，掃描乾衣機上提供的二維碼。",
  "Select the amount of time that you would like to add to the machine.": "選擇您要增加的時間到機器上。",
  "After the drying cycle ends, you can just remove the clothes from the machine.": "烘乾週期結束後，您可以將衣物從機器中取出。",
  "REMINDER - It is recommended to add drying time 5 minutes before the cycle ends. And you cannot add tokens for extra times when the machine finishes the drying cycle.": "提醒 - 建議在循環結束前 5 分鐘增加干燥時間。當機器完成乾燥週期時，您不能增加額外時間。",
  "Will the timer keep running when I have stopped the drying machine?": "當我停止烘乾機時，定時器會繼續運行嗎？",
  "Yes. Even after you stop the machine, the timer will continue to run. If you want to extend the drying time, add drying time 5 minutes before the cycle ends. This prevents the cycle from ending before your clothes are completely dried.": "是的。即使在您停止機器後，計時器仍會繼續運行。如果您想延長干燥時間，請在循環結束前 5 分鐘增加干燥時間。這可以防止您的衣服完全乾燥之前結束烘乾。",
  "How do I reload my credits for Cleanpro Plus?": "我如何為 Cleanpro Plus 充值？",
  "Here are the steps to reload your credits:": "以下是充值點數的步驟：",
  'Select the "Top Up Credit" button on the homepage.': '選擇主頁上的“充值”按鈕。',
  "Choose the amount you want to reload and the payment method you want to use.": "選擇您要充值的金額和您要使用的付款方式。",
  "You will be taken to the payment page. The screen will return to Cleanpro Plus once the transaction is completed.": "您將被帶到付款頁面。交易完成後，屏幕將返回 Cleanpro Plus。",
  "How many credits can I reload in one transaction?": "我可以在一次交易中充值多少點數？",
  "Reloading credits requires a minimum of RM 10 and a maximum of RM 50.": "充值點數需要最低 RM 10 和最高 RM 50。",
  "Can I pay using credits in the Cleanpro Plus app along with tokens or coins in a single transaction?": "我可以在單筆交易中使用 Cleanpro Plus 應用程序中的點數以及代幣或硬幣進行支付嗎？",
  "No. Only a single payment method may be chosen when performing any transactions.": "不能。進行任何交易時只能選擇一種付款方式。",
  "The amount I have reloaded in my account was not reflected in the app.": "我在我的賬戶中充值的金額沒有反映在應用程序中。",
  "Please close and restart the application and check if the new balance has been updated.": "請關閉並重新啟動應用程序並檢查新余額是否已更新。",
  "If the amount is still not updated, please contact our customer service line at 03-27700110 to receive further assistance.": "如果金額仍未更新，請聯繫我們的客戶服務熱線03-27700110以獲得進一步的幫助。",
  "Can I cash out the credits I reloaded in my account for this app?": "我可以兌現我為這個應用程序中充值的點數嗎？",
  "No. The credits that you have reloaded will remain in your Cleanpro Plus account.": "不能。您充值的點數將保留在您的 Cleanpro Plus 賬戶中。",
  "I have requested a refund, but I still have not received it.": "我已申請退款，但仍未收到。",
  "Please contact our customer service line at 03-27700100 to receive further assistance.": "請聯繫我們的客戶服務熱線03-27700100以獲得進一步的幫助。",
  "I have made the payment, and the app double or triple charged me.": "我已經付款，但應用程序向我收取了兩倍或三倍的費用。",
  "The washing machine cannot start even after I have paid using the app.": "使用應用程序付款後洗衣機無法啟動。",
  "Ensure that the temperature settings you have selected and paid for on the application are the same as what you have pressed on the washing machine. If not, select the correct temperature settings on the washing machine and try again.": "確保您在應用程序上選擇和支付的溫度設置與您在洗衣機上按下的溫度設置相同。如果不是，請在洗衣機上選擇正確的溫度設置並重試。",
  "If the machine is still not able to be started, please contact our customer service line at 03-27700100 to receive further assistance.": "若機器仍無法啟動，請聯絡我們的客服熱線03-27700100以獲得進一步協助。",
  "Can I use multiple vouchers together in the same washing or drying cycle?": "我可以在同一個洗滌或烘乾程序中同時使用多張優惠券嗎？",
  "No. Only a single voucher can be used for each washing and drying cycle.": "不能。每個洗滌和烘乾週期只能使用一張優惠券。",
  "Why can't I use my free wash voucher that I received after signing up?": "為什麼我不能使用註冊後收到的免費洗滌券？",
  "To redeem or use the voucher, you must complete at least one washing or drying cycle.": "要兌換或使用優惠券，您必須至少完成一次洗滌或烘乾程序。",
  "Can I change my phone number even after registering my account?": "我可以在註冊賬戶後更改我的電話號碼嗎？",
  "No. Once registered to an account, the phone number is locked into the account.": "不能。一旦註冊到帳戶，電話號碼就會被鎖定到該賬戶中。",
  "I have problems in the store, and who can I refer to for help?": "我在店裡遇到問題，我可以向誰尋求幫助？",
  "Do not worry, as you can contact our customer service line at 03-27700100 to receive assistance at any time.": "別擔心，您可以隨時撥打我們的客服熱線03-27700100尋求幫助。",
  "Should I add my own laundry detergent and softeners to the washing machine?": "我應該在洗衣機中添加自己的洗衣液和柔順劑嗎？",
  "There is no need for that, as all you have to do is bring your dirty clothes and put them in the washer. The detergent and softeners will be provided by us, and you can rest assured that the laundry detergents we used are certified industrial grade.": "沒有必要，因為您只需帶上髒衣服並將它們放入洗衣機即可。洗滌劑和柔順劑由我們提供，您可以放心，我們使用的洗衣液都是經過認證的工業級洗滌劑。",
  "I would like to add my own laundry detergent and softener to the washing machine.": "我想在洗衣機中添加自己的洗衣液和柔順劑。",
  "We do not recommend our customers add their own detergent, as the mixture of different detergents may affect the wash quality. And you can rest assured that the laundry detergent we used is certified industrial grade.": "我們不建議客戶自行添加洗滌劑，因為不同洗滌劑的混合可能會影響洗滌質量。您可以放心，我們使用的洗衣液是經過認證的工業級洗滌劑。",
  "What time does the store close?": "商店幾點關門？",
  "Our self-service laundry operation is open 24 hours a day, which means you can always be sure to go back home with a fresh set of laundry.": "我們的自助洗衣店全天 24 小時開放，這意味著您回家時一定可以洗乾淨一整套新衣服。",
  "Are you open on weekends?": "你們週末營業嗎？",
  "Yes. Cleanpro is also open on weekends and even on public holidays.": "是的。 Cleanpro 在周末甚至公共假期也營業。",
  "How many clothes can I fit in the washing or drying machine?": "我可以在洗衣機或烘乾機中放入多少件衣服？",
  "To ensure that your laundry is properly washed or dried, keep the amount of laundry inside the machine lower than the line of guide sticker on the machine.": "為確保您的衣物得到正確洗滌或烘乾，請使機器內的衣物量低於機器上的引導標籤線。",
  "How many e-stamps can I collect in a single day?": "我一天可以收集多少個電子印章？",
  "There is no limit to the amount of stamps that can be collected in a single day; ONE stamp will be awarded with every wash or dry.": "一天內可以收集的印章數量沒有限制；每次洗滌或烘乾都會獲得一枚印章。",
  "When I use the washing service, can I change the collected stamp to a dryer stamp, and vice versa?": "當我使用洗滌服務時，我可以將收集的印章換成烘乾機印章，反之亦然嗎？",
  "No, the type of stamp collected is only applied to the service that you have used.": "不能，所收集的印章類型只適用於您使用過的服務。",
  "What temperatures can I choose when I claim the free washing e-stamp reward?": "領取免費洗印章獎勵時，我可以選擇什麼溫度？",
  "Any washing temperature is available for the e-stamp reward.": "任何洗滌溫度均可獲得電子印章獎勵。",
  "What temperatures can I choose when I claim the free drying e-stamp reward? And how long can I set the time?": "領取免費烘乾印章獎勵時，我可以選擇什麼溫度？我可以設置多長時間？",
  "Any drying temperature and duration can be selected for the e-stamp reward.": "可以選擇任何干燥溫度和持續時間作為電子印章獎勵。",
  "I'm unable to change the coin.": "我無法兌換硬幣。",
  "What colour is the light shown on the machine, red or green? If it is a red light, please contact our customer service line at 03-27700100 to receive further assistance.": "機器上顯示的燈是什麼顏色，紅色還是綠色？如果是紅燈，請聯繫我們的客戶服務熱線03-27700100以獲得進一步的幫助。",
  "Green Light": "綠燈",
  "Red Light": "紅燈",
  "Machine functions as normal. Please try again.": "機器功能正常。請再試一次。",
  "(flashing 2x) - There are no coins/tokens in the machine.": "（閃爍 2 次）- 機器中沒有硬幣/代幣。",
  "OR": "或者",
  "Note is jammed, please proceed to contact customer service": "紙幣卡紙，請聯繫客服",
  "(flashing 3x) - Note is jammed, please proceed to contact customer service": "（閃爍 3 次）- 紙幣卡住，請繼續聯繫客戶服務",
  "My notes are stuck in the machine.": "我的紙幣卡在機器裡了。",
  "My detergent is not dispensing in the vending machine.": "我的洗滌劑沒有在自動售貨機中分配。",
  "The water is leaking out of the washing machine.": "水從洗衣機中漏出。",
  "Please check the washer machine to see whether there are any clothes that are stuck at the door side.": "請檢查洗衣機是否有衣服卡在門邊。",
  "If yes, here are the steps to solve it:": "如果是，這裡是解決它的步驟：",
  "Press the emergency stop button (RED Button) for 3 seconds.": "按下緊急停止按鈕（紅色按鈕）3 秒鐘。",
  "Wait for the sound to stop, and then only open the door. (If after 30 minutes the sound still has not stopped, please contact our customer service line at 03-27700100 to receive further assistance)": "等聲音停止，才打開門。 (若30分鐘後聲音仍未停止，請聯絡我們的客服熱線03-27700100以獲得進一步協助)",
  "Ensure the door is closed tightly, and press the START button.": "確保門緊閉，然後按下啟動按鈕。",
  "REMINDER - The emergency stop button can only be used once.": "提醒 - 緊急停止按鈕只能使用一次。",
  "There is no detergent dispensing in the washing machine.": "洗衣機中沒有洗滌劑配給。",
  "Ensure that the machine is in operation and that the START button was pressed.": "確保機器正在運行並且按下了啟動按鈕。",
  "If the detergent is still not dispensing, please contact our customer service department at 03-27700100 for further assistance.": "如果洗滌劑仍未配出，請致電03-27700100與我們的客戶服務部聯繫以獲得進一步的幫助。",
  "The price displayed on the machine's screen did not change after inserting the token.": "插入硬幣後，機器屏幕上顯示的價格沒有變化。",
  "Press the small button (in the centre) to reject the tokens that have been inserted. Press a few times till the coins come out.": "按小按鈕（在中心）拒絕已插入的硬幣。按幾次直到硬幣出來。",
  "If the coins still cannot come out or the button is not functioning, please contact our customer service line at 03-27700110 to receive further assistance.": "若仍無法出幣或按鈕無法使用，請聯絡我們的客服熱線03-27700110以獲得進一步協助。",
  "The machine is not starting, and it displays an error code.": "機器未啟動，並顯示錯誤代碼。",
  "We would like to apologise for any inconvenience caused. Please contact our customer service line at 03-27700100 to receive further assistance.": "對於由此造成的任何不便，我們深表歉意。請聯繫我們的客戶服務熱線03-27700100以獲得進一步的幫助。",
  "I want to wash the clothes again, but why couldn't the machine start even after I added tokens to the machine?": "我又想洗衣服，為什麼我給機器加了硬幣後機器還是啟動不了？",
  "Try opening and closing the door again before starting the machine.": "在啟動機器之前再次嘗試打開和關閉門。",
  "Can I change the water temperature of the machine?": "我可以改變機器的水溫嗎？",
  "Yes, but the washer temperature can only be changed from hot to cold after the machine has been started, and not from cold to hot.": "是的，但洗衣機溫度只能在機器啟動後由熱變冷，而不能由冷變熱。",
  "Can I stop the machine?": "我可以停止機器嗎？",
  "Here are the steps to stop the machine:": "以下是停止機器的步驟：",
  "Wait for the sound to stop, and then only open the door. (If after 30 minutes the sound still has not stopped, please contact our customer service line at to receive further assistance)": "等聲音停止，才打開門。 （若30分鐘後聲音仍未停止，請聯繫我們的客服熱線獲取進一步幫助）",
  "There is no water supply on the machine used.": "使用的機器上沒有供水。",
  "Why is the washing temperature cold even when I have selected HOT.": "為什麼我選擇了熱，洗滌溫度還是很冷。",
  "The water temperature is HOT or WARM only during washing. The water will turn COLD during rinsing.": "水溫僅在洗滌期間為熱或溫。沖洗時水會變冷。",
  "The machine has already finished washing, but it does not spin well, and there is water left on the load.": "機器已經完成洗滌，但旋轉不暢，衣物上還有水。",
  "Can I put wet laundry into the dryer to dry?": "我可以將濕衣物放入烘乾機中烘乾嗎？",
  "No, as the dripping wet laundry might cause the dryer to short circuit.": "不能，因為滴水的濕衣物可能會導致乾衣機短路。",
  "REMINDER - Refer to the labels on your clothes to know what kind of temperature is best for the kind of fabric that it uses. Then select the temperature on the machine accordingly.": "提醒 - 請參閱衣服上的標籤，了解哪種溫度最適合所使用的面料。然後相應地選擇機器上的溫度。",
  "My clothes are still wet even after drying.": "我的衣服烘乾後還是濕的。",
  "How can I stop the dryer when the machine is still running?": "機器仍在運行時如何停止烘乾機？",
  "Just open the door and the machine will automatically stop.": "只要打開門，機器就會自動停止。",
  "I added extra tokens for extra times, but the machine did not update the time.": "我為額外的時間添加了額外的貨幣，但機器沒有更新時間。",
  "For the time to be reflected accurately, it is recommended for you to add tokens 5 minutes before the services end.": "為了準確反映時間，建議您在服務結束前5分鐘添加貨幣。",
  "REMINDER - You cannot add tokens for extra times when the machine finishes the drying cycle.": "提醒 - 當機器完成烘乾循環時，您不能為額外時間添加貨幣。",
  "Why is the machine making noise?": "為什麼機器會發出噪音？",
  "Please check the drum to see if there are any coins or other small things inside.": "請檢查滾筒，看看裡面是否有任何硬幣或其他小東西。",
  "I have pressed the START button on the dryer, but it is still not working.": "我已經按下烘乾機上的啟動按鈕，但它仍然無法正常工作。",
  "Please try to open the door and close it again. Next, press the START button again to start drying.": "請嘗試打開並再次關閉門。接下來，再次按下開始按鈕開始乾燥。",
  "If the dryer is still not functioning, please contact our customer service line at 03-27700100 to receive further assistance.": "如果乾衣機仍然無法正常工作，請聯繫我們的客戶服務熱線 03-27700100 以獲得進一步的幫助。",
  "Paywave has deducted an amount from the card but the machine did not reflect the transaction.": "Paywave 已從卡中扣除一筆金額，但機器未反映這筆交易。",
  "The Paywave transaction has failed but the amount has still been deducted from my card.": "Paywave 交易失敗，但金額仍從我的卡中扣除。",
  "Paywave has double/triple charge but the machine cannot start.": "Paywave 有雙倍/三倍收費但機器無法啟動。",
  "Paywave after double/triple charge machines can't start.": "雙倍/三倍收費後的 Paywave 機器無法啟動。",
  "Paywave is offline": "Paywave 已離線",
  "Do Cleanpro Express offer franchise business/selling machines/laundry products?": "Cleanpro Express 是否提供特許經營/銷售機器/洗衣產品？",
  "For further inquiries, please email to info@cleanproexpress.com": "如需進一步查詢，請發送電子郵件至 info@cleanproexpress.com",
  "Step 6": "步驟 6",
  "User Existed": "用戶已存在",
  "This email is registered": "該郵箱已註冊",
  "This phone number is registered": "該電話號碼已註冊",
  "Invalid Email Address": "無效的郵件地址",
  "Let's get started!": "讓我們開始吧！",
  "Enter your e-mail/mobile number": "輸入您的電子郵件/手機號碼",
  "Enter your mobile number": "輸入你的手機號碼",
  "Enter your e-mail address": "輸入你的電子郵件地址",
  "Proceed": "繼續",
  "Enter OTP number": "輸入一次性密碼",
  "You will receive a six-digit OTP via SMS sent to": "您將收到一個六位數的一次性密碼短信在",
  "You will receive a six-digit OTP via e-mail sent to": "您將收到一個六位數的一次性密碼郵件在",
  "Verify": "驗證",
  "Resend OTP": "重新發送一次性密碼",
  "Welcome onboard!": "歡迎！",
  "Please fill in the info below": "請填寫以下資料",
  "Enter your name*": "輸入你的名字*",
  "Enter your email address*": "輸入你的電子郵箱地址*",
  "Please enter either phone number or email address": "請輸入電話號碼或電子郵件地址",
  "Create a password*": "創建密碼*",
  "Enter referral code (if got any)": "輸入推薦碼（如果有）",
  "Get started": "開始",
  "Birthday updated successfully": "生日更新成功",
  "Date of birth": "出生日期",
  "Enter your date of birth": "輸入你的出生日期",
  "Select your birth date": "選擇您的出生日期",
  "Update date of birth": "更新出生日期",
  "Update birth date": "更新出生日期",
  "Already have an account?": "已經有賬戶？",
  "Login now": "立即登錄",
  "Sign in": "登入",
  "Sign up now": "立即註冊",
  "COMPLETED": "完成",
  "WASHING NOW": "洗滌中",
  "DRYING NOW": "烘乾中",
  "It's done!": "完成了！",
  "Please collect your fresh, clean laundry in order to leave the machine available for the next person to use!": "請收集您清新、乾淨的衣物，以便讓機器可供下一個人使用！",
  "Leave a feedback": "留下反饋",
  "Time left": "剩餘時間",
  "Add drying time": "增加烘乾時間",
  "Hi": "你好",
  "customer": "顧客",
  "Thank you for your payment. Don't forget to select the <b>correct temperature</b> and press <b>START</b> when you're ready.": "謝謝您的付款。不要忘記選擇<b>正確的溫度</b>，並在準備就緒後按<b>開始</b>。",
  "Select how you want to receive a notification when your laundry is finished": "選擇洗衣完成後您希望如何接收通知",
  "Sign up": "註冊",
  "Continue to pay": "繼續付款",
  "Register as a member to enjoy more benefits!": "註冊成為會員，享受更多優惠！",
  "Get FREE {{ voucherAmount }} credits per new user": "每個新用戶免費獲得 {{ voucherAmount }} 個貨幣",
  "Get FREE {{ voucherAmount }}% per new user": "每個新用戶免費獲得 {{ voucherAmount }}%",
  "Enjoy exclusive promotions": "享受獨家促銷",
  "FREE voucher on your birthday": "生日當天可獲贈免費優惠券",
  "and more": "和更多",
  "Denied camera access": "拒絕攝像頭訪問",
  'Permission to access the camera has been denied. Please try again and click "Allow" to scan the QR code.': '訪問攝像頭的權限已被拒絕。請重試並點擊“允許”掃描二維碼。',
  "Okay": "好的",
  "Oops. You don't have enough balance to make the payment": "您沒有足夠的餘額來付款",
  "Please confirm": "請確認",
  "Do you want to proceed with this feedback?": "您想繼續處理此反饋嗎？",
  "Are you sure to purchase": "您確定購買",
  "credits for": "貨幣以",
  "Topup credit": "充值貨幣",
  "Extended duration will be instantly reflected on machine after payment": "付款後，延長的時間將立即反映在機器上",
  "Machine is currently busy": "機器當前正忙",
  "Do you want to extend drying time?": "您想延長烘乾時間嗎？",
  "Cuci Hari-Hari Bersama Cleanpro!": "Cuci Hari-Hari Bersama Cleanpro!",
  "Ready for a challenge?": "準備好迎接挑戰了嗎？",
  "Here's one for you - all you need to do is wash your laundry 10 times in 20 days at participating Cleanpro outlets. Stand a chance to win a Limited Edition Cleanpro Designer Laundry Bag worth up to RM40!": "挑戰如下 - 你只需在20天內於任何一家Cleanpro門店裡洗衣10次，即可贏得一款價值高達RM40的限量版Cleanpro設計師系列洗衣袋。",
  "Here's how to participate:": "參與方式：",
  "Step 1: Scan the QR Code on the washer to launch Cleanpro Plus": "步驟1：掃描QR Code來啟動Cleanpro Plus應用並註冊賬戶。",
  "Step 2: Pay for your wash with Cleanpro Plus": "步驟2：選擇您需要充值的額度並支付。",
  "Step 3: Complete x10 washes within 20 days to win": "步驟3：在20天內完成10次洗衣。",
  "Challenge Period: 01 July - 31 August 2023": "挑戰期：2023年7月1日 - 8月31日",
  "Drop by your nearest Cleanpro outlet to wash your laundry with us for 20 days straight! Good luck!": "前往最近的Cleanpro門店，與我們一起“天天喜衣”！祝您好運！",
  "TERMS & CONDITIONS": "條款及細則",
  "PARTICIPATION CRITERIA": "參與條件",
  "1) Participant needs to register an account in Cleanpro Plus web app.": "1）參與者需要在Cleanpro Plus應用上註冊一個賬戶。",
  "2) Participant can use any washer of any capacity in any participating outlets to qualify for entries.": "2）參與者在任何一間指定門店使用任何容量的洗衣機即符合資格。",
  "3) Payment needs to be made with Cleanpro Plus web app.": "3）款項需通過Cleanpro Plus進行支付。",
  "4) Washes must be completed and paid (with no dispute whatsoever) within campaign period.": "4）清洗及結賬必須在活動期間內完成（絕無爭議）。",
  "5) Campaign period starts from 1st July 2023 at 00:00 and ends at 31st August 2023 at 23:59.": "5）活動將從2023年7月1日00:00開始，至2023年8月31日23:59結束。",
  "WINNERS & PRIZES": "獲獎者及獎品",
  "1) Cleanpro will notify the winners of the campaign via registered WhatsApp number or email address provided, within 4 weeks after the campaign ends.": "1）Cleanpro將在活動結束後的4週內，通過註冊的WhatsApp號碼或所提供的電子郵件箱通知獲獎者。",
  "2) Any unclaimed prizes after 2 weeks from notification date will not be entertained thereafter.": "2）從通知日期起直至2週後仍未前來領取的獎品將不予理會。",
  "3) Upon completing the ten (10) washes within twenty (20) days campaign, participants will be a winner and be eligible to win ONE (1) Cleanpro Designer Laundry Bag.": "3）參與者若在20天的活動期間內完成十（10）次洗衣次數後，將贏得一（1）款Cleanpro設計師系列洗衣袋。",
  "4) Winners are eligible to win more than ONE (1) Cleanpro Designer Laundry Bag in this campaign.": "4）獲獎者有資格在此活動中贏得多於一（1）個Cleanpro設計師系列洗衣袋。",
  "5) Winners will be given the choice of his/her preferred laundry bag size; but only ONE (1) can be selected for each qualified win.": "5）獲獎者將可選擇自己喜歡的洗衣袋尺寸；但每次完成挑戰只能在獎品中選擇一（1）個。",
  "A) XL Size - Blue bag with up to 20KG in capacity": "A）XL尺寸 - 藍色洗衣袋，容量高達20公斤",
  "B) L Size - White bag with up to 12KG in capacity": "B）L尺寸 - 白色洗衣袋，容量高達12公斤",
  "6) Time and location for collection of prize will be informed via Whatsapp number and/or email address provided.": "6）獎品領取的時間和地點將通過參與者所提供的WhatsApp號碼和/或電子郵件箱通知。",
  "OTHER": "其他",
  "1) Changes to the terms and conditions will be at Cleanpro's discretion; without prior notice.": "1）Cleanpro有權不經通知修改本條款和細則。",
  "2) Completed wash is defined by washes performed with Cleanpro's washer and payments within Cleanpro Plus web app are made with no disputes.": "2）完整的洗衣過程意指使用Cleanpro的洗衣機進行洗滌，並在Cleanpro Plus網頁應用程序內進行付款 (絕無爭議)。",
  "3) By participating in this campaign automatically indicates that the Participant has agreed to all the campaign's Terms and Conditions as stipulated.": "3）參與此活動即表示參與者已同意所有活動條款及細則。",
  "Welcome Voucher": "“歡迎”禮券",
  "Welcome to the Cleanpro Plus family! This first gift is just the beginning. Stay tuned for a series of exciting promotions and campaigns, exclusively for Cleanpro Plus users.": "歡迎加入 Cleanpro Plus的大家庭！這份見面禮只是一個開始。請繼續關注 Cleanpro Plus以獲得我們獨家提供給用戶的一系列激動人心的促銷和活動。",
  "Terms & Conditions": "條款及細則",
  "Welcome Voucher is valid for one-time use only.": "“歡迎”禮券僅供一次性使用。",
  "There is no minimum spend required for the voucher to be used.": "使用禮券時，沒有最低消費要求。",
  "Welcome Voucher is auto-credited to the user's account at the point of registration on Cleanpro Plus and valid for {{ duration }} days only.": "“歡迎”禮券在 Cleanpro Plus 註冊時將會自動存入用戶的帳戶，有效期僅為 {{ duration }} 天。",
  "Users are eligible to use their Welcome Voucher AFTER the completion of their first wash or dry cycle using the same Cleanpro Plus account.": "用戶需使用同一個 Cleanpro Plus 帳戶完成第一次洗滌或烘乾後，才能使用“歡迎”禮券。",
  "Welcome Voucher cannot be used together with any ongoing promotions or any other promotional vouchers.": "“歡迎”禮券不能與任何正在進行的促銷活動或任何其他促銷禮券一起使用。",
  "The voucher is final and cannot be refunded in form of cash or credit to the user.": "該禮券不能以現金或點數形式退還給用戶。",
  "Cleanpro reserves the right to change the terms and conditions without prior notice.": "Cleanpro 保留更改條款和條件的權利，恕不另行通知。",
  "Collect 10 e-stamps to earn FREE {{ voucherAmount }} credits voucher (worth {{ currency }}{{ voucherAmount }})": "收集 10 個電子郵票即可免費獲得 {{ voucherAmount }} 點數禮券（價值 {{ currency }}{{ voucherAmount }}）",
  "Collect 10 e-stamps to earn FREE {{ voucherAmount }}% voucher (worth {{ voucherAmount }}%)": "收集 10 個電子郵票即可免費獲得 {{ voucherAmount }}% 禮券（價值 {{ voucherAmount }}%）",
  "Collect 10 e-Stamp to earn FREE Voucher": "收集 10 個電子郵票即可免費獲得禮券",
  "With every 10 e-stamps that you collected, you will be rewarded with a FREE {{ voucherAmount }} credits voucher (worth {{ currency }}{{ voucherAmount }}). Make your laundry routine not just hassle-free, but also rewarding.": "每收集 10 個電子印章，您將獲得一張免費 {{ voucherAmount }} 點數禮券（價值 {{ currency }}{{ voucherAmount }}）。讓您的洗衣程序不僅輕鬆無憂，而且收穫頗豐。",
  "With every 10 e-stamps that you collected, you will be rewarded with a FREE {{ voucherAmount }}% credits voucher (worth {{ voucherAmount }}%). Make your laundry routine not just hassle-free, but also rewarding.": "每收集 10 個電子印章，您將獲得一張免費 {{ voucherAmount }}% 禮券（價值 {{ voucherAmount }}%）。讓您的洗衣程序不僅輕鬆無憂，而且收穫頗豐。",
  "How to participate:": "參與方式：",
  "Prizes to be won:": "將贏得的獎品：",
  "RM10 OFF Welcome Voucher": "RM10 折扣歡迎禮券",
  "Welcome to the Cleanpro family, here is an appreciation voucher from us. Thank you for attending the COPE Investors Conference.": "歡迎加入 Cleanpro 大家庭，這是我們的感謝券。感謝您參加 COPE 投資者大會。",
  "Welcome Voucher | COPE Investors Conference": "歡迎券 | COPE投資者大會",
  "Payment methods": "付款方式",
  "Tap to proceed with your preferred mode of payment": "選擇您喜歡的付款方式",
  "Cleanpro Plus credits": "Cleanpro Plus 點數",
  "E-wallet or credit/debit cards": "電子錢包或信用卡",
  "Go to homepage": "前往主頁",
  // backend response or error
  "Admin is unauthorized to login": "管理員沒有權限登入",
  "Internal Error": "內部錯誤",
  "Please enter the correct credentials": "請輸入正確的密碼",
  "Operator not found": "未找到營運商",
  "Admin not found": "未找到管理員",
  "Distributor not found": "未找到經銷商",
  "Client not found": "未找到客戶",
  "Outlet not found": "未找到店鋪",
  "Token Package not found": "未找到電子代幣配套",
  "Top up history not found": "未找到儲值記錄",
  "Voucher not found": "未找到優惠券",
  "Faq not found": "未找到常見問題",
  "Faq Group not found": "未找到常見問題組",
  "News not found": "未找到新聞",
  "Transaction not found": "未找到交易",
  "Please Login Again": "請重新登入",
  "Decoding Error": "解碼錯誤",
  "Some error occured, please contact the dev team if this error continues.": "發生了一些錯誤，如果此錯誤仍然存在，請聯絡開發團隊。",
  "Please fill in all the required fields": "請填寫所有必填選項",
  "Only success transaction can be refund": "只有成功的交易才能退款",
  "Only token payment method can be refund": "只有電子代幣支付方式才能退款",
  "The discount code has been used, please change to other code": "該優惠碼已被使用，請更換其他優惠碼",
  "Cannot use ewallet in sandbox": "無法在沙盒中使用電子錢包",
  "Please close the door to proceed your payment": "請關上門以繼續付款",
  "Machine is Out of Service": "機器停止運作",
  "Machine is currently running, please try again later": "機器目前正在運行，請稍後重試",
  "Machine is currently offline, please try again later": "機器目前離線，請稍後重試",
  "Outlet not participating in Loyalty program": "商店未參與忠誠度計劃",
  "Please log in to make the payment": "請登入後進行支付",
  "Package not found, please refresh and try again": "找不到配套，請刷新重試",
  "You have reached the maximum number of purchase": "您已達到最大購買數量",
  "Store not found, please try again later": "找不到商店，請稍後重試",
  "Incorrect Password": "密碼錯誤",
  "Token already top up": "電子代幣已充值",
  "Payment failed": "支付失敗",
  "Transaction Failure": "交易失敗",
  "Transaction Refunded": "交易已退款",
  "Transaction Pending": "交易待處理",
  "Please enter your mobile number": "請輸入您的手機號碼",
  "User is unauthorized to register": "用戶無權註冊",
  "Please proceed to sign up": "請繼續註冊",
  "OTP expired, please request a new OTP": "OTP 已過期，請申請新的 OTP",
  "Incorrect verification code": "驗證碼不正確",
  "This referrer ID is not valid": "該推薦人 ID 無效",
  "This referrer ID is different operator": "此推薦人 ID 是不同的營運商",
  "This referrer ID is exceed the limit": "該推薦人 ID 超出限制",
  "User is unauthorized to login": "用戶無權登入",
  "Click here to register": "點此註冊",
  "User is unauthorized to login. Please contact the admin": "用戶無權登入。請聯絡管理員",
  "User is unauthorized": "用戶未經授權",
  "This outlet does not participate in the Loyalty Program": "該商店不參與忠誠度計劃",
  "This outlet is not belongs to the operator": "該店舖不屬於此營運商",
  "Processing request": "處理請求",
  "This user is not verified": "該用戶未驗證",
  "Insufficient token to make payment": "電子代幣不足，無法付款",
  "This voucher is not available for use": "此優惠券不可用",
  "This voucher is belongs to other user": "此優惠券屬於其他用戶",
  "Invalid Discount Code": "優惠碼無效",
  "This discount code cannot use in this outlet": "該優惠碼不能在該分店使用",
  "This discount code was expired": "該優惠碼已過期",
  "This discount code cannot use in washer machine transaction": "此優惠碼不能用於洗衣機交易",
  "This discount code cannot use in dryer machine transaction": "此優惠碼不能用於烘乾機交易",
  "This discount code was fully redeemed today, try again tomorrow": "該優惠碼今天已完全兌換，明天再試",
  "You have fully redeemed this discount code": "您已完全兌換此優惠碼",
  "This discount code have been redeemed": "此優惠碼已兌換",
  "This discount code is not applicable": "此優惠碼不適用",
  "Insufficient stamp": "印章不足",
  "Please provide at least one voucher setting": "請提供至少一項優惠券設置",
  "Previous batch are still generating, please try again later": "上一批仍在生成中，請稍後重試",
  "Number of voucher code should be more than 0": "兌換碼數量必須大於0",
  "Number of voucher code should be less than 50000": "兌換碼數量必須小於50000",
  "Number of discount code should be more than 0": "優惠碼數量必須大於0",
  "Number of discount code should be less than 50000": "優惠碼數量必須小於50000",
  "No Item": "沒有配套",
  "Please add item": "請添加配套",
  "Staff not found": "未找到工作人員",
  "This task already completed": "此任務已經完成",
  "Please complete the wash task first": "請先完成洗滌任務",
  "Please complete the dry task first": "請先完成烘乾任務",
  "Please select at least one outlet": "請至少選擇一個店鋪",
}

const translationTh = {
  "LOGIN": "เข้าสู่ระบบ",
  "REGISTER": "ลงทะเบียน",
  "SKIP TO PAYMENT": "ข้ามไปยังการชำระเงิน",
  "WELCOME": "ยินดีต้อนรับ",
  "Error": "ข้อผิดพลาด",
  "Please enter the correct phone number format. <br /><br /> <small>Example: 0129465628</small>": "กรุณากรอกหมายเลขโทรศัพท์ให้ถูกต้อง <br /><br /> <small>ตัวอย่าง: 0129465628</small>",
  "Info": "ข้อมูล",
  "Please scan QRCode to Login": "กรุณาสแกน QR Code เพื่อเข้าสู่ระบบ",
  "Forgot Password": "ลืมรหัสผ่าน",
  "Seems like you have forgotten your password<br />Create a temporary password?": "ดูเหมือนว่าคุณลืมรหัสผ่าน<br />ต้องการสร้างรหัสผ่านชั่วคราวหรือไม่?",
  "Cancel": "ยกเลิก",
  "Confirm": "ยืนยัน",
  "Reset Your Password": "รีเซ็ตรหัสผ่านของคุณ",
  "Enter Your Phone Number / Email Address": "กรอกหมายเลขโทรศัพท์ / ที่อยู่อีเมลของคุณ",
  "Please enter phone number / email address": "กรุณากรอกหมายเลขโทรศัพท์ / ที่อยู่อีเมล",
  "Submit": "ส่ง",
  "Please enter a valid phone number": "กรุณากรอกหมายเลขโทรศัพท์ที่ถูกต้อง",
  "Please enter a valid email address": "กรุณากรอกที่อยู่อีเมลที่ถูกต้อง",
  "Success!": "สำเร็จ!",
  "You may login using your one time password now.": "คุณสามารถเข้าสู่ระบบโดยใช้รหัสผ่านครั้งเดียวของคุณได้แล้ว",
  "Phone Number": "หมายเลขโทรศัพท์",
  "Password": "รหัสผ่าน",
  "Don't have an account?": "ยังไม่มีบัญชี?",
  "Forgot password?": "ลืมรหัสผ่าน?",
  "Unable to register without a QRCode": "ไม่สามารถลงทะเบียนได้โดยไม่มี QR Code",
  "Please read and agree to the privacy policies, terms and conditions": "กรุณาอ่านและยอมรับนโยบายความเป็นส่วนตัว ข้อกำหนดและเงื่อนไข",
  "Password length must be at least 6 characters": "รหัสผ่านต้องมีความยาวอย่างน้อย 6 ตัวอักษร",
  "Please enter the confirmation code that was sent to your phone": "กรุณากรอกรหัสยืนยันที่ถูกส่งไปยังเบอร์โทรศัพท์ของคุณ",
  "Example: 012xxxxxxx": "ตัวอย่าง: 012xxxxxxx",
  "Example: 09xxxxxxxx": "ตัวอย่าง: 09xxxxxxxx",
  "Verification Method": "วิธีการยืนยัน",
  "Name": "ชื่อ",
  "Email": "อีเมล",
  "optional": "ไม่บังคับ",
  "Birth Year": "ปีเกิด",
  "Year": "ปี",
  "Birth Month": "เดือนเกิด",
  "Month": "เดือน",
  "Jan": "ม.ค.",
  "Feb": "ก.พ.",
  "Mar": "มี.ค.",
  "April": "เม.ย.",
  "May": "พ.ค.",
  "June": "มิ.ย.",
  "July": "ก.ค.",
  "Aug": "ส.ค.",
  "Sept": "ก.ย.",
  "Oct": "ต.ค.",
  "Nov": "พ.ย.",
  "Dec": "ธ.ค.",
  "Birth Day": "วันเกิด",
  "Day": "วัน",
  "OTP": "รหัส OTP",
  "OTP Confirmation": "ยืนยันรหัส OTP",
  "I agree to the": "ฉันยอมรับ",
  "Privacy Policy": "นโยบายความเป็นส่วนตัว",
  "and": "และ",
  "Terms": "ข้อกำหนด",
  "Conditions": "เงื่อนไข",
  "SEND CODE": "ส่งรหัส",
  "Our One-Time Password (OTP) emails might end up in your spam or junk folder. We kindly ask you to take a moment to check your spam mailbox.": "อีเมลรหัสผ่านแบบใช้ครั้งเดียว (OTP) ของเราอาจจะอยู่ในโฟลเดอร์สแปมหรือขยะของคุณ เราขอความกรุณาให้คุณใช้เวลาสักครู่เพื่อตรวจสอบกล่องจดหมายขยะ",
  "Already have an account? Login": "มีบัญชีอยู่แล้ว? เข้าสู่ระบบ",
  "Didn't receive a code?": "ไม่ได้รับรหัส?",
  "Resend Code": "ส่งรหัสอีกครั้ง",
  "collected": "เก็บสะสม",
  "Collect": "เก็บ",
  "stamps": "แสตมป์",
  "get 1 voucher": "รับ 1 คูปอง",
  "Minimum": "ขั้นต่ำ",
  "spending to collect each washer stamp": "การใช้จ่ายเพื่อรับแสตมป์เครื่องซักผ้าแต่ละอัน",
  "spending to collect each dryer stamp": "การใช้จ่ายเพื่อรับแสตมป์เครื่องอบผ้าแต่ละอัน",
  "spending to collect each stamp": "การใช้จ่ายเพื่อรับแสตมป์สะสมแต่ละดวง",
  "Device don't have a camera": "อุปกรณ์ไม่มีกล้อง",
  "This is not a valid QRCode": "นี่ไม่ใช่ QR Code ที่ถูกต้อง",
  "Select Camera": "เลือกกล้อง",
  "Switch": "สลับ",
  "Camera": "กล้อง",
  "Loading": "กำลังโหลด",
  "Scan the QR code on the machine.": "สแกน QR Code บนเครื่อง",
  "Switch Camera": "สลับกล้อง",
  "Off torch light": "ปิดไฟสัมผัส", // new translation
  "On torch light": "เปิดไฟสัมผัส", // new translation
  "switch camera if your camera is not clear": "สลับกล้องถ้ากล้องของคุณไม่ชัด",
  "Switch your camera if the display is not clear": "สลับกล้องของคุณถ้าการแสดงผลไม่ชัดเจน",
  "Please allow this webapp to access your camera": "กรุณาอนุญาตให้เว็บแอปนี้เข้าถึงกล้องของคุณ",
  "DISCOUNT": "ส่วนลด",
  "Congratulation": "ยินดีด้วย!",
  "Expired Date": "วันหมดอายุ",
  "Voucher": "คูปอง",
  "Vouchers": "คูปอง",
  "Something went wrong": "เกิดข้อผิดพลาดบางอย่าง",
  "Stamping Collection": "การสะสมแสตมป์",
  "Total e-Token": "e-Token ทั้งหมด",
  "Total Wash": "ซักทั้งหมด",
  "Total Dry": "อบทั้งหมด",
  "Reload": "เติมเงิน",
  "Washer": "เครื่องซัก",
  "Dryer": "เครื่องอบ",
  "Congratulations!": "ยินดีด้วย!",
  "You have completed your Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "คุณได้สะสมแสตมป์ครบแล้ว<br />คุณได้รับคูปองและการสะสมแสตมป์จะได้รับการรีเฟรช",
  "You have completed Washer Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "คุณได้สะสมแสตมป์เครื่องซักครบแล้ว<br />คุณได้รับคูปองและการสะสมแสตมป์จะได้รับการรีเฟรช",
  "You have completed Dryer Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "คุณได้สะสมแสตมป์เครื่องอบครบแล้ว<br />คุณได้รับคูปองและการสะสมแสตมป์จะได้รับการรีเฟรช",
  "Latest News": "ข่าวล่าสุด",
  "Transaction History": "ประวัติการทำธุรกรรม",
  "Stamping": "การสะสมแสตมป์",
  "Transaction": "ธุรกรรม",
  "News": "ข่าว",
  "Confirmation": "การยืนยัน",
  "Logout Session?": "ออกจากระบบ?",
  "NO": "ไม่",
  "OK": "ตกลง",
  "Account": "บัญชี",
  "FAQs": "คำถามที่พบบ่อย",
  "Feedback": "ความคิดเห็น",
  "LOGOUT": "ออกจากระบบ",
  "Logout": "ออกจากระบบ",
  "Are you sure you want to submit your feedback?": "คุณแน่ใจหรือไม่ว่าต้องการส่งความคิดเห็นของคุณ?",
  "No": "ไม่",
  "Yes": "ใช่",
  "Success": "สำเร็จ",
  "Failed": "ล้มเหลว",
  "Pending": "รอดำเนินการ",
  "Your feedback is submitted successfully": "ส่งความคิดเห็นของคุณสำเร็จแล้ว",
  "Please fill in all the required fields before submit.": "กรุณากรอกข้อมูลในช่องที่จำเป็นทั้งหมดก่อนส่ง",
  "Give us your feedback!": "ให้ความคิดเห็นกับเรา!",
  "Type your name here (optional)": "พิมพ์ชื่อของคุณที่นี่ (ไม่บังคับ)",
  "required": "จำเป็น",
  "Type your number here": "พิมพ์หมายเลขของคุณที่นี่",
  "Outlet": "สาขา",
  "Select an outlet": "เลือกสาขา",
  "Outlet Cleanliness": "ความสะอาดของสาขา",
  "Washer Machine Performance": "ประสิทธิภาพของเครื่องซัก",
  "Dryer Machine Performance": "ประสิทธิภาพของเครื่องอบ",
  "Customer Service": "บริการลูกค้า",
  "Comment": "ความคิดเห็น",
  "Type your comment here (optional)": "พิมพ์ความคิดเห็นของคุณที่นี่ (ไม่บังคับ)",
  "SUBMIT": "ส่ง",
  "Join as a member now!": "สมัครเป็นสมาชิกเดี๋ยวนี้!",
  "Join as a loyalty member now to get more benefits such as vouchers and free machine usage!<br /><br /><strong>Register as loyalty member?</strong>": "สมัครเป็นสมาชิกระดับสมาชิกตอนนี้ เพื่อรับสิทธิประโยชน์มากขึ้น เช่น คูปองและการใช้เครื่องฟรี!<br /><br /><strong>ลงทะเบียนเป็นสมาชิกระดับสมาชิก ใช่ไหม?</strong>",
  "Maybe Next Time": "อาจจะครั้งหน้า",
  "Yes, Register Now": "ใช่ ลงทะเบียนเลย",
  "Washer Payment": "การชำระเงินเครื่องซัก",
  "Dryer Payment": "การชำระเงินเครื่องอบ",
  "Vending Machine Payment": "การชำระเงินตู้จำหน่ายสินค้าอัตโนมัติ",
  "Online": "ออนไลน์",
  "Offline": "ออฟไลน์",
  "Running": "กำลังทำงาน",
  "Vacant": "ว่าง",
  "Vending Machine": "ตู้จำหน่ายสินค้าอัตโนมัติ",
  "Discount": "ส่วนลด",
  "No Vouchers Available": "ไม่มีคูปองที่ใช้ได้",
  "Payment Method": "วิธีการชำระเงิน",
  "Would you like to pay with?": "คุณต้องการชำระเงินด้วยวิธีใด?",
  "e-Token": "e-Token",
  "E-TOKEN": "E-TOKEN",
  "E-Wallet": "กระเป๋าเงินอิเล็กทรอนิกส์",
  "Duitnow QR code": "รหัส QR Duitnow",
  "The Duitnow QR code will be downloaded and you need to open your banking app to make the payment. Pay {{ currency }}{{ totalPaymentAmount }} to {{ machineName }}?": "รหัส QR Duitnow จะถูกดาวน์โหลดและคุณต้องเปิดแอปธนาคารของคุณเพื่อทำการชำระเงิน ชำระเงิน {{ currency }}{{ totalPaymentAmount }} ให้กับ {{ machineName }}?",
  "Pay": "ชำระเงิน",
  "to": "ถึง",
  "You may under utilized the voucher.": "คุณอาจใช้คูปองไม่เต็มประสิทธิภาพ",
  "Proceed to pay": "ดำเนินการชำระเงิน",
  "Processing ...": "กำลังดำเนินการ ...",
  "You can't pay with e-token if voucher is in used, please remove your voucher to pay with e-token.": "คุณไม่สามารถชำระเงินด้วย e-token ถ้ามีการใช้คูปอง กรุณาลบคูปองของคุณเพื่อชำระเงินด้วย e-token",
  "Insufficient e-Tokens": "e-Tokens ไม่เพียงพอ",
  "Reload your e-token now?": "เติม e-token ของคุณตอนนี้?",
  "Reload now!": "เติมเงินตอนนี้!",
  "You have": "คุณมี",
  "e-Tokens": "e-Tokens",
  "Tokens to": "Tokens ถึง",
  "This machine is currently offline, please try again later.": "เครื่องนี้กำลังปิด กรุณาลองใหม่อีกครั้งในภายหลัง",
  "MACHINE": "เครื่อง",
  "Machine": "เครื่อง",
  "TYPE": "ประเภท",
  "CAPACITY": "ความจุ",
  "DURATION": "ระยะเวลา",
  "Select Duration": "เลือกระยะเวลา",
  "AMOUNT TO PAY": "จำนวนเงินที่ต้องชำระ",
  "PRICE": "ราคา",
  "VOUCHER": "คูปอง",
  "Select Voucher >": "เลือกคูปอง >",
  "TOTAL": "รวม",
  "PAY": "ชำระเงิน",
  "Remove Voucher": "ลบคูปอง",
  "Select Your Voucher": "เลือกคูปองของคุณ",
  "min": "นาที",
  "MIN": "นาที",
  "Select Payment Amount": "เลือกจำนวนเงินที่ต้องชำระ",
  "Select Water Temperature": "เลือกอุณหภูมิน้ำ",
  "Select temperature": "เลือกอุณหภูมิ",
  "Cold": "เย็น",
  "Warm": "อุ่น",
  "Hot": "ร้อน",
  "e-Tokens to": "e-Tokens ถึง",
  "No Purchase History": "ไม่มีประวัติการซื้อ",
  "e-tokens": "e-tokens",
  "No Promotions": "ไม่มีโปรโมชั่น",
  "New Password": "รหัสผ่านใหม่",
  "Back": "กลับ",
  "Buy this package?": "ซื้อแพ็คเกจนี้?",
  "Purchase": "ซื้อ",
  "e-Tokens for": "e-Tokens สำหรับ",
  "Reload Tokens": "เติม Tokens",
  "Are you sure you want to purchase {{ tokenAmount }} e-Token for {{ currency }}{{ paymentAmount }}?": "Are you sure you want to purchase {{ tokenAmount }} e-Token for {{ currency }}{{ paymentAmount }}?", // new translation
  "Current e-Token": "e-Token ปัจจุบัน",
  "History": "ประวัติ",
  "Your profile picture has been updated!": "รูปโปรไฟล์ของคุณได้รับการอัปเดตแล้ว!",
  "Please only use png, jpg or jpeg file extension type": "กรุณาใช้เฉพาะไฟล์นามสกุล png, jpg หรือ jpeg",
  "Please only use image type file": "กรุณาใช้เฉพาะไฟล์ประเภทรูปภาพ",
  "User name has updated successfully!": "อัปเดตชื่อผู้ใช้สำเร็จแล้ว!",
  "Email Address updated successfully": "อัปเดตที่อยู่อีเมลสำเร็จแล้ว",
  "Phone number updated successfully": "อัปเดตหมายเลขโทรศัพท์สำเร็จแล้ว",
  "Password must be at least 6 characters": "รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร",
  "Password updated successfully": "อัปเดตรหัสผ่านสำเร็จแล้ว",
  "User Settings": "การตั้งค่าผู้ใช้",
  "Profile Picture": "รูปโปรไฟล์",
  "Change Password": "เปลี่ยนรหัสผ่าน",
  "Do you want to submit your feedback?": "คุณต้องการส่งความคิดเห็นของคุณหรือไม่?",
  "Thank You!": "ขอบคุณ!",
  "Thank you for using our services.": "ขอบคุณที่ใช้บริการของเรา",
  "BACK TO HOMEPAGE": "กลับไปหน้าแรก",
  "How to pay?": "วิธีการชำระเงิน?",
  "Step 1": "ขั้นตอนที่ 1",
  "After scan the QR code on the machine, select either register new account, login your account or skip to payment.": "หลังจากสแกน QR code บนเครื่อง เลือกลงทะเบียนบัญชีใหม่ เข้าสู่ระบบบัญชีของคุณ หรือข้ามไปยังการชำระเงิน",
  "Step 2-1": "ขั้นตอนที่ 2-1",
  "For Dryer – Select your desired duration. Different duration will have different prices.": "สำหรับเครื่องอบ – เลือกระยะเวลาที่คุณต้องการ ระยะเวลาที่แตกต่างกันจะมีราคาที่แตกต่างกัน",
  "Step 2-2": "ขั้นตอนที่ 2-2",
  "For Washer – Select your desired water temperature. Different water temperature will have different prices.": "สำหรับเครื่องซัก – เลือกอุณหภูมิน้ำที่คุณต้องการ อุณหภูมิน้ำที่แตกต่างกันจะมีราคาที่แตกต่างกัน",
  "Step 3": "ขั้นตอนที่ 3",
  "For login user, Click on ‘Select Voucher’ to select your voucher.": "สำหรับผู้ใช้ที่เข้าสู่ระบบ คลิกที่ 'เลือกคูปอง' เพื่อเลือกคูปองของคุณ",
  "Step 4": "ขั้นตอนที่ 4",
  "Click on ‘PAY’ button to pay. For login user, select your payment method to pay.": "คลิกที่ปุ่ม 'ชำระเงิน' เพื่อชำระเงิน สำหรับผู้ใช้ที่เข้าสู่ระบบ เลือกวิธีการชำระเงินของคุณเพื่อชำระเงิน",
  "Step 5": "ขั้นตอนที่ 5",
  "After confirm the price and machine details, click on ‘OK’ button to pay.": "หลังจากยืนยันราคาและรายละเอียดเครื่อง คลิกที่ปุ่ม 'ตกลง' เพื่อชำระเงิน",
  "How to reload your e-Token?": "วิธีการเติม e-Token ของคุณ?",
  "Click on the \"Reload\" button.": "คลิกที่ปุ่ม \"เติมเงิน\"",
  "Select the package that you wish to purchase.": "เลือกแพ็คเกจที่คุณต้องการซื้อ",
  "Click on \"Purchase\" as confirmation before payment page.": "คลิกที่ \"ซื้อ\" เพื่อเป็นการยืนยันก่อนไปยังหน้าการชำระเงิน",
  "Is e-token can be refund/transfer back to e-wallet?": "e-token สามารถขอคืนเงิน/โอนกลับไปยังกระเป๋าเงินอิเล็กทรอนิกส์ได้หรือไม่?",
  "Why can't I applied my voucher?": "ทำไมฉันไม่สามารถใช้คูปองของฉันได้?",
  "When there is a Happy Hour Promotion is on going, you are unable to apply your voucher temporarily.": "เมื่อมีโปรโมชั่น Happy Hour กำลังดำเนินอยู่ คุณไม่สามารถใช้คูปองของคุณได้ชั่วคราว",
  "How to apply my voucher?": "วิธีการใช้คูปองของฉัน?",
  "Click on the \"Select Voucher\" button.": "คลิกที่ปุ่ม \"เลือกคูปอง\"",
  "Select the voucher that you wish to apply.": "เลือกคูปองที่คุณต้องการใช้",
  "Click on \"Pay\" once you have verified the voucher is successfully applied.": "คลิกที่ \"ชำระเงิน\" เมื่อคุณได้ตรวจสอบว่าคูปองถูกใช้สำเร็จแล้ว",
  "Step 2": "ขั้นตอนที่ 2",
  "Language has updated successfully!": "อัปเดตภาษาสำเร็จแล้ว!",
  "Change Language": "เปลี่ยนภาษา",
  "Language": "ภาษา",
  "Select language": "เลือกภาษา",
  "Referral Code": "รหัสแนะนำ",
  "Copied!": "คัดลอกแล้ว!",
  "Inviting friends to get more benefits!": "เชิญเพื่อนเพื่อรับสิทธิประโยชน์มากขึ้น!",
  "Would you like to share your referral code to your friends & family?": "คุณต้องการแชร์รหัสแนะนำของคุณให้กับเพื่อนและครอบครัวหรือไม่?",
  "Refer a friend and get": "แนะนำเพื่อนและรับ",
  "Share the love to your friends & family?": "แบ่งปันความรักให้กับเพื่อนและครอบครัวของคุณหรือไม่?",
  "voucher": "คูปอง",
  "token": "โทเคน",
  "stamp": "แสตมป์",
  "washer stamp": "แสตมป์เครื่องซัก",
  "dryer stamp": "แสตมป์เครื่องอบ",
  "Learn More": "เรียนรู้เพิ่มเติม",
  "Welcome Gift": "ของขวัญต้อนรับ",
  "Share Your Referral Code Now": "แชร์รหัสแนะนำของคุณตอนนี้",
  "Birthday Gift for You": "ของขวัญวันเกิดสำหรับคุณ",
  "Let's Pay with E-Token": "มาชำระเงินด้วย E-Token กัน",
  "Collect 10 Stamps and Get Voucher": "สะสมแสตมป์ 10 ดวงและรับคูปอง",
  "The voucher will be given if you registered your account successfully.": "คุณจะได้รับคูปองถ้าคุณลงทะเบียนบัญชีสำเร็จ",
  "There is no expiration date for this voucher.": "คูปองนี้ไม่มีวันหมดอายุ",
  "The voucher can be used for dryer machine only.": "คูปองนี้สามารถใช้ได้กับเครื่องอบเท่านั้น",
  "The voucher can be used for washer machine only.": "คูปองนี้สามารถใช้ได้กับเครื่องซักเท่านั้น",
  "The voucher can be used for Drop n Go transaction only.": "คูปองนี้สามารถใช้ได้กับธุรกรรม Drop n Go เท่านั้น",
  "The voucher can be used for washer machine or Drop n Go transaction.": "คูปองนี้สามารถใช้ได้กับเครื่องซักหรือธุรกรรม Drop n Go",
  "The voucher can be used for dryer machine or Drop n Go transaction.": "คูปองนี้สามารถใช้ได้กับเครื่องอบหรือธุรกรรม Drop n Go",
  "The voucher can be used for dryer or washer machine.": "คูปองนี้สามารถใช้ได้กับเครื่องอบหรือเครื่องซัก",
  "The voucher can be used for washer machine, dryer machine or Drop n Go transaction.": "คูปองนี้สามารถใช้ได้กับเครื่องซัก เครื่องอบ หรือธุรกรรม Drop n Go",
  "When you applied the voucher during payment, it will give you": "เมื่อคุณใช้คูปองระหว่างการชำระเงิน คุณจะได้รับ",
  "discount.": "ส่วนลด",
  "When promotion is on going, the voucher is not applicable.": "เมื่อมีโปรโมชั่นกำลังดำเนินอยู่ ไม่สามารถใช้คูปองได้",
  "The voucher cannot be used if your payment method is E-Token.": "ไม่สามารถใช้คูปองได้ถ้าวิธีการชำระเงินของคุณเป็น E-Token",
  "Your referral code can be found in user settings page.": "คุณสามารถพบรหัสแนะนำของคุณได้ในหน้าการตั้งค่าผู้ใช้",
  "You can get": "คุณสามารถรับ",
  "stamp for the reward.": "แสตมป์สำหรับรางวัล",
  "e-token for the reward.": "e-token สำหรับรางวัล",
  "e-token based on transaction payment amount for the reward.": "e-token based on transaction payment amount for the reward.", // new translation
  "for the reward.": "for the reward.",
  "based on transaction payment amount for the reward.": "based on transaction payment amount for the reward.",
  "You can get a voucher for the reward": "คุณสามารถรับคูปองเป็นรางวัล",
  "which have": "ซึ่งมี",
  "it can be used for dryer machine only.": "สามารถใช้ได้กับเครื่องอบเท่านั้น",
  "it can be used for washer machine only.": "สามารถใช้ได้กับเครื่องซักเท่านั้น",
  "it can be used for Drop n Go transaction only.": "สามารถใช้ได้กับธุรกรรม Drop n Go เท่านั้น",
  "it can be used for dryer or washer machine.": "สามารถใช้ได้กับเครื่องอบหรือเครื่องซัก",
  "it can be used for dryer machine or Drop n Go transaction.": "สามารถใช้ได้กับเครื่องอบหรือธุรกรรม Drop n Go",
  "it can be used for washer machine or Drop n Go transaction.": "สามารถใช้ได้กับเครื่องซักหรือธุรกรรม Drop n Go",
  "it can be used for washer machine, dryer machine or Drop n Go transaction.": "สามารถใช้ได้กับเครื่องซัก เครื่องอบ หรือธุรกรรม Drop n Go",
  "washer stamp for the reward.": "แสตมป์เครื่องซักสำหรับรางวัล",
  "dryer stamp for the reward.": "แสตมป์เครื่องอบสำหรับรางวัล",
  "washer stamp and": "แสตมป์เครื่องซักและ",
  "cleanproBirthdayShortDesc_one": "แลกรับคูปองวันเกิดมูลค่า {{ birthdayVoucherAmount }} เครดิตในวันพิเศษของคุณกับ Cleanpro Plus แตะเพื่อเรียนรู้เพิ่มเติม",
  "cleanproBirthdayShortDesc_other": "แลกรับคูปองวันเกิดมูลค่า {{ birthdayVoucherAmount }} เครดิตในวันพิเศษของคุณกับ Cleanpro Plus แตะเพื่อเรียนรู้เพิ่มเติม",
  "When you click on \"PAY\" button on the payment page, you can choose either using E-wallet or E-token to pay.": "เมื่อคุณคลิกที่ปุ่ม \"ชำระเงิน\" บนหน้าการชำระเงิน คุณสามารถเลือกใช้ E-wallet หรือ E-token เพื่อชำระเงินได้",
  "When you using your voucher during payment, you will be unable to pay with E-token.": "เมื่อคุณใช้คูปองของคุณระหว่างการชำระเงิน คุณจะไม่สามารถชำระเงินด้วย E-token ได้",
  "You can reload your E-token by clicking the reload button in the stamping page and select the package to purchase.": "คุณสามารถเติม E-token ของคุณได้โดยคลิกที่ปุ่มเติมเงินในหน้าสะสมแสตมป์และเลือกแพ็คเกจที่ต้องการซื้อ",
  "You can reload your E-token by clicking the reload button in the home page and select the package to purchase.": "คุณสามารถเติม E-token ของคุณได้โดยคลิกที่ปุ่มเติมเงินในหน้าหลักและเลือกแพ็คเกจที่ต้องการซื้อ",
  "You can view your E-token reload history and E-token transaction history in transaction page.": "คุณสามารถดูประวัติการเติม E-token และประวัติธุรกรรม E-token ได้ในหน้าธุรกรรม",
  "You can view your E-token transaction history in transaction page.": "คุณสามารถดูประวัติธุรกรรม E-token ได้ในหน้าธุรกรรม",
  "Using either dryer machine or washer machine with the minimum spending of": "ใช้เครื่องอบหรือเครื่องซักโดยมีการใช้จ่ายขั้นต่ำ",
  "to collect one stamp.": "เพื่อรับหนึ่งแสตมป์",
  "The voucher will be given if you have collected 10 stamps.": "คุณจะได้รับคูปองถ้าคุณสะสมแสตมป์ครบ 10 ดวง",
  "Using dryer machine with the minimum spending of": "ใช้เครื่องอบโดยมีการใช้จ่ายขั้นต่ำ",
  "Using washer machine with the minimum spending of": "ใช้เครื่องซักโดยมีการใช้จ่ายขั้นต่ำ",
  "to collect one dryer stamp.": "เพื่อรับหนึ่งแสตมป์เครื่องอบ",
  "to collect one washer stamp.": "เพื่อรับหนึ่งแสตมป์เครื่องซัก",
  "The voucher will be given if you have collected 10 dryer stamps or 10 washer stamps.": "คุณจะได้รับคูปองถ้าคุณสะสมแสตมป์เครื่องอบครบ 10 ดวงหรือแสตมป์เครื่องซักครบ 10 ดวง",
  "For the voucher given by the dryer stamps,": "สำหรับคูปองที่ได้รับจากแสตมป์เครื่องอบ",
  "For the voucher given by the washer stamps,": "สำหรับคูปองที่ได้รับจากแสตมป์เครื่องซัก",
  "When promotion is on going, all the vouchers is not applicable.": "เมื่อมีโปรโมชั่นกำลังดำเนินอยู่ ไม่สามารถใช้คูปองทั้งหมดได้",
  "All the vouchers cannot be used if your payment method is E-Token.": "ไม่สามารถใช้คูปองทั้งหมดได้ถ้าวิธีการชำระเงินของคุณเป็น E-Token",
  "Description": "คำอธิบาย",
  "Refer Friends": "แนะนำเพื่อน",
  "View our guideline": "ดูแนวทางของเรา",
  "When a friend / family register using your referral code, and completed a transaction with a minimum spending of": "เมื่อเพื่อน / ครอบครัวลงทะเบียนโดยใช้รหัสแนะนำของคุณ และทำธุรกรรมเสร็จสมบูรณ์โดยมีการใช้จ่ายขั้นต่ำ",
  ", you will be rewarded automatically.": " คุณจะได้รับรางวัลโดยอัตโนมัติ",
  "One user only allow to have": "ผู้ใช้หนึ่งคนสามารถมีได้เพียง",
  "referrals.": "การแนะนำ",
  "Confirmation Password": "ยืนยันรหัสผ่าน",
  "Password and confirmation password should be same.": "รหัสผ่านและการยืนยันรหัสผ่านควรเหมือนกัน",
  "You haven't applied your voucher": "คุณยังไม่ได้ใช้คูปองของคุณ",
  "Would you like to continue your payment without using your voucher?": "คุณต้องการดำเนินการชำระเงินต่อโดยไม่ใช้คูปองของคุณหรือไม่?",
  "Continue": "ดำเนินการต่อ",
  "Apply voucher": "ใช้คูปอง",
  "Transfer": "โอน",
  "Transfer Tokens": "โอนโทเคน",
  "Please fill in the phone number": "กรุณากรอกหมายเลขโทรศัพท์",
  "Please fill in the email": "กรุณากรอกอีเมล",
  "Please enter the phone number / email that you want to transfer to": "กรุณากรอกหมายเลขโทรศัพท์ / อีเมลที่คุณต้องการโอนไป",
  "Please fill in the transfer amount": "กรุณากรอกจำนวนเงินที่ต้องการโอน",
  "Transfer amount should be more than 0 and less than your current e-Token": "จำนวนเงินที่โอนควรมากกว่า 0 และน้อยกว่า e-Token ปัจจุบันของคุณ",
  "Please enter your password": "กรุณากรอกรหัสผ่านของคุณ",
  "Member ID": "รหัสสมาชิก",
  "E-Token Transfer Amount": "จำนวน E-Token ที่โอน",
  "Please confirm the details before proceeding": "กรุณายืนยันรายละเอียดก่อนดำเนินการต่อ",
  "Transfer From": "โอนจาก",
  "Transfer To": "โอนไปยัง",
  "Transfer Amount": "จำนวนเงินที่โอน",
  "Transfer Successfully!": "โอนสำเร็จ!",
  "Transfer Time": "เวลาที่โอน",
  "Token": "โทเคน",
  "Done": "เสร็จสิ้น",
  "Next": "ถัดไป",
  "Enter Password": "กรอกรหัสผ่าน",
  "Transfer E-Token Amount": "จำนวน E-Token ที่โอน",
  "User not found": "ไม่พบผู้ใช้",
  "Password is incorrect": "รหัสผ่านไม่ถูกต้อง",
  "Please go to your account setting and reset your password": "กรุณาไปที่การตั้งค่าบัญชีของคุณและรีเซ็ตรหัสผ่าน",
  "Only up to last 3 months transaction history": "ประวัติธุรกรรมย้อนหลังได้เพียง 3 เดือนเท่านั้น",
  "Reload History": "ประวัติการเติมเงิน",
  "Transfer History": "ประวัติการโอน",
  "{{ point }} History": "{{ point }} History", // new translation
  "Only up to last 3 months reload history": "ประวัติการเติมเงินย้อนหลังได้เพียง 3 เดือนเท่านั้น",
  "Only up to last 3 months transfer history": "ประวัติการโอนย้อนหลังได้เพียง 3 เดือนเท่านั้น",
  "Only up to last 3 months {{ point }} history": "Only up to last 3 months {{ point }} history", // new translation
  "If require urgent response, please contact us through whatapp": "หากต้องการการตอบสนองด่วน กรุณาติดต่อเราผ่านทาง WhatsApp",
  "Please scan the QR code again": "กรุณาสแกน QR code อีกครั้ง",
  "Home": "หน้าหลัก",
  "Quick": "ด่วน",
  "Super": "ซุปเปอร์",
  "Premium": "พรีเมียม",
  "All users will have infinite referrals.": "ผู้ใช้ทุกคนจะมีการแนะนำไม่จำกัด",
  "Collect 10 stamps to get 1 Voucher": "สะสม 10 แสตมป์เพื่อรับ 1 คูปอง",
  "The voucher is only for customer use only, not for sale.": "คูปองนี้สำหรับลูกค้าใช้เท่านั้น ไม่สามารถขายได้",
  "Outlet is currently offline, please try again later": "สาขากำลังออฟไลน์ กรุณาลองใหม่อีกครั้งในภายหลัง",
  "The voucher will be given if you completed a transaction with a minimum spending of": "คุณจะได้รับคูปองถ้าคุณทำธุรกรรมเสร็จสมบูรณ์โดยมีการใช้จ่ายขั้นต่ำ",
  "The birthday voucher will be given on the first day of your birthday month.": "คูปองวันเกิดจะมอบให้ในวันแรกของเดือนเกิดของคุณ",
  "The voucher only will be given if you have exceed spending amount of": "คุณจะได้รับคูปองเฉพาะเมื่อคุณมีการใช้จ่ายเกินกว่า",
  "Pay with KiplePay": "ชำระเงินด้วย KiplePay",
  "Pay with Scan2Pay": "ชำระเงินด้วย Scan2Pay",
  "Pay with Razer": "ชำระเงินด้วย Razer",
  "Pay with HitPay": "ชำระเงินด้วย HitPay",
  "Pay with Duitnow QR": "ชำระเงินด้วย Duitnow QR",
  "Pay with PagBank": "ชำระเงินด้วย PagBank",
  "DISCOUNT CODE": "รหัสส่วนลด",
  "Enter code >": "ป้อนรหัส >",
  "Enter Discount Code": "ป้อนรหัสส่วนลด",
  "Remove Code": "ลบรหัส",
  "Discount Code": "รหัสส่วนลด",
  "Close": "ปิด",
  "Please fill in your email address": "กรุณากรอกที่อยู่อีเมลของคุณ",
  "Notification": "การแจ้งเตือน",
  "Do you want to receive notification when wash cycle done?": "คุณต้องการรับการแจ้งเตือนเมื่อรอบการซักเสร็จสิ้นหรือไม่?",
  "pay extra": "จ่ายเพิ่ม",
  "Email Address": "ที่อยู่อีเมล",
  "Please fill in your phone number": "กรุณากรอกหมายเลขโทรศัพท์ของคุณ",
  "Invalid Phone Number": "หมายเลขโทรศัพท์ไม่ถูกต้อง",
  "Washing Complete": "ซักเสร็จสิ้น",
  "Drying Complete": "อบแห้งเสร็จสิ้น",
  "The voucher will be given if you registered your account successfully, but it only can be used when your total transaction spending reach": "คุณจะได้รับคูปองถ้าคุณลงทะเบียนบัญชีสำเร็จ แต่สามารถใช้ได้เมื่อการใช้จ่ายธุรกรรมรวมของคุณถึง",
  "and above.": "และมากกว่า",
  "This voucher is not applicable": "คูปองนี้ไม่สามารถใช้ได้",
  "Finish in": "เสร็จใน",
  "Need to refresh to update": "Need to refresh to update", // new translation
  "It's indicative and may change based on washer load": "It's indicative and may change based on washer load",
  "We'll let you know when it's ready to pick up": "เราจะแจ้งให้คุณทราบเมื่อสินค้าพร้อมให้รับ",
  "FREE": "ฟรี",
  "Enter voucher code (if got any)": "ป้อนรหัสคูปอง (ถ้ามี)",
  "Please fill in the voucher code": "กรุณากรอกรหัสคูปอง",
  "Available On": "ใช้ได้เมื่อ",
  "Apply": "ใช้",
  "Enter a code": "ป้อนรหัส",
  "Input Promo Code": "ป้อนรหัสโปรโมชั่น",
  "Voucher is redeemed successfully!": "แลกคูปองสำเร็จ!",
  "Not available": "ไม่พร้อมใช้งาน",
  "Unable to register": "ไม่สามารถลงทะเบียนได้",
  "Drop n Go Service": "บริการ Drop n Go",
  "Select Mode": "เลือกโหมด",
  "Quantity": "จำนวน",
  "Pack": "แพ็ค",
  "Add more": "เพิ่มเติม",
  "Select Size": "เลือกขนาด",
  "Select Services": "เลือกบริการ",
  "Are you sure to delete the item?": "คุณแน่ใจหรือไม่ที่จะลบรายการนี้?",
  "Delete": "ลบ",
  "Your Duitnow QR code": "รหัส QR Duitnow ของคุณ",
  "How to pay using Duitnow QR code?": "วิธีการชำระเงินโดยใช้รหัส QR Duitnow?",
  "Launch": "เปิด",
  "Launch your preferred Mobile Banking or eWallet app.": "เปิดแอปธนาคารบนมือถือหรือกระเป๋าเงินอิเล็กทรอนิกส์ที่คุณต้องการ",
  "Scan QR code from Gallery": "สแกนรหัส QR จากแกลเลอรี่",
  "Choose DuitNow QR from your gallery.": "เลือก DuitNow QR จากแกลเลอรี่ของคุณ",
  "Confirm payment details and pay.": "ยืนยันรายละเอียดการชำระเงินและชำระเงิน",
  "Receive payment confirmation.": "รับการยืนยันการชำระเงิน",
  "expiring on": "expiring on", // new translation
  "based on transaction payment amount": "based on transaction payment amount",
  "Join as our": "Join as our",
  "Loyalty Member": "Loyalty Member",
  "now to get more reward": "now to get more reward",
  "Payment amount should be greater than 0": "Payment amount should be greater than 0",
  // admin portal
  "Dashboard": "แดชบอร์ด",
  "Report": "รายงาน",
  "Store Performance": "ประสิทธิภาพของร้าน",
  "User Performance": "ประสิทธิภาพของผู้ใช้",
  "Voucher Redemption": "การแลกคูปอง",
  "Token Report": "รายงานโทเคน",
  "SMS Report": "รายงาน SMS",
  "Discount Code Report": "รายงานรหัสส่วนลด",
  "User Details": "รายละเอียดผู้ใช้",
  "User Compensation": "การชดเชยผู้ใช้",
  "Voucher Settings": "การตั้งค่าคูปอง",
  "Token Settings": "การตั้งค่าโทเคน",
  "Referral Settings": "การตั้งค่าการแนะนำ",
  "Voucher Code": "รหัสคูปอง",
  "Customize Color": "ปรับแต่งสี",
  "User Migration": "การย้ายผู้ใช้",
  "Back To": "กลับไปยัง",
  "Eligibility": "คุณสมบัติ",
  "If ticked, the voucher will have expired date": "ถ้าติ๊ก คูปองจะมีวันหมดอายุ",
  "Expiry": "วันหมดอายุ",
  "If ticked, the voucher can apply to washer machine transaction": "ถ้าติ๊ก คูปองสามารถใช้กับธุรกรรมเครื่องซักได้",
  "Washer Use": "ใช้กับเครื่องซัก",
  "If ticked, the voucher can apply to dryer machine transaction": "ถ้าติ๊ก คูปองสามารถใช้กับธุรกรรมเครื่องอบได้",
  "Dryer Use": "ใช้กับเครื่องอบ",
  "If ticked, the voucher can apply to Drop n Go transaction": "ถ้าติ๊ก คูปองสามารถใช้กับธุรกรรม Drop n Go ได้",
  "Package Use": "ใช้กับแพ็คเกจ",
  "Voucher Lifespan": "อายุการใช้งานของคูปอง",
  "days": "วัน",
  "Discount Type": "ประเภทส่วนลด",
  "Select a discount type": "เลือกประเภทส่วนลด",
  "Flat": "คงที่",
  "Rate": "อัตรา",
  "Voucher Title": "ชื่อคูปอง",
  "Voucher Description": "คำอธิบายคูปอง",
  "Voucher Amount": "จำนวนคูปอง",
  "CONFIRM": "ยืนยัน",
  "If ticked, the system will give voucher to user who completed washer stamp collection": "ถ้าติ๊ก ระบบจะให้คูปองกับผู้ใช้ที่สะสมแสตมป์เครื่องซักครบ",
  "If ticked, the system will give voucher to user who completed dryer stamp collection": "ถ้าติ๊ก ระบบจะให้คูปองกับผู้ใช้ที่สะสมแสตมป์เครื่องอบครบ",
  "If ticked, the user only can get/use the voucher after make a transaction": "ถ้าติ๊ก ผู้ใช้จะสามารถรับ/ใช้คูปองได้หลังจากทำธุรกรรมเท่านั้น",
  "Pay And Get Voucher": "ชำระเงินและรับคูปอง",
  "Pay And Use Code": "ชำระเงินและใช้รหัส",
  "If ticked, the system will only give the voucher to user after the user make a transaction": "ถ้าติ๊ก ระบบจะให้คูปองกับผู้ใช้หลังจากผู้ใช้ทำธุรกรรมเท่านั้น",
  "If ticked, the system will give voucher to user who completed registration process": "ถ้าติ๊ก ระบบจะให้คูปองกับผู้ใช้ที่ลงทะเบียนเสร็จสมบูรณ์",
  "If ticked, the system will give voucher to user on their birthday month": "ถ้าติ๊ก ระบบจะให้คูปองกับผู้ใช้ในเดือนเกิดของพวกเขา",
  "If ticked, the system will give voucher to user who completed stamp collection": "ถ้าติ๊ก ระบบจะให้คูปองกับผู้ใช้ที่สะสมแสตมป์ครบ",
  "Minimum Payment Amount To Get/Use Register Voucher": "จำนวนเงินขั้นต่ำในการชำระเงินเพื่อรับ/ใช้คูปองลงทะเบียน",
  "Voucher Method": "วิธีการคูปอง",
  "Select a voucher method": "เลือกวิธีการคูปอง",
  "The user directly get the voucher after register but only can use it after make transaction": "ผู้ใช้จะได้รับคูปองทันทีหลังจากลงทะเบียน แต่สามารถใช้ได้หลังจากทำธุรกรรมเท่านั้น",
  "The user only can get voucher after make a transaction": "ผู้ใช้จะสามารถรับคูปองได้หลังจากทำธุรกรรมเท่านั้น",
  "Minimum Payment Amount To Get Birthday Voucher": "จำนวนเงินขั้นต่ำในการชำระเงินเพื่อรับคูปองวันเกิด",
  "Minimum Payment Amount To Use Discount Code": "จำนวนเงินขั้นต่ำในการชำระเงินเพื่อใช้รหัสส่วนลด",
  "Outlets": "สาขา",
  "E-Payment Sales": "ยอดขายการชำระเงินอิเล็กทรอนิกส์",
  "Number of stamps granted to users": "จำนวนแสตมป์ที่มอบให้ผู้ใช้",
  "Total number of stamps granted": "จำนวนแสตมป์ทั้งหมดที่มอบให้",
  "Registered Users": "ผู้ใช้ที่ลงทะเบียน",
  "Stamps": "แสตมป์",
  "Referral": "การแนะนำ",
  "Register": "ลงทะเบียน",
  "Top Outlets": "สาขายอดนิยม",
  "Users": "ผู้ใช้",
  "Sort by": "เรียงตาม",
  "Top Users": "ผู้ใช้ยอดนิยม",
  "User name": "ชื่อผู้ใช้",
  "Number": "หมายเลข",
  "Spending": "การใช้จ่าย",
  "Number of registered users": "จำนวนผู้ใช้ที่ลงทะเบียน",
  "Number of voucher given to users": "จำนวนคูปองที่มอบให้ผู้ใช้",
  "Total number of voucher given": "จำนวนคูปองทั้งหมดที่มอบให้",
  "Voucher Type": "ประเภทคูปอง",
  "Voucher Value": "มูลค่าคูปอง",
  "Date": "วันที่",
  "Discount Code Type": "ประเภทรหัสส่วนลด",
  "Discount Code Amount": "จำนวนรหัสส่วนลด",
  "Number of Redemption": "จำนวนการแลก",
  "Total Discount Amount": "จำนวนส่วนลดทั้งหมด",
  "Select Outlet": "เลือกสาขา",
  "Select Date": "เลือกวันที่",
  "Select Transaction Date": "เลือกวันที่ทำธุรกรรม",
  "Reset": "รีเซ็ต",
  "Download Report": "ดาวน์โหลดรายงาน",
  "Please fill in the remark": "กรุณากรอกหมายเหตุ",
  "Transaction Date": "วันที่ทำธุรกรรม",
  "Machine Type": "ประเภทเครื่อง",
  "Machine Capacity": "ความจุของเครื่อง",
  "Total Amount": "จำนวนเงินทั้งหมด",
  "Payment Amount": "จำนวนเงินที่ชำระ",
  "Discount From": "ส่วนลดจาก",
  "Discount Amount": "จำนวนส่วนลด",
  "Action": "การดำเนินการ",
  "Remark": "หมายเหตุ",
  "Refund Date": "วันที่คืนเงิน",
  "Refund": "คืนเงิน",
  "Purchase Date": "วันที่ซื้อ",
  "e-Token Purchased (Tokens)": "e-Token ที่ซื้อ (โทเคน)",
  "Bought e-Token using": "ซื้อ e-Token โดยใช้",
  "Transaction ID": "รหัสธุรกรรม",
  "Method": "วิธีการ",
  "Requery": "สอบถามซ้ำ",
  "Reward Date": "วันที่ให้รางวัล",
  "Reward Type": "ประเภทรางวัล",
  "Notification Method": "Notification Method", // new translation
  "Email Preview": "Email Preview",
  "Message for User": "Message for User",
  "Enter the message to be sent to the user": "Enter the message to be sent to the user",
  "Email Address to show on Email Template": "Email Address to show on Email Template",
  "Input Company Email Address": "Input Company Email Address",
  "Company Name to show on Email Template": "Company Name to show on Email Template",
  "Input Company Name": "Input Company Name",
  "Company Phone No. to show on Email Template": "Company Phone No. to show on Email Template",
  "Input Company Phone No.": "Input Company Phone No.",
  "Voucher ID": "รหัสคูปอง",
  "Washer Stamp": "แสตมป์เครื่องซัก",
  "Dryer Stamp": "แสตมป์เครื่องอบ",
  "e-Token Amount": "จำนวน e-Token",
  "Transfer Date": "วันที่โอน",
  "Transfer Amount (Token)": "จำนวนที่โอน (โทเคน)",
  "Number (Transfer From)": "หมายเลข (โอนจาก)",
  "Name (Transfer From)": "ชื่อ (โอนจาก)",
  "Member ID (Transfer From)": "รหัสสมาชิก (โอนจาก)",
  "Number (Transfer To)": "หมายเลข (โอนไปยัง)",
  "Name (Transfer To)": "ชื่อ (โอนไปยัง)",
  "Member ID (Transfer To)": "รหัสสมาชิก (โอนไปยัง)",
  "Grant Date": "วันที่มอบให้",
  "Claim Date": "วันที่เรียกร้อง",
  "Member Details Report": "รายงานรายละเอียดสมาชิก",
  "Transaction Status": "สถานะธุรกรรม",
  "Token Reload": "เติมโทเคน",
  "Token Transfer": "โอนโทเคน",
  "Voucher History": "ประวัติคูปอง",
  "TOKEN": "โทเคน",
  "Message Sent": "ข้อความที่ส่ง",
  "Date Sent": "วันที่ส่ง",
  "Remaining SMS Credit": "เครดิต SMS ที่เหลือ",
  "Store": "ร้าน",
  "Total Registered Users": "จำนวนผู้ใช้ที่ลงทะเบียนทั้งหมด",
  "User registered but no transaction record": "ผู้ใช้ลงทะเบียนแต่ไม่มีประวัติธุรกรรม",
  "Registered": "ลงทะเบียนแล้ว",
  "User spending is less than": "การใช้จ่ายของผู้ใช้น้อยกว่า",
  "Active": "ใช้งาน",
  "User spending is from": "การใช้จ่ายของผู้ใช้ตั้งแต่",
  "until": "จนถึง",
  "Tier 1": "ระดับ 1",
  "User spending is": "การใช้จ่ายของผู้ใช้คือ",
  "and above": "และสูงกว่า",
  "Tier 2": "ระดับ 2",
  "Average spending in each transaction": "การใช้จ่ายเฉลี่ยในแต่ละธุรกรรม",
  "Average Spending": "การใช้จ่ายเฉลี่ย",
  "Total number of transactions": "จำนวนธุรกรรมทั้งหมด",
  "Total Transactions": "ธุรกรรมทั้งหมด",
  "Number of vouchers used in all the transactions": "จำนวนคูปองที่ใช้ในธุรกรรมทั้งหมด",
  "Voucher Claimed": "คูปองที่เรียกร้อง",
  "Total number of washer machine transactions": "จำนวนธุรกรรมเครื่องซักทั้งหมด",
  "Washer Usage": "การใช้งานเครื่องซัก",
  "Total number of dryer machine transactions": "จำนวนธุรกรรมเครื่องอบทั้งหมด",
  "Dryer Usage": "การใช้งานเครื่องอบ",
  "User Name": "ชื่อผู้ใช้",
  "e-Token Spent": "e-Token ที่ใช้ไป",
  "e-Token Detailed Report": "รายงานรายละเอียด e-Token",
  "e-Token Purchase History": "ประวัติการซื้อ e-Token",
  "Select Purchase Date": "เลือกวันที่ซื้อ",
  "Outlet Name": "ชื่อสาขา",
  "Total number of e-token used in all the transactions": "จำนวน e-token ทั้งหมดที่ใช้ในธุรกรรมทั้งหมด",
  "Total e-Token Collected": "e-Token ทั้งหมดที่เก็บได้",
  "Total number of e-token used in washer machine transactions": "จำนวน e-token ทั้งหมดที่ใช้ในธุรกรรมเครื่องซัก",
  "Washer e-Token Collected": "e-Token เครื่องซักที่เก็บได้",
  "Total number of e-token used in dryer machine transactions": "จำนวน e-token ทั้งหมดที่ใช้ในธุรกรรมเครื่องอบ",
  "Dryer e-Token Collected": "e-Token เครื่องอบที่เก็บได้",
  "Purchase History": "ประวัติการซื้อ",
  "All": "ทั้งหมด",
  "Most Bought e-Token Package": "แพ็คเกจ e-Token ที่ซื้อมากที่สุด",
  "for": "สำหรับ",
  "Tokens": "โทเคน",
  "Total Earned from users buying e-Tokens": "รายได้รวมจากผู้ใช้ที่ซื้อ e-Tokens",
  "Total e-Tokens Bought from you": "e-Tokens ทั้งหมดที่ซื้อจากคุณ",
  "Join Date": "วันที่เข้าร่วม",
  "Channel": "ช่องทาง",
  "Tier": "ระดับ",
  "Referrer": "ผู้แนะนำ",
  "Referrals": "การแนะนำ",
  "Number of transactions for last 7 days": "จำนวนธุรกรรมใน 7 วันที่ผ่านมา",
  "Number of Usage (in week)": "จำนวนการใช้งาน (ในสัปดาห์)",
  "Number of transactions for last 1 month": "จำนวนธุรกรรมใน 1 เดือนที่ผ่านมา",
  "Number of Usage (in month)": "จำนวนการใช้งาน (ในเดือน)",
  "Total amount spent in all the transactions": "จำนวนเงินรวมที่ใช้ในธุรกรรมทั้งหมด",
  "Total Spent": "การใช้จ่ายรวม",
  "Last Transaction Date": "วันที่ทำธุรกรรมล่าสุด",
  "Number of vouchers collected": "จำนวนคูปองที่เก็บได้",
  "Voucher Collected": "คูปองที่เก็บได้",
  "Number of vouchers used": "จำนวนคูปองที่ใช้",
  "Voucher Spent": "คูปองที่ใช้ไป",
  "Washer Used": "เครื่องซักที่ใช้",
  "Dryer Used": "เครื่องอบที่ใช้",
  "Total number of e-Token spent in all the transactions": "จำนวน e-Token ทั้งหมดที่ใช้ในธุรกรรมทั้งหมด",
  "Total number of stamps collected": "จำนวนแสตมป์ทั้งหมดที่เก็บได้",
  "Total Stamps": "แสตมป์ทั้งหมด",
  "Select Join Date": "เลือกวันที่เข้าร่วม",
  "Select Grant Date": "เลือกวันที่มอบให้",
  "Amount": "จำนวน",
  "The image size is over 2MB": "ขนาดรูปภาพเกิน 2MB",
  "Duplicate language selected": "เลือกภาษาซ้ำ",
  "Are you sure you want to add FAQ?": "คุณแน่ใจหรือไม่ที่จะเพิ่มคำถามที่พบบ่อย?",
  "FAQ added successfully": "เพิ่มคำถามที่พบบ่อยสำเร็จ",
  "FAQ Group added successfully": "เพิ่มกลุ่มคำถามที่พบบ่อยสำเร็จ",
  "Please provide the faq with the default language": "กรุณาระบุคำถามที่พบบ่อยพร้อมภาษาเริ่มต้น",
  "Add FAQ": "เพิ่มคำถามที่พบบ่อย",
  "Default Language": "ภาษาเริ่มต้น",
  "Select Default Language": "เลือกภาษาเริ่มต้น",
  "Image (Optional)": "รูปภาพ (ไม่บังคับ)",
  "max": "สูงสุด",
  "Question": "คำถาม",
  "Select Language": "เลือกภาษา",
  "Remove": "ลบ",
  "Answer": "คำตอบ",
  "Add Translation": "เพิ่มการแปล",
  "Are you sure you want to add FAQ Group?": "คุณแน่ใจหรือไม่ที่จะเพิ่มกลุ่มคำถามที่พบบ่อย?",
  "Please provide the faq group with the default language": "กรุณาระบุกลุ่มคำถามที่พบบ่อยด้วยภาษาเริ่มต้น",
  "Add FAQ Group": "เพิ่มกลุ่มคำถามที่พบบ่อย",
  "Title": "หัวข้อ",
  "Title Language": "ภาษาของหัวข้อ",
  "Please fill in the title": "กรุณากรอกหัวข้อ",
  "Please fill in the description": "กรุณากรอกคำอธิบาย",
  "Please fill in the short description": "กรุณากรอกคำอธิบายสั้นๆ",
  "Please select the end date for your news": "กรุณาเลือกวันที่สิ้นสุดสำหรับข่าวสารของคุณ",
  "Please provide the news with the default language": "กรุณาระบุข่าวสารด้วยภาษาเริ่มต้น",
  "Please provide the banner image": "กรุณาระบุรูปภาพแบนเนอร์",
  "Please provide the news image": "กรุณาระบุรูปภาพข่าวสาร",
  "Are you sure you want to add news?": "คุณแน่ใจหรือไม่ที่จะเพิ่มข่าวสาร?",
  "News added successfully": "เพิ่มข่าวสารสำเร็จ",
  "Please fill in all the required fields before submit": "กรุณากรอกข้อมูลในช่องที่จำเป็นทั้งหมดก่อนส่ง",
  "Add News": "เพิ่มข่าว",
  "Banner Image": "รูปภาพแบนเนอร์",
  "News Image": "รูปภาพข่าวสาร",
  "Banner": "แบนเนอร์",
  "aspect ratio": "อัตราส่วนภาพ",
  "Image": "รูปภาพ",
  "Display in": "แสดงใน",
  "Inbox Page": "หน้ากล่องข้อความ",
  "Promotion Banner": "แบนเนอร์โปรโมชั่น",
  "Select Period: (optional)": "เลือกช่วงเวลา: (ไม่บังคับ)",
  "Short Description": "คำอธิบายสั้น",
  "Your theme has been updated successfully": "อัปเดตธีมของคุณสำเร็จแล้ว",
  "Default Theme": "ธีมเริ่มต้น",
  "Change Logo": "เปลี่ยนโลโก้",
  "REMOVE LOGO": "ลบโลโก้",
  "Sms credit used up": "เครดิต SMS หมดแล้ว",
  "Please top up your sms credit": "กรุณาเติมเครดิต SMS ของคุณ",
  "Please": "กรุณา",
  "Again": "อีกครั้ง",
  "Welcome": "ยินดีต้อนรับ",
  "Ranking": "อันดับ",
  "Select Year": "เลือกปี",
  "Missing Field": "ช่องที่ขาดหายไป",
  "Please select can be used for washer, dryer or package": "กรุณาเลือกว่าสามารถใช้กับเครื่องซัก เครื่องอบ หรือแพ็คเกจ",
  "Please fill in the start date and end date": "กรุณากรอกวันที่เริ่มต้นและวันที่สิ้นสุด",
  "Discount amount should be more than 0": "จำนวนส่วนลดควรมากกว่า 0",
  "Redeem per user should be more than 0": "การแลกต่อผู้ใช้ควรมากกว่า 0",
  "Redeem per day should be more than 0": "การแลกต่อวันควรมากกว่า 0",
  "Your discount code have edited successfully": "แก้ไขรหัสส่วนลดของคุณสำเร็จแล้ว",
  "Your discount code have added successfully": "เพิ่มรหัสส่วนลดของคุณสำเร็จแล้ว",
  "Delete this entry?": "ลบรายการนี้?",
  "Your discount code have deleted successfully": "ลบรหัสส่วนลดของคุณสำเร็จแล้ว",
  "Created Date": "วันที่สร้าง",
  "Discount code": "รหัสส่วนลด",
  "General Discount Code": "รหัสส่วนลดทั่วไป",
  "Unique Discount Code": "รหัสส่วนลดเฉพาะ",
  "Start Date": "วันที่เริ่มต้น",
  "End Date": "วันที่สิ้นสุด",
  "Redeem Per User": "แลกรับต่อผู้ใช้",
  "Redeem Per Day": "แลกรับต่อวัน",
  "Add Discount Code": "เพิ่มรหัสส่วนลด",
  "Generate Discount Code": "สร้างรหัสส่วนลด",
  "No Discount Code": "ไม่มีรหัสส่วนลด",
  "Edit Discount Code": "แก้ไขรหัสส่วนลด",
  "Select Period": "เลือกช่วงเวลา",
  "Redeem per user": "แลกรับต่อผู้ใช้",
  "How many times the discount code can be used for each user": "รหัสส่วนลดสามารถใช้ได้กี่ครั้งสำหรับผู้ใช้แต่ละคน",
  "Redeem per day": "แลกต่อวัน",
  "How many times the discount code can be used in one day": "รหัสส่วนลดสามารถใช้ได้กี่ครั้งในหนึ่งวัน",
  "If ticked, the discount code can apply to washer machine transaction": "ถ้าติ๊ก รหัสส่วนลดสามารถใช้กับธุรกรรมเครื่องซักได้",
  "If ticked, the discount code can apply to dryer machine transaction": "ถ้าติ๊ก รหัสส่วนลดสามารถใช้กับธุรกรรมเครื่องอบได้",
  "If ticked, the discount code can apply to Drop n Go transaction": "ถ้าติ๊ก รหัสส่วนลดสามารถใช้กับธุรกรรม Drop n Go ได้",
  "The user enter the discount code": "ผู้ใช้ป้อนรหัสส่วนลด",
  "in the payment page will get": "ในหน้าชำระเงินจะได้รับ",
  "discount": "ส่วนลด",
  "The discount code only can be used start from {{ start_date }} until {{ end_date }}": "รหัสส่วนลดสามารถใช้ได้ตั้งแต่ {{ start_date }} จนถึง {{ end_date }}",
  "Every user only can use the discount code {{ redeem_per_user }} times": "ผู้ใช้แต่ละคนสามารถใช้รหัสส่วนลดได้ {{ redeem_per_user }} ครั้ง",
  "Every user only can use {{ redeem_per_user }} discount code from this batch of code": "ผู้ใช้แต่ละคนสามารถใช้รหัสส่วนลดจากชุดรหัสนี้ได้ {{ redeem_per_user }} ครั้ง",
  "Only {{ redeem_per_day }} transactions can use the discount code in one day_one": "มีเพียง {{ redeem_per_day }} ธุรกรรมที่สามารถใช้รหัสส่วนลดได้ในหนึ่งวัน",
  "Only {{ redeem_per_day }} transactions can use the discount code in one day_other": "มีเพียง {{ redeem_per_day }} ธุรกรรมที่สามารถใช้รหัสส่วนลดได้ในหนึ่งวัน",
  "The discount code only can be used for washer machine": "รหัสส่วนลดสามารถใช้ได้กับเครื่องซักเท่านั้น",
  "The discount code only can be used for dryer machine": "รหัสส่วนลดสามารถใช้ได้กับเครื่องอบเท่านั้น",
  "The discount code only can be used for Drop n Go transaction": "รหัสส่วนลดสามารถใช้ได้กับธุรกรรม Drop n Go เท่านั้น",
  "The discount code can be used for washer machine or Drop n Go transaction": "รหัสส่วนลดสามารถใช้ได้กับเครื่องซักหรือธุรกรรม Drop n Go",
  "The discount code can be used for dryer machine or Drop n Go transaction": "รหัสส่วนลดสามารถใช้ได้กับเครื่องอบหรือธุรกรรม Drop n Go",
  "The discount code can be used for washer machine, dryer machine or Drop n Go transaction": "รหัสส่วนลดสามารถใช้ได้กับเครื่องซัก เครื่องอบ หรือธุรกรรม Drop n Go",
  "The discount code can be used for washer or dryer machine": "รหัสส่วนลดสามารถใช้ได้กับเครื่องซักหรือเครื่องอบ",
  "The discount code only can be used after user spending {{ currency }} {{ pay_and_use_amount }}": "รหัสส่วนลดสามารถใช้ได้หลังจากผู้ใช้ใช้จ่าย {{ currency }} {{ pay_and_use_amount }}",
  "Are you sure you want to edit FAQ?": "คุณแน่ใจหรือไม่ที่จะแก้ไขคำถามที่พบบ่อย?",
  "FAQ updated successfully": "อัปเดตคำถามที่พบบ่อยสำเร็จแล้ว",
  "Edit FAQ": "แก้ไขคำถามที่พบบ่อย",
  "Are you sure you want to edit FAQ Group?": "คุณแน่ใจหรือไม่ที่จะแก้ไขกลุ่มคำถามที่พบบ่อย?",
  "FAQ Group updated successfully": "อัปเดตกลุ่มคำถามที่พบบ่อยสำเร็จแล้ว",
  "Edit FAQ Group": "แก้ไขกลุ่มคำถามที่พบบ่อย",
  "Are you sure you want to edit news?": "คุณแน่ใจหรือไม่ที่จะแก้ไขข่าวสาร?",
  "News edit successfully": "แก้ไขข่าวสารสำเร็จแล้ว",
  "Edit News": "แก้ไขข่าวสาร",
  "All the FAQ under this group will be deleted. Are you sure you want to delete FAQ Group?": "คำถามที่พบบ่อยทั้งหมดในกลุ่มนี้จะถูกลบ คุณแน่ใจหรือไม่ที่จะลบกลุ่มคำถามที่พบบ่อยนี้?",
  "FAQ Group deleted successfully": "ลบกลุ่มคำถามที่พบบ่อยสำเร็จแล้ว",
  "FAQ List": "รายการคำถามที่พบบ่อย",
  "NO FAQ GROUP": "ไม่มีกลุ่มคำถามที่พบบ่อย",
  "FAQ Group": "กลุ่มคำถามที่พบบ่อย",
  "Are you sure you want to delete FAQ?": "คุณแน่ใจหรือไม่ที่จะลบคำถามที่พบบ่อย?",
  "FAQ deleted successfully": "ลบคำถามที่พบบ่อยสำเร็จแล้ว",
  "NO FAQs": "ไม่มีคำถามที่พบบ่อย",
  "FAQ": "คำถามที่พบบ่อย",
  "Easy": "ง่าย",
  "Fast Laundromate": "ซักรีดด่วน",
  "Smart Laundry Management System": "ระบบจัดการซักรีดอัจฉริยะ",
  "Enter your username and password to login": "ป้อนชื่อผู้ใช้และรหัสผ่านของคุณเพื่อเข้าสู่ระบบ",
  "Username": "ชื่อผู้ใช้",
  "Are you sure you want to delete news?": "คุณแน่ใจหรือไม่ที่จะลบข่าวสาร?",
  "News deleted successfully": "ลบข่าวสารสำเร็จแล้ว",
  "NO NEWS": "ไม่มีข่าวสาร",
  "The referral setting has been updated successfully!": "อัปเดตการตั้งค่าการแนะนำสำเร็จแล้ว!",
  "Number of Stamp": "จำนวนแสตมป์",
  "Number of Washer Stamp": "จำนวนแสตมป์เครื่องซัก",
  "Number of Dryer Stamp": "จำนวนแสตมป์เครื่องอบ",
  "Enable Referral Feature?": "เปิดใช้งานคุณสมบัติการแนะนำ?",
  "Are you sure you want to delete the tier setting?": "Are you sure you want to delete the tier setting?", // new translation
  "Minimum referee transaction amount for referrer to get referral rewards": "Minimum referee transaction amount for referrer to get referral rewards",
  "Maximum number of reward within this tier": "Maximum number of reward within this tier",
  "Maximum number of reward can get from same user": "Maximum number of reward can get from same user",
  "Point Grant Type": "Point Grant Type",
  "Select Point Grant Type": "Select Point Grant Type",
  "Token Grant Type": "Token Grant Type",
  "Select Token Grant Type": "Select Token Grant Type",
  "Add Tier": "Add Tier",
  // "Maximum number of referrals for each user": "จำนวนการแนะนำสูงสุดสำหรับผู้ใช้แต่ละคน",
  // "Minimum amount of transaction": "จำนวนเงินขั้นต่ำของธุรกรรม",
  "Stamp": "แสตมป์",
  "If ticked, the system will give stamp to user": "ถ้าติ๊ก ระบบจะให้แสตมป์แก่ผู้ใช้",
  "If ticked, the system will give e-Token to user": "ถ้าติ๊ก ระบบจะให้ e-Token แก่ผู้ใช้",
  "If ticked, the system will give voucher to user": "ถ้าติ๊ก ระบบจะให้คูปองแก่ผู้ใช้",
  "Number of E-Token": "จำนวน E-Token",
  "Reward Giveaway": "การแจกรางวัล",
  "Which type of reward you want to give to customer?": "คุณต้องการให้รางวัลประเภทใดแก่ลูกค้า?",
  "For machine uses (required)": "สำหรับการใช้เครื่อง (จำเป็น)",
  "leave blank if no expiry date": "เว้นว่างไว้ถ้าไม่มีวันหมดอายุ",
  "Remarks": "หมายเหตุ",
  "Reward": "รางวัล",
  "Please fill in all the required field. (Title, description, and amount must be more than 0)": "กรุณากรอกข้อมูลในช่องที่จำเป็นทั้งหมด (หัวข้อ, คำอธิบาย และจำนวนต้องมากกว่า 0)",
  "Checkbox required": "กรุณาใส่เครื่องหมายช่อง",
  "Must select voucher can be used for washer, dryer or package": "ต้องเลือกว่าคูปองสามารถใช้กับเครื่องซัก เครื่องอบ หรือแพ็คเกจ",
  "How many e-token?": "จำนวน e-token เท่าไร?",
  "Number of e-Token": "จำนวน e-Token",
  "Token value must be more than 0": "มูลค่าโทเคนต้องมากกว่า 0",
  "STAMP": "แสตมป์",
  "Please fill in at least one of the required field": "กรุณากรอกข้อมูลในช่องที่จำเป็นอย่างน้อยหนึ่งช่อง",
  "The value must be more than 0": "ค่าต้องมากกว่า 0",
  "How many times rewarded to user": "ให้รางวัลแก่ผู้ใช้กี่ครั้ง",
  "Rewarded Times": "จำนวนครั้งที่ให้รางวัล",
  "How many times rewarded voucher to user": "ให้คูปองเป็นรางวัลแก่ผู้ใช้กี่ครั้ง",
  "Voucher Rewarded (times)": "บัตรกำนัลรับรางวัล (ครั้ง)",
  "How many times rewarded stamp to user": "ให้แสตมป์เป็นรางวัลแก่ผู้ใช้กี่ครั้ง",
  "Stamps Rewarded (times)": "แสตมป์รับรางวัล (ครั้ง)",
  "Total number of washer stamps rewarded to user": "จำนวนแสตมป์เครื่องซักทั้งหมดที่ให้เป็นรางวัลแก่ผู้ใช้",
  "Washer Stamps Granted (stamp)": "แสตมป์เครื่องซักที่มอบให้ (แสตมป์)",
  "Total number of dryer stamps rewarded to user": "จำนวนแสตมป์เครื่องอบทั้งหมดที่ให้เป็นรางวัลแก่ผู้ใช้",
  "Dryer Stamps Granted (stamp)": "แสตมป์เครื่องอบที่มอบให้ (แสตมป์)",
  "How many times rewarded e-Token to user": "ให้ e-Token เป็นรางวัลแก่ผู้ใช้กี่ครั้ง",
  "e-Token Rewarded (times)": "e-Token ที่รับรางวัล (ครั้ง)",
  "Total number of e-Token rewarded to user": "จำนวน e-Token ทั้งหมดที่รับรางวัลแก่ผู้ใช้",
  "Total e-Token Granted (token)": "e-Token ทั้งหมดที่มอบให้ (โทเคน)",
  "Point Rewarded (times)": "Point Rewarded (times)", // new translation
  "How many times rewarded point to user": "How many times rewarded point to user",
  "Total Point Granted (point)": "Total Point Granted (point)",
  "Total number of point rewarded to user": "Total number of point rewarded to user",
  "Point Amount": "Point Amount",
  "Reward History": "ประวัติการให้รางวัล",
  "Are you sure you want to change how the stamping mechanics works?": "คุณแน่ใจหรือไม่ที่จะเปลี่ยนวิธีการทำงานของกลไกการสะสมแสตมป์?",
  "The stamping mechanics setting has updated successfully!": "อัปเดตการตั้งค่าการสะสมแสตมป์สำเร็จแล้ว!",
  "The voucher setting has been updated successfully!": "อัปเดตการตั้งค่าคูปองสำเร็จแล้ว!",
  "Mechanics Settings": "การตั้งค่ากลไก",
  "Minimum transaction amount for getting stamp": "จำนวนเงินขั้นต่ำของธุรกรรมเพื่อรับแสตมป์",
  "Select User Stamping Mechanic": "เลือกกลไกการสะสมแสตมป์ของผู้ใช้",
  "Separated Stamping": "การสะสมแสตมป์แยกกัน",
  "Combined Stamping": "การสะสมแสตมป์รวมกัน",
  "Stamp for?": "แสตมป์สำหรับ?",
  "Both": "ทั้งสอง",
  "Washer Only": "เครื่องซักเท่านั้น",
  "Dryer Only": "เครื่องอบเท่านั้น",
  "Separated Stamping = Different washer and dryer stamp collection. (example: Using Washer will gain 1 washer's stamp, and Dryer will gain 1 dryer stamp)": "การสะสมแสตมป์แยกกัน = การสะสมแสตมป์เครื่องซักและเครื่องอบแยกกัน (ตัวอย่าง: การใช้เครื่องซักจะได้รับ 1 แสตมป์เครื่องซัก และเครื่องอบจะได้รับ 1 แสตมป์เครื่องอบ)",
  "Combined Stamping = Washer and dryer has the same collection. (example: Using Washer or Dryer will gain 1 stamp)": "การสะสมแสตมป์รวมกัน = เครื่องซักและเครื่องอบมีการสะสมเดียวกัน (ตัวอย่าง: การใช้เครื่องซักหรือเครื่องอบจะได้รับ 1 แสตมป์)",
  "Stamp for": "แสตมป์สำหรับ",
  "User will be able to collect both washer stamp and dryer stamp": "ผู้ใช้จะสามารถสะสมทั้งแสตมป์เครื่องซักและแสตมป์เครื่องอบได้",
  "User will only able to collect Washer stamp": "ผู้ใช้จะสามารถสะสมแสตมป์เครื่องซักได้เท่านั้น",
  "User will only able to collect Dryer stamp": "ผู้ใช้จะสามารถสะสมแสตมป์เครื่องอบได้เท่านั้น",
  "Registration": "การลงทะเบียน",
  "Birthday": "วันเกิด",
  "Please choose at least one payment method": "กรุณาเลือกวิธีการชำระเงินอย่างน้อยหนึ่งวิธี",
  "Please choose at least one member or non-member payment": "Please choose at least one member or non-member payment", // new translation
  "Are you sure you want to change the payment setting?": "คุณแน่ใจหรือไม่ที่จะเปลี่ยนการตั้งค่าการชำระเงิน?",
  "The payment setting has updated successfully!": "อัปเดตการตั้งค่าการชำระเงินสำเร็จแล้ว!",
  "Invalid input": "ข้อมูลไม่ถูกต้อง",
  "The price and token must be more than 0": "ราคาและโทเคนต้องมากกว่า 0",
  "Maximum number of purchase must be more than 1": "จำนวนการซื้อสูงสุดต้องมากกว่า 1",
  "Point amount must be more than 1": "Point amount must be more than 1", // new translation
  "Added a new Token Bundle Package": "เพิ่มแพ็คเกจชุดโทเคนใหม่แล้ว",
  "Bundle deleted successfully!": "ลบชุดสำเร็จแล้ว!",
  "Reload voucher setting have edited successfully!": "แก้ไขการตั้งค่าคูปองเติมเงินสำเร็จแล้ว!",
  "Added on": "เพิ่มเมื่อ",
  "remove": "ลบ",
  "edit": "แก้ไข",
  "NO PACKAGES ON SALES": "ไม่มีแพ็คเกจที่กำลังขาย",
  "Point Settings": "Point Settings", // new translation
  "Enable Loyalty Point": "Enable Loyalty Point",
  "Point Name": "Point Name",
  "Number Of Point Equals To": "Number Of Point Equals To",
  "If ticked, the point will have expired date": "If ticked, the point will have expired date",
  "Point Lifespan": "Point Lifespan",
  "months": "months",
  "Minimum Transaction Amount To Get Point": "Minimum Transaction Amount To Get Point",
  "Point Grant Amount": "Point Grant Amount",
  "Point settings updated successfully!": "Point settings updated successfully!",
  "Point Eligibility": "Point Eligibility",
  "If ticked, the system will give point to user when user reload token successfully": "If ticked, the system will give point to user when user reload token successfully",
  "{{ point_amount }} point will be given after user reload token successfully": "{{ point_amount }} point will be given after user reload token successfully",
  "Payment Settings": "การตั้งค่าการชำระเงิน",
  "Select Payment Method": "เลือกวิธีการชำระเงิน",
  "Enable E-Payment?": "เปิดใช้งานการชำระเงินอิเล็กทรอนิกส์?",
  "Enable Token Currency?": "เปิดใช้งานสกุลเงินโทเคน?",
  // "E-payment for?": "การชำระเงินอิเล็กทรอนิกส์สำหรับ?",
  // "Member & Non-Member Payment": "การชำระเงินสำหรับสมาชิกและไม่ใช่สมาชิก",
  // "Only Member Payment": "การชำระเงินสำหรับสมาชิกเท่านั้น",
  "Member and Non-Member Settings": "Member and Non-Member Settings", // new translation
  "Enable Member Payment?": "Enable Member Payment?",
  "Enable Non-Member Payment?": "Enable Non-Member Payment?",
  "Promotion Settings": "การตั้งค่าโปรโมชั่น",
  "Enable Non Member Promotion?": "เปิดใช้งานโปรโมชั่นสำหรับไม่ใช่สมาชิก?",
  "Enable Notification When Cycle Done?": "เปิดใช้งานการแจ้งเตือนเมื่อรอบการทำงานเสร็จสิ้น?",
  "SMS charges": "ค่าบริการ SMS",
  "SMS Settings": "SMS Settings", // new translation
  "Enable Free Sms For Members": "Enable Free Sms For Members",
  "If enable notification, user can choose either receive email or sms when complete washing or drying": "ถ้าเปิดใช้งานการแจ้งเตือน ผู้ใช้สามารถเลือกรับอีเมลหรือ SMS เมื่อซักหรืออบเสร็จสิ้น",
  // "If user choose sms, user have to pay extra {{ currency }}{{ sms_notification_amount }} for the sms": "ถ้าผู้ใช้เลือก SMS ผู้ใช้ต้องจ่ายเพิ่ม {{ สกุลเงิน }}{{ จำนวนเงินแจ้งเตือน sms}} สำหรับ SMS",
  "If user choose sms, non member have to pay extra {{ currency }}{{ sms_notification_amount }} for the sms": "If user choose sms, non member have to pay extra {{ currency }}{{ sms_notification_amount }} for the sms",
  "If user choose sms, member and non member have to pay extra {{ currency }}{{ sms_notification_amount }} for the sms": "If user choose sms, member and non member have to pay extra {{ currency }}{{ sms_notification_amount }} for the sms",
  "Add Package": "เพิ่มแพ็คเกจ",
  "Edit e-Token Package": "แก้ไขแพ็คเกจ e-Token",
  "Add e-Token Package": "เพิ่มแพ็คเกจ e-Token",
  "Please only input numberic value to prevent any bugs.": "กรุณาป้อนค่าตัวเลขเท่านั้นเพื่อป้องกันข้อผิดพลาด",
  "Price": "ราคา",
  "If ticked, the system will limit the number of purchase": "ถ้าติ๊ก ระบบจะจำกัดจำนวนการซื้อ",
  "Limit Number of Purchase": "จำกัดจำนวนการซื้อ",
  "Number of maximum purchase": "จำนวนการซื้อสูงสุด",
  "Reload Voucher": "คูปองเติมเงิน",
  "If ticked, the system will give voucher to user when user reload token successfully": "ถ้าติ๊ก ระบบจะให้คูปองแก่ผู้ใช้เมื่อผู้ใช้เติมโทเคนสำเร็จ",
  "If ticked, the system will give voucher repeatly": "ถ้าติ๊ก ระบบจะให้คูปองซ้ำๆ",
  "Recurring": "ทำซ้ำ",
  "Total number of voucher given to user": "จำนวนคูปองทั้งหมดที่ให้แก่ผู้ใช้",
  "Period for giving next voucher": "ระยะเวลาสำหรับการให้คูปองถัดไป",
  "Token package {{ currency }}{{ price }} for {{ numberOfToken }} tokens_one": "แพ็คเกจโทเคน {{ currency  }}{{ price }} สำหรับ {{numberOfToken }} โทเคน",
  "Token package {{ currency }}{{ price }} for {{ numberOfToken }} tokens_other": "แพ็คเกจโทเคน {{ currency  }}{{ price }} สำหรับ {{ numberOfToken }} โทเคน",
  "Each user only can purchase {{ max_purchase }} times for the token package": "ผู้ใช้แต่ละคนสามารถซื้อแพ็คเกจโทเคนได้เพียง {{ max_purchase}} ครั้งสำหรับแพ็คเกจโทเค็น",
  "Voucher will be given after user reload token successfully": "จะให้คูปองหลังจากผู้ใช้เติมโทเคนสำเร็จ",
  "Voucher will not given after user reload tokens successfully": "จะไม่ให้คูปองหลังจากผู้ใช้เติมโทเคนสำเร็จ",
  "The voucher will be expired after {{ voucher_duration }} days_one": "คูปองจะหมดอายุหลังจาก {{ voucher_duration }} วัน",
  "The voucher will be expired after {{ voucher_duration }} days_other": "คูปองจะหมดอายุหลังจาก {{ voucher_duration }} วัน",
  "The voucher will not expired": "คูปองจะไม่หมดอายุ",
  "Total {{ recurring_number }} vouchers will be given to user and each voucher will discount_one": "จะให้คูปองทั้งหมด {{ recurring_number }} ใบแก่ผู้ใช้และแต่ละคูปองจะลดราคา",
  "Total {{ recurring_number }} vouchers will be given to user and each voucher will discount_other": "จะให้คูปองทั้งหมด {{ recurring_number }} ใบแก่ผู้ใช้และแต่ละคูปองจะลดราคา",
  "Edit Package": "แก้ไขแพ็คเกจ",
  "Age": "อายุ",
  "Total number of vouchers collected": "จำนวนคูปองทั้งหมดที่สะสมได้",
  "Vouchers Acquired": "คูปองที่ได้รับ",
  "Total amount spent in washer machine transactions": "จำนวนเงินทั้งหมดที่ใช้ในธุรกรรมเครื่องซัก",
  "Total Washer Payment": "การชำระเงินเครื่องซักทั้งหมด",
  "Total amount spent in dryer machine transactions": "จำนวนเงินทั้งหมดที่ใช้ในธุรกรรมเครื่องอบ",
  "Total Dryer Payment": "การชำระเงินเครื่องอบทั้งหมด",
  "Total amount spent in all transactions": "จำนวนเงินทั้งหมดที่ใช้ในธุรกรรมทั้งหมด",
  "Total Payment": "การชำระเงินทั้งหมด",
  "report": "รายงาน",
  "Total amount spent for buying e-Token": "จำนวนเงินทั้งหมดที่ใช้ในการซื้อ e-Token",
  "Total number of e-Token purchased": "จำนวน e-Token ทั้งหมดที่ซื้อ",
  "Total e-Token Purchased (Tokens)": "e-Token ทั้งหมดที่ซื้อ (โทเคน)",
  "Total number of e-Token used in washer machine transactions": "จำนวน e-Token ทั้งหมดที่ใช้ในธุรกรรมเครื่องซัก",
  "Washer Payment (Tokens)": "การชำระเงินเครื่องซัก (โทเคน)",
  "Total number of e-Token used in dryer machine transactions": "จำนวน e-Token ทั้งหมดที่ใช้ในธุรกรรมเครื่องอบ",
  "Dryer Payment (Tokens)": "การชำระเงินเครื่องอบ (โทเคน)",
  "Total number of e-Token used in all the transactions": "จำนวน e-Token ทั้งหมดที่ใช้ในธุรกรรมทั้งหมด",
  "Total Payment (Tokens)": "การชำระเงินทั้งหมด (โทเคน)",
  "Number of washer stamp collected currently": "จำนวนแสตมป์เครื่องซักที่สะสมได้ในปัจจุบัน",
  "Number of dryer stamp collected currently": "จำนวนแสตมป์เครื่องอบที่สะสมได้ในปัจจุบัน",
  "User Phone Number / Member ID / Email": "หมายเลขโทรศัพท์ผู้ใช้ / รหัสสมาชิก / อีเมล",
  "Example: 0123456789,A123456": "ตัวอย่าง: 0123456789,A123456",
  "SEARCH": "ค้นหา",
  "Total Users Registered": "จำนวนผู้ใช้ที่ลงทะเบียนทั้งหมด",
  "Total Active Outlets": "จำนวนสาขาที่ใช้งานทั้งหมด",
  "Total Vouchers Given": "จำนวนคูปองที่ให้ทั้งหมด",
  "Total Transaction Counts": "จำนวนธุรกรรมทั้งหมด",
  "Total User Stamps Collected": "จำนวนแสตมป์ที่ผู้ใช้สะสมได้ทั้งหมด",
  "Distributor Login": "เข้าสู่ระบบผู้จัดจำหน่าย",
  "Client Login": "เข้าสู่ระบบลูกค้า",
  "Kiosk Login": "เข้าสู่ระบบคีออสก์",
  "Promotion": "โปรโมชั่น",
  "Please select operator": "กรุณาเลือกผู้ให้บริการ",
  "Please select distributor": "กรุณาเลือกผู้จัดจำหน่าย",
  "Select Operator": "เลือกผู้ให้บริการ",
  "Select Distributor": "เลือกผู้จัดจำหน่าย",
  "User can login or skip to payment without login to make the payment": "User can login or skip to payment without login to make the payment", // new translation
  "User only can skip to payment without login to make the payment": "ผู้ใช้สามารถข้ามไปยังการชำระเงินโดยไม่ต้องเข้าสู่ระบบเพื่อทำการชำระเงิน",
  "User have to login only can make the payment": "ผู้ใช้ต้องเข้าสู่ระบบเท่านั้นจึงจะสามารถทำการชำระเงินได้",
  "Only CSV file is accepted": "ยอมรับเฉพาะไฟล์ CSV เท่านั้น",
  "The user and the balance have updated": "ผู้ใช้และยอดคงเหลือได้รับการอัปเดตแล้ว",
  "Register Time": "เวลาลงทะเบียน",
  "Upload User CSV file": "อัปโหลดไฟล์ CSV ผู้ใช้",
  "Sample CSV file": "ไฟล์ CSV ตัวอย่าง",
  "Please follow the CSV file format": "กรุณาทำตามรูปแบบไฟล์ CSV",
  "User CSV file": "ไฟล์ CSV ผู้ใช้",
  "Only CSV file (comma delimited) is support now": "รองรับเฉพาะไฟล์ CSV (คั่นด้วยเครื่องหมายจุลภาค) เท่านั้นในขณะนี้",
  "Confirm uploaded data": "ยืนยันข้อมูลที่อัปโหลด",
  "Operator": "ผู้ให้บริการ",
  "Distributor": "ผู้จัดจำหน่าย",
  "Refund By": "คืนเงินโดย",
  "Reward By": "ให้รางวัลโดย",
  "Number Of Voucher Code": "จำนวนรหัสคูปอง",
  "Number Of Discount Code": "จำนวนรหัสส่วนลด",
  "New User Only": "เฉพาะผู้ใช้ใหม่เท่านั้น",
  "Minimum Transaction Amount To Redeem": "จำนวนเงินขั้นต่ำของธุรกรรมเพื่อแลก",
  "Download": "ดาวน์โหลด",
  "Generate Voucher Code": "สร้างรหัสคูปอง",
  "Your voucher code is generating": "กำลังสร้างรหัสคูปองของคุณ",
  "Your discount code is generating": "กำลังสร้างรหัสส่วนลดของคุณ",
  "Voucher Code Settings": "การตั้งค่ารหัสคูปอง",
  "If ticked, the voucher code only can redeem in register page": "ถ้าติ๊ก รหัสคูปองจะสามารถแลกได้ในหน้าลงทะเบียนเท่านั้น",
  "If ticked, the user only can use the voucher after make a transaction": "ถ้าติ๊ก ผู้ใช้จะสามารถใช้คูปองได้หลังจากทำธุรกรรมเท่านั้น",
  "If ticked, the user only can use the discount code after make a transaction": "ถ้าติ๊ก ผู้ใช้จะสามารถใช้รหัสส่วนลดได้หลังจากทำธุรกรรมเท่านั้น",
  "Expired Date Of Voucher Code": "วันหมดอายุของรหัสคูปอง",
  "Minimum Transaction Amount To Use Voucher": "จำนวนเงินขั้นต่ำของธุรกรรมเพื่อใช้คูปอง",
  "Minimum Transaction Amount To Use Code": "จำนวนเงินขั้นต่ำของธุรกรรมเพื่อใช้รหัส",
  "Max redemption per user in this batch voucher code": "การแลกสูงสุดต่อผู้ใช้ในชุดรหัสคูปองนี้",
  "Max redemption per user in this batch discount code": "การแลกสูงสุดต่อผู้ใช้ในชุดรหัสส่วนลดนี้",
  "Add Voucher": "เพิ่มคูปอง",
  "Completed": "เสร็จสิ้น",
  "Voucher Code Report": "รายงานรหัสคูปอง",
  "Floating Token Report": "Floating Token Report", // new translation
  "Floating Point Report": "Floating Point Report",
  // "User Floating Token Report": "รายงานโทเคนลอยของผู้ใช้",
  "E-Payment Report": "รายงานการชำระเงินอิเล็กทรอนิกส์",
  "Referral Reward History Report": "Referral Reward History Report", // new translation
  "Redemption Date": "วันที่แลก",
  "Redemption Code": "รหัสการแลก",
  "Select Redemption Date": "เลือกวันที่แลก",
  "Select Voucher Type": "เลือกประเภทคูปอง",
  "Giveaway": "แจกฟรี",
  "Select Name": "เลือกชื่อ",
  "Amount must be more than 1": "จำนวนต้องมากกว่า 1",
  "Payment method setting have updated successfully!": "อัปเดตการตั้งค่าวิธีการชำระเงินสำเร็จแล้ว!",
  "Edit Payment Method": "แก้ไขวิธีการชำระเงิน",
  "Minimum payment amount to include all the payment methods": "จำนวนเงินขั้นต่ำในการชำระเงินเพื่อรวมวิธีการชำระเงินทั้งหมด",
  "Exclude Payment Method in Revenue Monster": "ยกเว้นวิธีการชำระเงินใน Revenue Monster",
  "Welcome to": "ยินดีต้อนรับสู่",
  "Instruction": "คำแนะนำ",
  "Please select the machine": "กรุณาเลือกเครื่อง",
  "Login your account": "เข้าสู่ระบบบัญชีของคุณ",
  "Select the setting and make the payment": "เลือกการตั้งค่าและทำการชำระเงิน",
  "Phone Number / Email": "หมายเลขโทรศัพท์ / อีเมล",
  "Reload & Transfer In": "Reload & Transfer In", // new translation
  "Usage & Transfer Out": "Usage & Transfer Out",
  "Usage": "การใช้งาน",
  "Remaining": "คงเหลือ",
  "Gain": "Gain", // new translation
  "Type": "ประเภท",
  "Order Date": "วันที่สั่งซื้อ",
  "Pack Number": "หมายเลขแพ็ค",
  "Pack Details": "รายละเอียดแพ็ค",
  "Size": "ขนาด",
  "Mode": "โหมด",
  "Machine Payment": "การชำระเงินเครื่อง",
  "DNG History": "ประวัติ DNG",
  "Token History": "ประวัติโทเคน",
  "Stamp History": "ประวัติแสตมป์",
  "Staff Login": "เข้าสู่ระบบพนักงาน",
  "Drop And Go Portal": "พอร์ทัล Drop And Go",
  "Available Task": "งานที่มีอยู่",
  "Completed Task": "งานที่เสร็จสิ้น",
  "Task ID": "รหัสงาน",
  "Start Task": "เริ่มงาน",
  "Select Machine": "เลือกเครื่อง",
  "Start Machine": "เริ่มเครื่อง",
  "Reward To": "Reward To", // new translation
  "Reward From": "Reward From",
  "Transaction Account": "Transaction Account",
  "Point": "Point",
  "POINT": "POINT",
  "Number of Point": "Number of Point",
  "Point History": "Point History",
  "Customer Name": "Customer Name",
  "Number of Washer Transactions": "Number of Washer Transactions",
  "Number of Dryer Transactions": "Number of Dryer Transactions",
  "Vouchers Redeemed": "Vouchers Redeemed",
  "Discount Code Redemption": "Discount Code Redemption",
  "Total Spend": "Total Spend",
  "Select Outlet(s)": "Select Outlet(s)",
  "User Usage Report": "User Usage Report",
  // cleanpro plus th
  "Wash": "ซัก",
  "Dry": "อบ",
  "Fold": "พับ",
  "Scan QR": "สแกน QR",
  "Capacity": "ความจุ",
  "Duration": "ระยะเวลา",
  "Custom duration": "ระยะเวลาที่กำหนดเอง",
  "Choose duration": "เลือกระยะเวลา",
  "Select the correct amount on the machine after payment.": "เลือกจำนวนที่ถูกต้องบนเครื่องหลังจากชำระเงิน",
  "Offers": "ข้อเสนอ",
  "An easier way to pay": "วิธีการชำระเงินที่ง่ายขึ้น",
  "Create account": "สร้างบัญชี",
  "Login": "เข้าสู่ระบบ",
  "By signing up, you agree to": "โดยการลงทะเบียน คุณยอมรับ",
  "Amount to Pay": "จำนวนเงินที่ต้องชำระ",
  // "Total {{ recurring_number }} vouchers will be given to user and each voucher will discount_one": "จะให้คูปองทั้งหมด {{ recurring_number }} ใบแก่ผู้ใช้และแต่ละคูปองจะลด",
  // "Total {{ recurring_number }} vouchers will be given to user and each voucher will discount_other": "จะให้คูปองทั้งหมด {{ recurring_number }} ใบแก่ผู้ใช้และแต่ละคูปองจะลด",
  // "Edit Package": "แก้ไขแพ็คเกจ",
  // "Age": "อายุ",
  // "Total number of vouchers collected": "จำนวนคูปองทั้งหมดที่สะสมได้",
  // "Vouchers Acquired": "คูปองที่ได้รับ",
  // "Total amount spent in washer machine transactions": "จำนวนเงินทั้งหมดที่ใช้จ่ายในธุรกรรมเครื่องซัก",
  // "Total Washer Payment": "การชำระเงินเครื่องซักทั้งหมด",
  // "Total amount spent in dryer machine transactions": "จำนวนเงินทั้งหมดที่ใช้จ่ายในธุรกรรมเครื่องอบ",
  // "Total Dryer Payment": "การชำระเงินเครื่องอบทั้งหมด",
  // "Total amount spent in all transactions": "จำนวนเงินทั้งหมดที่จ่ายใช้ในธุรกรรมทั้งหมด",
  // "Total Payment": "การชำระเงินทั้งหมด",
  // "report": "รายงาน",
  // "Total amount spent for buying e-Token": "จำนวนเงินทั้งหมดที่ใช้ในการซื้อ e-Token",
  // "Total number of e-Token purchased": "จำนวน e-Token ทั้งหมดที่ซื้อ",
  // "Total e-Token Purchased (Tokens)": "e-Token ทั้งหมดที่ซื้อ (โทเคน)",
  // "Total number of e-Token used in washer machine transactions": "จำนวน e-Token ทั้งหมดที่ใช้ในธุรกรรมเครื่องซัก",
  // "Washer Payment (Tokens)": "การชำระเงินเครื่องซัก (โทเคน)",
  // "Total number of e-Token used in dryer machine transactions": "จำนวน e-Token ทั้งหมดที่ใช้ในธุรกรรมเครื่องอบ",
  // "Dryer Payment (Tokens)": "การชำระเงินเครื่องอบ (โทเคน)",
  // "Total number of e-Token used in all the transactions": "จำนวน e-Token ทั้งหมดที่ใช้ในธุรกรรมทั้งหมด",
  // "Total Payment (Tokens)": "การชำระเงินทั้งหมด (โทเคน)",
  // "Number of washer stamp collected currently": "จำนวนแสตมป์เครื่องซักที่สะสมได้ในปัจจุบัน",
  // "Number of dryer stamp collected currently": "จำนวนแสตมป์เครื่องอบที่สะสมได้ในปัจจุบัน",
  // "User Phone Number / Member ID / Email": "หมายเลขโทรศัพท์ผู้ใช้ / รหัสสมาชิก / อีเมล",
  // "Example: 0123456789,A123456": "ตัวอย่าง: 0123456789,A123456",
  // "SEARCH": "ค้นหา",
  // "Total Users Registered": "จำนวนผู้ใช้ที่ลงทะเบียนทั้งหมด",
  // "Total Active Outlets": "จำนวนสาขาที่ใช้งานทั้งหมด",
  // "Total Vouchers Given": "จำนวนคูปองที่ให้ทั้งหมด",
  // "Total Transaction Counts": "จำนวนธุรกรรมรวมทั้งหมด",
  // "Total User Stamps Collected": "จำนวนแสตมป์ที่ผู้ใช้สะสมได้ทั้งหมด",
  // "Distributor Login": "เข้าสู่ระบบผู้จัดจำหน่าย",
  // "Client Login": "เข้าสู่ระบบลูกค้า",
  // "Kiosk Login": "เข้าสู่ระบบคีออสก์",
  // "Promotion": "โปรโมชั่น",
  // "Please select operator": "กรุณาเลือกผู้ให้บริการ",
  // "Please select distributor": "กรุณาเลือกผู้จัดจำหน่าย",
  // "Select Operator": "เลือกผู้ให้บริการ",
  // "Select Distributor": "เลือกผู้จัดจำหน่าย",
  // "User can skip to payment without login to make the payment": "ผู้ใช้สามารถข้ามไปยังการชำระเงินโดยไม่ต้องเข้าสู่ระบบเพื่อทำการชำระเงิน",
  // "User have to login only can make the payment": "ผู้ใช้ต้องเข้าสู่ระบบเท่านั้นจึงจะสามารถทำการชำระเงินได้",
  // "Only CSV file is accepted": "ยอมรับเฉพาะไฟล์ CSV เท่านั้น",
  // "The user and the balance have updated": "ผู้ใช้และยอดคงเหลือได้รับการอัปเดตแล้ว",
  // "Register Time": "เวลาลงทะเบียน",
  // "Upload User CSV file": "อัปโหลดไฟล์ CSV ผู้ใช้",
  // "Sample CSV file": "ไฟล์ CSV ตัวอย่าง",
  // "Please follow the CSV file format": "กรุณาทำตามรูปแบบไฟล์ CSV",
  // "User CSV file": "ไฟล์ CSV ผู้ใช้",
  // "Only CSV file (comma delimited) is support now": "รองรับเฉพาะไฟล์ CSV (คั่นด้วยเครื่องหมายจุลภาค) เท่านั้นในขณะนี้",
  // "Confirm uploaded data": "ยืนยันข้อมูลที่อัปโหลด",
  // "Operator": "ผู้ให้บริการ",
  // "Distributor": "ผู้จัดจำหน่าย",
  // "Refund By": "คืนเงินโดย",
  // "Reward By": "ให้รางวัลโดย",
  // "Number Of Voucher Code": "จำนวนรหัสคูปอง",
  // "Number Of Discount Code": "จำนวนรหัสส่วนลด",
  // "New User Only": "เฉพาะผู้ใช้ใหม่เท่านั้น",
  // "Minimum Transaction Amount To Redeem": "จำนวนเงินขั้นต่ำของธุรกรรมเพื่อแลก",
  // "Download": "ดาวน์โหลด",
  // "Generate Voucher Code": "สร้างรหัสคูปอง",
  // "Your voucher code is generating": "กำลังสร้างรหัสคูปองของคุณ",
  // "Your discount code is generating": "กำลังสร้างรหัสส่วนลดของคุณ",
  // "Voucher Code Settings": "การตั้งค่ารหัสคูปอง",
  // "If ticked, the voucher code only can redeem in register page": "ถ้าติ๊ก รหัสคูปองจะสามารถแลกได้ในหน้าลงทะเบียนเท่านั้น",
  // "If ticked, the user only can use the voucher after make a transaction": "ถ้าติ๊ก ผู้ใช้จะสามารถใช้คูปองได้หลังจากทำธุรกรรมเท่านั้น",
  // "If ticked, the user only can use the discount code after make a transaction": "ถ้าติ๊ก ผู้ใช้จะสามารถใช้รหัสส่วนลดได้หลังจากทำธุรกรรมเท่านั้น",
  // "Expired Date Of Voucher Code": "วันหมดอายุของรหัสคูปอง",
  // "Minimum Transaction Amount To Use Voucher": "จำนวนเงินขั้นต่ำของธุรกรรมเพื่อใช้คูปอง",
  // "Minimum Transaction Amount To Use Code": "จำนวนเงินขั้นต่ำของธุรกรรมเพื่อใช้รหัส",
  // "Max redemption per user in this batch voucher code": "การแลกสูงสุดต่อผู้ใช้ในชุดรหัสคูปองนี้",
  // "Max redemption per user in this batch discount code": "การแลกสูงสุดต่อผู้ใช้ในชุดรหัสส่วนลดนี้",
  // "Add Voucher": "เพิ่มคูปอง",
  // "Completed": "เสร็จสิ้น",
  // "Voucher Code Report": "รายงานรหัสคูปอง",
  // "User Floating Token Report": "รายงานโทเคนลอยของผู้ใช้",
  // "E-Payment Report": "รายงานการชำระเงินอิเล็กทรอนิกส์",
  // "Redemption Date": "วันที่แลก",
  // "Redemption Code": "รหัสการแลก",
  // "Select Redemption Date": "เลือกวันที่แลก",
  // "Select Voucher Type": "เลือกประเภทคูปอง",
  // "Giveaway": "แจกฟรี",
  // "Select Name": "เลือกชื่อ",
  // "Amount must be more than 1": "จำนวนต้องมากกว่า 1",
  // "Payment method setting have updated successfully!": "อัปเดตการตั้งค่าวิธีการชำระเงินสำเร็จแล้ว!",
  // "Edit Payment Method": "แก้ไขวิธีการชำระเงิน",
  // "Minimum payment amount to include all the payment methods": "จำนวนเงินขั้นต่ำในการชำระเงินเพื่อรวมวิธีการชำระเงินทั้งหมด",
  // "Exclude Payment Method in Revenue Monster": "ยกเว้นวิธีการชำระเงินใน Revenue Monster",
  // "Welcome to": "ยินดีต้อนรับสู่",
  // "Instruction": "คำแนะนำ",
  // "Please select the machine": "กรุณาเลือกเครื่อง",
  // "Login your account": "เข้าสู่ระบบบัญชีของคุณ",
  // "Select the setting and make the payment": "เลือกการตั้งค่าและทำการชำระเงิน",
  // "Phone Number / Email": "หมายเลขโทรศัพท์ / อีเมล",
  // "Usage": "การใช้งาน",
  // "Remaining": "คงเหลือ",
  // "Type": "ประเภท",
  // "Order Date": "วันที่สั่งซื้อ",
  // "Pack Number": "หมายเลขแพ็ค",
  // "Pack Details": "รายละเอียดแพ็ค",
  // "Size": "ขนาด",
  // "Mode": "โหมด",
  // "Machine Payment": "การชำระเงินเครื่อง",
  // "DNG History": "ประวัติ DNG",
  // "Token History": "ประวัติโทเคน",
  // "Stamp History": "ประวัติแสตมป์",
  // "Staff Login": "เข้าสู่ระบบพนักงาน",
  // "Drop And Go Portal": "พอร์ทัล Drop And Go",
  // "Available Task": "งานที่มีอยู่",
  // "Completed Task": "งานที่เสร็จสิ้น",
  // "Task ID": "รหัสงาน",
  // "Start Task": "เริ่มงาน",
  // "Select Machine": "เลือกเครื่อง",
  // "Start Machine": "เริ่มเครื่อง",
  // "Wash": "ซัก",
  // "Dry": "อบ",
  // "Fold": "พับ",
  // "Scan QR": "สแกน QR",
  // "Capacity": "ความจุ",
  // "Duration": "ระยะเวลา",
  // "Custom duration": "ระยะเวลาที่กำหนดเอง",
  // "Choose duration": "เลือกระยะเวลา",
  // "Select the correct amount on the machine after payment.": "เลือกจำนวนที่ถูกต้องบนเครื่องหลังจากชำระเงิน",
  // "Offers": "ข้อเสนอ",
  // "An easier way to pay": "วิธีการชำระเงินที่ง่ายขึ้น",
  // "Create account": "สร้างบัญชี",
  // "Login": "เข้าสู่ระบบ",
  // "By signing up, you agree to": "โดยการลงทะเบียน คุณยอมรับ",
  // "Amount to Pay": "จำนวนเงินที่ต้องชำระ",
  "1 voucher selected": "เลือก 1 คูปอง",
  "No voucher selected": "ไม่ได้เลือกคูปอง",
  "Credits": "เครดิต",
  "credits": "เครดิต",
  "credit": "เครดิต",
  "Credit balance": "ยอดเครดิตคงเหลือ",
  "Please ensure that the machine door is shut & your laundry is inside.": "กรุณาตรวจสอบให้แน่ใจว่าประตูเครื่องปิดสนิทและผ้าของคุณอยู่ด้านใน",
  "Insufficient balance": "ยอดเงินไม่เพียงพอ",
  "Topup now": "เติมเงินตอนนี้",
  "Copyright © 2023 Cleanpro Laundry Holdings Sdn Bhd (821842-P) All rights reserved.": "ลิขสิทธิ์ © 2023 Cleanpro Laundry Holdings Sdn Bhd (821842-P) สงวนลิขสิทธิ์ทั้งหมด",
  "Good Evening!": "สวัสดีตอนเย็น!",
  "Hello": "สวัสดี",
  "Wallet Balance": "ยอดเงินในกระเป๋าเงิน",
  "Topup": "เติมเงิน",
  "More details": "รายละเอียดเพิ่มเติม",
  "Collect 10 e-stamps to earn x1 FREE wash or dry voucher": "สะสม 10 e-stamps เพื่อรับคูปองซักหรืออบฟรี 1 ครั้ง",
  "For every wash or dry cycle you complete with us, you'll earn one e-stamp. Collect 10 and you'll be able to redeem a FREE wash or dry cycle. It's our way of saying thank you for choosing Cleanpro!": "สำหรับทุกรอบการซักหรืออบที่คุณใช้บริการกับเรา คุณจะได้รับหนึ่ง e-stamp สะสม 10 ดวงและคุณจะสามารถแลกรับรอบการซักหรืออบฟรีได้ นี่คือวิธีที่เราขอบคุณที่คุณเลือก Cleanpro!",
  "For every wash cycle you complete with us, you'll earn one e-stamp. Collect 10 and you'll be able to redeem a FREE wash or dry cycle. It's our way of saying thank you for choosing Cleanpro!": "สำหรับทุกรอบการซักที่คุณใช้บริการกับเรา คุณจะได้รับหนึ่ง e-stamp สะสม 10 ดวงและคุณจะสามารถแลกรับรอบการซักหรืออบฟรีได้ นี่คือวิธีที่เราขอบคุณที่คุณเลือก Cleanpro!",
  "For every dry cycle you complete with us, you'll earn one e-stamp. Collect 10 and you'll be able to redeem a FREE wash or dry cycle. It's our way of saying thank you for choosing Cleanpro!": "สำหรับทุกรอบการอบที่คุณใช้บริการกับเรา คุณจะได้รับหนึ่ง e-stamp สะสม 10 ดวงและคุณจะสามารถแลกรับรอบการซักหรืออบฟรีได้ นี่คือวิธีที่เราขอบคุณที่คุณเลือก Cleanpro!",
  "Credits OFF": "ส่วนลดเครดิต",
  "% OFF": "% ส่วนลด",
  "Valid Till": "ใช้ได้ถึง",
  "Used On": "ใช้เมื่อ",
  "Expired On": "หมดอายุเมื่อ",
  "Available": "พร้อมใช้งาน",
  "Expired": "หมดอายุ",
  "Used": "ใช้แล้ว",
  "Promotions": "โปรโมชั่น",
  "Rewards": "รางวัล",
  "Inbox": "กล่องข้อความ",
  "Activities": "กิจกรรม",
  "Support": "การสนับสนุน",
  "Active Rewards": "รางวัลที่ใช้งานได้",
  "Past Rewards": "รางวัลที่ผ่านมา",
  "Get rewarded for your loyalty with Cleanpro Plus Rewards!": "รับรางวัลสำหรับความภักดีของคุณกับ Cleanpro Plus Rewards!",
  "Earn vouchers and redeem them for discounts, freebies and more. The more you use our services the more you save!": "รับคูปองและแลกรับส่วนลด ของแถม และอื่นๆ ยิ่งคุณใช้บริการของเรามากเท่าไหร่ คุณก็ยิ่งประหยัดมากขึ้นเท่านั้น!",
  "Up to 3 months history": "ประวัติย้อนหลังสูงสุด 3 เดือน",
  "Filter": "กรอง",
  "All Transactions": "ธุรกรรมทั้งหมด",
  "Transactions": "ธุรกรรม",
  "Select amount to topup": "เลือกจำนวนเงินที่จะเติม",
  "Balance": "ยอดคงเหลือ",
  "You've just paid": "คุณเพิ่งชำระเงิน",
  "Need help?": "ต้องการความช่วยเหลือ?",
  "24-Hour Careline Number": "หมายเลขสายด่วน 24 ชั่วโมง",
  "Email Us": "ส่งอีเมลถึงเรา",
  "Call Now": "โทรตอนนี้",
  "Email Now": "ส่งอีเมลตอนนี้",
  "Update name": "อัปเดตชื่อ",
  "Update email address": "อัปเดตที่อยู่อีเมล",
  "Update phone number": "อัปเดตหมายเลขโทรศัพท์",
  "Change password": "เปลี่ยนรหัสผ่าน",
  "Change language": "เปลี่ยนภาษา",
  "User ID": "รหัสผู้ใช้",
  "Let us know your feedback": "แจ้งความคิดเห็นของคุณให้เราทราบ",
  "Poor": "แย่",
  "Good": "ดี",
  "Leave addtional feedback here (optional)": "แสดงความคิดเห็นเพิ่มเติมที่นี่ (ไม่บังคับ)",
  "Submit feedback": "ส่งความคิดเห็น",
  "Pay as guest": "ชำระเงินในฐานะผู้ใช้ทั่วไป",
  "Pay now": "ชำระเงินตอนนี้",
  "Select voucher to use": "เลือกคูปองที่จะใช้",
  "Use voucher": "ใช้คูปอง",
  "Total": "รวม",
  "Transaction Type": "ประเภทธุรกรรม",
  "Credit Topup": "เติมเครดิต",
  "Credit topup": "เติมเครดิต",
  "Credit Transfer": "โอนเครดิต",
  "Date/Time": "วันที่/เวลา",
  "Reference ID": "รหัสอ้างอิง",
  "Status": "สถานะ",
  "Share Your Referral Code": "แชร์รหัสแนะนำของคุณ",
  "cleanproReferralShortDesc_one": "รับเครดิตฟรี {{ voucherAmount }} เครดิตเมื่อคุณแชร์รหัสแนะนำของคุณให้กับเพื่อนและครอบครัวหลังจากทำธุรกรรมสำเร็จ แตะเพื่อเรียนรู้เพิ่มเติม",
  "cleanproReferralShortDesc_other": "รับเครดิตฟรี {{ voucherAmount }} เครดิตเมื่อคุณแชร์รหัสแนะนำของคุณให้กับเพื่อนและครอบครัวหลังจากทำธุรกรรมสำเร็จ แตะเพื่อเรียนรู้เพิ่มเติม",
  "Use": "ใช้",
  "dryer or washer": "เครื่องอบหรือเครื่องซัก",
  "washer": "เครื่องซัก",
  "dryer": "เครื่องอบ",
  "dryer and washer": "เครื่องอบและเครื่องซัก",
  "machine with the minimum spending of": "เครื่องที่มีการใช้จ่ายขั้นต่ำ",
  "Welcome to Cleanpro Plus": "ยินดีต้อนรับสู่ Cleanpro Plus",
  "Only scan, set & pay! An easier, more convenient way to pay with Cleanpro Plus! No need to download an app. Enjoy more rewards when you use Cleanpro Plus today! Tap to learn more.": "เพียงสแกน ตั้งค่า และชำระเงิน! วิธีการชำระเงินที่ง่ายและสะดวกกว่าด้วย Cleanpro Plus! ไม่จำเป็นต้องดาวน์โหลดแอพ รับรางวัลมากขึ้นเมื่อคุณใช้ Cleanpro Plus วันนี้! แตะเพื่อเรียนรู้เพิ่มเติม",
  "Cleanpro Pay Migration Notice": "ประกาศการย้ายไปใช้ Cleanpro Pay",
  "Dear valued Cleanpro customers, please be informed that as of 22 June 2023, the Cleanpro Pay app will be upgraded and and no longer available for use. Tap to learn more.": "เรียนลูกค้า Cleanpro ที่มีค่า โปรดทราบว่าตั้งแต่วันที่ 22 มิถุนายน 2566 แอพ Cleanpro Pay จะได้รับการอัพเกรดและไม่สามารถใช้งานได้อีกต่อไป แตะเพื่อเรียนรู้เพิ่มเติม",
  "Answer simple questions and win credits for your next wash or dry! This contest is only available for selected outlets only. Tap to learn more.": "ตอบคำถามง่ายๆ และรับเครดิตสำหรับการซักหรืออบครั้งต่อไปของคุณ! การแข่งขันนี้มีให้เฉพาะสาขาที่เลือกเท่านั้น แตะเพื่อเรียนรู้เพิ่มเติม",
  "The all-new Cleanpro Plus is a new payment method that includes loyalty features built into the app. The more you use our services and pay using Cleanpro Plus, the more you can save. With every transaction, you can earn rewards that will help you save on your next laundry expenses.": "Cleanpro Plus ใหม่ล่าสุดเป็นวิธีการชำระเงินใหม่ที่รวมคุณสมบัติความภักดีเข้าไว้ในแอพ ยิ่งคุณใช้บริการของเราและชำระเงินด้วย Cleanpro Plus มากเท่าไหร่ คุณก็ยิ่งประหยัดได้มากขึ้นเท่านั้น ในทุกๆ ธุรกรรม คุณสามารถรับรางวัลที่จะช่วยให้คุณประหยัดค่าใช้จ่ายในการซักรีดครั้งต่อไปของคุณ",
  "To use Cleanpro Plus, simply scan the QR code on the machines and pay using your preferred credit/debit card or e-wallet. We hope that Cleanpro Plus will help simplify your laundry routine and give you more time for the things that matter.": "ในการใช้ Cleanpro Plus เพียงสแกนรหัส QR บนเครื่องและชำระเงินโดยใช้บัตรเครดิต/เดบิตหรือกระเป๋าเงินอิเล็กทรอนิกส์ที่คุณต้องการ เราหวังว่า Cleanpro Plus จะช่วยทำให้การซักรีดของคุณง่ายขึ้นและให้เวลากับคุณมากขึ้นสำหรับสิ่งที่สำคัญ",
  "If you have any questions about Cleanpro Plus, please read our FAQ in the menu or contact our careline at +603 2770 0100 or email us at info@cleanprolaundry.com. Thank you for choosing Cleanpro.": "หากคุณมีคำถามใดๆ เกี่ยวกับ Cleanpro Plus โปรดอ่าน FAQ ในเมนูหรือติดต่อสายด่วนของเราที่ +603 2770 0100 หรือส่งอีเมลถึงเราที่ info@cleanprolaundry.com ขอบคุณที่เลือก Cleanpro",
  "Dear valued Cleanpro customers, please be informed that as of 22 June 2023, the existing Cleanpro Pay mobile app will be upgraded and no longer available for use.": "เรียนลูกค้า Cleanpro ที่มีค่า โปรดทราบว่าตั้งแต่วันที่ 22 มิถุนายน 2566 แอพมือถือ Cleanpro Pay ที่มีอยู่จะได้รับการอัพเกรดและไม่สามารถใช้งานได้อีกต่อไป",
  "We are migrating to the all-new Cleanpro Plus. It is a new cashless payment method, without the need to download apps.": "เรากำลังย้ายไปใช้ Cleanpro Plus เวอร์ชันใหม่ ซึ่งเป็นวิธีการชำระเงินแบบไม่ใช้เงินสด โดยไม่จำเป็นต้องดาวน์โหลดแอพ",
  "If you are the existing user of Cleanpro Pay, any of your remaining balance in the app will be transferred to your Cleanpro Plus account. You are required to use the same mobile number to sign up for Cleanpro Plus for balance transfer.": "หากคุณเป็นผู้ใช้ Cleanpro Pay ที่มีอยู่ ยอดเงินคงเหลือของคุณในแอพจะถูกโอนไปยังบัญชี Cleanpro Plus ของคุณ คุณจำเป็นต้องใช้หมายเลขโทรศัพท์มือถือเดิมในการลงทะเบียน Cleanpro Plus เพื่อโอนยอดเงิน",
  "If you need help on Cleanpro Plus, please feel free to call our careline at +603 2770 0100 or email us at info@cleanprolaundry.com": "หากคุณต้องการความช่วยเหลือเกี่ยวกับ Cleanpro Plus โปรดติดต่อสายด่วนของเราที่ +603 2770 0100 หรือส่งอีเมลถึงเราที่ info@cleanprolaundry.com",
  "Answer simple questions and win credits for your next wash or dry!": "ตอบคำถามง่ายๆ และรับเครดิตสำหรับการซักหรืออบครั้งต่อไปของคุณ!",
  "Step 1 : Scan the Jawab & Gerenti Menang QR code in the participated outlets.": "ขั้นตอนที่ 1 : สแกนรหัส QR Jawab & Gerenti Menang ในสาขาที่เข้าร่วม",
  "Step 2 : Answer 3 simple questions.": "ขั้นตอนที่ 2 : ตอบคำถามง่ายๆ 3 ข้อ",
  "Step 3 : Submit and stand a chance to win prizes.": "ขั้นตอนที่ 3 : ส่งคำตอบและลุ้นรับรางวัล",
  "Grand Prize :": "รางวัลใหญ่ :",
  "x10 per outlet - 100 credits worth of vouchers in Cleanpro Plus": "10 รางวัลต่อสาขา - คูปองมูลค่า 100 เครดิตใน Cleanpro Plus",
  "Consolation Prize :": "รางวัลปลอบใจ :",
  "X25 per outlet - 50 credits worth of vouchers in Cleanpro Plus": "25 รางวัลต่อสาขา - คูปองมูลค่า 50 เครดิตใน Cleanpro Plus",
  "Participation prize:": "รางวัลการเข้าร่วม:",
  "Every participants are eligible to receive a FREE 3 credits voucher (worth RM3) in Cleanpro Plus.": "ผู้เข้าร่วมทุกคนมีสิทธิ์รับคูปอง 3 เครดิตฟรี (มูลค่า 3 ริงกิต) ใน Cleanpro Plus",
  "If you have any questions please don't hesitate to contact our careline at +603 2770 0100 or email us at info@cleanprolaundry.com. Thank you for choosing Cleanpro.": "หากคุณมีคำถามใดๆ โปรดอย่าลังเลที่จะติดต่อสายด่วนของเราที่ +603 2770 0100 หรือส่งอีเมลถึงเราที่ info@cleanprolaundry.com ขอบคุณที่เลือก Cleanpro",
  "Payment Confirmation": "ยืนยันการชำระเงิน",
  "This campaign is only eligible for users who use the all-new Cleanpro Plus web application.": "แคมเปญนี้มีสิทธิ์เฉพาะผู้ใช้ที่ใช้แอปพลิเคชันเว็บ Cleanpro Plus ใหม่ล่าสุดเท่านั้น",
  "ONE e-stamp will be rewarded for each wash or dry cycle.": "จะได้รับ ONE e-stamp สำหรับแต่ละรอบการซักหรืออบ",
  "ONE e-stamp will be rewarded for each wash cycle.": "จะได้รับ ONE e-stamp สำหรับแต่ละรอบการซัก",
  "ONE e-stamp will be rewarded for each dry cycle.": "จะได้รับ ONE e-stamp สำหรับแต่ละรอบการอบ",
  "Regardless of the washer or dryer settings, the number of e-stamps being collected is the same.": "ไม่ว่าจะเป็นการตั้งค่าเครื่องซักหรือเครื่องอบ จำนวน e-stamps ที่สะสมได้จะเท่ากัน",
  "The type of e-stamp collected is based on the services that you have used.": "ประเภทของ e-stamp ที่สะสมได้ขึ้นอยู่กับบริการที่คุณใช้",
  "Washing service will reward washer e-stamp and drying service will reward dryer e-stamp.": "บริการซักจะให้รางวัลเป็น e-stamp เครื่องซัก และบริการอบจะให้รางวัลเป็น e-stamp เครื่องอบ",
  "Washing service will reward washer e-stamp.": "บริการซักจะให้รางวัลเป็น e-stamp เครื่องซัก",
  "Drying service will reward dryer e-stamp.": "บริการอบจะให้รางวัลเป็น e-stamp เครื่องอบ",
  "E-stamps will automatically be added to your account once the condition has been fulfilled.": "E-stamps จะถูกเพิ่มเข้าบัญชีของคุณโดยอัตโนมัติเมื่อเงื่อนไขครบถ้วน",
  "There is no limit on how many e-stamps can be gathered in a single day. So long as you meet the requirements to collect the e-stamps.": "ไม่มีข้อจำกัดว่าสามารถสะสม e-stamps ได้กี่ดวงในหนึ่งวัน ตราบใดที่คุณตรงตามข้อกำหนดในการสะสม e-stamps",
  "The type of e-stamps that have been collected is final and cannot be changed.": "ประเภทของ e-stamps ที่สะสมได้เป็นขั้นสุดท้ายและไม่สามารถเปลี่ยนแปลงได้",
  "There is no expiration for the e-stamps; only till the discontinuation of the campaign.": "ไม่มีวันหมดอายุสำหรับ e-stamps เว้นแต่จะมีการยกเลิกแคมเปญ",
  "E-stamps do not have any currency value, and they cannot be converted to cash or credits.": "E-stamps ไม่มีมูลค่าเป็นเงินตรา และไม่สามารถแลกเป็นเงินสดหรือเครดิตได้",
  "This campaign is not applicable with any other ongoing promotions, offers, vouchers, and/or discounts provided by the participating outlets.": "แคมเปญนี้ไม่สามารถใช้ร่วมกับโปรโมชั่น ข้อเสนอ คูปอง และ/หรือส่วนลดอื่นๆ ที่กำลังดำเนินอยู่ซึ่งจัดโดยสาขาที่เข้าร่วม",
  "These terms and conditions are subject to change at any time without prior notice.": "ข้อกำหนดและเงื่อนไขเหล่านี้อาจมีการเปลี่ยนแปลงได้ตลอดเวลาโดยไม่ต้องแจ้งให้ทราบล่วงหน้า",
  "Press START on the machine to begin washing.": "กดปุ่ม START บนเครื่องเพื่อเริ่มการซัก",
  "Press START on the machine to begin drying.": "กดปุ่ม START บนเครื่องเพื่อเริ่มการอบ",
  "Reminder": "การแจ้งเตือน",
  "Remember to select the same settings you have paid for on the corresponding machine.": "อย่าลืมเลือกการตั้งค่าเดียวกันกับที่คุณได้ชำระเงินไว้บนเครื่องที่เกี่ยวข้อง",
  "Please ensure that your laundry is inside the machine and door is properly shut.": "กรุณาตรวจสอบให้แน่ใจว่าผ้าของคุณอยู่ในเครื่องและประตูปิดสนิทแล้ว",
  "voucherCreditsOff_one": "ส่วนลด {{currentVoucherAmount}} เครดิต",
  "voucherCreditsOff_other": "ส่วนลด {{currentVoucherAmount}} เครดิต",
  "voucherPercentageOff": "ส่วนลด {{currentVoucherAmount}}%",
  "cleanproReferralFlatVoucher_one": "รับเครดิตฟรีเมื่อคุณแชร์รหัสแนะนำ Cleanpro Plus ของคุณให้กับเพื่อนและครอบครัว! สำหรับการแนะนำที่สำเร็จแต่ละครั้งที่ทำธุรกรรมแรก คุณจะได้รับคูปองเครดิตฟรี {{ referralVoucherAmount }} เครดิต",
  "cleanproReferralFlatVoucher_other": "รับเครดิตฟรีเมื่อคุณแชร์รหัสแนะนำ Cleanpro Plus ของคุณให้กับเพื่อนและครอบครัว! สำหรับการแนะนำที่สำเร็จแต่ละครั้งที่ทำธุรกรรมแรก คุณจะได้รับคูปองเครดิตฟรี {{ referralVoucherAmount }} เครดิต",
  "cleanproReferralRateVoucher": "รับเครดิตฟรีเมื่อคุณแชร์รหัสแนะนำ Cleanpro Plus ของคุณให้กับเพื่อนและครอบครัว! สำหรับการแนะนำที่สำเร็จแต่ละครั้งที่ทำธุรกรรมแรก คุณจะได้รับคูปองส่วนลด {{ referralVoucherAmount }}% ฟรี",
  "cleanproReferralTokenReward_one": "รับเครดิตฟรีเมื่อคุณแชร์รหัสแนะนำ Cleanpro Plus ของคุณให้กับเพื่อนและครอบครัว! สำหรับการแนะนำที่สำเร็จแต่ละครั้งที่ทำธุรกรรมแรก คุณจะได้รับ {{ referralToken }} เครดิตฟรี",
  "cleanproReferralTokenReward_other": "รับเครดิตฟรีเมื่อคุณแชร์รหัสแนะนำ Cleanpro Plus ของคุณให้กับเพื่อนและครอบครัว! สำหรับการแนะนำที่สำเร็จแต่ละครั้งที่ทำธุรกรรมแรก คุณจะได้รับ {{ referralToken }} เครดิตฟรี",
  "How to get & use code:": "วิธีรับและใช้รหัส:",
  "Go to menu = icon and click “Refer friends”": "ไปที่เมนู = ไอคอนและคลิก “แนะนำเพื่อน”",
  "Share with everyone who will likely to use Cleanpro Plus.": "แชร์กับทุกคนที่มีแนวโน้มจะใช้ Cleanpro Plus",
  "Referral Terms & Conditions:": "ข้อกำหนดและเงื่อนไขการแนะนำ:",
  "When your friend or family register using your referral code, and completed a transaction (a minimum spending of {{ currency }}{{ referralTransactionAmount }}) , you will be rewarded automatically.": "เมื่อเพื่อนหรือครอบครัวของคุณลงทะเบียนโดยใช้รหัสแนะนำของคุณ และทำธุรกรรมเสร็จสมบูรณ์ (มีการใช้จ่ายขั้นต่ำ {{ currency }}{{ referralTransactionAmount }}) คุณจะได้รับรางวัลโดยอัตโนมัติ",
  "cleanproNumberOfReferralReward": "คุณสามารถแชร์ได้กับคนจำนวนมากเท่าที่คุณต้องการ แต่ผู้ใช้แต่ละคนสามารถรับรางวัลการแนะนำได้เพียง {{ numberOfReferral }} ครั้งเท่านั้น",
  "cleanproNumberOfReferral_one": "คุณสามารถแชร์ได้กับคนจำนวนมากเท่าที่คุณต้องการ แต่ผู้ใช้แต่ละคนสามารถรับคูปองการแนะนำได้เพียง {{ numberOfReferral }} ใบเท่านั้น",
  "cleanproNumberOfReferral_other": "คุณสามารถแชร์ได้กับคนจำนวนมากเท่าที่คุณต้องการ แต่ผู้ใช้แต่ละคนสามารถรับคูปองการแนะนำได้เพียง {{ numberOfReferral }} ใบเท่านั้น",
  "The voucher is valid for {{ duration }} days from the day voucher be given._one": "คูปองมีอายุ {{ duration }} วันนับจากวันที่ได้รับคูปอง",
  "The voucher is valid for {{ duration }} days from the day voucher be given._other": "คูปองมีอายุ {{ duration }} วันนับจากวันที่ได้รับคูปอง",
  "The voucher is for customer use only and not for sale.": "คูปองนี้สำหรับลูกค้าใช้เท่านั้น ไม่สามารถขายต่อได้",
  "We believe that your special day deserves a little extra sparkle! That's why we're thrilled to introduce our exclusive birthday rewards program just for you! Starting this year, and every year thereafter, we're giving away {{ currency }}{{ voucherAmount }} credit vouchers to you on your birthday.": "เราเชื่อว่าวันพิเศษของคุณสมควรได้รับความพิเศษเพิ่มเติม! นั่นคือเหตุผลที่เรารู้สึกตื่นเต้นที่จะแนะนำโปรแกรมรางวัลวันเกิดพิเศษสำหรับคุณโดยเฉพาะ! เริ่มตั้งแต่ปีนี้และทุกๆ ปีหลังจากนี้ เราจะมอบคูปองเครดิตมูลค่า {{ currency }}{{ voucherAmount }} ให้คุณในวันเกิดของคุณ",
  "cleanproBirthdayRateAmount": "เราเชื่อว่าวันพิเศษของคุณสมควรได้รับความพิเศษเพิ่มเติม! นั่นคือเหตุผลที่เรารู้สึกตื่นเต้นที่จะแนะนำโปรแกรมรางวัลวันเกิดพิเศษสำหรับคุณโดยเฉพาะ! เริ่มตั้งแต่ปีนี้และทุกๆ ปีหลังจากนี้ เราจะมอบคูปองส่วนลด {{ voucherAmount }}% ให้คุณในวันเกิดของคุณ",
  "Make sure your account details include your birthdate. This way, we'll know exactly when to send you your special birthday surprise.": "ตรวจสอบให้แน่ใจว่ารายละเอียดบัญชีของคุณมีวันเกิดของคุณ เพื่อให้เราทราบได้ชัดเจนว่าควรส่งเซอร์ไพรส์ของขวัญวันเกิดพิเศษให้คุณเมื่อใด",
  "Birthday Voucher Terms & Conditions:": "ข้อกำหนดและเงื่อนไขคูปองวันเกิด:",
  "The voucher will be given on the first day of your birthday month.": "คูปองจะมอบให้ในวันแรกของเดือนเกิดของคุณ",
  "When you applied the voucher during payment, it will give you {{ voucherAmount }} credits ({{ currency }}{{ voucherAmount }}) discount._one": "เมื่อคุณใช้คูปองระหว่างการชำระเงิน คุณจะได้รับส่วนลด {{ voucherAmount }} เครดิต ({{ currency }}{{ voucherAmount }})",
  "When you applied the voucher during payment, it will give you {{ voucherAmount }} credits ({{ currency }}{{ voucherAmount }}) discount._other": "เมื่อคุณใช้คูปองระหว่างการชำระเงิน คุณจะได้รับส่วนลด {{ voucherAmount }} เครดิต ({{ currency }}{{ voucherAmount }})",
  "When you applied the voucher during payment, it will give you {{ voucherAmount }}% discount.": "เมื่อคุณใช้คูปองระหว่างการชำระเงิน คุณจะได้รับส่วนลด {{ voucherAmount }}%",
  "All vouchers is not valid with other ongoing promotions, discounts and offers.": "คูปองทั้งหมดไม่สามารถใช้ร่วมกับโปรโมชั่น ส่วนลด และข้อเสนออื่นๆ ที่กำลังดำเนินอยู่",
  "Claim a complimentary wash and dry for every 10 e-stamps collected! We value your loyalty and want to reward you for choosing Cleanpro Plus. Tap to learn more.": "รับสิทธิ์การซักและอบฟรีทุกๆ 10 e-stamps ที่สะสมได้! เราให้คุณค่ากับความภักดีของคุณและต้องการตอบแทนคุณที่เลือก Cleanpro Plus แตะเพื่อเรียนรู้เพิ่มเติม",
  "cleanproStampFlat_one": "ด้วยทุกๆ 10 e-stamps ที่คุณสะสมได้ คุณสามารถแลกรับการซักหรืออบฟรีสูงสุด {{ voucherAmount }} เครดิตกับ Cleanpro Plus ทำให้การซักรีดของคุณไม่เพียงแต่สะดวกสบาย แต่ยังคุ้มค่าอีกด้วย",
  "cleanproStampFlat_other": "ด้วยทุกๆ 10 e-stamps ที่คุณสะสมได้ คุณสามารถแลกรับการซักหรืออบฟรีสูงสุด {{ voucherAmount }} เครดิตกับ Cleanpro Plus ทำให้การซักรีดของคุณไม่เพียงแต่สะดวกสบาย แต่ยังคุ้มค่าอีกด้วย",
  "cleanproStampRate": "ด้วยทุกๆ 10 e-stamps ที่คุณสะสมได้ คุณสามารถแลกรับส่วนลดสูงสุด {{ voucherAmount }}% สำหรับการซักหรืออบกับ Cleanpro Plus ทำให้การซักรีดของคุณไม่เพียงแต่สะดวกสบาย แต่ยังคุ้มค่าอีกด้วย",
  "A voucher will be instantly added to your Rewards page when you reach 10 washes. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.": "คูปองจะถูกเพิ่มลงในหน้ารางวัลของคุณทันทีเมื่อคุณซักครบ 10 ครั้ง มิเตอร์ e-stamp ของคุณจะถูกรีเซ็ตเป็นศูนย์สำหรับการสะสมครั้งต่อไป แลกคูปองในหน้าสแกน QR เมื่อคุณซักผ้าครั้งต่อไป",
  "A voucher will be instantly added to your Rewards page when you reach 10 drys. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.": "คูปองจะถูกเพิ่มลงในหน้ารางวัลของคุณทันทีเมื่อคุณอบครบ 10 ครั้ง มิเตอร์ e-stamp ของคุณจะถูกรีเซ็ตเป็นศูนย์สำหรับการสะสมครั้งต่อไป แลกคูปองในหน้าสแกน QR เมื่อคุณซักผ้าครั้งต่อไป",
  "A voucher will be instantly added to your Rewards page when you reach 10 washes or drys. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.": "คูปองจะถูกเพิ่มลงในหน้ารางวัลของคุณทันทีเมื่อคุณซักหรืออบครบ 10 ครั้ง มิเตอร์ e-stamp ของคุณจะถูกรีเซ็ตเป็นศูนย์สำหรับการสะสมครั้งต่อไป แลกคูปองในหน้าสแกน QR เมื่อคุณซักผ้าครั้งต่อไป",
  "Terms & Conditions:": "ข้อกำหนดและเงื่อนไข:",
  "To earn ONE e-stamp, you must spend at least {{ stampTransactionAmount }} credits ({{ currency }}{{ stampTransactionAmount }}) per session._one": "เพื่อรับ ONE e-stamp คุณต้องใช้จ่ายอย่างน้อย {{ stampTransactionAmount }} เครดิต ({{ currency }}{{ stampTransactionAmount }}) ต่อครั้ง",
  "To earn ONE e-stamp, you must spend at least {{ stampTransactionAmount }} credits ({{ currency }}{{ stampTransactionAmount }}) per session._other": "เพื่อรับ ONE e-stamp คุณต้องใช้จ่ายอย่างน้อย {{ stampTransactionAmount }} เครดิต ({{ currency }}{{ stampTransactionAmount }}) ต่อครั้ง",
  "You will be automatically issued a voucher if you have collected 10 washer stamps.": "คุณจะได้รับคูปองโดยอัตโนมัติหากคุณสะสมแสตมป์เครื่องซักครบ 10 ดวง",
  "You will be automatically issued a voucher if you have collected 10 dryer stamps.": "คุณจะได้รับคูปองโดยอัตโนมัติหากคุณสะสมแสตมป์เครื่องอบครบ 10 ดวง",
  "You will be automatically issued a voucher if you have collected 10 dryer stamps or 10 washer stamps.": "คุณจะได้รับคูปองโดยอัตโนมัติหากคุณสะสมแสตมป์เครื่องอบครบ 10 ดวงหรือแสตมป์เครื่องซักครบ 10 ดวง",
  "You will be automatically issued a voucher if you have collected 10 stamps.": "คุณจะได้รับคูปองโดยอัตโนมัติหากคุณสะสมแสตมป์ครบ 10 ดวง",
  "The voucher is non-refundable and cannot be exchanged for cash; in part or in full.": "คูปองไม่สามารถคืนเงินได้และไม่สามารถแลกเป็นเงินสดได้ ไม่ว่าจะบางส่วนหรือทั้งหมด",
  "This voucher is valid for single transaction only.": "คูปองนี้ใช้ได้สำหรับการทำธุรกรรมครั้งเดียวเท่านั้น",
  "General questions": "คำถามทั่วไป",
  "E-stamps collection": "การสะสม E-stamps",
  "Coin exchange machines": "เครื่องแลกเหรียญ",
  "Washer machines": "เครื่องซักผ้า",
  "Dryer machines": "เครื่องอบผ้า",
  "Paywave issues": "ปัญหาเกี่ยวกับ Paywave",
  "Cleanpro franchise": "แฟรนไชส์ Cleanpro",
  "How can I wash my laundry using Cleanpro Plus?": "ฉันจะซักผ้าโดยใช้ Cleanpro Plus ได้อย่างไร?",
  "Here are the steps to wash your laundry using Cleanpro Plus:": "นี่คือขั้นตอนการซักผ้าโดยใช้ Cleanpro Plus:",
  "Find an unoccupied washing machine in the store, and insert your laundry in the washer.": "หาเครื่องซักผ้าที่ว่างในร้าน และใส่ผ้าของคุณลงในเครื่องซัก",
  "Select the desired temperature that you would like to use for your laundry.": "เลือกอุณหภูมิที่คุณต้องการใช้สำหรับการซักผ้าของคุณ",
  "Using the scanner in the app, scan the QR code provided on the washing machine. Then select the same settings that you have pressed on the machine.": "ใช้เครื่องสแกนในแอพ สแกนรหัส QR ที่อยู่บนเครื่องซักผ้า จากนั้นเลือกการตั้งค่าเดียวกันกับที่คุณได้กดบนเครื่อง",
  'Tap on “Pay with credits” to pay the amount shown on the app.': 'แตะที่ "ชำระด้วยเครดิต" เพื่อชำระเงินตามจำนวนที่แสดงในแอพ',
  "Once the payment has been accepted, press the START button on the washer machine.": "เมื่อการชำระเงินได้รับการยอมรับแล้ว ให้กดปุ่ม START บนเครื่องซักผ้า",
  "After the washing cycle ends, you can just remove your laundry from the machine.": "หลังจากรอบการซักสิ้นสุดลง คุณสามารถนำผ้าของคุณออกจากเครื่องได้",
  "How can I dry my laundry using Cleanpro Plus?": "ฉันจะอบผ้าโดยใช้ Cleanpro Plus ได้อย่างไร?",
  "Here are the steps to dry your laundry using Cleanpro Plus:": "นี่คือขั้นตอนการอบผ้าโดยใช้ Cleanpro Plus:",
  "Find an unoccupied drying machine in the store, and insert your laundry in the dryer.": "หาเครื่องอบผ้าที่ว่างในร้าน และใส่ผ้าของคุณลงในเครื่องอบ",
  "Select the desired temperature and duration that you would like to use for your laundry.": "เลือกอุณหภูมิและระยะเวลาที่คุณต้องการใช้สำหรับการอบผ้าของคุณ",
  "Using the scanner in the app, scan the QR code provided on the drying machine. Then select the same settings that you have pressed on the machine.": "ใช้เครื่องสแกนในแอพ สแกนรหัส QR ที่อยู่บนเครื่องอบผ้า จากนั้นเลือกการตั้งค่าเดียวกันกับที่คุณได้กดบนเครื่อง",
  "Once the payment has been accepted, press the START button on the dryer machine.": "เมื่อการชำระเงินได้รับการยอมรับแล้ว ให้กดปุ่ม START บนเครื่องอบผ้า",
  "After the drying cycle ends, you can just remove your laundry from the machine.": "หลังจากรอบการอบสิ้นสุดลง คุณสามารถนำผ้าของคุณออกจากเครื่องได้",
  "Why does the QR code I scanned take me to a different machine?": "ทำไมรหัส QR ที่ฉันสแกนจึงพาฉันไปยังเครื่องอื่น?",
  "Here are the steps to solve it:": "นี่คือขั้นตอนในการแก้ไขปัญหา:",
  "Make sure that the number on the QR code (e.g. W1, W2, etc) is the same as the machine that you would like to use.": "ตรวจสอบให้แน่ใจว่าหมายเลขบนรหัส QR (เช่น W1, W2 เป็นต้น) ตรงกับเครื่องที่คุณต้องการใช้",
  "If the QR code number does not match the machine you want, use the other machine. Please contact our customer service line at 03-27700100 to report this issue.": "หากหมายเลขรหัส QR ไม่ตรงกับเครื่องที่คุณต้องการ ให้ใช้เครื่องอื่น กรุณาติดต่อสายบริการลูกค้าของเราที่ 03-27700100 เพื่อรายงานปัญหานี้",
  "How can I add more time to the washer that I am currently using?": "ฉันจะเพิ่มเวลาให้กับเครื่องซักผ้าที่ฉันกำลังใช้งานอยู่ได้อย่างไร?",
  "The washing time for our washer is fixed and cannot add any additional time.": "เวลาในการซักสำหรับเครื่องซักผ้าของเราถูกกำหนดไว้แน่นอนและไม่สามารถเพิ่มเวลาเพิ่มเติมได้",
  "How can I add more time to the dryer that I am currently using?": "ฉันจะเพิ่มเวลาให้กับเครื่องอบผ้าที่ฉันกำลังใช้งานอยู่ได้อย่างไร?",
  "Here are the steps to add more time to the dryer machine:": "นี่คือขั้นตอนในการเพิ่มเวลาให้กับเครื่องอบผ้า:",
  "By using the scanner in the app, scan the QR code provided at the dryer machine.": "โดยใช้เครื่องสแกนในแอพ สแกนรหัส QR ที่อยู่บนเครื่องอบผ้า",
  "Select the amount of time that you would like to add to the machine.": "เลือกจำนวนเวลาที่คุณต้องการเพิ่มให้กับเครื่อง",
  "After the drying cycle ends, you can just remove the clothes from the machine.": "หลังจากรอบการอบสิ้นสุดลง คุณสามารถนำเสื้อผ้าออกจากเครื่องได้",
  "REMINDER - It is recommended to add drying time 5 minutes before the cycle ends. And you cannot add tokens for extra times when the machine finishes the drying cycle.": "การเตือน - แนะนำให้เพิ่มเวลาอบ 5 นาทีก่อนรอบการอบจะสิ้นสุด และคุณไม่สามารถเพิ่มโทเคนสำหรับเวลาเพิ่มเติมเมื่อเครื่องเสร็จสิ้นรอบการอบแล้ว",
  "Will the timer keep running when I have stopped the drying machine?": "ตัวจับเวลาจะยังคงทำงานต่อไปหรือไม่เมื่อฉันหยุดเครื่องอบผ้า?",
  "Yes. Even after you stop the machine, the timer will continue to run. If you want to extend the drying time, add drying time 5 minutes before the cycle ends. This prevents the cycle from ending before your clothes are completely dried.": "ใช่ แม้หลังจากคุณหยุดเครื่อง ตัวจับเวลาจะยังคงทำงานต่อไป หากคุณต้องการขยายเวลาอบ ให้เพิ่มเวลาอบ 5 นาทีก่อนรอบการอบจะสิ้นสุด วิธีนี้จะช่วยป้องกันไม่ให้รอบการอบสิ้นสุดลงก่อนที่เสื้อผ้าของคุณจะแห้งสนิท",
  "How do I reload my credits for Cleanpro Plus?": "ฉันจะเติมเครดิตสำหรับ Cleanpro Plus ได้อย่างไร?",
  "Here are the steps to reload your credits:": "นี่คือขั้นตอนในการเติมเครดิตของคุณ:",
  "Select the \"Top Up Credit\" button on the homepage.": "เลือกปุ่ม \"เติมเครดิต\" บนหน้าแรก",
  "Choose the amount you want to reload and the payment method you want to use.": "เลือกจำนวนเงินที่คุณต้องการเติมและวิธีการชำระเงินที่คุณต้องการใช้",
  "You will be taken to the payment page. The screen will return to Cleanpro Plus once the transaction is completed.": "คุณจะถูกนำไปยังหน้าการชำระเงิน หน้าจอจะกลับไปยัง Cleanpro Plus เมื่อการทำธุรกรรมเสร็จสมบูรณ์",
  "How many credits can I reload in one transaction?": "ฉันสามารถเติมเครดิตได้กี่เครดิตในหนึ่งธุรกรรม?",
  "Reloading credits requires a minimum of RM 10 and a maximum of RM 50.": "การเติมเครดิตต้องมีขั้นต่ำ 10 ริงกิตและสูงสุด 50 ริงกิต",
  "Can I pay using credits in the Cleanpro Plus app along with tokens or coins in a single transaction?": "ฉันสามารถชำระเงินโดยใช้เครดิตในแอพ Cleanpro Plus พร้อมกับโทเคนหรือเหรียญในธุรกรรมเดียวได้หรือไม่?",
  "No. Only a single payment method may be chosen when performing any transactions.": "ไม่ได้ สามารถเลือกวิธีการชำระเงินได้เพียงวิธีเดียวเท่านั้นเมื่อทำธุรกรรมใดๆ",
  "The amount I have reloaded in my account was not reflected in the app.": "จำนวนเงินที่ฉันได้เติมในบัญชีของฉันไม่ปรากฏในแอพ",
  "Please close and restart the application and check if the new balance has been updated.": "กรุณาปิดและเปิดแอพลิเคชันใหม่ และตรวจสอบว่ายอดเงินใหม่ได้รับการอัปเดตแล้วหรือไม่",
  "If the amount is still not updated, please contact our customer service line at 03-27700110 to receive further assistance.": "หากยอดเงินยังไม่ได้รับการอัปเดต กรุณาติดต่อสายบริการลูกค้าของเราที่ 03-27700110 เพื่อรับความช่วยเหลือเพิ่มเติม",
  "Can I cash out the credits I reloaded in my account for this app?": "ฉันสามารถถอนเงินสดจากเครดิตที่ฉันเติมในบัญชีของฉันสำหรับแอพนี้ได้หรือไม่?",
  "No. The credits that you have reloaded will remain in your Cleanpro Plus account.": "ไม่ได้ เครดิตที่คุณได้เติมจะยังคงอยู่ในบัญชี Cleanpro Plus ของคุณ",
  "I have requested a refund, but I still have not received it.": "ฉันได้ขอคืนเงินแล้ว แต่ยังไม่ได้รับเงินคืน",
  "Please contact our customer service line at 03-27700100 to receive further assistance.": "กรุณาติดต่อสายบริการลูกค้าของเราที่ 03-27700100 เพื่อรับความช่วยเหลือเพิ่มเติม",
  "I have made the payment, and the app double or triple charged me.": "ฉันได้ชำระเงินแล้ว และแอพเรียกเก็บเงินจากฉันสองหรือสามเท่า",
  "The washing machine cannot start even after I have paid using the app.": "เครื่องซักผ้าไม่สามารถเริ่มทำงานได้แม้ว่าฉันจะชำระเงินผ่านแอพแล้ว",
  "Ensure that the temperature settings you have selected and paid for on the application are the same as what you have pressed on the washing machine. If not, select the correct temperature settings on the washing machine and try again.": "Ensure that the temperature settings you have selected and paid for on the application are the same as what you have pressed on the washing machine. If not, select the correct temperature settings on the washing machine and try again.", // new translation
  "If the machine is still not able to be started, please contact our customer service line at 03-27700100 to receive further assistance.": "หากเครื่องยังไม่สามารถเริ่มทำงานได้ กรุณาติดต่อสายบริการลูกค้าของเราที่ 03-27700100 เพื่อรับความช่วยเหลือเพิ่มเติม",
  "Can I use multiple vouchers together in the same washing or drying cycle?": "ฉันสามารถใช้หลายคูปองพร้อมกันในรอบการซักหรืออบเดียวกันได้หรือไม่?",
  "No. Only a single voucher can be used for each washing and drying cycle.": "ไม่ได้ สามารถใช้ได้เพียงหนึ่งคูปองเท่านั้นสำหรับแต่ละรอบการซักและอบ",
  "Why can't I use my free wash voucher that I received after signing up?": "ทำไมฉันจึงไม่สามารถใช้คูปองซักฟรีที่ฉันได้รับหลังจากลงทะเบียนได้?",
  "To redeem or use the voucher, you must complete at least one washing or drying cycle.": "เพื่อแลกหรือใช้คูปอง คุณต้องทำการซักหรืออบให้เสร็จสิ้นอย่างน้อยหนึ่งรอบ",
  "Can I change my phone number even after registering my account?": "ฉันสามารถเปลี่ยนหมายเลขโทรศัพท์ของฉันได้หรือไม่ แม้จะลงทะเบียนบัญชีแล้ว?",
  "No. Once registered to an account, the phone number is locked into the account.": "ไม่ได้ เมื่อลงทะเบียนกับบัญชีแล้ว หมายเลขโทรศัพท์จะถูกล็อคเข้ากับบัญชีนั้น",
  "I have problems in the store, and who can I refer to for help?": "ฉันมีปัญหาในร้าน และฉันสามารถขอความช่วยเหลือจากใครได้บ้าง?",
  "Do not worry, as you can contact our customer service line at 03-27700100 to receive assistance at any time.": "ไม่ต้องกังวล คุณสามารถติดต่อสายบริการลูกค้าของเราที่ 03-27700100 เพื่อรับความช่วยเหลือได้ตลอดเวลา",
  "Should I add my own laundry detergent and softeners to the washing machine?": "ฉันควรเพิ่มผงซักฟอกและน้ำยาปรับผ้านุ่มของตัวเองลงในเครื่องซักผ้าหรือไม่?",
  "There is no need for that, as all you have to do is bring your dirty clothes and put them in the washer. The detergent and softeners will be provided by us, and you can rest assured that the laundry detergents we used are certified industrial grade.": "ไม่จำเป็น เพราะสิ่งที่คุณต้องทำคือนำเสื้อผ้าที่สกปรกมาใส่ในเครื่องซักผ้าเท่านั้น ผงซักฟอกและน้ำยาปรับผ้านุ่มจะถูกจัดเตรียมโดยเรา และคุณสามารถมั่นใจได้ว่าผงซักฟอกที่เราใช้เป็นเกรดอุตสาหกรรมที่ได้รับการรับรอง",
  "I would like to add my own laundry detergent and softener to the washing machine.": "ฉันต้องการเพิ่มผงซักฟอกและน้ำยาปรับผ้านุ่มของตัวเองลงในเครื่องซักผ้า",
  "We do not recommend our customers add their own detergent, as the mixture of different detergents may affect the wash quality. And you can rest assured that the laundry detergent we used is certified industrial grade.": "เราไม่แนะนำให้ลูกค้าของเราเพิ่มผงซักฟอกของตัวเอง เนื่องจากการผสมผงซักฟอกที่แตกต่างกันอาจส่งผลต่อคุณภาพการซัก และคุณสามารถมั่นใจได้ว่าผงซักฟอกที่เราใช้เป็นเกรดอุตสาหกรรมที่ได้รับการรับรอง",
  "What time does the store close?": "ร้านปิดเวลาไหน?",
  "Our self-service laundry operation is open 24 hours a day, which means you can always be sure to go back home with a fresh set of laundry.": "บริการซักรีดแบบบริการตนเองของเราเปิดให้บริการ 24 ชั่วโมงต่อวัน ซึ่งหมายความว่าคุณสามารถมั่นใจได้เสมอว่าจะได้กลับบ้านพร้อมกับชุดเสื้อผ้าที่สะอาดสดชื่น",
  "Are you open on weekends?": "คุณเปิดให้บริการในวันหยุดสุดสัปดาห์หรือไม่?",
  "Yes. Cleanpro is also open on weekends and even on public holidays.": "ใช่ Cleanpro เปิดให้บริการในวันหยุดสุดสัปดาห์และแม้แต่ในวันหยุดนักขัตฤกษ์",
  "How many clothes can I fit in the washing or drying machine?": "ฉันสามารถใส่เสื้อผ้าได้กี่ชิ้นในเครื่องซักหรือเครื่องอบ?",
  "To ensure that your laundry is properly washed or dried, keep the amount of laundry inside the machine lower than the line of guide sticker on the machine.": "เพื่อให้แน่ใจว่าเสื้อผ้าของคุณจะถูกซักหรืออบอย่างเหมาะสม ให้ใส่เสื้อผ้าในเครื่องให้ต่ำกว่าเส้นสติกเกอร์แนะนำบนเครื่อง",
  "How many e-stamps can I collect in a single day?": "ฉันสามารถสะสม e-stamps ได้กี่ดวงในหนึ่งวัน?",
  "There is no limit to the amount of stamps that can be collected in a single day; ONE stamp will be awarded with every wash or dry.": "ไม่มีข้อจำกัดในจำนวนแสตมป์ที่สามารถสะสมได้ในหนึ่งวัน จะได้รับหนึ่งแสตมป์สำหรับทุกการซักหรืออบ",
  "When I use the washing service, can I change the collected stamp to a dryer stamp, and vice versa?": "เมื่อฉันใช้บริการซัก ฉันสามารถเปลี่ยนแสตมป์ที่สะสมได้เป็นแสตมป์เครื่องอบได้หรือไม่ และในทางกลับกัน?",
  "No, the type of stamp collected is only applied to the service that you have used.": "ไม่ได้ ประเภทของแสตมป์ที่สะสมได้จะใช้ได้เฉพาะกับบริการที่คุณได้ใช้เท่านั้น",
  "What temperatures can I choose when I claim the free washing e-stamp reward?": "ฉันสามารถเลือกอุณหภูมิใดได้บ้างเมื่อฉันแลกรางวัล e-stamp ซักฟรี?",
  "Any washing temperature is available for the e-stamp reward.": "สามารถเลือกอุณหภูมิการซักใดก็ได้สำหรับรางวัล e-stamp",
  "What temperatures can I choose when I claim the free drying e-stamp reward? And how long can I set the time?": "ฉันสามารถเลือกอุณหภูมิใดได้บ้างเมื่อฉันแลกรางวัล e-stamp อบฟรี? และฉันสามารถตั้งเวลาได้นานเท่าไร?",
  "Any drying temperature and duration can be selected for the e-stamp reward.": "สามารถเลือกอุณหภูมิและระยะเวลาการอบใดก็ได้สำหรับรางวัล e-stamp",
  "I'm unable to change the coin.": "ฉันไม่สามารถแลกเหรียญได้",
  "What colour is the light shown on the machine, red or green? If it is a red light, please contact our customer service line at 03-27700100 to receive further assistance.": "สีของไฟที่แสดงบนเครื่องเป็นสีแดงหรือสีเขียว? ถ้าเป็นไฟสีแดง กรุณาติดต่อสายบริการลูกค้าของเราที่ 03-27700100 เพื่อรับความช่วยเหลือเพิ่มเติม",
  "Green Light": "ไฟสีเขียว",
  "Red Light": "ไฟสีแดง",
  "Machine functions as normal. Please try again.": "เครื่องทำงานเป็นปกติ กรุณาลองอีกครั้ง",
  "(flashing 2x) - There are no coins/tokens in the machine.": "(กะพริบ 2 ครั้ง) - ไม่มีเหรียญ/โทเคนในเครื่อง",
  "OR": "หรือ",
  "Note is jammed, please proceed to contact customer service": "ธนบัตรติด กรุณาติดต่อฝ่ายบริการลูกค้า",
  "(flashing 3x) - Note is jammed, please proceed to contact customer service": "(กะพริบ 3 ครั้ง) - ธนบัตรติด กรุณาติดต่อฝ่ายบริการลูกค้า",
  "My notes are stuck in the machine.": "ธนบัตรของฉันติดอยู่ในเครื่อง",
  "My detergent is not dispensing in the vending machine.": "ผงซักฟอกของฉันไม่ถูกจ่ายออกมาในเครื่องจำหน่ายสินค้าอัตโนมัติ",
  "The water is leaking out of the washing machine.": "น้ำรั่วออกมาจากเครื่องซักผ้า",
  "Please check the washer machine to see whether there are any clothes that are stuck at the door side.": "กรุณาตรวจสอบเครื่องซักผ้าว่ามีเสื้อผ้าติดอยู่ที่ด้านประตูหรือไม่",
  "If yes, here are the steps to solve it:": "ถ้าใช่ นี่คือขั้นตอนในการแก้ไข:",
  "Press the emergency stop button (RED Button) for 3 seconds.": "กดปุ่มหยุดฉุกเฉิน (ปุ่มสีแดง) เป็นเวลา 3 วินาที",
  "Wait for the sound to stop, and then only open the door. (If after 30 minutes the sound still has not stopped, please contact our customer service line at 03-27700100 to receive further assistance)": "รอให้เสียงหยุด จากนั้นจึงเปิดประตู (หากหลังจาก 30 นาทีเสียงยังไม่หยุด กรุณาติดต่อสายบริการลูกค้าของเราที่ 03-27700100 เพื่อรับความช่วยเหลือเพิ่มเติม)",
  "Ensure the door is closed tightly, and press the START button.": "ตรวจสอบให้แน่ใจว่าประตูปิดสนิท และกดปุ่ม START",
  "REMINDER - The emergency stop button can only be used once.": "การเตือน - ปุ่มหยุดฉุกเฉินสามารถใช้ได้เพียงครั้งเดียวเท่านั้น",
  "There is no detergent dispensing in the washing machine.": "ไม่มีการจ่ายผงซักฟอกในเครื่องซักผ้า",
  "Ensure that the machine is in operation and that the START button was pressed.": "ตรวจสอบให้แน่ใจว่าเครื่องกำลังทำงานอยู่และได้กดปุ่ม START แล้ว",
  "If the detergent is still not dispensing, please contact our customer service department at 03-27700100 for further assistance.": "หากผงซักฟอกยังไม่ถูกจ่ายออกมา กรุณาติดต่อฝ่ายบริการลูกค้าของเราที่ 03-27700100 เพื่อรับความช่วยเหลือเพิ่มเติม",
  "The price displayed on the machine's screen did not change after inserting the token.": "ราคาที่แสดงบนหน้าจอของเครื่องไม่เปลี่ยนแปลงหลังจากใส่โทเคน",
  "Press the small button (in the centre) to reject the tokens that have been inserted. Press a few times till the coins come out.": "กดปุ่มเล็ก (ตรงกลาง) เพื่อคืนโทเคนที่ได้ใส่ไป กดหลายๆ ครั้งจนกว่าเหรียญจะออกมา",
  "If the coins still cannot come out or the button is not functioning, please contact our customer service line at 03-27700110 to receive further assistance.": "หากเหรียญยังไม่ออกมาหรือปุ่มไม่ทำงาน กรุณาติดต่อสายบริการลูกค้าของเราที่ 03-27700110 เพื่อรับความช่วยเหลือเพิ่มเติม",
  "The machine is not starting, and it displays an error code.": "เครื่องไม่เริ่มทำงานและแสดงรหัสข้อผิดพลาด",
  "We would like to apologise for any inconvenience caused. Please contact our customer service line at 03-27700100 to receive further assistance.": "เราขออภัยในความไม่สะดวกที่เกิดขึ้น กรุณาติดต่อสายบริการลูกค้าของเราที่ 03-27700100 เพื่อรับความช่วยเหลือเพิ่มเติม",
  "I want to wash the clothes again, but why couldn't the machine start even after I added tokens to the machine?": "ฉันต้องการซักผ้าอีกครั้ง แต่ทำไมเครื่องถึงไม่เริ่มทำงานแม้ว่าฉันจะเพิ่มโทเคนลงในเครื่องแล้ว?",
  "Try opening and closing the door again before starting the machine.": "ลองเปิดและปิดประตูอีกครั้งก่อนเริ่มเครื่อง",
  "Can I change the water temperature of the machine?": "ฉันสามารถเปลี่ยนอุณหภูมิน้ำของเครื่องได้หรือไม่?",
  "Yes, but the washer temperature can only be changed from hot to cold after the machine has been started, and not from cold to hot.": "ได้ แต่อุณหภูมิของเครื่องซักผ้าสามารถเปลี่ยนได้เฉพาะจากร้อนเป็นเย็นหลังจากเครื่องเริ่มทำงานแล้วเท่านั้น และไม่สามารถเปลี่ยนจากเย็นเป็นร้อนได้",
  "Can I stop the machine?": "ฉันสามารถหยุดเครื่องได้หรือไม่?",
  "Here are the steps to stop the machine:": "นี่คือขั้นตอนในการหยุดเครื่อง:",
  "Wait for the sound to stop, and then only open the door. (If after 30 minutes the sound still has not stopped, please contact our customer service line at to receive further assistance)": "รอให้เสียงหยุด จากนั้นจึงเปิดประตู (หากหลังจาก 30 นาทีเสียงยังไม่หยุด กรุณาติดต่อสายบริการลูกค้าของเราเพื่อรับความช่วยเหลือเพิ่มเติม)",
  "There is no water supply on the machine used.": "ไม่มีน้ำเข้าเครื่องที่ใช้งาน",
  "Why is the washing temperature cold even when I have selected HOT.": "ทำไมอุณหภูมิการซักถึงเย็นแม้ว่าฉันจะเลือก HOT",
  "The water temperature is HOT or WARM only during washing. The water will turn COLD during rinsing.": "อุณหภูมิของน้ำจะร้อนหรืออุ่นเฉพาะในระหว่างการซักเท่านั้น น้ำจะเปลี่ยนเป็นเย็นในระหว่างการล้าง",
  "The machine has already finished washing, but it does not spin well, and there is water left on the load.": "เครื่องซักผ้าเสร็จสิ้นการซักแล้ว แต่ไม่ปั่นแห้งดี และยังมีน้ำเหลืออยู่ในผ้า",
  "Can I put wet laundry into the dryer to dry?": "ฉันสามารถใส่ผ้าเปียกลงในเครื่องอบผ้าเพื่ออบแห้งได้หรือไม่?",
  "No, as the dripping wet laundry might cause the dryer to short circuit.": "ไม่ได้ เนื่องจากผ้าที่เปียกชุ่มอาจทำให้เครื่องอบผ้าเกิดไฟฟ้าลัดวงจรได้",
  "REMINDER - Refer to the labels on your clothes to know what kind of temperature is best for the kind of fabric that it uses. Then select the temperature on the machine accordingly.": "การเตือน - อ้างอิงป้ายกำกับบนเสื้อผ้าของคุณเพื่อทราบว่าอุณหภูมิแบบใดเหมาะสมที่สุดสำหรับประเภทของผ้าที่ใช้ จากนั้นเลือกอุณหภูมิบนเครื่องตามนั้น",
  "My clothes are still wet even after drying.": "เสื้อผ้าของฉันยังเปียกอยู่แม้จะอบแล้ว",
  "How can I stop the dryer when the machine is still running?": "ฉันจะหยุดเครื่องอบผ้าได้อย่างไรเมื่อเครื่องยังทำงานอยู่?",
  "Just open the door and the machine will automatically stop.": "เพียงแค่เปิดประตู เครื่องจะหยุดโดยอัตโนมัติ",
  "I added extra tokens for extra times, but the machine did not update the time.": "ฉันเพิ่มโทเคนพิเศษสำหรับเวลาเพิ่มเติม แต่เครื่องไม่อัปเดตเวลา",
  "For the time to be reflected accurately, it is recommended for you to add tokens 5 minutes before the services end.": "เพื่อให้เวลาถูกแสดงอย่างถูกต้อง แนะนำให้คุณเพิ่มโทเคน 5 นาทีก่อนบริการจะสิ้นสุด",
  "REMINDER - You cannot add tokens for extra times when the machine finishes the drying cycle.": "การเตือน - คุณไม่สามารถเพิ่มโทเคนสำหรับเวลาเพิ่มเติมเมื่อเครื่องเสร็จสิ้นรอบการอบ",
  "Why is the machine making noise?": "ทำไมเครื่องถึงมีเสียงดัง?",
  "Please check the drum to see if there are any coins or other small things inside.": "กรุณาตรวจสอบถังซักว่ามีเหรียญหรือสิ่งของเล็กๆ อื่นๆ อยู่ข้างในหรือไม่",
  "I have pressed the START button on the dryer, but it is still not working.": "ฉันได้กดปุ่ม START บนเครื่องอบผ้าแล้ว แต่มันยังไม่ทำงาน",
  "Please try to open the door and close it again. Next, press the START button again to start drying.": "กรุณาลองเปิดประตูและปิดอีกครั้ง จากนั้นกดปุ่ม START อีกครั้งเพื่อเริ่มการอบ",
  "If the dryer is still not functioning, please contact our customer service line at 03-27700100 to receive further assistance.": "หากเครื่องอบผ้ายังไม่ทำงาน กรุณาติดต่อสายบริการลูกค้าของเราที่ 03-27700100 เพื่อรับความช่วยเหลือเพิ่มเติม",
  "Paywave has deducted an amount from the card but the machine did not reflect the transaction.": "Paywave ได้หักเงินจากบัตรแล้ว แต่เครื่องไม่แสดงการทำรายการ",
  "The Paywave transaction has failed but the amount has still been deducted from my card.": "การทำรายการ Paywave ล้มเหลว แต่ยอดเงินยังถูกหักจากบัตรของฉัน",
  "Paywave has double/triple charge but the machine cannot start.": "Paywave เรียกเก็บเงินสองเท่า/สามเท่า แต่เครื่องไม่สามารถเริ่มทำงานได้",
  "Paywave after double/triple charge machines can't start.": "Paywave หลังจากเรียกเก็บเงินสองเท่า/สามเท่า เครื่องไม่สามารถเริ่มทำงานได้",
  "Paywave is offline": "Paywave ออฟไลน์",
  "Do Cleanpro Express offer franchise business/selling machines/laundry products?": "Cleanpro Express เสนอธุรกิจแฟรนไชส์/ขายเครื่อง/ผลิตภัณฑ์ซักรีดหรือไม่?",
  "For further inquiries, please email to info@cleanproexpress.com": "สำหรับการสอบถามเพิ่มเติม กรุณาส่งอีเมลไปที่ info@cleanproexpress.com",
  "Step 6": "ขั้นตอนที่ 6",
  "User Existed": "มีผู้ใช้อยู่แล้ว",
  "This email is registered": "อีเมลนี้ลงทะเบียนแล้ว",
  "This phone number is registered": "หมายเลขโทรศัพท์นี้ลงทะเบียนแล้ว",
  "Invalid Email Address": "ที่อยู่อีเมลไม่ถูกต้อง",
  "Let's get started!": "มาเริ่มกันเลย!",
  "Enter your e-mail/mobile number": "ป้อนอีเมล/หมายเลขโทรศัพท์มือถือของคุณ",
  "Enter your mobile number": "ป้อนหมายเลขโทรศัพท์มือถือของคุณ",
  "Enter your e-mail address": "ป้อนที่อยู่อีเมลของคุณ",
  "Proceed": "ดำเนินการต่อ",
  "Enter OTP number": "ป้อนหมายเลข OTP",
  "You will receive a six-digit OTP via SMS sent to": "คุณจะได้รับรหัส OTP 6 หลักทาง SMS ที่ส่งไปยัง",
  "You will receive a six-digit OTP via e-mail sent to": "คุณจะได้รับรหัส OTP 6 หลักทางอีเมลที่ส่งไปยัง",
  "Verify": "ยืนยัน",
  "Resend OTP": "ส่ง OTP อีกครั้ง",
  "Welcome onboard!": "ยินดีต้อนรับ!",
  "Please fill in the info below": "กรุณากรอกข้อมูลด้านล่าง",
  "Enter your name*": "ป้อนชื่อของคุณ*",
  "Enter your email address*": "ป้อนที่อยู่อีเมลของคุณ*",
  "Please enter either phone number or email address": "กรุณาป้อนหมายเลขโทรศัพท์หรือที่อยู่อีเมล",
  "Create a password*": "สร้างรหัสผ่าน*",
  "Enter referral code (if got any)": "ป้อนรหัสแนะนำ (ถ้ามี)",
  "Get started": "เริ่มต้นใช้งาน",
  "Birthday updated successfully": "อัปเดตวันเกิดสำเร็จ",
  "Date of birth": "วันเกิด",
  "Enter your date of birth": "ป้อนวันเกิดของคุณ",
  "Select your birth date": "เลือกวันเกิดของคุณ",
  "Update date of birth": "อัปเดตวันเกิด",
  "Update birth date": "อัปเดตวันเกิด",
  "Already have an account?": "มีบัญชีอยู่แล้ว?",
  "Login now": "เข้าสู่ระบบตอนนี้",
  "Sign in": "เข้าสู่ระบบ",
  "Sign up now": "ลงทะเบียนตอนนี้",
  "COMPLETED": "เสร็จสิ้น",
  "WASHING NOW": "กำลังซักอยู่",
  "DRYING NOW": "กำลังอบอยู่",
  "It's done!": "เสร็จแล้ว!",
  "Please collect your fresh, clean laundry in order to leave the machine available for the next person to use!": "กรุณารับผ้าที่สะอาดสดชื่นของคุณเพื่อให้เครื่องว่างสำหรับคนต่อไปที่จะใช้!",
  "Leave a feedback": "ให้ข้อเสนอแนะ",
  "Time left": "เวลาที่เหลือ",
  "Add drying time": "เพิ่มเวลาอบ",
  "Hi": "สวัสดี",
  "customer": "ลูกค้า",
  "Thank you for your payment. Don't forget to select the <b>correct temperature</b> and press <b>START</b> when you're ready.": "ขอบคุณสำหรับการชำระเงินของคุณ อย่าลืมเลือก<b>อุณหภูมิที่ถูกต้อง</b>และกด<b>START</b>เมื่อคุณพร้อม",
  "Select how you want to receive a notification when your laundry is finished": "เลือกวิธีที่คุณต้องการรับการแจ้งเตือนเมื่อการซักผ้าของคุณเสร็จสิ้น",
  "Sign up": "ลงทะเบียน",
  "Continue to pay": "ดำเนินการชำระเงินต่อ",
  "Register as a member to enjoy more benefits!": "ลงทะเบียนเป็นสมาชิกเพื่อรับสิทธิประโยชน์มากขึ้น!",
  "Get FREE {{ voucherAmount }} credits per new user_one": "รับ {{ voucherAmount }} เครดิตฟรีต่อผู้ใช้ใหม่",
  "Get FREE {{ voucherAmount }} credits per new user_other": "รับ {{ voucherAmount }} เครดิตฟรีต่อผู้ใช้ใหม่",
  "Get FREE {{ voucherAmount }}% per new user": "รับส่วนลด {{ voucherAmount }}% ฟรีต่อผู้ใช้ใหม่",
  "Enjoy exclusive promotions": "เพลิดเพลินกับโปรโมชั่นพิเศษ",
  "FREE voucher on your birthday": "คูปองฟรีในวันเกิดของคุณ",
  "and more": "และอื่นๆ อีกมากมาย",
  "Denied camera access": "ปฏิเสธการเข้าถึงกล้อง",
  "Permission to access the camera has been denied. Please try again and click \"Allow\" to scan the QR code.": "การอนุญาตให้เข้าถึงกล้องถูกปฏิเสธ กรุณาลองอีกครั้งและคลิก \"อนุญาต\" เพื่อสแกนรหัส QR",
  "Okay": "ตกลง",
  "Oops. You don't have enough balance to make the payment": "อุ๊ปส์ คุณมียอดเงินไม่เพียงพอที่จะชำระเงิน",
  "Please confirm": "กรุณายืนยัน",
  "Do you want to proceed with this feedback?": "คุณต้องการดำเนินการกับข้อเสนอแนะนี้หรือไม่?",
  "Are you sure to purchase": "คุณแน่ใจหรือไม่ที่จะซื้อ",
  "credits for": "เครดิตสำหรับ",
  "Topup credit": "เติมเครดิต",
  "Extended duration will be instantly reflected on machine after payment": "ระยะเวลาที่ขยายจะถูกแสดงทันทีบนเครื่องหลังจากชำระเงิน",
  "Machine is currently busy": "เครื่องกำลังทำงานอยู่",
  "Do you want to extend drying time?": "คุณต้องการขยายเวลาอบหรือไม่?",
  "Cuci Hari-Hari Bersama Cleanpro!": "ซักทุกวันกับ Cleanpro!",
  "Ready for a challenge?": "พร้อมรับความท้าทายหรือไม่?",
  "Here's one for you - all you need to do is wash your laundry 10 times in 20 days at participating Cleanpro outlets. Stand a chance to win a Limited Edition Cleanpro Designer Laundry Bag worth up to RM40!": "นี่คือความท้าทายสำหรับคุณ - สิ่งที่คุณต้องทำคือซักผ้า 10 ครั้งภายใน 20 วันที่สาขา Cleanpro ที่ร่วมรายการ มีโอกาสชนะถุงซักผ้าดีไซเนอร์ Limited Edition ของ Cleanpro มูลค่าสูงถึง 40 ริงกิต!",
  "Here's how to participate:": "นี่คือวิธีการเข้าร่วม:",
  "Step 1: Scan the QR Code on the washer to launch Cleanpro Plus": "ขั้นตอนที่ 1: สแกนรหัส QR บนเครื่องซักผ้าเพื่อเปิด Cleanpro Plus",
  "Step 2: Pay for your wash with Cleanpro Plus": "ขั้นตอนที่ 2: ชำระค่าซักผ้าด้วย Cleanpro Plus",
  "Step 3: Complete x10 washes within 20 days to win": "ขั้นตอนที่ 3: ซักผ้าให้ครบ 10 ครั้งภายใน 20 วันเพื่อชนะ",
  "Challenge Period: 01 July - 31 August 2023": "ระยะเวลาของความท้าทาย: 1 กรกฎาคม - 31 สิงหาคม 2566",
  "Drop by your nearest Cleanpro outlet to wash your laundry with us for 20 days straight! Good luck!": "แวะมาที่สาขา Cleanpro ที่ใกล้ที่สุดเพื่อซักผ้ากับเราเป็นเวลา 20 วันติดต่อกัน! ขอให้โชคดี!",
  "TERMS & CONDITIONS": "ข้อกำหนดและเงื่อนไข",
  "PARTICIPATION CRITERIA": "เกณฑ์การเข้าร่วม",
  "1) Participant needs to register an account in Cleanpro Plus web app.": "1) ผู้เข้าร่วมต้องลงทะเบียนบัญชีในแอพเว็บ Cleanpro Plus",
  "2) Participant can use any washer of any capacity in any participating outlets to qualify for entries.": "2) ผู้เข้าร่วมสามารถใช้เครื่องซักผ้าใดก็ได้ที่มีความจุเท่าใดก็ได้ในสาขาที่เข้าร่วมรายการเพื่อมีสิทธิ์เข้าร่วม",
  "3) Payment needs to be made with Cleanpro Plus web app.": "3) ต้องชำระเงินด้วยแอพเว็บ Cleanpro Plus",
  "4) Washes must be completed and paid (with no dispute whatsoever) within campaign period.": "4) ต้องซักผ้าให้เสร็จสิ้นและชำระเงิน (โดยไม่มีข้อโต้แย้งใดๆ) ภายในระยะเวลาของแคมเปญ",
  "5) Campaign period starts from 1st July 2023 at 00:00 and ends at 31st August 2023 at 23:59.": "5) ระยะเวลาของแคมเปญเริ่มตั้งแต่วันที่ 1 กรกฎาคม 2566 เวลา 00:00 น. และสิ้นสุดวันที่ 31 สิงหาคม 2566 เวลา 23:59 น.",
  "WINNERS & PRIZES": "ผู้ชนะและรางวัล",
  "1) Cleanpro will notify the winners of the campaign via registered WhatsApp number or email address provided, within 4 weeks after the campaign ends.": "1) Cleanpro จะแจ้งผู้ชนะของแคมเปญผ่านทางหมายเลข WhatsApp หรือที่อยู่อีเมลที่ลงทะเบียนไว้ ภายใน 4 สัปดาห์หลังจากแคมเปญสิ้นสุด",
  "2) Any unclaimed prizes after 2 weeks from notification date will not be entertained thereafter.": "2) รางวัลที่ไม่มีการเรียกร้องหลังจาก 2 สัปดาห์นับจากวันที่แจ้งจะไม่ได้รับการพิจารณาหลังจากนั้น",
  "3) Upon completing the ten (10) washes within twenty (20) days campaign, participants will be a winner and be eligible to win ONE (1) Cleanpro Designer Laundry Bag.": "3) เมื่อซักผ้าครบสิบ (10) ครั้งภายในยี่สิบ (20) วันของแคมเปญ ผู้เข้าร่วมจะเป็นผู้ชนะและมีสิทธิ์ได้รับถุงซักผ้าดีไซเนอร์ Cleanpro หนึ่ง (1) ใบ",
  "4) Winners are eligible to win more than ONE (1) Cleanpro Designer Laundry Bag in this campaign.": "4) ผู้ชนะมีสิทธิ์ได้รับถุงซักผ้าดีไซเนอร์ Cleanpro มากกว่าหนึ่ง (1) ใบในแคมเปญนี้",
  "5) Winners will be given the choice of his/her preferred laundry bag size; but only ONE (1) can be selected for each qualified win.": "5) ผู้ชนะจะได้รับสิทธิ์เลือกขนาดถุงซักผ้าที่ต้องการ แต่สามารถเลือกได้เพียงหนึ่ง (1) ใบสำหรับแต่ละการชนะที่มีคุณสมบัติครบ",
  "A) XL Size - Blue bag with up to 20KG in capacity": "A) ขนาด XL - ถุงสีน้ำเงินที่มีความจุสูงสุด 20 กิโลกรัม",
  "B) L Size - White bag with up to 12KG in capacity": "B) ขนาด L - ถุงสีขาวที่มีความจุสูงสุด 12 กิโลกรัม",
  "6) Time and location for collection of prize will be informed via Whatsapp number and/or email address provided.": "6) เวลาและสถานที่สำหรับการรับรางวัลจะแจ้งให้ทราบผ่านทางหมายเลข Whatsapp และ/หรือที่อยู่อีเมลที่ให้ไว้",
  "OTHER": "อื่นๆ",
  "1) Changes to the terms and conditions will be at Cleanpro's discretion; without prior notice.": "1) การเปลี่ยนแปลงข้อกำหนดและเงื่อนไขจะขึ้นอยู่กับดุลยพินิจของ Cleanpro โดยไม่ต้องแจ้งให้ทราบล่วงหน้า",
  "2) Completed wash is defined by washes performed with Cleanpro's washer and payments within Cleanpro Plus web app are made with no disputes.": "2) การซักที่เสร็จสมบูรณ์หมายถึงการซักที่ดำเนินการด้วยเครื่องซักผ้าของ Cleanpro และการชำระเงินภายในแอพเว็บ Cleanpro Plus โดยไม่มีข้อโต้แย้ง",
  "3) By participating in this campaign automatically indicates that the Participant has agreed to all the campaign's Terms and Conditions as stipulated.": "3) การเข้าร่วมในแคมเปญนี้โดยอัตโนมัติแสดงว่าผู้เข้าร่วมได้ยอมรับข้อกำหนดและเงื่อนไขทั้งหมดของแคมเปญตามที่ระบุไว้",
  "Welcome Voucher": "คูปองต้อนรับ",
  "Welcome to the Cleanpro Plus family! This first gift is just the beginning. Stay tuned for a series of exciting promotions and campaigns, exclusively for Cleanpro Plus users.": "ยินดีต้อนรับสู่ครอบครัว Cleanpro Plus! ของขวัญชิ้นแรกนี้เป็นเพียงจุดเริ่มต้น โปรดติดตามโปรโมชั่นและแคมเปญที่น่าตื่นเต้นมากมาย ซึ่งมีไว้เฉพาะสำหรับผู้ใช้ Cleanpro Plus เท่านั้น",
  "Terms & Conditions": "ข้อกำหนดและเงื่อนไข",
  "Welcome Voucher is valid for one-time use only.": "คูปองต้อนรับใช้ได้เพียงครั้งเดียวเท่านั้น",
  "There is no minimum spend required for the voucher to be used.": "ไม่มีการกำหนดยอดใช้จ่ายขั้นต่ำสำหรับการใช้คูปอง",
  "Welcome Voucher is auto-credited to the user's account at the point of registration on Cleanpro Plus and valid for {{ duration }} days only._one": "คูปองต้อนรับจะถูกเครดิตเข้าบัญชีผู้ใช้โดยอัตโนมัติ ณ จุดที่ลงทะเบียนบน Cleanpro Plus และมีอายุการใช้งานเพียง {{ duration }} วันเท่านั้น",
  "Welcome Voucher is auto-credited to the user's account at the point of registration on Cleanpro Plus and valid for {{ duration }} days only._other": "คูปองต้อนรับจะถูกเครดิตเข้าบัญชีผู้ใช้โดยอัตโนมัติ ณ จุดที่ลงทะเบียนบน Cleanpro Plus และมีอายุการใช้งานเพียง {{ duration }} วันเท่านั้น",
  "Users are eligible to use their Welcome Voucher AFTER the completion of their first wash or dry cycle using the same Cleanpro Plus account.": "ผู้ใช้มีสิทธิ์ใช้คูปองต้อนรับหลังจากเสร็จสิ้นการซักหรืออบรอบแรกโดยใช้บัญชี Cleanpro Plus เดียวกัน",
  "Welcome Voucher cannot be used together with any ongoing promotions or any other promotional vouchers.": "คูปองต้อนรับไม่สามารถใช้ร่วมกับโปรโมชั่นที่กำลังดำเนินอยู่หรือคูปองโปรโมชั่นอื่นๆ ได้",
  "The voucher is final and cannot be refunded in form of cash or credit to the user.": "คูปองเป็นสิ่งสุดท้ายและไม่สามารถคืนเงินในรูปแบบเงินสดหรือเครดิตให้กับผู้ใช้ได้",
  "Cleanpro reserves the right to change the terms and conditions without prior notice.": "Cleanpro ขอสงวนสิทธิ์ในการเปลี่ยนแปลงข้อกำหนดและเงื่อนไขโดยไม่ต้องแจ้งให้ทราบล่วงหน้า",
  "Collect 10 e-stamps to earn FREE {{ voucherAmount }} credits voucher (worth {{ currency }}{{ voucherAmount }})_one": "สะสม 10 e-stamps เพื่อรับคูปองเครดิตฟรี {{ voucherAmount }} เครดิต (มูลค่า {{ currency }}{{ voucherAmount }})",
  "Collect 10 e-stamps to earn FREE {{ voucherAmount }} credits voucher (worth {{ currency }}{{ voucherAmount }})_other": "สะสม 10 e-stamps เพื่อรับคูปองเครดิตฟรี {{ voucherAmount }} เครดิต (มูลค่า {{ currency }}{{ voucherAmount }})",
  "Collect 10 e-stamps to earn FREE {{ voucherAmount }}% voucher (worth {{ voucherAmount }}%)": "สะสม 10 e-stamps เพื่อรับคูปองส่วนลด {{ voucherAmount }}% ฟรี (มูลค่า {{ voucherAmount }}%)",
  "Collect 10 e-Stamp to earn FREE Voucher": "Collect 10 e-Stamp to earn FREE Voucher", // new translation
  "With every 10 e-stamps that you collected, you will be rewarded with a FREE {{ voucherAmount }} credits voucher (worth {{ currency }}{{ voucherAmount }}). Make your laundry routine not just hassle-free, but also rewarding._one": "ทุกๆ 10 e-stamps ที่คุณสะสม คุณจะได้รับคูปองเครดิตฟรี {{ voucherAmount }} เครดิต (มูลค่า {{ currency }}{{ voucherAmount }}) ทำให้การซักรีดของคุณไม่เพียงแต่ไร้ความยุ่งยาก แต่ยังให้รางวัลด้วย",
  "With every 10 e-stamps that you collected, you will be rewarded with a FREE {{ voucherAmount }} credits voucher (worth {{ currency }}{{ voucherAmount }}). Make your laundry routine not just hassle-free, but also rewarding._other": "ทุกๆ 10 e-stamps ที่คุณสะสม คุณจะได้รับคูปองเครดิตฟรี {{ voucherAmount }} เครดิต (มูลค่า {{ currency }}{{ voucherAmount }}) ทำให้การซักรีดของคุณไม่เพียงแต่ไร้ความยุ่งยาก แต่ยังให้รางวัลด้วย",
  "With every 10 e-stamps that you collected, you will be rewarded with a FREE {{ voucherAmount }}% credits voucher (worth {{ voucherAmount }}%). Make your laundry routine not just hassle-free, but also rewarding.": "ทุกๆ 10 e-stamps ที่คุณสะสม คุณจะได้รับคูปองส่วนลด {{ voucherAmount }}% ฟรี (มูลค่า {{ voucherAmount }}%) ทำให้การซักรีดของคุณไม่เพียงแต่ไร้ความยุ่งยาก แต่ยังให้รางวัลด้วย",
  "How to participate:": "วิธีการเข้าร่วม:",
  "Prizes to be won:": "รางวัลที่จะได้รับ:",
  "RM10 OFF Welcome Voucher": "คูปองต้อนรับส่วนลด 10 ริงกิต",
  "Welcome to the Cleanpro family, here is an appreciation voucher from us. Thank you for attending the COPE Investors Conference.": "ยินดีต้อนรับสู่ครอบครัว Cleanpro นี่คือคูปองแสดงความขอบคุณจากเรา ขอบคุณที่เข้าร่วมการประชุมนักลงทุน COPE",
  "Welcome Voucher | COPE Investors Conference": "คูปองต้อนรับ | การประชุมนักลงทุน COPE",
  "Payment methods": "วิธีการชำระเงิน",
  "Tap to proceed with your preferred mode of payment": "แตะเพื่อดำเนินการด้วยวิธีการชำระเงินที่คุณต้องการ",
  "Cleanpro Plus credits": "เครดิต Cleanpro Plus",
  "E-wallet or credit/debit cards": "กระเป๋าเงินอิเล็กทรอนิกส์หรือบัตรเครดิต/เดบิต",
  "Go to homepage": "ไปที่หน้าหลัก",
  // backend response or error
  "Admin is unauthorized to login": "ผู้ดูแลระบบไม่ได้รับอนุญาตให้เข้าสู่ระบบ",
  "Internal Error": "ข้อผิดพลาดภายใน",
  "Please enter the correct credentials": "กรุณาป้อนข้อมูลประจำตัวที่ถูกต้อง",
  "Operator not found": "ไม่พบผู้ให้บริการ",
  "Admin not found": "ไม่พบผู้ดูแลระบบ",
  "Distributor not found": "ไม่พบผู้จัดจำหน่าย",
  "Client not found": "ไม่พบลูกค้า",
  "Outlet not found": "ไม่พบสาขา",
  "Token Package not found": "ไม่พบแพ็คเกจโทเคน",
  "Top up history not found": "ไม่พบประวัติการเติมเงิน",
  "Voucher not found": "ไม่พบคูปอง",
  "Faq not found": "ไม่พบคำถามที่พบบ่อย",
  "Faq Group not found": "ไม่พบกลุ่มคำถามที่พบบ่อย",
  "News not found": "ไม่พบข่าว",
  "Transaction not found": "ไม่พบธุรกรรม",
  "Please Login Again": "กรุณาเข้าสู่ระบบอีกครั้ง",
  "Decoding Error": "ข้อผิดพลาดในการถอดรหัส",
  "Some error occured, please contact the dev team if this error continues.": "เกิดข้อผิดพลาดบางอย่าง กรุณาติดต่อทีมนักพัฒนาหากข้อผิดพลาดนี้ยังคงเกิดขึ้น",
  "Please fill in all the required fields": "กรุณากรอกข้อมูลในช่องที่จำเป็นทั้งหมด",
  "Only success transaction can be refund": "เฉพาะธุรกรรมที่สำเร็จเท่านั้นที่สามารถขอคืนเงินได้",
  "Only token payment method can be refund": "เฉพาะวิธีการชำระเงินด้วยโทเคนเท่านั้นที่สามารถขอคืนเงินได้",
  "The discount code has been used, please change to other code": "รหัสส่วนลดนี้ถูกใช้ไปแล้ว กรุณาเปลี่ยนเป็นรหัสอื่น",
  "Cannot use ewallet in sandbox": "ไม่สามารถใช้กระเป๋าเงินอิเล็กทรอนิกส์ในโหมดทดลองได้",
  "Please close the door to proceed your payment": "กรุณาปิดประตูเพื่อดำเนินการชำระเงินของคุณ",
  "Machine is Out of Service": "เครื่องไม่พร้อมให้บริการ",
  "Machine is currently running, please try again later": "เครื่องกำลังทำงานอยู่ กรุณาลองอีกครั้งในภายหลัง",
  "Machine is currently offline, please try again later": "เครื่องกำลังออฟไลน์อยู่ กรุณาลองอีกครั้งในภายหลัง",
  "Outlet not participating in Loyalty program": "สาขาไม่ได้เข้าร่วมโปรแกรมสมาชิก",
  "Please log in to make the payment": "กรุณาเข้าสู่ระบบเพื่อทำการชำระเงิน",
  "Package not found, please refresh and try again": "ไม่พบแพ็คเกจ กรุณารีเฟรชและลองอีกครั้ง",
  "You have reached the maximum number of purchase": "คุณได้ถึงจำนวนการซื้อสูงสุดแล้ว",
  "Store not found, please try again later": "ไม่พบร้าน กรุณาลองอีกครั้งในภายหลัง",
  "Incorrect Password": "รหัสผ่านไม่ถูกต้อง",
  "Token already top up": "เติมโทเคนแล้ว",
  "Payment failed": "การชำระเงินล้มเหลว",
  "Transaction Failure": "ธุรกรรมล้มเหลว",
  "Transaction Refunded": "คืนเงินธุรกรรมแล้ว",
  "Transaction Pending": "ธุรกรรมกำลังดำเนินการ",
  "Please enter your mobile number": "กรุณาป้อนหมายเลขโทรศัพท์มือถือของคุณ",
  "User is unauthorized to register": "ผู้ใช้ไม่ได้รับอนุญาตให้ลงทะเบียน",
  "Please proceed to sign up": "กรุณาดำเนินการลงทะเบียน",
  "OTP expired, please request a new OTP": "OTP หมดอายุ กรุณาขอ OTP ใหม่",
  "Incorrect verification code": "รหัสยืนยันไม่ถูกต้อง",
  "This referrer ID is not valid": "รหัสผู้แนะนำนี้ไม่ถูกต้อง",
  "This referrer ID is different operator": "รหัสผู้แนะนำนี้เป็นผู้ให้บริการที่แตกต่างกัน",
  "This referrer ID is exceed the limit": "รหัสผู้แนะนำนี้เกินขีดจำกัด",
  "User is unauthorized to login": "ผู้ใช้ไม่ได้รับอนุญาตให้เข้าสู่ระบบ",
  "Click here to register": "คลิกที่นี่เพื่อลงทะเบียน",
  "User is unauthorized to login. Please contact the admin": "ผู้ใช้ไม่ได้รับอนุญาตให้เข้าสู่ระบบ กรุณาติดต่อผู้ดูแลระบบ",
  "User is unauthorized": "ผู้ใช้ไม่ได้รับอนุญาต",
  "This outlet does not participate in the Loyalty Program": "สาขานี้ไม่ได้เข้าร่วมโปรแกรมสมาชิก",
  "This outlet is not belongs to the operator": "สาขานี้ไม่ได้เป็นของผู้ให้บริการ",
  "Processing request": "กำลังประมวลผลคำขอ",
  "This user is not verified": "ผู้ใช้นี้ยังไม่ได้รับการยืนยัน",
  "Insufficient token to make payment": "โทเคนไม่เพียงพอที่จะทำการชำระเงิน",
  "This voucher is not available for use": "คูปองนี้ไม่สามารถใช้งานได้",
  "This voucher is belongs to other user": "คูปองนี้เป็นของผู้ใช้อื่น",
  "Invalid Discount Code": "รหัสส่วนลดไม่ถูกต้อง",
  "This discount code cannot use in this outlet": "ไม่สามารถใช้รหัสส่วนลดนี้ในสาขานี้ได้",
  "This discount code was expired": "รหัสส่วนลดนี้หมดอายุแล้ว",
  "This discount code cannot use in washer machine transaction": "ไม่สามารถใช้รหัสส่วนลดนี้ในธุรกรรมเครื่องซักผ้าได้",
  "This discount code cannot use in dryer machine transaction": "ไม่สามารถใช้รหัสส่วนลดนี้ในธุรกรรมเครื่องอบผ้าได้",
  "This discount code was fully redeemed today, try again tomorrow": "รหัสส่วนลดนี้ถูกใช้หมดแล้วในวันนี้ ลองอีกครั้งในวันพรุ่งนี้",
  "You have fully redeemed this discount code": "คุณได้ใช้รหัสส่วนลดนี้เต็มจำนวนแล้ว",
  "This discount code have been redeemed": "รหัสส่วนลดนี้ถูกใช้ไปแล้ว",
  "This discount code is not applicable": "รหัสส่วนลดนี้ไม่สามารถใช้ได้",
  "Insufficient stamp": "แสตมป์ไม่เพียงพอ",
  "Please provide at least one voucher setting": "กรุณาระบุการตั้งค่าคูปองอย่างน้อยหนึ่งรายการ",
  "Previous batch are still generating, please try again later": "ชุดก่อนหน้ายังอยู่ในระหว่างการสร้าง กรุณาลองอีกครั้งในภายหลัง",
  "Number of voucher code should be more than 0": "จำนวนรหัสคูปองควรมากกว่า 0",
  "Number of voucher code should be less than 50000": "จำนวนรหัสคูปองควรน้อยกว่า 50000",
  "Number of discount code should be more than 0": "จำนวนรหัสส่วนลดควรมากกว่า 0",
  "Number of discount code should be less than 50000": "จำนวนรหัสส่วนลดควรน้อยกว่า 50000",
  "No Item": "ไม่มีรายการ",
  "Please add item": "กรุณาเพิ่มรายการ",
  "Staff not found": "ไม่พบพนักงาน",
  "This task already completed": "งานนี้เสร็จสมบูรณ์แล้ว",
  "Please complete the wash task first": "กรุณาทำงานซักให้เสร็จก่อน",
  "Please complete the dry task first": "กรุณาทำงานอบให้เสร็จก่อน",
  "Please select at least one outlet": "Please select at least one outlet", // new translation
}

// function findMissingKeys(oldVersion, newVersion) {
//   const missingKeys = {};
//   Object.keys(newVersion).forEach(key => {
//     if (!oldVersion.hasOwnProperty(key)) {
//       missingKeys[key] = newVersion[key];
//     }
//   });
//   return missingKeys;
// }

// const missingKeys = findMissingKeys(translationTh, translationEn);
// console.log(missingKeys);

const resources = {
  en: {
    translation: translationEn,
  },
  ms: {
    translation: translationMs,
  },
  zh: {
    translation: translationZh,
  },
  zhtw: {
    translation: translationZhtw
  },
  th: {
    translation: translationTh
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng:
    localStorage.getItem("language") ||
    window.navigator.language.substring(0, 2),
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
